import { useDispatch, useSelector } from "react-redux"
import { useEffect, useMemo } from "react"
import { useTranslate } from "../../../utils/hooks/useTranslate"
import Accordion from "../../../components/Accordion"
import AccordionPanel from "../../../components/Accordion/AccordionPanel"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"

import {
  OrderFilterTypeEnum,
  SortOrderEnum,
  selectOrdersFilter,
  selectOrdersMonthAccordion,
  selectOrdersSortAccordion,
  selectOrdersYearsAccordion,
  setMonthAccordionActiveIndex,
  setOrderFilter,
  setSortAccordionActiveIndex,
  setYearAccordionActiveIndex
} from "../orders.controller"

import styles from "./index.module.scss"

const OrdersFilters = () => {
  const dispatch = useDispatch()
  const { t } = useTranslate()
  const { isDesktop } = useWindowSize()

  const yearAccordion = useSelector(selectOrdersYearsAccordion)
  const monthAccordion = useSelector(selectOrdersMonthAccordion)
  const sortAccordion = useSelector(selectOrdersSortAccordion)

  const selectedFilters = useSelector(selectOrdersFilter)

  const yearSelectedLabel = useMemo(() => {
    if (!selectedFilters?.year) return t["year-accordion-label"]
    if (selectedFilters.year.id === -1) return t[selectedFilters?.year.label]

    return selectedFilters.year.label
  }, [yearAccordion, t])

  return (
    <div className={styles.box}>
      <div className={styles.year}>
        <Accordion
          size={isDesktop ? "md" : "sm"}
          activeIndex={yearAccordion.activeIndex}
          collapseOnBlur
          onOpen={(i) => {
            dispatch(setYearAccordionActiveIndex(i))
          }}
          list={[
            {
              title: yearSelectedLabel,
              content: (
                <AccordionPanel
                  hover
                  background='#ffffff'
                  size={isDesktop ? "md" : "sm-light"}
                  data={yearAccordion?.options?.map((item, index) => {
                    return (
                      <button
                        type='button'
                        key={index}
                        onClick={() => {
                          dispatch(setYearAccordionActiveIndex(-1))
                          dispatch(setOrderFilter({ type: OrderFilterTypeEnum.YEAR, data: item }))
                        }}
                      >
                        {item.id === -1 ? t[item.label] : item.label}
                      </button>
                    )
                  })}
                />
              )
            }
          ]}
          classNames={styles["accordion-regular"]}
        />
      </div>
      <div className={styles.month}>
        <Accordion
          size={isDesktop ? "md" : "sm"}
          activeIndex={monthAccordion.activeIndex}
          collapseOnBlur
          disabled={!selectedFilters?.year}
          onOpen={(i) => {
            dispatch(setMonthAccordionActiveIndex(i))
          }}
          list={[
            {
              title: (
                <span
                  dangerouslySetInnerHTML={{
                    __html: t[selectedFilters?.month?.label] || t["month-accordion-label"]
                  }}
                />
              ),
              content: (
                <AccordionPanel
                  hover
                  background='#ffffff'
                  size={isDesktop ? "md" : "sm-light"}
                  data={monthAccordion?.options?.map((item, index) => {
                    return (
                      <button
                        type='button'
                        key={index}
                        onClick={() => {
                          dispatch(setMonthAccordionActiveIndex(-1))
                          dispatch(setOrderFilter({ type: OrderFilterTypeEnum.MONTH, data: item }))
                        }}
                        dangerouslySetInnerHTML={{
                          __html: t[item.label]
                        }}
                      />
                    )
                  })}
                />
              )
            }
          ]}
          classNames={styles["accordion-regular"]}
        />
      </div>
      <div className={styles.sort}>
        <Accordion
          size='sm'
          activeIndex={sortAccordion.activeIndex}
          collapseOnBlur
          onOpen={(i) => {
            dispatch(setSortAccordionActiveIndex(i))
          }}
          list={[
            {
              title: (
                <span
                  dangerouslySetInnerHTML={{
                    __html: t[selectedFilters?.sort?.label] || t["sort-accordion-label"]
                  }}
                />
              ),
              content: (
                <AccordionPanel
                  hover
                  background='#ffffff'
                  size={isDesktop ? "md" : "sm-light"}
                  data={sortAccordion?.options?.map((item, index) => {
                    return (
                      <button
                        type='button'
                        key={index}
                        onClick={() => {
                          dispatch(setSortAccordionActiveIndex(-1))
                          dispatch(setOrderFilter({ type: OrderFilterTypeEnum.SORT, data: { ...item, sort: SortOrderEnum.ASC } }))
                        }}
                        dangerouslySetInnerHTML={{ __html: t[item.label] }}
                      />
                    )
                  })}
                />
              )
            }
          ]}
          classNames={styles["accordion-regular"]}
        />
      </div>
    </div>
  )
}

export default OrdersFilters
