export const breadcrumbsApi = [
  {
    url: "/cart",
    label: "Warenkorb"
  }
]

export const orderDataApi = {
  id: "1",
  number: "OO-10588",
  delivery: "",
  desiredDeliveryDate: "",
  paymentMethod: "Vereinbarte Konditionen",
  link: "",
  comment: "",
  paymentAddress: {
    id: 2,
    SysNumberKundeAnschrift: 3742,
    PosNumber: 1,
    Bezeichnung1: "TITANIA KOREA LTD",
    Bezeichnung2: null,
    Bezeichnung3: "6F, BANGWOO BUILDING",
    Strasse: "636-2 SHINSA-DONG",
    Telefon: "25 08 08 38",
    Telefax: "25 08 07 67",
    "E-Mail": null,
    ISOCode2KonstSprache: null,
    SysNumberKundeAnschrift_Webshop: 0,
    sorter: 0,
    visible: 1,
    KI_WebshopIDKunde: "1A9D4867-6AD1-4BA7-A566-91EBD680D212",
    ISOCode3KonstLand: null,
    EMail: null,
    PLZOrt: null,
    Ort: null
  },
  deliveryAddress: {
    id: 1,
    SysNumberKundeAnschrift_Webshop: -1,
    Bezeichnung1: "Test Bezeichnung1",
    Bezeichnung2: null,
    Bezeichnung3: null,
    Strasse: "test Strasse",
    Telefon: "test Telefon",
    Telefax: null,
    "E-Mail": "dm@emit.digital",
    ISOCode3KonstLand: "DEU",
    sorter: 0,
    visible: 1,
    EMail: "dm@emit.digital",
    PLZOrt: null,
    Ort: null,
    KI_WebshopIDKunde: null
  },
  deliveryDate: "",
  summ: "2.356€",
  name: "",
  mail: "",
  downloadLink: "/",
  products: [
    {
      id: "1",
      image: "/img/products/p-1.png",
      name: "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel...",
      count: 1,
      ve: 12,
      gk: 80,
      number: "1013 Set",
      summ: 3.99,
      totalSumm: 3.99,
      currency: "EUR"
    },
    {
      id: "12",
      image: "/img/products/p-1.png",
      name: "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel...",
      count: 1,
      ve: 12,
      gk: 80,
      number: "1013 Set",
      summ: 3.99,
      totalSumm: 3.99,
      currency: "EUR"
    },
    {
      id: "13",
      image: "/img/products/p-1.png",
      name: "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel...",
      count: 1,
      ve: 12,
      gk: 80,
      number: "1013 Set",
      summ: 3.99,
      totalSumm: 3.99,
      currency: "EUR"
    },
    {
      id: "14",
      image: "/img/products/p-1.png",
      name: "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel...",
      count: 1,
      ve: 12,
      gk: 80,
      number: "1013 Set",
      summ: 3.99,
      totalSumm: 3.99,
      currency: "EUR"
    },
    {
      id: "15",
      image: "/img/products/p-1.png",
      name: "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel...",
      count: 1,
      ve: 12,
      gk: 80,
      number: "1013 Set",
      summ: 3.99,
      totalSumm: 3.99,
      currency: "EUR"
    },
    {
      id: "16",
      image: "/img/products/p-1.png",
      name: "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel...",
      count: 1,
      ve: 12,
      gk: 80,
      number: "1013 Set",
      summ: 3.99,
      totalSumm: 3.99,
      currency: "EUR"
    }
  ],
  total: 80.52,
  currency: "EUR",
  methodOptions: [
    {
      id: 1,
      label: "Standard",
      value: "1"
    },
    {
      id: 2,
      label: "Type 1",
      value: "2"
    },
    {
      id: 3,
      label: "Type 2",
      value: "3"
    }
  ],
  addressData: [
    {
      id: "1",
      title: "Musterfirma Ltd. 1",
      text: "Hauptsitz Fortunastraße 10, Wülfrath, Nordrhein-Westfalen 42489, Deutschland",
      checked: false
    },
    {
      id: "2",
      title: "Musterfirma Ltd. 2",
      text: "TITANIA® FABRIK GMBH Hauptsitz Fortunastraße 10, Wülfrath, Nordrhein-Westfalen 42489, Deutschland",
      checked: true
    }
  ]
}

export const orderToCopyDataApi = {
  id: "1",
  number: "OO-10588",
  delivery: "Luftfracht",
  desiredDeliveryDate: "01.06.2024",
  paymentMethod: "Vereinbarte Konditionen",
  link: "Lorem Ipsum amet",
  comment:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. ",
  paymentAddress: "TITANIA® FABRIK GMBH Hauptsitz Fortunastraße 10, Wülfrath, Nordrhein-Westfalen 42489, Deutschland",
  deliveryAddress: "TITANIA® FABRIK GMBH Hauptsitz Fortunastraße 10, Wülfrath, Nordrhein-Westfalen 42489, Deutschland",
  deliveryDate: "12.01.24",
  summ: "2.356€",
  name: "Max Mustermann",
  mail: "j.hollefeld@stepstoft.de",
  downloadLink: "/",
  products: [
    {
      id: "1",
      image: "/img/products/p-1.png",
      name: "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel...",
      count: 10,
      ve: 12,
      gk: 80,
      number: "1013 Set",
      summ: 3.99,
      totalSumm: 3.99,
      currency: "EUR"
    },
    {
      id: "12",
      image: "/img/products/p-1.png",
      name: "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel...",
      count: 122,
      ve: 12,
      gk: 80,
      number: "1013 Set",
      summ: 3.99,
      totalSumm: 3.99,
      currency: "EUR"
    },
    {
      id: "13",
      image: "/img/products/p-1.png",
      name: "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel...",
      count: 10,
      ve: 12,
      gk: 80,
      number: "1013 Set",
      summ: 3.99,
      totalSumm: 3.99,
      currency: "EUR"
    },
    {
      id: "14",
      image: "/img/products/p-1.png",
      name: "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel...",
      count: 10,
      ve: 12,
      gk: 80,
      number: "1013 Set",
      summ: 3.99,
      totalSumm: 3.99,
      currency: "EUR"
    },
    {
      id: "15",
      image: "/img/products/p-1.png",
      name: "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel...",
      count: 10,
      ve: 12,
      gk: 80,
      number: "1013 Set",
      summ: 3.99,
      totalSumm: 3.99,
      currency: "EUR"
    },
    {
      id: "16",
      image: "/img/products/p-1.png",
      name: "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel...",
      count: 10,
      ve: 12,
      gk: 80,
      number: "1013 Set",
      summ: 3.99,
      totalSumm: 3.99,
      currency: "EUR"
    }
  ],
  total: 80.52,
  currency: "EUR",
  methodOptions: [
    {
      id: 1,
      label: "Standard",
      value: "1"
    },
    {
      id: 2,
      label: "Type 1",
      value: "2"
    },
    {
      id: 3,
      label: "Type 2",
      value: "3"
    }
  ],
  addressData: [
    {
      id: "1",
      title: "Musterfirma Ltd. 1",
      text: "Hauptsitz Fortunastraße 10, Wülfrath, Nordrhein-Westfalen 42489, Deutschland",
      checked: false
    },
    {
      id: "2",
      title: "Musterfirma Ltd. 2",
      text: "TITANIA® FABRIK GMBH Hauptsitz Fortunastraße 10, Wülfrath, Nordrhein-Westfalen 42489, Deutschland",
      checked: true
    }
  ]
}
