import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import Breadcrumbs from "../../components/Breadcrumbs"
import Container from "../../modules/Container"
import Layout from "../../modules/Layout"
import Tabs from "../../components/Tabs/index"
import ContactCard from "../../components/Cards/ContactCard"
import { useTranslate } from "../../utils/hooks/useTranslate"
import UserInformationTab from "./Tabs/UserInformationTab"
import CompanyTab from "./Tabs/CompanyTab"

import {
  disposeProfilePage,
  getInvoiceAddress,
  getManagerInfo,
  getProfileUserData,
  getShippingAddress,
  selectManagerInfo
} from "./profile.controller"

import styles from "./index.module.scss"

const Profile = () => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const profilePesonal = useSelector(selectManagerInfo)

  const breadcrumbs = useMemo(
    () => [
      {
        url: "/",
        label: t["navigation-home"]
      },
      {
        url: "/profile",
        label: t["navigation-declaration"]
      }
    ],
    [t]
  )

  const dataTabs = useMemo(() => {
    return [
      {
        title: <span dangerouslySetInnerHTML={{ __html: t["profile-content-personal"] }} />,
        children: <UserInformationTab />
      },
      {
        title: <span dangerouslySetInnerHTML={{ __html: t["profile-content-company"] }} />,
        children: <CompanyTab />
      }
    ]
  }, [t])

  useEffect(() => {
    document.title = "Titania - Profil"

    dispatch(getProfileUserData())
    dispatch(getManagerInfo())
    dispatch(getShippingAddress())
    dispatch(getInvoiceAddress())

    return () => dispatch(disposeProfilePage())
  }, [])

  return (
    <Layout>
      <div className={styles.box}>
        <Container className={styles.box__inner}>
          <div className={styles.box__header}>
            <Breadcrumbs path={breadcrumbs} className={styles.breadcrumbs} />
          </div>
          <div className={styles.box__content}>
            <div className={styles.box__profile}>
              <ContactCard {...profilePesonal} vertical={true} />
            </div>
            <div className={styles.box__information}>
              <Tabs data={dataTabs} billingAddress />
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default Profile
