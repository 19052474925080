import { useEffect, useState } from "react"

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const [isSmallTablet, setIsSmallTablet] = useState(window.innerWidth <= 991 && window.innerWidth >= 768)
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1024 && window.innerWidth >= 768)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024)

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
      // eslint-disable-next-line no-restricted-globals
      setIsMobile(window.innerWidth < 768)
      setIsSmallTablet(window.innerWidth <= 991 && window.innerWidth >= 768)
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth >= 768)
      setIsDesktop(window.innerWidth > 1024)
    }

    handleResize()

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return { windowSize, isMobile, isTablet, isDesktop, isSmallTablet }
}
