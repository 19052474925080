import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams, useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet"
import DynamicComponents from "../../../modules/Dynamic"
import Layout from "../../../modules/Layout"
import { PagesTypeEnum, disposePage, getPageData, selectPageData } from "../../../store/slice/page"
import { loginAuthCode } from "../../../store/slice/auth"
import { selectCurrentLocale } from "../../../store/slice/translations"

import styles from "./index.module.scss"

const Login = () => {
  const dispatch = useDispatch()
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const data = useSelector(selectPageData)
  const currentLocale = useSelector(selectCurrentLocale)
  const [searchParams] = useSearchParams()
  const token = searchParams.get("token")

  useEffect(() => {
    // TODO: Preview feature
    if (token) {
      localStorage.setItem("auth", token)
      window.location = "/"
    }
  }, [token])

  useEffect(() => {
    dispatch(getPageData(PagesTypeEnum.LOGIN))
  }, [currentLocale])

  useEffect(() => {
    document.title = data?.meta_title || "Titania - Login"

    if (params.get("magic_link")) {
      dispatch(loginAuthCode({ key: params.get("magic_link") }))
        .unwrap()
        .then(() => {
          navigate("/")
        })
        .catch((er) => {
          navigate("/404")
        })
    }

    return () => dispatch(disposePage())
  }, [])

  return (
    <Layout>
      <Helmet>
        <title>{data?.meta_title}</title>
        <meta name='description' content={data?.meta_description} />
        <meta property='og:title' content={data?.meta_title} />
        <meta property='og:description' content={data?.og_description} />
        <meta property='og:image' content={data?.og_img} />
      </Helmet>

      <div className={styles.box}>
        <DynamicComponents content={data.content} />
      </div>
    </Layout>
  )
}

export default Login
