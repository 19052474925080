export const responseErrorParser = (data) => {
  if (!data?.data) return ""

  if (data.data.data?.message) {
    return data.data.data.message
  }

  if (!data.data.data?.length) {
    return data.data.status
  }

  if (data.data.data) {
    return data.data.data
  }

  return ""
}
