import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import ChainedBackend from "i18next-chained-backend"
import HttpBackend from "i18next-http-backend"
import resourcesToBackend from "i18next-resources-to-backend"
import { i18nextPlugin } from "translation-check"
import CONFIG from "./config"

i18n
  .use(ChainedBackend)
  .use(i18nextPlugin)
  .use(initReactI18next)
  .init({
    ns: ["common", "translation", "validation", "landing"],
    defaultNS: "translation",
    backend: {
      backends: [
        HttpBackend,
        resourcesToBackend((language, namespace, callback) => {
          import(`../public/locales/${language}/${namespace}.json`)
            .then(({ default: resources }) => {
              callback(null, resources)
            })
            .catch((error) => {
              callback(error, null)
            })
        })
      ],
      backendOptions: [
        {
          loadPath: "/locales/{{lng}}/{{ns}}.json"
        }
      ]
    },
    debug: CONFIG.MODE !== "production",
    lng: "de",
    fallbackLng: ["en", "de"],
    preload: ["en"],
    supportedLngs: ["en", "de"],
    saveMissing: true,
    interpolation: {
      escapeValue: false
    },
    returnNull: false,
    react: {
      useSuspense: true
    }
  })
  .then(() => {})

export default i18n
