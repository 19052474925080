// Input format type 'DD.MM.YYYY'
export const dateFormatReq = (date = "", mask = ".") => {
  if (!date) return null

  const splitted = date.split(mask)

  return `${splitted[2]}-${splitted[1]}-${splitted[0]}`
}

export const dateFormatInput = (date = "", mask = ".") => {
  if (!date) return date

  const splitted = date.split(mask)

  let day = splitted[0] || "01"
  let month = splitted[1] || "01"
  let year = splitted[2] || new Date().getFullYear()

  if (Number(day) === 0 || Number(day) > 31) {
    day = "01"
  } else if (Number(day) < 10) {
    day = `0${Number(day)}`
  }

  if (Number(month) === 0 || Number(month) > 12) {
    month = 12
  } else if (Number(month) < 10) {
    month = `0${Number(month)}`
  }

  if (Number(year) < 2000) {
    year = 2000 + Number(year)
  }

  return `${day}.${month}.${year}`
}
