import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import BurgerMenu, { BurgerMenuDirectionEnum } from "../../../modules/Burger/BurgerMenu"
import { useTranslate } from "../../../utils/hooks/useTranslate"
import AddToCart from "../../../modules/AddToCart"
import Navigation from "../Navigation"

import {
  ProductFilterKeyEnum,
  selectProductsFiltrersBurgerMenu,
  selectProductsSortBurgerMenu,
  selectProductsSortOptions,
  toggleProductsFiltersBurger,
  toggleProductsSortBurger
} from "../products.controller"

import CloseIcon from "../../../assets/icons/CloseIcon"

import styles from "./index.module.scss"

const FilterSubmenu = () => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const mobileFiltersBurger = useSelector(selectProductsFiltrersBurgerMenu)
  const mobileSortBurger = useSelector(selectProductsSortBurgerMenu)
  const sortOptions = useSelector(selectProductsSortOptions)

  return (
    <>
      <BurgerMenu
        isOpen={mobileFiltersBurger.isOpen}
        onClose={() => {
          dispatch(toggleProductsFiltersBurger(false))
        }}
        content={
          <div className={styles["mobile-filters"]}>
            <div className={styles["mobile-filters__header"]}>
              <button
                className={styles["mobile-filters__close"]}
                type='button'
                onClick={() => dispatch(toggleProductsFiltersBurger(false))}
              >
                <CloseIcon width='40px' height='40px' circleColor='#E2E2E2' />
              </button>
            </div>
            <div className={styles["mobile-filters__body"]}>
              <AddToCart
                burgerType
                buttonSize='md'
                title={`${t["quick-buy-title"]}`}
                subtitle={`${t["quick-buy-title-2"]}`}
                buttonLabel={t["button-add-to-cart"]}
                namePlaceholder={t["quick-buy-placeholder-article"]}
                stkPlaceholder={t["quick-buyp-laceholder-stk"]}
              />
              <Navigation />
            </div>
          </div>
        }
      />
      <BurgerMenu
        isOpen={mobileSortBurger.isOpen}
        onClose={() => {
          dispatch(toggleProductsSortBurger(false))
        }}
        direction={BurgerMenuDirectionEnum.LEFT}
        content={
          <div className={styles["mobile-filters"]}>
            <div className={styles["mobile-filters__header"]}>
              <button
                className={styles["mobile-filters__close"]}
                type='button'
                onClick={() => dispatch(toggleProductsSortBurger(false))}
              >
                <CloseIcon width='40px' height='40px' circleColor='#E2E2E2' />
              </button>
            </div>
            <div className={styles["mobile-filters__body-sort"]}>
              {sortOptions?.map((item, index) => {
                return (
                  <button
                    type='button'
                    key={index}
                    onClick={() => {
                      const params = new URLSearchParams(window.location.search)
                      params.set(ProductFilterKeyEnum.SORT, item.id || "")
                      params.set(ProductFilterKeyEnum.PAGE, 1)
                      dispatch(toggleProductsSortBurger(false))
                      navigate(
                        {
                          search: params.toString()
                        },
                        {
                          replace: true
                        }
                      )
                    }}
                    className={styles["mobile-filters__sort-btn"]}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: item.name
                      }}
                    />
                  </button>
                )
              })}
            </div>
          </div>
        }
      />
    </>
  )
}

FilterSubmenu.propTypes = {}

export default FilterSubmenu
