import { useSelector } from "react-redux"
import { selectCurrentDictionary, selectCurrentLocale } from "../../store/slice/translations"

export const useTranslate = () => {
  const locale = useSelector(selectCurrentLocale)
  const t = useSelector(selectCurrentDictionary)

  return {
    t,
    locale
  }
}
