/* eslint-disable indent */
import { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import Button from "../../components/Button"
import Input from "../../components/Input"
import Modal from "../../components/Modal"
import { pxToRem } from "../../utils/px-to-rem"
import { useWindowSize } from "../../utils/hooks/useWindowSize"
import { useTranslate } from "../../utils/hooks/useTranslate"
import { NOOP } from "../../constants/noop"
import { responseErrorParser } from "../../utils/response-error-parser"
import { requestAuthData, selectIsRequestAuthDataLoading } from "../../store/slice/auth"
import TextAreaInput from "../../components/TextAreaInput"

import styles from "./index.module.scss"

const formSchemaCompany = (t) => () =>
  Yup.object().shape({
    company: Yup.string().required(t["form-required-field"]),
    firstName: Yup.string().required(t["form-required-field"]),
    lastName: Yup.string().required(t["form-required-field"]),
    mail: Yup.string().email(t["form-invalid-email"]).required(t["form-required-field"]),
    comment: Yup.string().nullable()
  })

const RequestAuthModal = ({ isModalOpen, closeModal, onSubmit = NOOP }) => {
  const { t } = useTranslate()
  const { isDesktop } = useWindowSize()
  const dispatch = useDispatch()

  const [message, setMessage] = useState("")
  const loading = useSelector(selectIsRequestAuthDataLoading)

  const formik = useFormik({
    initialValues: {
      company: "",
      firstName: "",
      lastName: "",
      mail: "",
      comment: ""
    },
    validationSchema: formSchemaCompany(t)(),
    validateOnBlur: true,
    onSubmit: async (payload) => {
      dispatch(requestAuthData(payload))
        .unwrap()
        .then((res) => {
          setMessage(t["request-manager-modal-success"])
          onSubmit()
        })
        .catch((err) => {
          setMessage(responseErrorParser(err))
        })
    }
  })

  const clearState = () => {
    formik.setValues({
      company: "",
      firstName: "",
      lastName: "",
      mail: "",
      comment: ""
    })
    formik.setErrors({})
    formik.setTouched({})
    setMessage("")
  }

  useEffect(() => {
    if (!isModalOpen) {
      clearState()
    }
  }, [isModalOpen])

  return (
    <Modal
      modalIsOpen={isModalOpen}
      closeModal={closeModal}
      title={
        <span
          dangerouslySetInnerHTML={{
            __html: t["request-manager-modal-title"]
          }}
        />
      }
      cancelHandler={closeModal}
      style={{
        content: isDesktop
          ? {
              padding: `${pxToRem(25)}rem`,
              width: `${pxToRem(550)}rem`,
              height: "min-height"
            }
          : {
              padding: `${pxToRem(25)}rem ${pxToRem(15)}rem`,
              height: "min-height",
              maxHeight: "90%"
            }
      }}
    >
      <div className={styles.modal}>
        {!message && (
          <div className={styles.inputs}>
            <Input
              label={<span dangerouslySetInnerHTML={{ __html: t["profile-field-company"] }} />}
              value={formik.values?.company}
              errors={!!formik.errors?.company && !!formik.touched?.company}
              onChange={(e, val) => formik.setFieldValue("company", val)}
            />
            <Input
              label={<span dangerouslySetInnerHTML={{ __html: t["profile-field-firstName"] }} />}
              value={formik.values?.firstName}
              errors={!!formik.errors?.firstName && !!formik.touched?.firstName}
              onChange={(e, val) => formik.setFieldValue("firstName", val)}
            />
            <Input
              label={<span dangerouslySetInnerHTML={{ __html: t["profile-field-lastName"] }} />}
              value={formik.values?.lastName}
              errors={!!formik.errors?.lastName && !!formik.touched?.lastName}
              onChange={(e, val) => formik.setFieldValue("lastName", val)}
            />
            <Input
              label={<span dangerouslySetInnerHTML={{ __html: t["profile-field-email"] }} />}
              value={formik.values?.mail}
              errors={!!formik.errors?.mail && !!formik.touched?.mail}
              onChange={(e, val) => formik.setFieldValue("mail", val)}
            />
            <TextAreaInput
              placeholder={t["request-manager-modal-comment-placeholder"]}
              value={formik.values?.comment}
              errors={!!formik.errors?.comment && !!formik.touched?.comment}
              onChange={(e) => formik?.setFieldValue("comment", e.target.value)}
              onFocus={() => formik?.setFieldTouched("comment", false)}
              onBlur={() => formik?.setFieldTouched("comment", true)}
            />
            <div>
              <Button loading={loading} onClick={formik.handleSubmit} text={t["button-send"]} />
            </div>
          </div>
        )}
        {message && <p dangerouslySetInnerHTML={{ __html: message }} />}
      </div>
    </Modal>
  )
}

RequestAuthModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func
}

export default RequestAuthModal
