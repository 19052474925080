import React, { useEffect, useState } from "react"
import propTypes from "prop-types"
import { NOOP } from "../../constants/noop"

const FallbackImg = ({ src, alt, fallback, setIsFallback = NOOP, ...other }) => {
  const [fallbacSrc, setFallbackSrc] = useState(src || fallback || "null")

  useEffect(() => {
    setFallbackSrc(src || fallback || "null")
    setIsFallback(!src)
  }, [src, fallback])

  return (
    <img
      {...other}
      src={fallbacSrc}
      alt={alt}
      onError={() => {
        setFallbackSrc(fallback || "null")
        setIsFallback(true)
      }}
    />
  )
}

FallbackImg.propTypes = {
  src: propTypes.string.isRequired,
  fallback: propTypes.string.isRequired,
  alt: propTypes.string.isRequired,
  setIsFallback: propTypes.func
}

export default FallbackImg
