/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getProductsReq, getSubCategories, getBrands, getProductSortOptionsReq, getProductBannersReq } from "../../api/product"
import { productResponseMapper } from "../../utils/product-response-mapper"
import { bannerResponseMapper } from "./utils/banner-response-mapper"

export const ListViewEnum = {
  TABLE: "table",
  LIST: "list"
}

export const ProductFilterKeyEnum = {
  BRAND: "brand",
  SORT: "sorting",
  SUB_CATEGORIES: "subCategories",
  PAGE: "page"
}

export const BrandsEnum = {
  TITANIA: "Titania",
  NIYOK: "Niyok",
  BERRYWELL: "Berrywell",
  PRIVATE_LABEL: "Private Label"
}

export const PRODUCT_ALL_FILTER_OPTION = {
  id: "all",
  name: "all"
}

const initialState = {
  products: [],
  meta: {
    all_page: 1,
    current_page: 1,
    default_view: null
  },

  brandsOptions: [],
  categoriesOptions: [],
  sortOptions: [],

  banners: [],
  isBannersVisible: true,

  isBrandsOptionsLoading: false,
  isCategoryOptionsLoading: true,
  isSortOptionsLoading: false,
  isProductListLoading: false,
  isProductListLoaded: false,
  isBannersLoading: false,

  sidebarVisible: true,

  filters: {
    page: 1,
    isLoadMore: false
  },
  isFiltersFirstInited: false,

  view: ListViewEnum.LIST,

  sortAccordion: {
    activeIndex: -1
  },
  brandAccordion: {
    activeIndex: -1
  },
  categoryAccordeon: {
    activeIndex: -1
  },
  mobileFiltersBurger: {
    isOpen: false
  },
  mobileSortBurger: {
    isOpen: false
  }
}

export const getDataBrands = createAsyncThunk("products/getBrands", async (_, { rejectWithValue }) => {
  try {
    const response = await getBrands()
    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

export const getProducts = createAsyncThunk("products/getProducts", async ({ data, isLoadMore }, { rejectWithValue }) => {
  try {
    const response = await getProductsReq(data)

    if (response.status === 200) {
      if (response.data) {
        return { ...response.data, isLoadMore }
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

export const getCategoriesByBrand = createAsyncThunk("products/getCategoriesByBrand", async (id, { rejectWithValue }) => {
  try {
    const response = await getSubCategories(id)

    if (response.status === 200) {
      if (response.data) {
        const { data } = response.data
        return data?.map((category) => {
          return {
            ...category,
            subcategories: [PRODUCT_ALL_FILTER_OPTION, ...category.subcategories]
          }
        })
      }
      return []
    }
  } catch (error) {
    return rejectWithValue()
  }
})

export const getProductSortOptions = createAsyncThunk("products/getProductSortOptions", async (id, { rejectWithValue }) => {
  try {
    const response = await getProductSortOptionsReq()

    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

export const getProductBanners = createAsyncThunk(
  "products/getProductBanners",
  async ({ brand, category, subcategory }, { rejectWithValue }) => {
    try {
      const response = await getProductBannersReq({ brand, category, subcategory })

      if (response.status === 200) {
        if (response.data) {
          return response.data
        }
        return {}
      }
    } catch (error) {
      return rejectWithValue()
    }
  }
)

/* eslint-disable no-param-reassign */
export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    changeProductPage(state) {
      if (state.isProductListLoading === false && state.meta.all_page > state.meta.current_page) {
        state.isProductListLoading = true
        state.filters = {
          ...state.filters,
          page: state.meta.current_page + 1,
          isLoadMore: true
        }
      }
    },
    setProductsPageFilter(state, action) {
      state.filters = {
        ...state.filters,
        page: action.payload.page || 1,
        isLoadMore: action.payload.isLoadMore
      }
    },
    setProductFilterFirstInited(state) {
      state.isFiltersFirstInited = true
    },
    toggleListView(state, action) {
      if (action.payload) {
        state.view = action.payload
      } else {
        state.view = state.view === ListViewEnum.LIST ? ListViewEnum.TABLE : ListViewEnum.LIST
      }
    },
    toggleSidebarVisible(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    setProductListLoading(state, action) {
      state.isProductListLoading = action.payload
    },
    setProductsSortAccordionActiveIndex(state, action) {
      state.sortAccordion.activeIndex = action.payload
    },
    setProductsBrandAccordionActiveIndex(state, action) {
      state.brandAccordion.activeIndex = action.payload
    },
    setProductsCategoryAccordionActiveIndex(state, action) {
      state.categoryAccordeon.activeIndex = action.payload
    },
    toggleProductsBannersVisible(state, action) {
      state.isBannersVisible = action.payload
    },
    toggleProductsFiltersBurger(state, action) {
      return {
        ...state,
        mobileFiltersBurger: {
          ...state.mobileFiltersBurger,
          isOpen: action.payload
        }
      }
    },
    toggleProductsSortBurger(state, action) {
      return {
        ...state,
        mobileSortBurger: {
          ...state.mobileSortBurger,
          isOpen: action.payload
        }
      }
    },
    toggleProductFavorite(state, action) {
      return {
        ...state,
        products: state.products.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              favorite: action.payload.favorite
            }
          }
          return item
        })
      }
    },
    disposeProductFilters(state) {
      state.filters = {
        isLoadMore: false,
        page: 1
      }
      state.isFiltersFirstInited = false
      state.sortAccordion = {
        activeIndex: -1
      }
      state.brandAccordion = {
        activeIndex: -1
      }
      state.categoryAccordeon = {
        activeIndex: -1
      }
    },
    disposePage(state) {
      return {
        ...initialState,
        brandsOptions: state.brandsOptions
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.isProductListLoading = true
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.isProductListLoading = false
        state.meta = action.payload.data.meta
        state.isProductListLoaded = true
        if (action.payload.isLoadMore) {
          state.products = [...state.products, ...productResponseMapper(action.payload.data.products)]
        } else {
          state.products = productResponseMapper(action.payload.data.products)
          const defaultView = action.payload.data.meta.default_view
          state.view =
            !defaultView || defaultView === "cell" || window.innerWidth <= 1024 ? ListViewEnum.LIST : ListViewEnum.TABLE
        }
      })
      .addCase(getProducts.rejected, (state) => {
        state.isProductListLoading = false
        state.isProductListLoaded = false
      })
      .addCase(getProductBanners.pending, (state) => {
        state.isBannersLoading = true
      })
      .addCase(getProductBanners.fulfilled, (state, action) => {
        state.banners = bannerResponseMapper(action.payload.data)
        state.isBannersLoading = false
      })
      .addCase(getProductBanners.rejected, (state) => {
        state.isBannersLoading = false
      })
      .addCase(getCategoriesByBrand.pending, (state) => {
        state.isCategoriesLoading = true
      })
      .addCase(getCategoriesByBrand.fulfilled, (state, action) => {
        state.categoriesOptions = action.payload || []
        state.isCategoriesLoading = false
      })
      .addCase(getCategoriesByBrand.rejected, (state) => {
        state.isCategoriesLoading = false
      })
      .addCase(getDataBrands.pending, (state) => {
        state.isBrandsOptionsLoading = true
      })
      .addCase(getDataBrands.fulfilled, (state, action) => {
        const { data } = action.payload

        state.isBrandsOptionsLoading = false
        state.brandsOptions = data
      })
      .addCase(getDataBrands.rejected, (state, action) => {
        state.isBrandsOptionsLoading = false
      })
      // Product sort options
      .addCase(getProductSortOptions.pending, (state) => {
        state.isProductSortOptionsLoading = true
      })
      .addCase(getProductSortOptions.fulfilled, (state, action) => {
        const data = action.payload.data
        state.isProductSortOptionsLoading = false
        state.sortOptions =
          data?.map((item) => {
            return {
              id: item.sort,
              name: item.name
            }
          }) || []
      })
      .addCase(getProductSortOptions.rejected, (state, action) => {
        state.isProductSortOptionsLoading = false
      })
  }
})

// Lists data state
export const selectProductsList = (state) => state.products.products
export const selectProductsBanners = (state) => state.products.banners
export const selectProductsBrands = (state) => state.products.brandsOptions
export const selectProductsSortOptions = (state) => state.products.sortOptions
export const selectProductsCategoriesOptions = (state) => state.products.categoriesOptions
export const selectProductFilterFirstInited = (state) => state.products.isFiltersFirstInited
export const selectProductListLoaded = (state) => state.products.isProductListLoaded
export const selectProductsBannersVisible = (state) => state.products.isBannersVisible
// Selected filters state
export const selectProductsFilters = (state) => state.products.filters
// Views state
export const selectProductsListView = (state) => state.products.view
export const selectProductsSidebarVisible = (state) => state.products.sidebarVisible
// Loaders
export const selectProductsListLoading = (state) => state.products.isProductListLoading
export const selectCategoriesLoading = (state) => state.products.isCategoriesLoading
export const selectProductSortOptionsLoading = (state) => state.products.isProductSortOptionsLoading
// Accordions state
export const selectProductsSortAccordion = (state) => state.products.sortAccordion
export const selectProductsBrandAccordion = (state) => state.products.brandAccordion
export const selectProductsCategoryAccordion = (state) => state.products.categoryAccordeon
// Mobile filters burger menu
export const selectProductsFiltrersBurgerMenu = (state) => state.products.mobileFiltersBurger
export const selectProductsSortBurgerMenu = (state) => state.products.mobileSortBurger

// meta
export const selectProductsMeta = (state) => state.products.meta

export const {
  toggleListView,
  toggleSidebarVisible,
  toggleProductsBannersVisible,
  setProductListLoading,
  toggleProductFavorite,
  toggleProductsFiltersBurger,
  toggleProductsSortBurger,
  setProductsPageFilter,
  setProductFilterFirstInited,
  setProductsSortAccordionActiveIndex,
  setProductsBrandAccordionActiveIndex,
  setProductsCategoryAccordionActiveIndex,
  disposeProductFilters,
  disposePage,
  changeSubCategory,
  changeProductPage
} = productsSlice.actions

export default productsSlice.reducer
