import React, { memo } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { PRODUCT_TYPE_COLOR } from "../../constants/product"
import { useTranslate } from "../../utils/hooks/useTranslate"

import styles from "./index.module.scss"

const Badge = ({ type = "", size = "md", className }) => {
  const { t } = useTranslate()

  if (!type) return null

  return (
    <div
      className={`${cx(styles.box, {
        [styles[size]]: size
      })} ${className}`}
      style={{
        "--badge-color": PRODUCT_TYPE_COLOR[type.toUpperCase()]
      }}
    >
      {t[`product-type-${type.toLowerCase()}`]}
    </div>
  )
}

Badge.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string
}

export default memo(Badge)
