import { useEffect } from "react"

export const useElementFixed = (ref, setFixed, setVisible) => {
  let timeout

  const makeHeaderFixed = () => {
    if (window.innerWidth <= 1024) return

    const scroll = window.scrollY
    const height = ref?.current?.clientHeight
    clearTimeout(timeout)

    if (scroll > height) {
      setFixed(true)
      timeout = setTimeout(() => {
        setVisible(true)
      }, 50)
    }

    if (scroll <= height) {
      setVisible(false)
      timeout = setTimeout(() => {
        setFixed(false)
      }, 0)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", makeHeaderFixed)
    window.addEventListener("resize", makeHeaderFixed)

    return () => {
      window.removeEventListener("scroll", makeHeaderFixed)
      window.removeEventListener("resize", makeHeaderFixed)
    }
  }, [ref])
}
