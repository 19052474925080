/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types"
import cx from "classnames"
import { useMemo } from "react"
import ProductCard from "../../../components/Cards/ProductCard"
import TeaserCard from "../../../components/Cards/TeaserCard"
import { ListViewEnum } from "../favorites.controller"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"

import styles from "./index.module.scss"

const List = ({ listData = [], listView, isFavoritesListLoading }) => {
  const { isMobile, isTablet } = useWindowSize()

  const column = useMemo(() => {
    if (isMobile) return 2
    if (isTablet) return 3
    return 4
  }, [isMobile, isTablet])

  return (
    <div
      className={cx(styles.box, {
        [styles.grid]: listView === ListViewEnum.LIST
      })}
      style={{
        "--grid-template-cols": column
      }}
    >
      {listData.map((item, index) => {
        return item.isTeaser ? (
          listView !== ListViewEnum.TABLE ? (
            <div className={styles.box__item}>
              <TeaserCard key={index} {...item} />
            </div>
          ) : null
        ) : (
          <div className={styles.box__item}>
            <ProductCard key={index} {...item} horizontal={listView === ListViewEnum.TABLE} />
          </div>
        )
      })}
    </div>
  )
}

List.propTypes = {
  listData: PropTypes.any,
  listView: PropTypes.string,
  isFavoritesListLoading: PropTypes.bool
}

export default List
