/* eslint-disable react/no-danger */
import React, { memo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { useDispatch } from "react-redux"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import Container from "../../Container"
import Button from "../../../components/Button"
import { toggleManagerModal } from "../../../store/slice/page"

import "react-tabs/style/react-tabs.css"
import styles from "./index.module.scss"

const ContentBlock1 = ({ data = [] }) => {
  const dispatch = useDispatch()
  const [animation, setAnimation] = useState(false)

  const getButton = (label, url, target, type) => {
    if (type === "submit") {
      return (
        <Button
          text={label}
          onClick={() => {
            dispatch(toggleManagerModal(true))
          }}
        />
      )
    }

    return <Button text={label} href={url} nativeLink openInNewTab={target} />
  }

  return (
    <div className={styles.box}>
      <Container className={styles.box__inner}>
        <Tabs defaultIndex={0} forceRenderTabPanel>
          <TabList className={styles.brands}>
            {data.map((item, index) => {
              return (
                <Tab
                  key={index}
                  onClick={() => {
                    setAnimation(true)
                  }}
                >
                  <img src={item.logo} alt={item.title} />
                </Tab>
              )
            })}
          </TabList>
          {data.map((item, index) => {
            return (
              <TabPanel
                key={index}
                className={cx(styles["tab-panel"], {
                  [styles.animation]: animation
                })}
              >
                <div
                  className={styles.img}
                  style={{
                    "--image-background-color": item.color
                  }}
                >
                  <img src={item.image} alt={item.title} />
                </div>
                <div
                  className={styles.content}
                  style={{
                    "--content-background-color": item.color
                  }}
                >
                  <div className={styles.content__text}>
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: item.title
                      }}
                    />
                    <div
                      className={styles.text}
                      dangerouslySetInnerHTML={{
                        __html: item.description
                      }}
                    />
                  </div>
                  <div className={styles.btn}>
                    {item.button1Label && getButton(item.button1Label, item.button1Url, item.button1NewTab, item.button1Type)}
                    {item.button2Label && getButton(item.button2Label, item.button2Url, item.button2NewTab, item.button2Type)}
                  </div>
                </div>
              </TabPanel>
            )
          })}
        </Tabs>
      </Container>
    </div>
  )
}

ContentBlock1.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
      button1Label: PropTypes.string,
      button1Url: PropTypes.string,
      button2Label: PropTypes.string,
      button2Url: PropTypes.string,
      brand: PropTypes.shape({
        image: PropTypes.string
      })
    })
  )
}

export default memo(ContentBlock1)
