import { useEffect, useMemo } from "react"
import cx from "classnames"
import { useDispatch, useSelector } from "react-redux"
import Container from "../../modules/Container"
import Layout from "../../modules/Layout"
import ProductSlider from "../../components/Sliders/ProductSlider"
import Tag from "../../components/Tag"
import Button from "../../components/Button"
import { HighlightTypeEnum } from "../../components/Cards/HighlightCard"
import CategorySlider from "../../components/Sliders/CategorySlider"
import Quicklinks from "./Quicklinks"
import { useTranslate } from "../../utils/hooks/useTranslate"

import {
  disposePage,
  getDataCategory,
  getViewidProducts,
  selectCategoryTags,
  selectDashboardViewedProducts,
  selectDashboardViewedProductsLoading,
  selectHighlightsTags,
  selectQuickLinksAccorion,
  setCategoryTag,
  setHighlightsTag,
  toggleQuicklinks
} from "./home.controller"
import { getManagerInfo } from "../Profile/profile.controller"

import styles from "./index.module.scss"

const Home = () => {
  const { t } = useTranslate()
  const dispatch = useDispatch()

  const quicklinksAccordion = useSelector(selectQuickLinksAccorion)
  const categoryTags = useSelector(selectCategoryTags)
  const highlightsTags = useSelector(selectHighlightsTags)
  const viewedProducts = useSelector(selectDashboardViewedProducts)
  const viewedProductsLoading = useSelector(selectDashboardViewedProductsLoading)

  const activeCategoryTag = useMemo(() => {
    const tag = categoryTags?.find((item) => item.active)

    return tag || {}
  }, [categoryTags])

  const activeHighlightTag = useMemo(() => {
    const tag = highlightsTags?.find((item) => item.active)

    return tag || {}
  }, [highlightsTags])

  useEffect(() => {
    document.title = "Titania - Home"
    dispatch(getDataCategory())
    dispatch(getManagerInfo())
    dispatch(getViewidProducts())

    return () => dispatch(disposePage())
  }, [])

  return (
    <Layout>
      <div className={styles.box}>
        <div className={cx(styles.quicklinks)}>
          <Quicklinks
            activeCollapseIndex={quicklinksAccordion?.activeIndex}
            collapseIndex={1}
            setActive={(i) => {
              dispatch(toggleQuicklinks(i))
            }}
          />
        </div>
        <div className={styles.row}>
          {!!categoryTags.length && (
            <Container className={styles.box__inner}>
              <div className={styles.title} dangerouslySetInnerHTML={{ __html: t["brands-and-categories-title"] }} />
              <div className={styles.category}>
                <div className={styles.tags}>
                  {categoryTags.map((item) => {
                    return (
                      <Tag
                        key={item.id}
                        id={item.id}
                        name={item.name}
                        active={item.active}
                        onClick={() => {
                          dispatch(setCategoryTag(item.id))
                        }}
                      />
                    )
                  })}
                </div>
              </div>
            </Container>
          )}
          <div className={styles.category__slider}>
            <CategorySlider mobileNavRight brandId={activeCategoryTag.id} data={activeCategoryTag.categories} />
          </div>
          {!!categoryTags.length && (
            <Container>
              <div className={styles.btn}>
                <Button text={t["button-to-shop"]} href={`/products?brand=${activeCategoryTag.id}`} />
              </div>
            </Container>
          )}
        </div>
        <div className={cx(styles.row, styles.highlights)}>
          <Container className={styles.box__inner}>
            <div className={styles.highlights}>
              <div className={styles.title}>{t["highlights-title"]}</div>
              <div className={styles.tags}>
                {highlightsTags?.map((item) => {
                  return (
                    <Tag
                      key={item.id}
                      id={item.id}
                      name={item.name}
                      active={item.active}
                      onClick={() => {
                        dispatch(setHighlightsTag(item.id))
                      }}
                    />
                  )
                })}
              </div>
            </div>
          </Container>
          <div className={styles.list}>
            <CategorySlider
              highlights
              mobileNavRight
              data={[
                {
                  type: HighlightTypeEnum.TOP,
                  href: `/products?brand=${activeHighlightTag.id}&sorting=top_seller`
                },
                {
                  type: HighlightTypeEnum.NEW,
                  href: `/products?brand=${activeHighlightTag.id}&sorting=newest`,
                  size: "lg"
                }
              ]}
            />
          </div>
        </div>
        <div className={cx(styles.row, styles.products)}>
          {!viewedProductsLoading && (
            <ProductSlider
              mobileNavRight
              data={viewedProducts}
              background='#E0DBDA'
              title={t["last-seen-title"]}
              emptyMsg={t["product-empty-history-msg"]}
            />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Home
