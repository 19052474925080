/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import styles from "./index.module.scss"

const Tab = ({ title, onClick, isActive }) => {
  return (
    <div
      className={cx(styles.tab, {
        [styles.tab__active]: isActive
      })}
    >
      <span onClick={onClick} className={styles["tab-button"]}>
        {title}
      </span>
    </div>
  )
}

const TabContent = ({ data }) => {
  return <div className={styles.tabs__content}>{data?.children}</div>
}

const Tabs = ({ data = [] }) => {
  const [activeTab, setActiveTab] = useState(0)

  const handleTabClick = (index) => {
    setActiveTab(index)
  }

  return (
    <div className={styles.tabs}>
      <div className={styles.tabs__buttons}>
        {data.map((item, index) => {
          return <Tab title={item.title} onClick={() => handleTabClick(index)} isActive={activeTab === index} />
        })}
      </div>
      <TabContent data={data[activeTab]} />
    </div>
  )
}

Tabs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string
    })
  )
}

export default Tabs
