import axios from "axios"

// Orders api
export const getOrdersReq = (data) => axios.post("/orders", data)

export const getOrderInfoReq = (id) => axios.get(`/orders/${id}`)

export const copyOrderReq = (id, date) => axios.post(`/orders/copy/edit/${id}`, { date })

export const copyOrderAndOrderReq = (id, data) => axios.post(`/orders/copy/order/${id}`, data)

export const getOrderYearsFilterOptionsReq = () => axios.get("orders/years")

export const orderDownloadFileReq = (id) => axios.get(`/orders/download/${id}`)

// Cart api
export const getCartInfoReq = () => axios.get("/orders/cart")

export const confirmCartDataReq = (data) => axios.post("/orders/cart", data)

export const updateCartInfoReq = (data) => axios.put("/orders/cart", data)

export const getOrdersCartCountReq = () => axios.get("/orders/cart/count")

export const addProductToCartReq = (id, quantity) =>
  axios.post(`/orders/add-product/${id}`, {
    quantity
  })

export const importProductsReq = (data, config) =>
  axios.post(
    "/orders/add-import",
    {
      import: data
    },
    {
      ...config
    }
  )

export const updateCartProductCountReq = (id, quantity) =>
  axios.put(`/orders/update-product/${id}`, {
    quantity
  })

export const deleteProductFromCartReq = (id) => axios.delete(`/orders/remove-product/${id}`)
