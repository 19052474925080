/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authCodeUser, authUser, requestAuthCredentialsReq } from "../../api/auth"

const initialState = {
  isAuthorized: false,
  isUserLoading: false,
  isRequestAuthDataLoading: false
}

export const loginAuth = createAsyncThunk("auth/login", async (body, { rejectWithValue }) => {
  try {
    const response = await authUser(body)
    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
    }
  } catch (error) {
    return rejectWithValue(error.data)
  }
})

export const loginAuthCode = createAsyncThunk("auth/auth", async (body, { rejectWithValue }) => {
  try {
    const response = await authCodeUser(body)
    if (response.status === 200) {
      if (response.data) {
        return response.data.data
      }
    }
  } catch (error) {
    return rejectWithValue(error.data)
  }
})

export const requestAuthData = createAsyncThunk("auth/requestAuthData", async (body, { rejectWithValue }) => {
  try {
    const response = await requestAuthCredentialsReq(body)
    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

/* eslint-disable no-param-reassign */
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    checkIfUserAlreadyLoggedIn(state) {
      if (localStorage.getItem("auth")) {
        state.isAuthorized = true
      }
    },
    logOut() {
      localStorage.removeItem("auth")

      return {
        ...initialState
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAuth.pending, (state) => {
        state.isUserLoading = true
      })
      .addCase(loginAuth.fulfilled, (state, action) => {
        state.isUserLoading = false
      })
      .addCase(loginAuth.rejected, (state) => {
        state.isUserLoading = false
      })
      .addCase(loginAuthCode.pending, (state) => {
        state.isUserLoading = true
      })
      .addCase(loginAuthCode.fulfilled, (state, action) => {
        state.isUserLoading = false
        state.isAuthorized = true
        localStorage.setItem("auth", action.payload)
      })
      .addCase(loginAuthCode.rejected, (state) => {
        state.isUserLoading = false
      })
      .addCase(requestAuthData.pending, (state) => {
        state.isRequestAuthDataLoading = true
      })
      .addCase(requestAuthData.fulfilled, (state) => {
        state.isRequestAuthDataLoading = false
      })
      .addCase(requestAuthData.rejected, (state) => {
        state.isRequestAuthDataLoading = false
      })
  }
})

// State
export const selectIsAuth = (state) => state.auth.isAuthorized
// Loaders
export const selectUserLoading = (state) => state.auth.isUserLoading
export const selectIsRequestAuthDataLoading = (state) => state.auth.isRequestAuthDataLoading

export const { checkIfUserAlreadyLoggedIn, logOut } = authSlice.actions

export default authSlice.reducer
