/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
import React, { memo, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Navigation, Autoplay } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import ChewronLeftIcon from "../../../assets/icons/ChewronLeftIcon"
import ProductCard from "../../Cards/ProductCard"
import Container from "../../../modules/Container"
import { NOOP } from "../../../constants/noop"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/scrollbar"
import "swiper/css/autoplay"

import styles from "./index.module.scss"

const ProductSlider = ({ data = [], background, title, emptyMsg, mobileNavRight }) => {
  const [loaded, setLoaded] = useState(false)
  const [isNoSwipe, setIsNoSwipe] = useState(false)

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const checkIsNoSwipe = () => {
    setIsNoSwipe(
      navigationPrevRef?.current?.classList.contains("swiper-button-lock") &&
        navigationNextRef?.current?.classList.contains("swiper-button-lock")
    )
  }

  useEffect(() => {
    setLoaded(true)
  })

  if (!data.length) {
    return (
      <div
        className={cx(styles.box)}
        style={{
          "--product-slider-bg": background
        }}
      >
        <Container className={styles.header}>
          <div className={styles.title}>{title}</div>
        </Container>
        <Container>
          <div className={styles["empty-message"]}>{emptyMsg}</div>
        </Container>
      </div>
    )
  }

  return (
    <div
      className={cx(styles.box, {
        [styles["no-swipe"]]: isNoSwipe,
        [styles["nav-right"]]: mobileNavRight
      })}
      style={{
        "--product-slider-bg": background
      }}
    >
      <Container className={styles.header}>
        <div className={styles.title}>{title}</div>
        <button type='button' className={styles.button_prev} ref={navigationPrevRef}>
          <ChewronLeftIcon color='#333333' />
        </button>
        <button type='button' className={styles.button_next} ref={navigationNextRef}>
          <ChewronLeftIcon color='#333333' />
        </button>
      </Container>
      <div className={styles.slider}>
        {loaded && !!data.length && (
          <Swiper
            modules={[Navigation, Autoplay]}
            spaceBetween={0}
            slidesPerView='auto'
            slidesOffsetBefore={0}
            height='auto'
            slidesOffsetAfter={0}
            pagination={{ clickable: true }}
            onSlideChange={NOOP}
            onSwiper={NOOP}
            watchSlidesProgress
            watchOverflow
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
              swiper.navigation.init()
              swiper.navigation.update()
            }}
            onAfterInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
              swiper.navigation.init()
              swiper.navigation.update()
              checkIsNoSwipe()
            }}
            onResize={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
              swiper.navigation.init()
              swiper.navigation.update()
              checkIsNoSwipe()
            }}
            breakpoints={{
              // TODO: BUG ON MOBILE, NOT CORRECT CALCULATING SWIPER AREA, BUTTONS DOESN'T WORK SOMETIMES (AUTO SLIDES FIX THIS)
              // 500: {
              //   slidesPerView: 3.3
              // },
              // 768: {
              //   slidesPerView: 4.3
              // },
              1025: {
                slidesPerView: "auto"
              }
            }}
          >
            {data.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <ProductCard key={index} {...item} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}
      </div>
    </div>
  )
}

ProductSlider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      horizontal: PropTypes.bool,
      favorite: PropTypes.bool,
      price: PropTypes.number,
      ve: PropTypes.number,
      gk: PropTypes.number,
      discount: PropTypes.number,
      article: PropTypes.string,
      count: PropTypes.number,
      title: PropTypes.string,
      currency: PropTypes.string,
      type: PropTypes.string,
      image: PropTypes.string,
      addToFavorite: PropTypes.func
    })
  ),
  mobileNavRight: PropTypes.bool,
  background: PropTypes.string,
  title: PropTypes.string,
  emptyMsg: PropTypes.string
}

export default memo(ProductSlider)
