/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Tooltip } from "react-tooltip"
import { NOOP } from "../../constants/noop"
import { useTranslate } from "../../utils/hooks/useTranslate"

import FileIcon from "../../assets/icons/FileIcon"
import InfoIcon from "../../assets/icons/InfoIcon"
import WarningIcon from "../../assets/icons/WarningIcon"
import FileItem from "./FileItem/FileItem"
import MarkIcon from "../../assets/icons/MarkIcon"

import styles from "./index.module.scss"

const EmptyText = ({ dropText }) => (
  <div className={styles.empty}>
    <FileIcon />
    {dropText}
  </div>
)

EmptyText.propTypes = {
  dropText: PropTypes.string
}

const preventEvent = (event) => {
  event.preventDefault()
  event.stopPropagation()
}

const FileUpload = ({
  id,
  file,
  setFile = NOOP,
  title,
  tooltipTitle,
  tooltipInfo,
  onChange = NOOP,
  fileTypes,
  proggress,
  successUploaded,
  onCancel = NOOP,
  errorMsg,
  successMsg,
  showSuccess
}) => {
  const { t } = useTranslate()
  const [error, setError] = useState()
  const [isDragOver, setIsDragOver] = useState(false)
  const fileInput = useRef(null)

  const cancelHandler = (event) => {
    preventEvent(event)
    fileInput.current.value = null
    setFile(null)
    setError(null)
    onCancel()
  }

  const uploadFiles = async (files) => {
    const filesToUpload = files[0]

    const type = filesToUpload?.name?.split(".")?.pop()?.toLowerCase()

    if (!filesToUpload || !fileTypes?.includes(type)) {
      setError({
        message: t["invalid-file-foormat"]
      })

      fileInput.current.value = null
      setFile(null)
      return
    }

    setError(null)
    setFile(filesToUpload)
    onChange(filesToUpload)
  }

  const dropHandler = async (event) => {
    preventEvent(event)

    const files = []

    if (event.dataTransfer.items) {
      const arr = [...event.dataTransfer.items]
      arr.forEach((item) => {
        if (item.kind === "file") {
          files.push(item.getAsFile())
        }
      })
    } else {
      const arr = [...event.dataTransfer.files]
      arr.forEach((file) => {
        files.push(file)
      })
    }

    uploadFiles(files)
  }

  const handleChange = (event) => {
    preventEvent(event)
    uploadFiles(Array.from(event.target.files))
  }

  const clickHandler = () => {
    fileInput.current.click()
  }

  useEffect(() => {
    setError(errorMsg)
  }, [errorMsg])

  useEffect(() => {
    if (!file) {
      fileInput.current.value = null
    }
  }, [file])

  return (
    <div className={styles.box}>
      <div className={styles.title}>{title}</div>
      <div
        className={cx(styles.area, {
          [styles.error]: error,
          [styles.file]: file,
          [styles.success]: successUploaded,
          [styles["drag-over"]]: isDragOver
        })}
        onDragOver={(e) => {
          preventEvent(e)
          setIsDragOver(true)
        }}
        onDragEnter={preventEvent}
        onDragLeave={(e) => {
          preventEvent(e)
          setIsDragOver(false)
        }}
        onDrop={dropHandler}
        onClick={clickHandler}
        type='button'
      >
        <input
          className={styles.hidden}
          type='file'
          ref={fileInput}
          onChange={handleChange}
          accept={fileTypes}
          aria-label='file-input'
        />
        {file && !showSuccess && (
          <FileItem
            name={file.name}
            size={file.size}
            proggress={proggress}
            onCancel={cancelHandler}
            error={error}
            success={!error}
          />
        )}
        {!file && !showSuccess && (
          <EmptyText
            dropText={
              <span className={styles["drop-text"]}>
                {t["file-upload-dnd-area-1"]} <span dangerouslySetInnerHTML={{ __html: t["file-upload-dnd-area-2"] }} />{" "}
                {t["file-upload-dnd-area-3"]}
              </span>
            }
          />
        )}
        {showSuccess && (
          <div className={styles["success-msg"]} onClick={(e) => preventEvent(e)}>
            <div>
              <MarkIcon />
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: successMsg
              }}
            />
          </div>
        )}
      </div>
      <div className={styles.info}>
        <div className={styles.tooltip} data-tooltip-id='file-upload'>
          <InfoIcon />
          {/* TODO: Display if necessary */}
          {/* {tooltipTitle} */}
        </div>
        <a className={styles.template} href='/docs/titania_xlsx_template.xlsx' download data-tooltip-id='file-download'>
          <FileIcon color='#aaaaaa' />
        </a>
        {error && (
          <div className={styles.error}>
            <WarningIcon /> {error.message}
          </div>
        )}
      </div>
      <Tooltip id='file-download' place='top-start' className={cx(styles.tooltip__content, styles.sm)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t["file-template-download-title"]
          }}
        />
      </Tooltip>
      <Tooltip id='file-upload' place='top-start' className={styles.tooltip__content}>
        <div
          dangerouslySetInnerHTML={{
            __html: tooltipInfo
          }}
        />
      </Tooltip>
    </div>
  )
}

FileUpload.propTypes = {
  id: PropTypes.string,
  file: PropTypes.any,
  setFile: PropTypes.func,
  title: PropTypes.string,
  tooltipTitle: PropTypes.string,
  tooltipInfo: PropTypes.string,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  fileTypes: PropTypes.string,
  errorMsg: PropTypes.string,
  successMsg: PropTypes.string,
  proggress: PropTypes.number,
  successUploaded: PropTypes.bool,
  showSuccess: PropTypes.bool
}

export default memo(FileUpload)
