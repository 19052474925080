/* eslint-disable no-use-before-define */
import { useEffect, useRef, useMemo, useCallback } from "react"
import cx from "classnames"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import _debounce from "lodash/debounce"
import _throttle from "lodash/throttle"
import { toast } from "react-toastify"
import Breadcrumbs from "../../components/Breadcrumbs"
import Container from "../../modules/Container"
import Layout from "../../modules/Layout"
import List from "./List"
import AddToCart from "../../modules/AddToCart"
import Navigation from "./Navigation"
import Accordion from "../../components/Accordion"
import AccordionPanel from "../../components/Accordion/AccordionPanel"
import { useTranslate } from "../../utils/hooks/useTranslate"
import Spinner from "../../components/Spinner"
import { useInfinityScroll } from "../../utils/hooks/useInfinityScroll"
import { getFiltersFromParams } from "./utils/parse-params"
import ScreenCheck from "../../components/ScreenCheck"
import FiltersBar from "./FiltersBar"
import FilterSubmenu from "./FilterSubmenu"
import { responseErrorParser } from "../../utils/response-error-parser"
import { TOAST_DEFAULT_CONFIG } from "../../constants/toast"
import { useWindowSize } from "../../utils/hooks/useWindowSize"

import ListIcon from "../../assets/icons/ListIcon"
import GridIcon from "../../assets/icons/GridIcon"

import {
  ListViewEnum,
  selectProductsListLoading,
  selectProductsListView,
  selectProductsSidebarVisible,
  selectProductsSortOptions,
  selectProductsSortAccordion,
  setProductsSortAccordionActiveIndex,
  toggleListView,
  toggleSidebarVisible,
  disposePage,
  getProductSortOptions,
  selectProductsList,
  getDataBrands,
  ProductFilterKeyEnum,
  getCategoriesByBrand,
  getProducts,
  selectProductFilterFirstInited,
  setProductFilterFirstInited,
  selectProductsBrands,
  selectProductsCategoriesOptions,
  getProductBanners,
  selectProductsMeta,
  selectProductListLoaded,
  setProductsCategoryAccordionActiveIndex
} from "./products.controller"

import { preparePayload } from "./utils/prepare-payload"

import styles from "./index.module.scss"

const Products = () => {
  const { t } = useTranslate()
  const { isMobile, isSmallTablet, isTablet } = useWindowSize()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  // Sort state
  const sortOptions = useSelector(selectProductsSortOptions)
  const isFilterFirstInited = useSelector(selectProductFilterFirstInited)
  const brandOptions = useSelector(selectProductsBrands)
  const categoryOptions = useSelector(selectProductsCategoriesOptions)
  const meta = useSelector(selectProductsMeta)
  // Product list
  const productList = useSelector(selectProductsList)
  const productListLoading = useSelector(selectProductsListLoading)
  const productListLoaded = useSelector(selectProductListLoaded)
  // UI state
  const sortAccordionState = useSelector(selectProductsSortAccordion)

  const sidebarVisible = useSelector(selectProductsSidebarVisible)
  const listView = useSelector(selectProductsListView)

  const listContainerRef = useRef()

  const { brandID, sorting, categoryIDS, page } = getFiltersFromParams()

  const selectSortOption = useMemo(() => {
    return sortOptions.find((item) => item.id === sorting)
  }, [sorting, sortOptions])

  const breadcrumbs = useMemo(
    () => [
      {
        url: "/",
        label: t["navigation-home"]
      },
      {
        url: "/products",
        label: t["navigation-sortiment"]
      }
    ],
    [t]
  )

  const getCategories = (brands = []) => {
    const defaultBrandID = brands?.[0]?.id
    dispatch(getCategoriesByBrand(brandID || defaultBrandID))
      .unwrap()
      .then((res) => {
        // Set default filter configuration on page init
        if (!brandID || (page > 1 && !isFilterFirstInited)) {
          const params = new URLSearchParams()
          params.set(ProductFilterKeyEnum.BRAND, brandID || defaultBrandID)
          params.set(ProductFilterKeyEnum.SORT, sorting || "")
          params.set(ProductFilterKeyEnum.PAGE, 1)
          navigate(
            {
              search: params.toString()
            },
            {
              replace: true
            }
          )
        }
        // Open search filter dropdown on page init
        if (brandID && categoryIDS.length) {
          const targetIndex = res.findIndex((item) => item.id === categoryIDS[0].id)
          dispatch(setProductsCategoryAccordionActiveIndex(targetIndex))
        }
        dispatch(setProductFilterFirstInited())
        dispatch(
          getProductBanners({
            brand: brandID || defaultBrandID
          })
        )
      })
      .catch((err) => toast.error(responseErrorParser(err), TOAST_DEFAULT_CONFIG))
  }

  const initFilters = () => {
    if (!brandOptions.length) {
      dispatch(getDataBrands())
        .unwrap()
        .then((res) => {
          getCategories(res.data)
        })
        .catch((err) => toast.error(responseErrorParser(err), TOAST_DEFAULT_CONFIG))
    } else {
      getCategories(brandOptions)
    }
  }

  const debounceFetchProduct = useCallback(
    _debounce(
      (brandID, payload) => {
        dispatch(getCategoriesByBrand(brandID))
        if (!productListLoading) dispatch(getProducts(payload))
      },
      200,
      {
        leading: false
      }
    ),
    [productListLoading]
  )

  useInfinityScroll(listContainerRef, productListLoading, null, () => {
    if (!productListLoading && meta.all_page > meta.current_page) {
      const params = new URLSearchParams(window.location.search)
      params.set(ProductFilterKeyEnum.PAGE, meta.current_page + 1)
      navigate(
        {
          search: params.toString()
        },
        {
          replace: true
        }
      )
    }
  })

  useEffect(() => {
    if (!isFilterFirstInited) return

    const { brandID, sorting, categoryIDS, page } = getFiltersFromParams()

    if (!brandID) return initFilters()

    const payload = preparePayload({
      brandID,
      page,
      isLoadMore: page > 1,
      sorting: sorting === "null" ? null : sorting,
      categoryIDS,
      allCategoryData: categoryOptions
    })

    debounceFetchProduct(brandID, payload)
  }, [location, isFilterFirstInited])

  useEffect(() => {
    if (isMobile || isSmallTablet || isTablet) {
      dispatch(toggleListView(ListViewEnum.LIST))
    }
  }, [isMobile, isSmallTablet, isTablet])

  useEffect(() => {
    document.title = "Titania - Shop"

    dispatch(getProductSortOptions())
    initFilters()

    return () => {
      dispatch(disposePage())
    }
  }, [])

  return (
    <Layout>
      <div className={styles.box}>
        <Container className={styles.box__inner}>
          <div className={styles.box__header}>
            <Breadcrumbs path={breadcrumbs} className={styles.breadcrumbs} />
            <ScreenCheck desktop>
              <div className={styles.toolbar}>
                <div className={styles.toolbar__col}>
                  <AddToCart
                    title={`${t["quick-buy-title"]}`}
                    subtitle={`${t["quick-buy-title-2"]}`}
                    infoTooltip
                    buttonLabel={t["button-add-to-cart"]}
                    namePlaceholder={t["quick-buy-placeholder-article"]}
                    stkPlaceholder={t["quick-buyp-laceholder-stk"]}
                  />
                </div>
                <div className={styles.toolbar__col}>
                  <div className={styles.toolbar__sort}>
                    <Accordion
                      size='sm'
                      activeIndex={sortAccordionState.activeIndex}
                      collapseOnBlur
                      onOpen={(i) => {
                        dispatch(setProductsSortAccordionActiveIndex(i))
                      }}
                      list={[
                        {
                          title: (
                            <span dangerouslySetInnerHTML={{ __html: selectSortOption?.name || t["sort-accordion-label"] }} />
                          ),
                          content: (
                            <AccordionPanel
                              size='sm-light'
                              hover
                              background='#ffffff'
                              paddingBottom='10px'
                              data={sortOptions?.map((item, index) => {
                                return (
                                  <button
                                    type='button'
                                    key={index}
                                    onClick={() => {
                                      dispatch(setProductsSortAccordionActiveIndex(-1))
                                      const params = new URLSearchParams(window.location.search)
                                      params.set(ProductFilterKeyEnum.SORT, item.id || "")
                                      params.set(ProductFilterKeyEnum.PAGE, 1)
                                      navigate(
                                        {
                                          search: params.toString()
                                        },
                                        {
                                          replace: true
                                        }
                                      )
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: item.name
                                    }}
                                  />
                                )
                              })}
                            />
                          )
                        }
                      ]}
                    />
                  </div>
                  <button
                    type='button'
                    onClick={() => {
                      dispatch(toggleListView())
                    }}
                    className={styles["view-btn"]}
                  >
                    {listView === ListViewEnum.TABLE && <GridIcon />}
                    {listView === ListViewEnum.LIST && <ListIcon />}
                  </button>
                </div>
              </div>
            </ScreenCheck>
            <ScreenCheck mobile smallTablet tablet>
              <FiltersBar />
            </ScreenCheck>
          </div>
          <button className={styles.layout__title} type='button' onClick={() => dispatch(toggleSidebarVisible())}>
            {sidebarVisible ? t["hide-navigation"] : t["show-navigation"]}
          </button>
          <div
            className={cx(styles.layout, {
              [styles["sidebar-hidden"]]: !sidebarVisible
            })}
          >
            <ScreenCheck desktop>
              <div className={styles.layout__sidebar}>
                <Navigation />
              </div>
            </ScreenCheck>
            <div className={styles.layout__content} ref={listContainerRef}>
              <List column={sidebarVisible ? 3 : 4} />
              {productListLoading ? <Spinner /> : null}
              {!productList.length && productListLoaded && <div className={styles.empty}>{t["products-list-empty-message"]}</div>}
            </div>
          </div>
        </Container>
        <ScreenCheck mobile smallTablet tablet>
          <FilterSubmenu />
        </ScreenCheck>
      </div>
    </Layout>
  )
}

export default Products
