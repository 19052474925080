/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getFooterDataReq, getPageByIdReq } from "../../api/page"

// List of dynamic pages id
export const PagesTypeEnum = {
  LOGIN: "LOGIN"
}

const initialState = {
  pageId: "",
  footerData: {
    service: [],
    information: []
  },
  pageData: {
    id: "",
    og_img: "",
    textid: "",
    name_f: "",
    linkname: "",
    meta_title: "",
    meta_description: "",
    og_title: "",
    og_description: "",
    content: []
  },
  accountPopover: {
    isOpen: false
  },
  burgerMenu: {
    isOpen: false
  },
  burgerAccordion: {
    isOpen: false
  },
  managerModal: {
    isOpen: false
  },
  isPageLoading: false,
  locale: localStorage.getItem("lang") || "de",
  isFooterDataLoading: true,
  isFooterDataLoaded: {
    service: false,
    information: false
  }
}

export const getPageData = createAsyncThunk("auth/getLoginPageData", async (id, { rejectWithValue }) => {
  try {
    const response = await getPageByIdReq(id)
    if (response.status === 200) {
      if (response.data) {
        return response.data.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

export const getFooterData = createAsyncThunk("page/getFooterData", async (id, { rejectWithValue }) => {
  try {
    const response = await getFooterDataReq(id)
    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

/* eslint-disable no-param-reassign */
export const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    toggleAccountPopover(state) {
      return {
        ...state,
        accountPopover: {
          ...state.accountPopover,
          isOpen: !state.accountPopover.isOpen
        }
      }
    },
    toggleBurgerMenu(state, action) {
      return {
        ...state,
        burgerMenu: {
          ...state.burgerMenu,
          isOpen: action.payload
        }
      }
    },
    toggleBurgerMenuAccordion(state, action) {
      return {
        ...state,
        burgerAccordion: {
          ...state.burgerAccordion,
          isOpen: action.payload
        }
      }
    },
    toggleManagerModal(state, action) {
      return {
        ...state,
        managerModal: {
          ...state.accountPopover,
          isOpen: action.payload
        }
      }
    },
    disposePage(state) {
      return {
        ...state,
        pageData: {
          ...initialState
        },
        isPageLoading: false
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPageData.pending, (state) => {
        state.isPageLoading = true
      })
      .addCase(getPageData.fulfilled, (state, action) => {
        return {
          ...state,
          isPageLoading: false,
          pageData: { ...action.payload, content: action.payload?.content || [] }
        }
      })
      .addCase(getPageData.rejected, (state) => {
        state.isPageLoading = false
      })
      .addCase(getFooterData.pending, (state) => {
        state.isFooterDataLoading = true
      })
      .addCase(getFooterData.fulfilled, (state, action) => {
        state.isFooterDataLoading = false
        state.isFooterDataLoaded[action.meta.arg] = true
        state.footerData[action.meta.arg] = action.payload.data
      })
      .addCase(getFooterData.rejected, (state) => {
        state.isFooterDataLoaded = true
        state.isFooterDataLoading = false
      })
  }
})

// State
export const selectPageData = (state) => state.page.pageData
export const selectFooterData = (state) => state.page.footerData
// Loaders
export const selectPageDataLoading = (state) => state.page.isPageLoading
export const selectIsFooterDataLoading = (state) => state.page.isFooterDataLoading
export const selectIsFooterDataLoaded = (state) => state.page.isFooterDataLoaded
// UI components state
export const selectAccountPopover = (state) => state.page.accountPopover
export const selectBurgerMenu = (state) => state.page.burgerMenu
export const selectBurgerMenuAccordion = (state) => state.page.burgerAccordion
export const selectManagerModal = (state) => state.page.managerModal

export const { toggleBurgerMenu, toggleBurgerMenuAccordion, toggleManagerModal, toggleAccountPopover, disposePage } =
  pageSlice.actions

export default pageSlice.reducer
