/* eslint-disable react/prop-types */
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { selectIsAuth } from "../store/slice/auth"

const PrivateRoute = ({ children, ...props }) => {
  const auth = useSelector(selectIsAuth)

  if (auth) {
    return children
  }

  return props.redirect ? <Navigate to={props.redirect} /> : <Navigate to='/' />
}
export default PrivateRoute
