import React, { memo } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"
import { useTranslate } from "../../../utils/hooks/useTranslate"
import FallbackImg from "../../FallbackImg"
import { CONTACT_CARD_FALLBACK_IMAGE_URL } from "../../../constants/fallback-image"

import MailIcon from "../../../assets/icons/MailIcon"
import PhoneIcon from "../../../assets/icons/PhoneIcon"

import styles from "./index.module.scss"

const ContactCard = ({ id, title, name, post, mail, phone, image, vertical }) => {
  const { isTablet, isDesktop } = useWindowSize()
  const { t } = useTranslate()

  return (
    <div
      className={cx(styles.box, {
        [styles.vertical]: vertical
      })}
    >
      <div className={styles.title} dangerouslySetInnerHTML={{ __html: t["contact-manager-card-title"] }} />
      <div className={styles.initials}>
        {(vertical || isTablet) && (
          <FallbackImg src={image} alt={`${name} ${post}`} className={styles.photo} fallback={CONTACT_CARD_FALLBACK_IMAGE_URL} />
        )}
        <div>
          <div className={styles.name}>{name}</div>
          <div className={styles.post}>{post}</div>
        </div>
      </div>
      <div className={styles.contacts}>
        <div className={styles["contacts-info"]}>
          <a className={styles.mail} href={`mailto:${mail}`}>
            <MailIcon /> <span>{mail}</span>
          </a>
          <a className={styles.phone} href={`tel:${phone?.replace(/\s[(][)][-][_][/]/g, "")}`}>
            <PhoneIcon /> <span>{phone}</span>
          </a>
        </div>
        {!vertical && isDesktop && (
          <FallbackImg src={image} alt={`${name} ${post}`} className={styles.photo} fallback={CONTACT_CARD_FALLBACK_IMAGE_URL} />
        )}
      </div>
    </div>
  )
}

ContactCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  post: PropTypes.string,
  mail: PropTypes.string,
  phone: PropTypes.string,
  image: PropTypes.string,
  vertical: PropTypes.bool
}

export default memo(ContactCard)
