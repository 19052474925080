import React, { memo } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { NOOP } from "../../constants/noop"

import styles from "./index.module.scss"

const Burger = ({ isOpen, onClick = NOOP, className }) => {
  return (
    <button
      className={`${cx(styles.box, {
        [styles.open]: isOpen
      })} ${className}`}
      type='button'
      onClick={onClick}
    >
      <div className={styles.box__wrap}>
        <div className={styles.burgx} />
        <div className={styles.burgx2} />
        <div className={styles.burgx3} />
      </div>
    </button>
  )
}

Burger.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string
}

export default memo(Burger)
