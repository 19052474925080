/* eslint-disable no-debugger */
/* eslint-disable indent */
import { NOOP } from "../../../constants/noop"
import { ProductFilterKeyEnum } from "../products.controller"
import { getFiltersFromParams } from "./parse-params"
import { prepareBannersPayload } from "./prepare-payload"

/* 
- Filter search params products?brand=10&sorting=&10=10,20,30&20=all
- Brand filter brand=10
- Sort filter sort=price_asc, // values: new, art_no_desc, art_no_asc, price_desc, price_asc, newest, oldest
- Categories filter format [categoryID]=[subcategoryID],...,[subcategoryID]&[categoryID]=[subcategoryID],...,[subcategoryID]

- POST Request payload example
    "brandId": 10,
    "categories":[],
    "subcategories":[],
    "sorting":"price_asc", 
    "page": 1
*/

export const initDefaultCategoryWithAllSubcategories = (categories = []) => {
  const result = []

  return result.join("&")
}

const getCategoryByID = (categoryID, category) => {
  return category.find((cat) => cat.id === categoryID)
}

const checkIsSubcategorySelected = (subcategoryID, category) => {
  return category.subcategoryIDS.find((sub) => sub === subcategoryID)
}

const toggleSubcategoryAllFilter = (category) => {
  const isAll = checkIsSubcategorySelected("all", category)

  if (isAll) return null

  return {
    ...category,
    subcategoryIDS: ["all"]
  }
}

const toggleSubcategoryFilterByID = (subcategoryID, category) => {
  const isAll = checkIsSubcategorySelected("all", category)

  if (isAll) {
    return {
      ...category,
      subcategoryIDS: [subcategoryID]
    }
  }

  const isSubcategoryAlredySelected = checkIsSubcategorySelected(subcategoryID, category)

  if (isSubcategoryAlredySelected) {
    const filtered = category.subcategoryIDS.filter((item) => item !== subcategoryID)
    return filtered.length
      ? {
          ...category,
          subcategoryIDS: filtered
        }
      : null
  }

  return {
    ...category,
    subcategoryIDS: [...category.subcategoryIDS, subcategoryID]
  }
}

export const updateSubcategoryID = ({ subcategoryID, categoryID, navigate = NOOP, getProductBanners = NOOP }) => {
  const { categoryIDS, brandID, sorting } = getFiltersFromParams()
  const isCategoryExist = getCategoryByID(categoryID, categoryIDS)

  let newCategoryFilters

  if (!isCategoryExist) {
    newCategoryFilters = [
      ...categoryIDS,
      {
        id: categoryID,
        subcategoryIDS: [subcategoryID]
      }
    ]
  } else {
    newCategoryFilters = categoryIDS
      .map((item) => {
        if (item.id !== categoryID) {
          return item
        }

        if (subcategoryID === "all") {
          return toggleSubcategoryAllFilter(item)
        }

        return toggleSubcategoryFilterByID(String(subcategoryID), item)
      })
      .filter((item) => item)
  }

  getProductBanners(prepareBannersPayload(categoryID, subcategoryID))

  const params = new URLSearchParams("")
  params.set(ProductFilterKeyEnum.BRAND, brandID)
  params.set(ProductFilterKeyEnum.SORT, sorting)
  params.set(ProductFilterKeyEnum.PAGE, 1)

  newCategoryFilters.forEach((cat) => {
    params.set(cat.id, cat.subcategoryIDS.join(","))
  })

  navigate(
    {
      search: params.toString()
    },
    {
      replace: true
    }
  )
}
