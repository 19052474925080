/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable camelcase */
/* eslint-disable react/no-danger */
import cx from "classnames"
import PropTypes from "prop-types"
import { Tooltip } from "react-tooltip"
import { useCallback, useRef } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import PreviewSlider from "../../../components/Sliders/PreviewSlider"
import Badge from "../../../components/Badge"
import Counter from "../../../components/Counter"
import Button, { ButtonVariantEnum } from "../../../components/Button"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"
import { useTranslate } from "../../../utils/hooks/useTranslate"
import { addProductToFavorite, changeProductCount } from "../product.controller"
import { setProductFavoriteReq, unsetProductFavoriteReq } from "../../../api/product"
import { addProductToCart, getCartProductCount } from "../../Cart/cart.controller"
import { responseErrorParser } from "../../../utils/response-error-parser"
import { TOAST_DEFAULT_CONFIG } from "../../../constants/toast"
import { numberFormat } from "../../../utils/number-format"

import EyeIcon from "../../../assets/icons/EyeIcon"
import HeartIcon from "../../../assets/icons/HeartIcon"

import "react-tooltip/dist/react-tooltip.css"
import styles from "./index.module.scss"

const ProductTop = ({
  id,
  images,
  type,
  title,
  /* TODO: Not currently in use */
  // description,
  price,
  discount,
  currency,
  article,
  beanGtin,
  countryOrigin,
  ve,
  gk,
  count,
  favorite,
  logistics = [],
  isPreviewActive,
  closeProductPreview,
  openProductPreview
}) => {
  const { isDesktop } = useWindowSize()
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const ref = useRef()

  const changeFavorite = useCallback(
    async (favorite, id) => {
      try {
        if (!favorite) {
          await setProductFavoriteReq(id)
          dispatch(addProductToFavorite(true))
        } else {
          await unsetProductFavoriteReq(id)
          dispatch(addProductToFavorite(false))
        }
      } catch {
        addProductToFavorite(favorite)
      }
    },
    [favorite, id]
  )

  const addToCartHandler = () => {
    dispatch(
      addProductToCart({
        id,
        quantity: count
      })
    )
      .unwrap()
      .then((res) => {
        const { is_optimize, message } = res?.data || {}
        dispatch(getCartProductCount())
        if (is_optimize) {
          toast.warning(<span dangerouslySetInnerHTML={{ __html: message }} />, TOAST_DEFAULT_CONFIG)
        } else {
          toast.success(<span dangerouslySetInnerHTML={{ __html: message }} />, TOAST_DEFAULT_CONFIG)
        }
      })
      .catch((err) => {
        toast.error(responseErrorParser(err), TOAST_DEFAULT_CONFIG)
      })
  }

  const getTooltip = () => (
    <Tooltip id={`${id}-product-tooltip`} place='bottom' className={styles.tooltip} ref={ref}>
      <table className={styles.tooltip__content}>
        <thead>
          <tr>
            <th
              scope='col'
              dangerouslySetInnerHTML={{
                __html: t["product-tooltip-amount"]
              }}
            />
            <th
              scope='col'
              dangerouslySetInnerHTML={{
                __html: t["product-tooltip-ean-gtin"]
              }}
            />
            <th
              scope='col'
              dangerouslySetInnerHTML={{
                __html: t["product-tooltip-weight"]
              }}
            />
            <th
              scope='col'
              dangerouslySetInnerHTML={{
                __html: t["product-tooltip-length"]
              }}
            />
            <th
              scope='col'
              dangerouslySetInnerHTML={{
                __html: t["product-tooltip-width"]
              }}
            />
            <th
              scope='col'
              dangerouslySetInnerHTML={{
                __html: t["product-tooltip-height"]
              }}
            />
          </tr>
        </thead>
        <tbody>
          {logistics.map(({ menge, ean, weight, weight_measure, length, length_width_hight_measure, width, hight, logistic }) => {
            return (
              <tr key={ean}>
                <td>
                  {Number(menge)} {logistic}
                </td>
                <td>{ean || "-"}</td>
                <td>
                  {Number(weight)} {weight_measure}
                </td>
                <td>
                  {Number(length)} {length_width_hight_measure}
                </td>
                <td>
                  {Number(width)} {length_width_hight_measure}
                </td>
                <td>
                  {Number(hight)} {length_width_hight_measure}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </Tooltip>
  )

  return (
    <div className={styles.top}>
      {isDesktop && (
        <div className={styles.top__preview}>
          <PreviewSlider
            data={images}
            closeModal={closeProductPreview}
            openModal={openProductPreview}
            isModalOpen={isPreviewActive}
          />
        </div>
      )}
      <div className={styles.top__info}>
        {type && (
          <div className={cx(styles["top__info-row"], styles.badge)}>
            <Badge type={type} size={!isDesktop ? "sm-2" : "md"} />
          </div>
        )}
        <div className={styles["top__info-row"]}>
          {!isDesktop && (
            <div className={styles.top__preview}>
              <PreviewSlider
                data={images}
                closeModal={closeProductPreview}
                openModal={openProductPreview}
                isModalOpen={isPreviewActive}
              />
            </div>
          )}
          <div className={styles.product__title}>{title}</div>
          {/* TODO: Not currently in use */}
          {/* <div className={styles.product__desc}>{description}</div> */}
        </div>
        <div
          className={cx(styles["top__info-row"], styles.product__price, {
            [styles.discount]: discount
          })}
        >
          {!!discount && (
            <div className={styles["product__price-discount"]}>
              {numberFormat(discount, 2)} {currency}
            </div>
          )}
          <div>{numberFormat(price, 2, 2, currency) || t["product-price-on-request"]}</div>
        </div>
        <div className={cx(styles["top__info-row"], styles.additional)}>
          <div className={styles.additional__list}>
            <div className={styles["additional__list-col"]}>
              <div className={styles["additional__list-row"]}>
                <span>{t["product-info-number"]}:</span>
                <span>{article}</span>
              </div>
              <div className={styles["additional__list-row"]}>
                <span>{t["product-info-gtin-bean"]}:</span>
                <span>{beanGtin}</span>
              </div>
              <div className={styles["additional__list-row"]}>
                <span>{t["product-info-country"]}:</span>
                <span>{countryOrigin}</span>
              </div>
            </div>
            <div className={styles["additional__list-col"]}>
              <div className={styles["additional__list-row"]}>
                <span>{t["product-info-amount-ve"]}:</span>
                <span>{ve}</span>
              </div>
              <div className={styles["additional__list-row"]}>
                <span>{t["product-info-amount-gk"]}:</span>
                <span>{gk}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={cx(styles["top__info-row"], styles.product__controls)}>
          <div>
            <div className={styles.product__counter}>
              <Counter
                count={count}
                gk={gk}
                ve={ve}
                min={1}
                step={ve}
                size={isDesktop ? "md" : "full"}
                onChange={(v) => dispatch(changeProductCount(v))}
              />
            </div>
            <div className={styles.product__btn}>
              <Button text={t["button-add-to-cart"]} variant={ButtonVariantEnum.SECONDARY} onClick={addToCartHandler} />
              {isDesktop && (
                <>
                  <div className={styles.product__tooltip} data-tooltip-id={`${id}-product-tooltip`}>
                    <EyeIcon />
                    {t["product-logistic-information"]}
                  </div>
                  {getTooltip()}
                </>
              )}
            </div>
            {!isDesktop && (
              <div className={styles.mobile__btn}>
                <div>
                  <div
                    className={styles.product__tooltip}
                    data-tooltip-id={`${id}-product-tooltip`}
                    onClick={() => {
                      if (ref.current.isOpen) {
                        ref.current?.close()
                      } else {
                        ref.current?.open()
                      }
                    }}
                  >
                    <EyeIcon />
                    {t["product-logistic-information"]}
                  </div>
                  {getTooltip()}
                </div>
                <button className={styles.favorite} onClick={() => changeFavorite(favorite, id)} type='button'>
                  <HeartIcon strokeWidth={1} color={favorite ? "#ffb7b7" : "#333333"} fill={favorite} />
                </button>
              </div>
            )}
            {isDesktop && (
              <button className={styles.favorite} onClick={() => changeFavorite(favorite, id)} type='button'>
                <HeartIcon strokeWidth={1} color={favorite ? "#ffb7b7" : "#333333"} fill={favorite} />
              </button>
            )}
          </div>
          <div />
        </div>
      </div>
    </div>
  )
}

ProductTop.propTypes = {
  id: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string
    })
  ),
  logistics: PropTypes.arrayOf(
    PropTypes.shape({
      ean: PropTypes.string,
      menge: PropTypes.string,
      weight: PropTypes.string,
      weight_decimal: PropTypes.number,
      weight_measure: PropTypes.string,
      length: PropTypes.string,
      width: PropTypes.string,
      hight: PropTypes.string,
      length_width_hight_decimal: PropTypes.number,
      length_width_hight_measure: PropTypes.string
    })
  ),
  type: PropTypes.string,
  title: PropTypes.string,
  /* TODO: Not currently in use */
  // description: PropTypes.string,
  price: PropTypes.number,
  discount: PropTypes.number,
  currency: PropTypes.string,
  article: PropTypes.string,
  beanGtin: PropTypes.string,
  countryOrigin: PropTypes.string,
  ve: PropTypes.number,
  gk: PropTypes.number,
  count: PropTypes.number,
  favorite: PropTypes.bool,
  isPreviewActive: PropTypes.bool,
  closeProductPreview: PropTypes.func,
  openProductPreview: PropTypes.func
}

export default ProductTop
