import React, { memo } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { NOOP } from "../../constants/noop"

import UserIcon from "../../assets/icons/UserIcon"

import styles from "./index.module.scss"

const AccountButton = ({ isOpen, toggleAccountPopover = NOOP, content, className }) => {
  return (
    <div onMouseEnter={toggleAccountPopover} onMouseLeave={toggleAccountPopover}>
      <button
        className={`${cx(styles.box, {
          [styles.active]: isOpen
        })} ${className}`}
        type='button'
      >
        <UserIcon />
      </button>
      {isOpen && (
        <div className={styles.panel}>
          <div className={styles.panel__inner}>{content}</div>
        </div>
      )}
    </div>
  )
}

AccountButton.propTypes = {
  content: PropTypes.node,
  isOpen: PropTypes.bool,
  toggleAccountPopover: PropTypes.func,
  className: PropTypes.string
}

export default memo(AccountButton)
