/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-named-default */
import React, { memo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { default as ReactSwitch } from "react-switch"
import { NOOP } from "../../constants/noop"

import styles from "./index.module.scss"

export const SwitchVariantEnum = {
  PRIMARY: "primary",
  SECONDARY: "secondary"
}

const Switch = ({
  id,
  name,
  label,
  value,
  checked,
  checkedIcon = null,
  uncheckedIcon = null,
  checkedHandleIcon = null,
  uncheckedHandleIcon = null,
  onChange = NOOP,
  classNames,
  variant = SwitchVariantEnum.PRIMARY,
  disabled
}) => {
  const [innerValue, setInnerValue] = useState()

  const changeHandler = (e) => {
    if (disabled) return
    setInnerValue(e)
    onChange(e)
  }

  useEffect(() => {
    setInnerValue(value)
  }, [value])

  return (
    <div
      className={`${cx(styles.box, {
        [styles[variant]]: variant,
        [styles[disabled]]: disabled
      })} ${classNames}`}
    >
      <label className={styles.label} htmlFor={id}>
        <ReactSwitch
          name={name}
          id={id}
          checked={checked || innerValue}
          onChange={changeHandler}
          uncheckedIcon={checkedIcon}
          checkedIcon={uncheckedIcon}
          checkedHandleIcon={checkedHandleIcon}
          uncheckedHandleIcon={uncheckedHandleIcon}
          className={cx(styles.switch, {
            [styles.checked]: checked || innerValue
          })}
        />
        {label && <span>{label}</span>}
      </label>
    </div>
  )
}

Switch.propTypes = {
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  checkedIcon: PropTypes.node,
  uncheckedIcon: PropTypes.node,
  checkedHandleIcon: PropTypes.node,
  uncheckedHandleIcon: PropTypes.node,
  classNames: PropTypes.string,
  variant: PropTypes.string,
  onChange: PropTypes.func
}

export default memo(Switch)
