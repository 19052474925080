import React, { useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import styles from "./index.module.scss"

const AccordionPanel = ({ data = [], size = "md", paddingBottom, hover, background, emptyMsg }) => {
  return (
    <div
      className={cx(styles.box, {
        [styles["padding-bottom"]]: paddingBottom
      })}
      style={{
        "--background-color": background
      }}
    >
      {data.map((item, index) => {
        return (
          <div
            key={index}
            className={cx(styles.box__item, {
              [styles[size]]: size,
              [styles.hover]: hover
            })}
          >
            {item}
          </div>
        )
      })}
      {!data.length && emptyMsg && <div className={styles.emtpy}>{emptyMsg}</div>}
    </div>
  )
}

AccordionPanel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.node),
  size: PropTypes.string,
  background: PropTypes.string,
  emptyMsg: PropTypes.string,
  paddingBottom: PropTypes.bool,
  hover: PropTypes.bool
}

export default React.memo(AccordionPanel)
