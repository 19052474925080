export const MONTH_FILTER_OPTIONS = [
  {
    id: -1,
    label: "all"
  },
  {
    id: 1,
    label: "month-name-1"
  },
  {
    id: 2,
    label: "month-name-2"
  },
  {
    id: 3,
    label: "month-name-3"
  },
  {
    id: 4,
    label: "month-name-4"
  },
  {
    id: 5,
    label: "month-name-5"
  },
  {
    id: 6,
    label: "month-name-6"
  },
  {
    id: 7,
    label: "month-name-7"
  },
  {
    id: 8,
    label: "month-name-8"
  },
  {
    id: 9,
    label: "month-name-9"
  },
  {
    id: 10,
    label: "month-name-10"
  },
  {
    id: 11,
    label: "month-name-11"
  },
  {
    id: 12,
    label: "month-name-12"
  }
]
