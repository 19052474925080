import React, { memo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Link } from "react-router-dom"
import { Tooltip } from "react-tooltip"
import { NOOP } from "../../constants/noop"
import Spinner from "../Spinner"

import styles from "./index.module.scss"

// eslint-disable-next-line react/prop-types
const DivElement = ({ children, ...props }) => <div {...props}>{children}</div>

const SearchResultPanel = ({ data = [], isLoading, emptyMsg, onClick = NOOP, parentRef }) => {
  const [elemTop, setElemTop] = useState(0)
  const [elemLeft, setElemLeft] = useState(0)
  const [elemWidth, setElemWidthTop] = useState(0)

  const setPosition = () => {
    if (parentRef?.current) {
      setElemTop(parentRef.current.getBoundingClientRect().bottom)
      setElemLeft(parentRef.current.getBoundingClientRect().left)
      setElemWidthTop(parentRef.current.getBoundingClientRect().width)
    }
  }

  useEffect(() => {
    setPosition()

    window.addEventListener("resize", setPosition)
    window.addEventListener("scroll", setPosition)

    return () => {
      window.removeEventListener("resize", setPosition)
      window.removeEventListener("scroll", setPosition)
    }
  }, [])

  return (
    <div
      className={cx(styles.box)}
      style={{
        top: `${elemTop}px`,
        left: `${elemLeft - 10}px`,
        maxWidth: `${elemWidth + 20}px`
      }}
    >
      {!isLoading ? (
        data.map((item, index) => {
          const Elem = item.url ? Link : DivElement
          return (
            <Elem to={item.url} key={index} onClick={() => onClick(item)}>
              <div className={styles.box__item} data-tooltip-id='search-result-tooltip' data-tooltip-html={item.title}>
                <span>
                  {item.article} | VE: {item.ve}, GK: {item.gk}{" "}
                </span>
                <span>{item.title}</span>
              </div>
            </Elem>
          )
        })
      ) : (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
      <Tooltip id='search-result-tooltip' place='top-start' className={`tooltip-content ${styles.tooltip}`} />
      {!isLoading && !data.length && <div className={styles.msg} dangerouslySetInnerHTML={{ __html: emptyMsg }} />}
    </div>
  )
}

SearchResultPanel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      article: PropTypes.string
    })
  ),
  isLoading: PropTypes.bool,
  emptyMsg: PropTypes.string,
  onClick: PropTypes.func,
  parentRef: PropTypes.node
}

export default memo(SearchResultPanel)
