/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { toast } from "react-toastify"
import { useTranslate } from "../../../utils/hooks/useTranslate"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"
import DesktopTable from "./DesktopTable"
import MobileTable from "./MobileTable"
import { responseErrorParser } from "../../../utils/response-error-parser"
import { TOAST_DEFAULT_CONFIG } from "../../../constants/toast"
import { dateFormatReq } from "../../../utils/date-format"

import {
  confirmCartData,
  getCartData,
  selectCartAddressModal,
  selectCartData,
  selectCartShippingMethod,
  selectCartShippingOptions,
  setShippingAccordionActive,
  setShippingAccordionValue,
  toggleAddressCreateModal,
  toggleAddressModal,
  updateCart
} from "../cart.controller"
import { selectProfileInvoiceAddress } from "../../Profile/profile.controller"

import { dateInputValidate } from "../../../utils/date-input-validate"

import styles from "./index.module.scss"

const SignInFormSchema = (t) => () =>
  Yup.object().shape({
    date: Yup.string().required(t["form-required-field"]),
    comment: Yup.string().max(250, t["validation-error-max-250"]).nullable(),
    link: Yup.string().max(250, t["validation-error-max-250"]).nullable()
  })

const OrderInfo = ({ formId }) => {
  const { t } = useTranslate()
  const { isDesktop } = useWindowSize()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const invoiceAddress = useSelector(selectProfileInvoiceAddress)

  const { paymentMethod, date, link, comment, shippingAddress } = useSelector(selectCartData)
  const { selectedAddress } = useSelector(selectCartAddressModal)

  const methodAccordion = useSelector(selectCartShippingMethod)
  const methodOptions = useSelector(selectCartShippingOptions)

  const deliveryAddress = useMemo(() => {
    if (selectedAddress) {
      return selectedAddress
    }

    return shippingAddress || {}
  }, [shippingAddress, selectedAddress])

  const formik = useFormik({
    initialValues: {
      date: date ? moment(date).format("DD.MM.YYYY") : "",
      comment,
      link
    },
    validationSchema: SignInFormSchema(t)(),
    validateOnBlur: true,
    onSubmit: async (values) => {
      const val = formik.values.date
      const isCorrectDate = dateInputValidate(val)

      if (val && (!isCorrectDate.isValid || !isCorrectDate.isAfterToday)) {
        return formik.setErrors({
          date: t["form-error-invalid-date"]
        })
      }

      const { date, comment, link } = values

      const payload = {
        date: dateFormatReq(date),
        shipping_address: deliveryAddress.id,
        comment,
        link
      }

      dispatch(confirmCartData(payload))
        .unwrap()
        .then((res) => {
          navigate(`/cart?success=true&order-number=${res.data}`)
          dispatch(getCartData())
        })
        .catch((err) => {
          toast.error(responseErrorParser(err), TOAST_DEFAULT_CONFIG)
        })
    }
  })

  const handleKeyPress = (event) => {
    // const { which, keyCode, shiftKey } = event
    // if ((keyCode === 13 || which === 13) && !shiftKey) {
    //   if (!formik.errors?.date) {
    //     formik.handleSubmit()
    //   }
    // }
  }

  const updateCartInfo = (data = {}) => {
    const { date, comment, link } = formik.values
    dispatch(
      updateCart({
        date: dateFormatReq(date),
        shipping_address: deliveryAddress.id,
        comment,
        link,
        ...data
      })
    )
  }

  useEffect(() => {
    updateCartInfo({
      shipping_address: deliveryAddress.id
    })
  }, [deliveryAddress])

  useEffect(() => {
    if (formik.errors) {
      window.scrollTo(0, 0)
    }
  }, [formik.errors])

  useEffect(() => {
    return () => formik.resetForm()
  }, [])

  return (
    <div className={styles.box}>
      <div className={styles.title} dangerouslySetInnerHTML={{ __html: t["shop-cart-title"] }} />
      {isDesktop && (
        <DesktopTable
          formId={formId}
          formik={formik}
          methodAccordion={methodAccordion}
          methodOptions={methodOptions}
          paymentMethod={paymentMethod}
          paymentAddress={invoiceAddress}
          deliveryAddress={deliveryAddress}
          handleKeyPress={handleKeyPress}
          setShippingAccordionActive={(v) => dispatch(setShippingAccordionActive(v))}
          setShippingAccordionValue={(v) => dispatch(setShippingAccordionValue(v))}
          toggleAddressModal={() => dispatch(toggleAddressModal(true))}
          toggleAddressCreateModal={() => dispatch(toggleAddressCreateModal(true))}
          updateCartInfo={updateCartInfo}
        />
      )}
      {!isDesktop && (
        <MobileTable
          formId={formId}
          formik={formik}
          methodAccordion={methodAccordion}
          methodOptions={methodOptions}
          paymentMethod={paymentMethod}
          paymentAddress={invoiceAddress}
          deliveryAddress={deliveryAddress}
          handleKeyPress={handleKeyPress}
          setShippingAccordionActive={(v) => dispatch(setShippingAccordionActive(v))}
          setShippingAccordionValue={(v) => dispatch(setShippingAccordionValue(v))}
          toggleAddressModal={() => dispatch(toggleAddressModal(true))}
          toggleAddressCreateModal={() => dispatch(toggleAddressCreateModal(true))}
          updateCartInfo={updateCartInfo}
        />
      )}
    </div>
  )
}

export default OrderInfo
