import React, { memo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Link } from "react-router-dom"

import styles from "./index.module.scss"

const Breadcrumbs = ({ path = [], className }) => {
  return (
    <div className={`${styles.box} ${className}`}>
      {path.map((item, i) => {
        return (
          <Link
            key={i}
            to={item.url}
            className={cx({
              [styles.active]: item.url === window.location.pathname
            })}
          >
            <span>{item.label}</span>
          </Link>
        )
      })}
    </div>
  )
}

Breadcrumbs.propTypes = {
  path: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string
    })
  ),
  className: PropTypes.string
}

export default memo(Breadcrumbs)
