import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useEffect, useMemo } from "react"
import cx from "classnames"
import Layout from "../../modules/Layout"
import Container from "../../modules/Container"
import { useTranslate } from "../../utils/hooks/useTranslate"
import SuccessMessage from "../../modules/SuccessMessage"
import OrderInfo from "./OrderInfo"
import OrderList from "./OrderList"
import Button, { ButtonVariantEnum } from "../../components/Button"
import Breadcrumbs from "../../components/Breadcrumbs"
import CopyOrder from "../../modules/CopyOrder"

import {
  disposeCopyOrderModal,
  disposePage,
  getOrderData,
  selectOrderCopyOrderModal,
  selectOrderData,
  setOrderCopyModalOpen
} from "./order.controller"
import { downloadFileByFetchUrl } from "../../utils/download-by-fetch"
import { orderDownloadFileReq } from "../../api/order"

import styles from "./index.module.scss"

const Order = () => {
  const dispatch = useDispatch()
  const { t } = useTranslate()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const success = searchParams.get("success")
  const download = searchParams.get("download")
  const csvOrderName = searchParams.get("name")
  const orderNumber = searchParams.get("order-number")
  const navigate = useNavigate()

  const { id, number, desiredDeliveryDate, paymentMethod } = useSelector(selectOrderData)

  const { isOpen, data } = useSelector(selectOrderCopyOrderModal)

  const breadcrumbs = useMemo(
    () => [
      {
        url: "/profile",
        label: t["navigation-profile"]
      },
      {
        url: "/profile/orders",
        label: t["navigation-orders"]
      },
      {
        url: `/profile/orders/${id}`,
        label: number
      }
    ],
    [t, number]
  )

  const closeCopyModalHandler = () => {
    dispatch(disposeCopyOrderModal())
  }

  const openModalHandler = (data) => {
    dispatch(setOrderCopyModalOpen(data))
  }

  useEffect(() => {
    if (params?.id) {
      dispatch(getOrderData(params.id))
    }
  }, [params])

  useEffect(() => {
    if (params?.id && download) {
      downloadFileByFetchUrl(
        orderDownloadFileReq(params.id),
        "data:application/csv;charset=utf-8",
        `${csvOrderName || "CSV"}.csv`
      )
      const newParams = new URLSearchParams("")
      navigate(
        {
          search: newParams.toString()
        },
        {
          replace: true
        }
      )
    }
  }, [params, download, csvOrderName])

  useEffect(() => {
    return () => dispatch(disposePage())
  }, [])

  if (success && orderNumber) {
    return (
      <SuccessMessage
        breadcrumbs={<Breadcrumbs path={breadcrumbs} className={styles.breadcrumbs} />}
        title={t["order-success-text-1"]}
        subtitle={t["order-success-text-2"]}
        description={
          <>
            <span dangerouslySetInnerHTML={{ __html: t["order-success-text-3"] }} /> <b>{orderNumber}</b>
          </>
        }
        buttonUrl='/profile/orders'
        buttonLabel={t["button-to-my-orders"]}
      />
    )
  }

  return (
    <Layout>
      <div className={styles.box}>
        <Container className={styles.box__inner}>
          <div className={styles.box__header}>
            <Breadcrumbs path={breadcrumbs} className={styles.breadcrumbs} />
          </div>
          <div className={styles.info}>
            <OrderInfo openModalHandler={openModalHandler} />
          </div>
          <div className={styles.list}>
            <OrderList />
          </div>
          <div className={styles.buttons}>
            <Button variant={ButtonVariantEnum.SECONDARY} href='/profile/orders' text={t["button-review-all-orders"]} />
            <Button
              text={t["button-order-again"]}
              onClick={() => {
                openModalHandler({
                  id,
                  number,
                  date: desiredDeliveryDate,
                  paymentMethod
                })
              }}
            />
          </div>
        </Container>
        <CopyOrder isModalOpen={isOpen} closeModal={closeCopyModalHandler} data={data} redirect />
      </div>
    </Layout>
  )
}

export default Order
