/* eslint-disable react/no-danger */
import React, { memo } from "react"
import PropTypes from "prop-types"

import Container from "../Container/index"
import AuthForm from "../AuthForm"

import styles from "./index.module.scss"

const AuthBanner = ({ image, title, description, recipient }) => {
  return (
    <div className={styles.box}>
      <div className={styles.bg}>
        <img src={image} alt='login banner' />
      </div>
      <Container className={styles.box__inner}>
        <AuthForm className={styles.form} recipient={recipient} />
        <div className={styles.content}>
          <h1
            dangerouslySetInnerHTML={{
              __html: title
            }}
            className={styles.title}
          />
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: description
            }}
          />
        </div>
      </Container>
    </div>
  )
}

AuthBanner.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  recipient: PropTypes.string,
  description: PropTypes.string
}

export default memo(AuthBanner)
