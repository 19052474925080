/* eslint-disable no-plusplus */
import * as XLSX from "xlsx"
import { NOOP } from "../constants/noop"

export const XLSXParser = (file, setIsReading = NOOP, success = NOOP, error = NOOP) => {
  const reader = new FileReader()
  let res = []

  reader.onload = (e) => {
    try {
      const data = new Uint8Array(e.target.result)
      const workbook = XLSX.read(data, { type: "array" })

      for (let i = 0; i < workbook.SheetNames.length; i++) {
        const array = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[i]])
        const isValid = array.every((el) => Object.keys(el).length === 2)

        if (isValid) {
          res = res.concat(array)
        } else {
          throw new Error("invalid-file-foormat")
        }
      }

      setIsReading(false)
      success(res)
    } catch (err) {
      setIsReading(false)
      error(err)
    }
  }

  setIsReading(true)
  reader.readAsArrayBuffer(file)
}
