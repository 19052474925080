import * as React from "react"
import { pxToRem } from "../../utils/px-to-rem"

const ImportedIcon = () => (
  <svg
    width={`${pxToRem(19)}rem`}
    height={`${pxToRem(19)}rem`}
    viewBox='0 0 19 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='9.5' cy='9.5' r='9.5' fill='#F8F1E9' />
    <path d='M7.9801 14V7.60878H5V5H14V7.60878H11.0195V14H7.9801Z' fill='#333333' />
  </svg>
)

ImportedIcon.propTypes = {}

export default ImportedIcon
