/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { searchProductReq } from "../../api/search"

const initialState = {
  headerSearch: {
    value: "",
    isLoading: false,
    isShowTextInput: false,
    isShowResultPanel: false,
    result: [] // Search result DTO {id, article, title}
  },
  addToCartSearch: {
    selectedID: "",
    name: "",
    count: 0,
    isLoading: false,
    isShowResultPanel: false,
    result: [] // Search result DTO {id, article, title}
  }
}

export const headerSearchProductData = createAsyncThunk("page/headerSearchProductData", async (data, { rejectWithValue }) => {
  try {
    const response = await searchProductReq(data)
    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

export const addToCartSearchProductData = createAsyncThunk(
  "page/addToCartSearchProductData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await searchProductReq(data)
      if (response.status === 200) {
        if (response.data) {
          return response.data
        }
        return {}
      }
    } catch (error) {
      return rejectWithValue()
    }
  }
)

/* eslint-disable no-param-reassign */
export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setPageHeaderSearchValue(state, action) {
      return {
        ...state,
        headerSearch: {
          ...state.headerSearch,
          value: action.payload
        }
      }
    },
    toggleHeaderSearchPanel(state, action) {
      return {
        ...state,
        headerSearch: {
          ...state.headerSearch,
          isShowResultPanel: action.payload
        }
      }
    },
    toggleHeaderSearchTextInput(state, action) {
      return {
        ...state,
        headerSearch: {
          ...state.headerSearch,
          isShowTextInput: action.payload
        }
      }
    },
    setAddToCartSearchValue(state, action) {
      return {
        ...state,
        addToCartSearch: {
          ...state.addToCartSearch,
          ...action.payload
        }
      }
    },
    toggleAddToCartSearchPanel(state, action) {
      return {
        ...state,
        addToCartSearch: {
          ...state.addToCartSearch,
          isShowResultPanel: action.payload
        }
      }
    },
    disposeHeaderSearch(state) {
      return {
        ...state,
        headerSearch: {
          ...initialState.headerSearch
        }
      }
    },
    disposeAddToCartSearch(state) {
      return {
        ...state,
        addToCartSearch: {
          ...initialState.addToCartSearch
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(headerSearchProductData.pending, (state) => {
        state.headerSearch.isLoading = true
      })
      .addCase(headerSearchProductData.fulfilled, (state, action) => {
        state.headerSearch.isLoading = false
        state.headerSearch.result = action.payload.data.map((item) => ({
          ...item,
          url: `/products/${item.id}` // TODO: need additional info for url(????). Update response(????)
        }))
      })
      .addCase(headerSearchProductData.rejected, (state) => {
        state.headerSearch.isLoading = false
      })
      .addCase(addToCartSearchProductData.pending, (state) => {
        state.addToCartSearch.isLoading = true
      })
      .addCase(addToCartSearchProductData.fulfilled, (state, action) => {
        state.addToCartSearch.isLoading = false
        state.addToCartSearch.result = action.payload.data
      })
      .addCase(addToCartSearchProductData.rejected, (state) => {
        state.addToCartSearch.isLoading = false
      })
  }
})

export const selectHeaderSearchData = (state) => state.search.headerSearch
export const selectAddToCartSearchData = (state) => state.search.addToCartSearch

export const {
  setPageHeaderSearchValue,
  toggleHeaderSearchPanel,
  toggleHeaderSearchTextInput,
  setAddToCartSearchValue,
  toggleAddToCartSearchPanel,
  disposeHeaderSearch,
  disposeAddToCartSearch
} = searchSlice.actions

export default searchSlice.reducer
