import { useLocation } from "react-router-dom"
import { useEffect } from "react"
import { ToastContainer } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { store } from "./store/store"
import { checkIfUserAlreadyLoggedIn, selectIsAuth } from "./store/slice/auth"
import { useTranslateLoad } from "./utils/hooks/useTranslateLoad"
import { TRANSLATE_CONSTANT_KEYS } from "./constants/translate-keys"
import { FooterColTypeEnum } from "./modules/Footer"
import { getFooterData } from "./store/slice/page"
import { getDataBrands } from "./pages/Products/products.controller"
import { selectCurrentLocale, setLocale } from "./store/slice/translations"
import { getCartProductCount } from "./pages/Cart/cart.controller"
import { getProfileUserData } from "./pages/Profile/profile.controller"
import { usePageMinHeight } from "./utils/hooks/usePageMinHeight"
import { useMatomoTracker } from "./utils/hooks/useMatomoTraker"
import { useCookieManager } from "./utils/hooks/useCookieManager"

import RouterConfig from "./routes/RouterConfig"

import "react-toastify/dist/ReactToastify.css"

store.dispatch(checkIfUserAlreadyLoggedIn())

function App() {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const isAuth = useSelector(selectIsAuth)
  const currentLocale = useSelector(selectCurrentLocale)
  const location = useLocation()

  useTranslateLoad(TRANSLATE_CONSTANT_KEYS)
  usePageMinHeight()

  useEffect(() => {
    document.documentElement.lang = currentLocale

    if (isAuth) {
      dispatch(getDataBrands())
    }
    dispatch(getFooterData(FooterColTypeEnum.INFORMATION))
    dispatch(getFooterData(FooterColTypeEnum.SERVICE))
  }, [currentLocale])

  useEffect(() => {
    if (isAuth) {
      dispatch(getDataBrands())
      dispatch(getProfileUserData())
        .unwrap()
        .then((res) => {
          const { lang } = res?.data || {}
          dispatch(setLocale(lang))
        })
        .catch(() => {})
    }
  }, [isAuth])

  useEffect(() => {
    if (isAuth) {
      dispatch(getCartProductCount())
    }
  }, [location?.pathname])

  useCookieManager()
  useMatomoTracker()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <RouterConfig />
      <ToastContainer />
    </>
  )
}

export default App
