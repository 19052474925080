/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
import React, { memo, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Navigation, Pagination, Autoplay } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { Link } from "react-router-dom"
import FallbackImg from "../../FallbackImg"
import { CATEGORY_CARD_FALLBACK_IMAGE_URL } from "../../../constants/fallback-image"
import { NOOP } from "../../../constants/noop"

import ChewronLeftIcon from "../../../assets/icons/ChewronLeftIcon"
import CloseIcon from "../../../assets/icons/CloseIcon"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import "swiper/css/autoplay"

import styles from "./index.module.scss"

// eslint-disable-next-line react/prop-types
const DivElement = ({ children, ...props }) => <div {...props}>{children}</div>

const ImageSlider = ({ images = [], md, hideHandler = NOOP }) => {
  const [loaded, setLoaded] = useState(false)

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  useEffect(() => {
    setLoaded(true)
  })

  return (
    <div
      className={cx(styles.box, {
        [styles.md]: md
      })}
    >
      <div className={styles.slider}>
        <div className={styles.button_prev} ref={navigationPrevRef}>
          <ChewronLeftIcon color='#ffffff' />
        </div>
        <div className={styles.button_next} ref={navigationNextRef}>
          <ChewronLeftIcon color='#ffffff' />
        </div>
        {loaded && (
          <Swiper
            modules={[Navigation, Autoplay, Pagination]}
            spaceBetween={20}
            slidesPerView={1}
            slidesOffsetBefore={0}
            height='auto'
            slidesOffsetAfter={0}
            autoplay={{ delay: 5000 }}
            pagination={{ clickable: true }}
            onSlideChange={() => {}}
            onSwiper={() => {}}
            breakpoints={{
              767: {
                slidesPerView: 1,
                spaceBetween: 20,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0
              },
              1024: {
                slidesPerView: 1,
                spaceBetween: 20,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0
              }
            }}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
            }}
            onAfterInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
            }}
            onResize={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
            }}
          >
            {images.map((item, index) => {
              const Elem = item.href ? Link : DivElement
              return (
                <SwiperSlide key={index}>
                  <FallbackImg src={item.image} alt={item.title} fallback={CATEGORY_CARD_FALLBACK_IMAGE_URL} />
                  <Elem
                    className={styles.content}
                    to={item.href}
                    onClick={(e) => {
                      if (!item.href) {
                        e.preventDefault()
                      }
                    }}
                  >
                    {item.title && <div className={styles.title}>{item.title}</div>}
                    {item.description && <div className={styles.description}>{item.description}</div>}
                  </Elem>
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}
      </div>
      <button onClick={hideHandler} type='button' className={styles.hide}>
        <CloseIcon fill='#33333340' color='#fff' circleColor='transparent' />
      </button>
    </div>
  )
}

ImageSlider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
      alt: PropTypes.string
    })
  ),
  md: PropTypes.bool,
  hideHandler: PropTypes.func
}

export default memo(ImageSlider)
