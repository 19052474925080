import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import Modal from "../../../components/Modal"
import { pxToRem } from "../../../utils/px-to-rem"
import { useTranslate } from "../../../utils/hooks/useTranslate"
import { NOOP } from "../../../constants/noop"
import { responseErrorParser } from "../../../utils/response-error-parser"

import styles from "./index.module.scss"

const ModalConfirm = ({
  isModalOpen,
  isLoading,
  closeModal = NOOP,
  cancelHandler = NOOP,
  successHangler = NOOP,
  callback,
  successMsg = ""
}) => {
  const { t } = useTranslate()
  const [message, setMessage] = useState()
  const dispatch = useDispatch()

  const successMethod = () => {
    if (callback) {
      dispatch(callback)
        .unwrap()
        .then(() => {
          setMessage(successMsg)
          successHangler()
        })
        .catch((err) => {
          setMessage(responseErrorParser(err))
        })
    } else {
      successHangler()
    }
  }

  useEffect(() => {
    if (isModalOpen) {
      setMessage("")
    }
  }, [isModalOpen])

  return (
    <Modal
      modalIsOpen={isModalOpen}
      closeModal={closeModal}
      isLoading={isLoading}
      title={
        <span
          dangerouslySetInnerHTML={{
            __html: t["delete-approval-message"]
          }}
        />
      }
      style={{
        content: {
          padding: `${pxToRem(25)}rem ${pxToRem(15)}rem`,
          height: "min-height",
          maxWidth: `${pxToRem(550)}rem`
        }
      }}
      cancelHandler={cancelHandler}
      successHangler={successMethod}
      hideButtons={message}
      buttonCanselLabel={t.no}
      buttonSuccessLabel={t.yes}
    >
      {message && <div className={styles.success} dangerouslySetInnerHTML={{ __html: message }} />}
    </Modal>
  )
}

ModalConfirm.propTypes = {
  isModalOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  successMsg: PropTypes.string,
  closeModal: PropTypes.func,
  callback: PropTypes.func,
  cancelHandler: PropTypes.func,
  successHangler: PropTypes.func
}

export default ModalConfirm
