/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getProductReq, getSimilarProductsReq, getViewedProductReq } from "../../api/product"
import { productResponseMapper } from "../../utils/product-response-mapper"
import { CurrencyEnum } from "../../constants/currency"

const initialState = {
  productData: {
    id: "",
    article: "",
    ve: "",
    gk: "",
    countryOrigin: "",
    type: "",
    title: "",
    description: "",
    price: "",
    favorite: false,
    brand: { id: "", name: "" },
    category: { id: "", name: "" },
    subcategory: { id: "", name: "" },
    discount: 0,
    currency: CurrencyEnum.EUR,
    images: [],
    beanGtin: "",
    count: 0
  },
  similarProducts: [],
  viewedProducts: [],
  contentText: {
    text: ""
  },
  isProductLoading: true,
  isPreviewActive: false,
  isProductLoaded: false,
  isSimillarProductsLoading: true,
  isViewedProductsLoading: true
}

export const getProduct = createAsyncThunk("product/getProduct", async (id, { rejectWithValue }) => {
  try {
    const response = await getProductReq(id)
    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

export const getSimilarProduct = createAsyncThunk("product/getSimilarProduct", async (id, { rejectWithValue }) => {
  try {
    const response = await getSimilarProductsReq(id)
    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

export const getViewedProducts = createAsyncThunk("product/getViewidProducts", async (_, { rejectWithValue }) => {
  try {
    const response = await getViewedProductReq()
    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

/* eslint-disable no-param-reassign */
export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    openProductPreview(state) {
      return {
        ...state,
        isPreviewActive: true
      }
    },
    closeProductPreview(state) {
      return {
        ...state,
        isPreviewActive: false
      }
    },
    changeProductCount(state, action) {
      return {
        ...state,
        productData: {
          ...state.productData,
          count: action.payload
        }
      }
    },
    addProductToFavorite(state, action) {
      return {
        ...state,
        productData: {
          ...state.productData,
          favorite: action.payload
        }
      }
    },
    disposeProduct() {
      return {
        ...initialState
      }
    }
  },
  extraReducers: (builder) => {
    builder
      // Product detail info
      .addCase(getProduct.pending, (state) => {
        state.isProductLoaded = false
        state.isProductLoading = true
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        const data = productResponseMapper([action.payload.data])[0]
        return {
          ...state,
          productData: {
            id: data.id,
            article: data.article,
            ve: data.ve,
            gk: data.gk,
            countryOrigin: data.country_code,
            type: data.type,
            title: data.title,
            description: data.description,
            price: data.price,
            favorite: data.favorite,
            brand: data.brand,
            category: data.category,
            subcategory: data.subcategory,
            discount: data.discount,
            currency: CurrencyEnum.EUR,
            images: data.images,
            beanGtin: data.ean,
            count: data.ve,
            logistics: data.logistics
          },
          contentText: {
            text: data.description
          },
          isProductLoading: false,
          isProductLoaded: true
        }
      })
      .addCase(getProduct.rejected, (state) => {
        window.location = "/404"
        state.isProductLoaded = false
        state.isProductLoading = false
      })
      // Viewed products list
      .addCase(getViewedProducts.pending, (state) => {
        state.isViewedProductsLoading = true
      })
      .addCase(getViewedProducts.fulfilled, (state, action) => {
        const data = productResponseMapper(action.payload.data)

        return {
          ...state,
          viewedProducts: data,
          isViewedProductsLoading: false
        }
      })
      .addCase(getViewedProducts.rejected, (state) => {
        state.isViewedProductsLoading = false
      })
      // Similar products list
      .addCase(getSimilarProduct.pending, (state) => {
        state.isSimillarProductsLoading = true
      })
      .addCase(getSimilarProduct.fulfilled, (state, action) => {
        const data = productResponseMapper(action.payload.data)

        return {
          ...state,
          similarProducts: data,
          isSimillarProductsLoading: false
        }
      })
      .addCase(getSimilarProduct.rejected, (state) => {
        state.isSimillarProductsLoading = false
      })
  }
})

// Product detail data
export const selectProductData = (state) => state.product.productData
export const selectProductContentText = (state) => state.product.contentText
// Product sliders data
export const selectProductSimilarProducts = (state) => state.product.similarProducts
export const selectProductViewedProducts = (state) => state.product.viewedProducts
// UI preview slider
export const selectProductPreviewActive = (state) => state.product.isPreviewActive
// Loaders
export const selectProductLoading = (state) => state.product.isProductLoading
export const selectProductLoaded = (state) => state.product.isProductLoaded
export const selectIsSimilarProductsLoading = (state) => state.product.isSimillarProductsLoading
export const selectIsViewedProductsLoading = (state) => state.product.isViewedProductsLoading

export const { openProductPreview, addProductToFavorite, changeProductCount, closeProductPreview, disposeProduct } =
  productSlice.actions

export default productSlice.reducer
