export const breadcrumbsApi = [
  {
    url: "/",
    label: "Home"
  },
  {
    url: "/products",
    label: "Sortiment"
  },
  {
    url: "/products",
    label: "Hands & Feet"
  },
  {
    url: "/products",
    label: "Einlegesohlen"
  },
  {
    url: "/products/5230",
    label: "5230"
  }
]

export const productSliderApi = [
  {
    title:
      "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel Handtaschenset 3 tlg., Bürste, Kamm, Spiegel Handtaschenset 3 tlg., Bürste, Kamm, Spiegel",
    type: "NEU",
    favorite: true,
    price: 3.99,
    currency: "EUR",
    discount: 4.63,
    article: "15880",
    count: 12,
    image: "/img/products/p-1.png",
    ve: 12,
    gk: 60
  },
  {
    title:
      "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel Handtaschenset 3 tlg., Bürste, Kamm, Spiegel Handtaschenset 3 tlg., Bürste, Kamm, Spiegel",
    type: "NEU",
    favorite: true,
    price: 3.99,
    currency: "EUR",
    discount: 0,
    article: "15880",
    count: 12,
    image: "/img/products/p-1.png",
    ve: 12,
    gk: 60
  },
  {
    title:
      "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel Handtaschenset 3 tlg., Bürste, Kamm, Spiegel Handtaschenset 3 tlg., Bürste, Kamm, Spiegel",
    type: "NEU",
    favorite: true,
    price: 3.99,
    currency: "EUR",
    discount: 0,
    article: "15880",
    count: 12,
    image: "/img/products/p-2.png",
    ve: 12,
    gk: 60
  },
  {
    title:
      "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel Handtaschenset 3 tlg., Bürste, Kamm, Spiegel Handtaschenset 3 tlg., Bürste, Kamm, Spiegel",
    type: "NEU",
    favorite: true,
    price: 3.99,
    currency: "EUR",
    discount: 0,
    article: "15880",
    count: 12,
    image: "/img/products/p-3.png",
    ve: 12,
    gk: 60
  },
  {
    title:
      "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel Handtaschenset 3 tlg., Bürste, Kamm, Spiegel Handtaschenset 3 tlg., Bürste, Kamm, Spiegel",
    type: "NEU",
    favorite: true,
    price: 3.99,
    currency: "EUR",
    discount: 0,
    article: "15880",
    count: 12,
    image: "/img/products/p-4.png",
    ve: 12,
    gk: 60
  }
]

export const categoryBrandsApi = [
  {
    id: 1,
    name: "Titania"
  },
  {
    id: 2,
    name: "Berrywell"
  },
  {
    id: 3,
    name: "Niyok"
  },
  {
    id: 4,
    name: "Private label"
  }
]

export const categorySliderApi = [
  {
    id: "1",
    image: "/img/category/c-1.jpeg",
    title: "Face"
  },
  {
    id: "1",
    image: "/img/category/c-2.jpeg",
    title: "Hands & Feet"
  },
  {
    id: "1",
    image: "/img/category/c-3.jpeg",
    title: "Hair"
  },
  {
    id: "1",
    image: "/img/category/c-4.jpeg",
    title: "Body"
  },
  {
    id: "1",
    image: "/img/category/c-1.jpeg",
    title: "Men"
  }
]

export const highlightsBrandsApi = [
  {
    id: 1,
    name: "Titania"
  },
  {
    id: 2,
    name: "Berrywell"
  },
  {
    id: 3,
    name: "Niyok"
  }
]

export const contactApi = {
  title: "Ihr persönlicher Ansprechpartner bei TITANIA",
  name: "Andreas Vollbach",
  post: "Vice President, Vertriebsleiter, TITANIA® Fabrik GmbH",
  mail: "andreas.vollbach@titania-beauty.de",
  phone: "+49 (0) 20 58 / 8 92 88 - 12",
  image: "/img/admin.jpeg"
}

export const csvColumnFilters = [
  {
    id: "1",
    label: {
      de: "Art.-Nr.",
      en: "Art. No."
    }
  },
  {
    id: "2",
    label: {
      de: "Anzahl",
      en: "Quantity"
    }
  }
]
