import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const PhoneIcon = ({ color, strokeWidth, ...props }) => (
  <svg
    width={`${pxToRem(16)}rem`}
    height={`${pxToRem(17)}rem`}
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15 12.281V14.5124C15 15.3802 14.4221 16 13.613 16H13.4974C11.3014 15.7521 9.22096 15.0083 7.48725 13.6446C5.63796 12.5289 4.25099 11.0413 3.21076 9.18182C2.05495 7.19835 1.24589 4.96694 1.01473 2.61157C0.899146 1.86777 1.47705 1.12397 2.28612 1H2.4017H4.48215C5.17563 1 5.75354 1.49587 5.86912 2.23967C5.9847 2.98347 6.21586 3.72727 6.44702 4.47107C6.67818 4.96694 6.5626 5.58678 6.10028 6.08264L5.17563 6.95041C6.21586 8.80992 7.71841 10.4215 9.45212 11.4132L10.3768 10.4215C10.7235 10.0496 11.3014 9.92562 11.8793 10.0496C12.5728 10.2975 13.1507 10.4215 13.8442 10.5455C14.4221 10.7934 15 11.4132 15 12.281Z'
      stroke={color || "#333333"}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

PhoneIcon.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number
}

export default PhoneIcon
