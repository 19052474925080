import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getTranslations, selectCurrentLocale } from "../../store/slice/translations"

export const useTranslateLoad = (keys = []) => {
  const dispatch = useDispatch()
  const currentLocale = useSelector(selectCurrentLocale)

  useEffect(() => {
    dispatch(getTranslations(keys))
  }, [currentLocale])
}
