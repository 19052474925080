/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getTranslationsReq } from "../../api/translations"
import { TRANSLATE_FALLBACK } from "../../constants/translate-fallback"

const initialState = {
  currentLocale: localStorage.getItem("locale") || "de",
  currentDictionary: {},
  isLoading: false
}

export const getTranslations = createAsyncThunk("translate/getTranslations", async (keys, { rejectWithValue }) => {
  try {
    const response = await getTranslationsReq(keys)
    if (response.status === 200) {
      if (response.data) {
        return response.data.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

/* eslint-disable no-param-reassign */
export const translateSlice = createSlice({
  name: "translate",
  initialState,
  reducers: {
    setLocale(state, action) {
      localStorage.setItem("locale", action.payload || "de")

      return {
        ...state,
        currentLocale: action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTranslations.pending, (state) => {
        return {
          ...state,
          isLoading: true
        }
      })
      .addCase(getTranslations.fulfilled, (state, action) => {
        const locale = localStorage.getItem("locale") || "de"
        return {
          ...state,
          isLoading: false,
          currentDictionary: action.payload || TRANSLATE_FALLBACK[locale]
        }
      })
      .addCase(getTranslations.rejected, (state) => {
        return {
          ...state,
          isLoading: false
        }
      })
  }
})

export const selectCurrentLocale = (state) => state.translate.currentLocale
export const selectCurrentDictionary = (state) => state.translate.currentDictionary
export const selectTranslateLoading = (state) => state.translate.isLoading

export const { setLocale } = translateSlice.actions

export default translateSlice.reducer
