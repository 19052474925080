import React, { memo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Link } from "react-router-dom"
import { NOOP } from "../../constants/noop"
import Spinner from "../Spinner"

import styles from "./index.module.scss"

export const ButtonVariantEnum = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "tertiary"
}

const Button = ({
  size = "md",
  text,
  href,
  variant = "primary",
  submitType,
  onClick = NOOP,
  nativeLink,
  openInNewTab,
  disabled,
  loading,
  ...rest
}) => {
  if (href && !nativeLink)
    return (
      <Link
        to={href}
        className={cx(styles.box, {
          [styles[size]]: size,
          [styles[variant]]: variant,
          [styles.disabled]: disabled
        })}
        {...rest}
      >
        {loading ? <Spinner classNames={styles.spinner} /> : text}
      </Link>
    )

  if (href && nativeLink)
    return (
      <a
        href={href}
        className={cx(styles.box, {
          [styles[size]]: size,
          [styles[variant]]: variant,
          [styles.disabled]: disabled
        })}
        target={openInNewTab ? "_blank" : "_self"}
        rel='noreferrer'
        {...rest}
      >
        {loading ? <Spinner classNames={styles.spinner} /> : text}
      </a>
    )

  return (
    <button
      className={cx(styles.box, {
        [styles[size]]: size,
        [styles[variant]]: variant,
        [styles.disabled]: disabled
      })}
      type={submitType ? "submit" : "button"}
      onClick={(e) => {
        if (submitType) return
        e.preventDefault()
        e.stopPropagation()
        if (loading) return
        onClick()
      }}
      disabled={disabled}
      {...rest}
    >
      {loading ? <Spinner classNames={styles.spinner} /> : text}
    </button>
  )
}

Button.propTypes = {
  size: PropTypes.string,
  text: PropTypes.string,
  href: PropTypes.string,
  variant: PropTypes.string,
  submitType: PropTypes.bool,
  openInNewTab: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  nativeLink: PropTypes.bool,
  loading: PropTypes.bool
}

export default memo(Button)
