/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from "react"
import cx from "classnames"
import { useDispatch, useSelector } from "react-redux"
/* TODO: Not in use now */
// import { Tooltip } from "react-tooltip"
import Accordion from "../../../../components/Accordion"
import { useTranslate } from "../../../../utils/hooks/useTranslate"
import Switch from "../../../../components/Switch"
import { AddressCreateEditModal } from "../../../../modules/AddressCreateEditModal"
import Button from "../../../../components/Button"
import {
  addShippingAddress,
  deleteShippingAddress,
  editShippingAddress,
  selectIsShippingAddressDeleteLoading,
  selectProfileAddShippingAddressModal,
  selectProfileConfirmDeleteAddressModal,
  selectProfileEditShippingAddressModal,
  selectProfileInvoiceAddress,
  selectProfileShippingAddressList,
  selectProfileUpdateInvoiceAddressModal,
  setShippingAddressDefault,
  toggleProfileAddNewShippingAddressModal,
  toggleProfileConfirmDeleteAddressModal,
  toggleProfileEditShippingAddressModal,
  toggleProfileUpdateInvoiceAddressModal,
  updateInvoiceAddress
} from "../../profile.controller"
import { NOOP } from "../../../../constants/noop"
import ModalConfirm from "../../ModalConfirm"

import ThrashIcon from "../../../../assets/icons/ThrashIcon"
import EditIcon from "../../../../assets/icons/Edit"

import styles from "../index.module.scss"

const Accardion = ({ children, title, defaultOpen, disabledSwitch, isDefault, onChangeDefault = NOOP, hideSwitch = false }) => {
  const { t } = useTranslate()

  const [isActive, setIsActive] = useState(defaultOpen ? 0 : null)

  useEffect(() => {
    setIsActive(defaultOpen ? 0 : null)
  }, [defaultOpen])

  return (
    <Accordion
      borderBottom
      activeIndex={isActive}
      onOpen={(i) => {
        setIsActive(i)
      }}
      list={[
        {
          title: (
            <div>
              {!hideSwitch && isActive !== null ? (
                <div onClick={(e) => e.stopPropagation()}>
                  <Switch
                    label={<span dangerouslySetInnerHTML={{ __html: t["profile-shipping-address"] }} />}
                    classNames={styles.switch}
                    value={!!isDefault}
                    checked={!!isDefault}
                    onChange={(v) => {
                      onChangeDefault(v)
                    }}
                    disabled={disabledSwitch}
                  />
                </div>
              ) : null}
              <div
                className={cx(styles["accardion-header"], {
                  [styles.animated]: !hideSwitch && isActive !== null
                })}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </div>
          ),
          content: children
        }
      ]}
    />
  )
}

const CompanyTab = () => {
  const { t } = useTranslate()
  const dispatch = useDispatch()

  const shippingAddress = useSelector(selectProfileShippingAddressList)
  const invoiceAddress = useSelector(selectProfileInvoiceAddress)

  const addShippingAddressModal = useSelector(selectProfileAddShippingAddressModal)
  const editShippingModal = useSelector(selectProfileEditShippingAddressModal)
  const updateInvoiceAddressModal = useSelector(selectProfileUpdateInvoiceAddressModal)
  const confirmDeleteAddresModal = useSelector(selectProfileConfirmDeleteAddressModal)
  const deleteAddressLoading = useSelector(selectIsShippingAddressDeleteLoading)

  return (
    <div className={cx(styles.container, styles["company-tab"])}>
      <div className={styles.box}>
        <div className={styles.box__left}>
          <div className={styles.title}>{t["profile-content-address"]}</div>
          {shippingAddress?.map((item, index) => {
            const { id, info_1, info_2, info_3, street, city, land, isDefault } = item
            return (
              <Accardion
                title={info_1}
                defaultOpen={index === 0}
                isDefault={isDefault}
                disabledSwitch={shippingAddress?.length === 1}
                onChangeDefault={(v) => {
                  if (v) {
                    dispatch(setShippingAddressDefault({ id, value: v }))
                  } else {
                    const defaultItem = index !== 0 ? shippingAddress[0] : shippingAddress[1]
                    dispatch(setShippingAddressDefault({ id: defaultItem.id, value: true }))
                  }
                }}
              >
                <div className={cx(styles["accordion-body"], styles["padding-top"])}>
                  <div className={styles.text}>{info_2}</div>
                  <div className={styles.text}>{info_3}</div>
                  <div className={styles.text}>{street}</div>
                  <div className={styles.text}>{city}</div>
                  <div className={styles.text}>{land}</div>
                  <div className={styles.buttons}>
                    <button
                      className={styles.remove}
                      type='button'
                      onClick={() => {
                        dispatch(toggleProfileConfirmDeleteAddressModal(id))
                      }}
                      /* TODO: Not in use now */
                      // disabled={id > 0}
                      // data-tooltip-id={id > 0 ? "unable-to-delete-edit-tooltip" : ""}
                    >
                      <ThrashIcon />
                    </button>
                    <button
                      className={styles["edit-address"]}
                      type='button'
                      onClick={() => {
                        dispatch(toggleProfileEditShippingAddressModal({ open: true, data: item }))
                      }}
                      /* TODO: Not in use now */
                      // disabled={id > 0}
                      // data-tooltip-id={id > 0 ? "unable-to-delete-edit-tooltip" : ""}
                    >
                      <EditIcon />
                    </button>
                  </div>
                </div>
              </Accardion>
            )
          })}
          {/* TODO: Not in use now */}
          {/* <Tooltip place='top-start' id='unable-to-delete-edit-tooltip' className={`tooltip-content ${styles.tooltip}`}>
            <span dangerouslySetInnerHTML={{ __html: t["profile-unable-edit-delete-tooltip"] }} />
          </Tooltip> */}
          <div className={styles.add}>
            <Button
              onClick={() => dispatch(toggleProfileAddNewShippingAddressModal(true))}
              text={<span dangerouslySetInnerHTML={{ __html: t["profile-add-address"] }} />}
            />
          </div>
        </div>
        <div className={styles.box__right}>
          <div className={styles.title}>{t["profile-content-billing"]}</div>
          <Accardion hideSwitch title={invoiceAddress.info_1} defaultOpen>
            <div className={styles["accordion-body"]}>
              <div className={styles.text}>{invoiceAddress.info_2}</div>
              <div className={styles.text}>{invoiceAddress.info_3}</div>
              <div className={styles.text}>{invoiceAddress.street}</div>
              <div className={styles.text}>{invoiceAddress.city}</div>
              <div className={styles.text}>{invoiceAddress.land}</div>
              <div
                onClick={() => dispatch(toggleProfileUpdateInvoiceAddressModal(true))}
                className={cx(styles.button, styles.edit)}
                dangerouslySetInnerHTML={{ __html: t["profile-company-edit"] }}
              />
            </div>
          </Accardion>
        </div>
      </div>
      {/* SHIPPING ADDRESS ADD */}
      <AddressCreateEditModal
        isModalOpen={addShippingAddressModal.isOpen}
        onSubmit={addShippingAddress}
        closeModal={() => dispatch(toggleProfileAddNewShippingAddressModal(false))}
      />
      {/* SHIPPING ADDRESS EDIT */}
      <AddressCreateEditModal
        isModalOpen={editShippingModal.isOpen}
        onSubmit={(data) => editShippingAddress({ id: editShippingModal?.data?.id, data })}
        closeModal={() => dispatch(toggleProfileEditShippingAddressModal({ open: false }))}
        initialData={editShippingModal.data || {}}
        isEdit
        successMsg={t["profile-success-edit-address"]}
      />
      {/* INVOICE ADDRESS EDIT */}
      <AddressCreateEditModal
        isModalOpen={updateInvoiceAddressModal.isOpen}
        onSubmit={updateInvoiceAddress}
        closeModal={() => dispatch(toggleProfileUpdateInvoiceAddressModal(false))}
        initialData={invoiceAddress}
        isEdit
        successMsg={t["profile-success-edit-address"]}
      />
      {/* SHIPPING ADDRESS DELETE */}
      <ModalConfirm
        isModalOpen={confirmDeleteAddresModal.isOpen}
        closeModal={() => {
          dispatch(toggleProfileConfirmDeleteAddressModal(false))
        }}
        cancelHandler={() => {
          dispatch(toggleProfileConfirmDeleteAddressModal(false))
        }}
        isLoading={deleteAddressLoading}
        callback={deleteShippingAddress(confirmDeleteAddresModal.id)}
        successMsg={t["profile-success-delete-address"]}
      />
    </div>
  )
}

CompanyTab.propTypes = {}

export default CompanyTab
