import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import Layout from "../../modules/Layout"
import Container from "../../modules/Container"
import Breadcrumbs from "../../components/Breadcrumbs"
import { useTranslate } from "../../utils/hooks/useTranslate"
import OrdersFilters from "./OrdersFilters"
import OrdersList from "./OrdersList"
import { useWindowSize } from "../../utils/hooks/useWindowSize"

import {
  changeOrdersPageProducts,
  disposePage,
  getOrderYearsFilterOptions,
  getOrdersData,
  selectOrdersFilter,
  selectOrdersListLoading
} from "./orders.controller"
import { useInfinityScroll } from "../../utils/hooks/useInfinityScroll"

import styles from "./index.module.scss"
import { getShippingAddress } from "../Profile/profile.controller"

// Map fronted sort state to backend values
const normalizeSortFilterToRequest = {
  number: {
    asc: "order_no_desc",
    desc: "order_no_asc"
  },
  summ: {
    asc: "order_summ_asc",
    desc: "order_summ_desc"
  },
  date: {
    asc: "order_date_asc",
    desc: "order_date_desc"
  },
  user: {
    asc: "order_user_asc",
    desc: "order_user_desc"
  }
}

const Orders = () => {
  const dispatch = useDispatch()
  const { t } = useTranslate()
  const { isDesktop } = useWindowSize()
  const selectedFilters = useSelector(selectOrdersFilter)
  const isListLoading = useSelector(selectOrdersListLoading)

  const breadcrumbs = useMemo(
    () => [
      {
        url: "/profile",
        label: t["navigation-profile"]
      },
      {
        url: "/profile/orders",
        label: t["navigation-orders"]
      }
    ],
    [t]
  )

  const preparePayload = () => {
    return {
      year: selectedFilters?.year?.id < 0 ? null : selectedFilters?.year?.id,
      month: selectedFilters?.month?.id === -1 ? null : selectedFilters?.month?.id,
      sorting: normalizeSortFilterToRequest[selectedFilters?.sort?.key]?.[selectedFilters?.sort?.sort] || null,
      page: selectedFilters?.page,
      quantity: isDesktop ? 15 : 10
    }
  }

  useInfinityScroll(null, isListLoading, dispatch, changeOrdersPageProducts)

  useEffect(() => {
    dispatch(getOrdersData({ data: preparePayload(), isLoadMore: selectedFilters.isLoadMore }))
  }, [selectedFilters])

  useEffect(() => {
    document.title = "Titania - Orders"
  }, [])

  useEffect(() => {
    dispatch(getOrderYearsFilterOptions())
    dispatch(getShippingAddress())

    return () => dispatch(disposePage())
  }, [])

  return (
    <Layout>
      <div className={styles.box}>
        <Container className={styles.box__inner}>
          <div className={styles.box__header}>
            <Breadcrumbs path={breadcrumbs} className={styles.breadcrumbs} />
          </div>
          <div className={styles.content}>
            <div className={styles.content__col}>
              <div className={styles.title}>{t["orders-title"]}</div>
              <div className={styles.filters}>
                <OrdersFilters />
              </div>
            </div>
            <div className={styles.content__col}>
              <OrdersList />
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default Orders
