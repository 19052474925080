export const addressResponseMapper = (data = []) => {
  if (!data || !data?.length) {
    return []
  }

  return data.map((item) => {
    return {
      id: item?.SysNumberKundeAnschrift,
      posNumber: item?.PosNumber,
      info_1: item?.Bezeichnung1,
      info_2: item?.Bezeichnung2,
      info_3: item?.Bezeichnung3,
      street: item?.Strasse,
      index: item?.PLZOrt,
      city: item?.Ort,
      land: item?.ISOCode3KonstLand,
      isDefault: item?.is_default
    }
  })
}
