import React, { memo } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { NOOP } from "../../constants/noop"

import styles from "./index.module.scss"

const Tag = ({ id, name, size = "md", className, active, onClick = NOOP }) => {
  return (
    <button
      className={`${cx(styles.box, {
        [styles.active]: active,
        [styles[size]]: size
      })} ${className}`}
      type='button'
      onClick={onClick}
    >
      {name}
    </button>
  )
}

Tag.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func
}

export default memo(Tag)
