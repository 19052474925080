export const getFiltersFromParams = () => {
  const params = new URLSearchParams(window.location.search)
  const brandID = params.get("brand")
  const sorting = params.get("sorting")
  const page = params.get("page")

  const categoryParams = decodeURIComponent(params)
    ?.toString()
    ?.split("&")
    ?.filter((item) => !item.includes("brand") && !item?.includes("sorting") && !item?.includes("page"))
    ?.map((item) => {
      const splittedCategoryIDAndSubcategoryIDS = item?.split("=")
      const splittedSubCategoryIDS = splittedCategoryIDAndSubcategoryIDS?.[1]?.split(",") || []
      return {
        id: Number(splittedCategoryIDAndSubcategoryIDS?.[0]),
        subcategoryIDS: splittedSubCategoryIDS
      }
    })

  return {
    brandID: Number(brandID),
    sorting,
    categoryIDS: categoryParams || [],
    page: Number(page) || 1
  }
}
