/* eslint-disable indent */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import Button from "../../components/Button"
import Input from "../../components/Input"
import Modal from "../../components/Modal"
import { pxToRem } from "../../utils/px-to-rem"
import { useWindowSize } from "../../utils/hooks/useWindowSize"
import { useTranslate } from "../../utils/hooks/useTranslate"
import { NOOP } from "../../constants/noop"
import { responseErrorParser } from "../../utils/response-error-parser"
import { TOAST_DEFAULT_CONFIG } from "../../constants/toast"

import styles from "./index.module.scss"

const formSchemaCompany = (t) => () =>
  Yup.object().shape({
    company: Yup.string().required(t["form-required-field"]),
    street: Yup.string().required(t["form-required-field"]),
    optional: Yup.string().nullable(),
    optional2: Yup.string().nullable(),
    city: Yup.string().required(t["form-required-field"]),
    index: Yup.string().required(t["form-required-field"]),
    country: Yup.string().required(t["form-required-field"])
  })

const AddressCreateEditModal = ({ isModalOpen, closeModal, onSubmit = NOOP, initialData, isEdit, successMsg }) => {
  const { t } = useTranslate()
  const { isDesktop } = useWindowSize()
  const dispatch = useDispatch()

  const [isCreate, setIsCreate] = useState(true)
  const [errorText, setErrorText] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      company: initialData?.info_1 || "",
      optional: initialData?.info_2 || "",
      optional2: initialData?.info_3 || "",
      street: initialData?.street || "",
      city: initialData?.city || "",
      index: initialData?.index || "",
      country: initialData?.land || ""
    },
    validationSchema: formSchemaCompany(t)(),
    validateOnBlur: true,
    onSubmit: async (payload) => {
      setIsLoading(true)

      dispatch(onSubmit(payload))
        .unwrap()
        .then((res) => {
          setIsCreate(false)
          setIsLoading(false)
        })
        .catch((err) => {
          toast.error(responseErrorParser(err), TOAST_DEFAULT_CONFIG)
          setIsLoading(false)
          setErrorText(responseErrorParser(err))
        })
    }
  })

  const initForm = () => {
    formik.setValues({
      company: initialData?.info_1 || "",
      street: initialData?.street || "",
      optional: initialData?.info_2 || "",
      optional2: initialData?.info_3 || "",
      city: initialData?.city || "",
      index: initialData?.index || "",
      country: initialData?.land || ""
    })
    formik.setErrors({})
    formik.setTouched({})
    setErrorText("")
    setIsCreate(true)
    setIsLoading(false)
  }

  useEffect(() => {
    initForm()
  }, [initialData])

  useEffect(() => {
    if (!isModalOpen) {
      initForm()
    }
  }, [isModalOpen])

  return (
    <Modal
      modalIsOpen={isModalOpen}
      closeModal={closeModal}
      title={
        <span
          dangerouslySetInnerHTML={{
            __html: isEdit
              ? t["profile-edit-new-address"]
              : isCreate
                ? t["profile-add-new-address"]
                : t["profile-content-success"]
          }}
        />
      }
      cancelHandler={closeModal}
      style={{
        content: isDesktop
          ? {
              padding: `${pxToRem(25)}rem`,
              width: `${pxToRem(550)}rem`,
              height: "min-height"
            }
          : {
              padding: `${pxToRem(25)}rem ${pxToRem(15)}rem`,
              height: "min-height",
              maxHeight: "90%"
            }
      }}
    >
      <div className={styles.modal}>
        {isCreate ? (
          <>
            <div className={styles.inputs}>
              <Input
                label={<span dangerouslySetInnerHTML={{ __html: t["profile-field-company"] }} />}
                value={formik.values?.company}
                errors={!!formik.errors?.company && !!formik.touched?.company}
                onChange={(e, val) => formik.setFieldValue("company", val)}
              />
              <Input
                label={<span dangerouslySetInnerHTML={{ __html: t["profile-field-street"] }} />}
                value={formik.values?.street}
                errors={!!formik.errors?.street && !!formik.touched?.street}
                onChange={(e, val) => formik.setFieldValue("street", val)}
              />
              <Input
                label={<span dangerouslySetInnerHTML={{ __html: t["profile-field-optional"] }} />}
                value={formik.values?.optional}
                errors={!!formik.errors?.optional && !!formik.touched?.optional}
                onChange={(e, val) => formik.setFieldValue("optional", val)}
              />
              <Input
                label={<span dangerouslySetInnerHTML={{ __html: t["profile-field-optional-2"] }} />}
                value={formik.values?.optional2}
                errors={!!formik.errors?.optional2 && !!formik.touched?.optional2}
                onChange={(e, val) => formik.setFieldValue("optional2", val)}
              />
              <Input
                label={<span dangerouslySetInnerHTML={{ __html: t["profile-field-city"] }} />}
                value={formik.values?.city}
                errors={!!formik.errors?.city && !!formik.touched?.city}
                onChange={(e, val) => formik.setFieldValue("city", val)}
              />
              <Input
                label={<span dangerouslySetInnerHTML={{ __html: t["profile-field-postal_code"] }} />}
                value={formik.values?.index}
                errors={!!formik.errors?.index && !!formik.touched?.index}
                onChange={(e, val) => formik.setFieldValue("index", val)}
              />
              <Input
                label={<span dangerouslySetInnerHTML={{ __html: t["profile-field-country"] }} />}
                value={formik.values?.country}
                errors={!!formik.errors?.country && !!formik.touched?.country}
                onChange={(e, val) => formik.setFieldValue("country", val)}
              />
            </div>
            <div>
              <Button disabled={isLoading} onClick={formik.handleSubmit} text={t["button-save"]} />
            </div>
          </>
        ) : errorText ? (
          <p dangerouslySetInnerHTML={{ __html: errorText }} />
        ) : (
          <p dangerouslySetInnerHTML={{ __html: successMsg || t["profile-success-create-address"] }} />
        )}
      </div>
    </Modal>
  )
}

AddressCreateEditModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  initialData: PropTypes.shape({
    id: PropTypes.string,
    company: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    index: PropTypes.string,
    country: PropTypes.string
  })
}

export { AddressCreateEditModal }
