/* eslint-disable react/no-danger */
import React, { memo } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import * as Yup from "yup"

import { useDispatch, useSelector } from "react-redux"
import { useFormik } from "formik"
import { loginAuth, selectUserLoading } from "../../../store/slice/auth"
import Button from "../../../components/Button"
import TextInput from "../../../components/TextInput"
import { NOOP } from "../../../constants/noop"
import { useTranslate } from "../../../utils/hooks/useTranslate"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"

import WarningIcon from "../../../assets/icons/WarningIcon"

import styles from "./index.module.scss"
import { toggleManagerModal } from "../../../store/slice/page"

const SignInFormSchema = (t) =>
  Yup.object().shape({
    email: Yup.string().email(t["form-invalid-email"]).required(t["form-required-field"])
  })

const MailStep = ({ nextStep = NOOP }) => {
  const dispatch = useDispatch()
  const { t } = useTranslate()
  const { isMobile } = useWindowSize()
  const isLoadingUser = useSelector(selectUserLoading)

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: SignInFormSchema(t),
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        dispatch(loginAuth({ email: values.email }))
          .unwrap()
          .then((res) => {
            nextStep()
          })
          .catch((er) => {
            formik.setErrors({
              email: er?.data?.message || er?.message || er?.data
            })
          })
      } catch (error) {
        formik.setErrors({
          email: error.message
        })
      }
    }
  })

  const handleKeyPress = (event) => {
    const { which, keyCode } = event
    if (keyCode === 13 || which === 13) {
      if (!formik.errors?.email) {
        formik.handleSubmit()
      }
    }
  }

  return (
    <div className={styles.box}>
      <form className={styles.form} noValidate autoComplete='off' onSubmit={formik.handleSubmit}>
        <div className={styles.form__row}>
          <div className={styles.form__title}>{t["sign-in-title"]}</div>
        </div>
        <div className={cx(styles.form__row, styles.form__input)}>
          <TextInput
            disabled={isLoadingUser}
            placeholder={t["input-mail-placeholder"]}
            value={formik.values?.email}
            errors={!!formik.errors?.email && !!formik.touched?.email}
            onChange={(e) => formik.setFieldValue("email", e.target.value)}
            onFocus={() => formik.setFieldTouched("email", false)}
            onBlur={() => formik.setFieldTouched("email", true)}
            onKeyPress={handleKeyPress}
          />
          {isMobile && (
            <div
              className={cx(styles.form__alert, {
                [styles.visible]: formik.touched?.email && formik.errors?.email
              })}
            >
              <WarningIcon />
              {formik.errors?.email}
            </div>
          )}
        </div>
        <div className={cx(styles.form__row, styles.form__submit)}>
          <Button disabled={isLoadingUser} text={t["login-link-request-button"]} size={isMobile ? "sm" : "lg"} submitType />
          {!isMobile && (
            <div
              className={cx(styles.form__alert, {
                [styles.visible]: formik.touched?.email && formik.errors?.email
              })}
            >
              <WarningIcon />
              {formik.errors?.email}
            </div>
          )}
        </div>
        <div className={cx(styles.form__row, styles.form__link)}>
          <button
            type='button'
            dangerouslySetInnerHTML={{ __html: t["sign-in-no-login-data"] }}
            onClick={() => {
              dispatch(toggleManagerModal(true))
            }}
          />
        </div>
      </form>
    </div>
  )
}

MailStep.propTypes = {
  nextStep: PropTypes.func
}

export default memo(MailStep)
