/* eslint-disable react/no-danger */
import React, { memo, useState } from "react"
import PropTypes from "prop-types"

import { useDispatch, useSelector } from "react-redux"
import MailStep from "./MailStep"
import PinStep from "./PinStep"
import RequestAuthModal from "../RequestAuthModal"
import { selectManagerModal, toggleManagerModal } from "../../store/slice/page"

import styles from "./index.module.scss"

const AuthForm = ({ className, recipient }) => {
  const [step, setStep] = useState(0)
  const dispatch = useDispatch()
  const managerModal = useSelector(selectManagerModal)

  return (
    <div className={`${styles.box} ${className}`}>
      {step === 0 && (
        <MailStep
          nextStep={() => {
            setStep(1)
          }}
          recipient={recipient}
        />
      )}
      {step === 1 && (
        <PinStep
          prevStep={() => {
            setStep(0)
          }}
        />
      )}
      <RequestAuthModal
        isModalOpen={managerModal?.isOpen}
        closeModal={() => {
          dispatch(toggleManagerModal(false))
        }}
      />
    </div>
  )
}

AuthForm.propTypes = {
  className: PropTypes.string,
  recipient: PropTypes.string
}

export default memo(AuthForm)
