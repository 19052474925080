import { useEffect } from "react"
import CONFIG from "../../config"

export const useCookieManager = () => {
  if (CONFIG.MODE === "production") {
    useEffect(() => {
      const scriptId = "Cookiebot"
      if (!document.getElementById(scriptId)) {
        const script = document.createElement("script")
        script.id = scriptId
        script.src = "https://consent.cookiebot.com/uc.js"
        script.setAttribute("data-cbid", "2b9b0443-8bb3-408d-b49f-b70e7c023d02")
        script.setAttribute("data-blockingmode", "auto")
        script.type = "text/javascript"

        document.head.appendChild(script)
        return () => {
          document.head.removeChild(script)
        }
      }
    }, [])
  }
}
