import cx from "classnames"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTranslate } from "../../../utils/hooks/useTranslate"
import OrderCard from "../../../components/Cards/OrderCard"
import LoadingBullets from "../../../components/LoadingBullets"
import Chewron3Icon from "../../../assets/icons/Chewron3Icon"
import CopyOrder from "../../../modules/CopyOrder"

import {
  SortOrderEnum,
  disposeCopyOrderModal,
  selectOrdersCopyOrderModal,
  selectOrdersData,
  selectOrdersListLoading,
  selectOrdersTableSortFilters,
  selectOrdersTableSortOptions,
  setOrderCopyModalOpen,
  toggleTableSort
} from "../orders.controller"

import styles from "./index.module.scss"

const OrdersList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslate()

  const selectTableFilters = useSelector(selectOrdersTableSortFilters)
  const list = useSelector(selectOrdersData)
  const tableSortlist = useSelector(selectOrdersTableSortOptions)
  const { isOpen, data } = useSelector(selectOrdersCopyOrderModal)
  const isListLoading = useSelector(selectOrdersListLoading)

  const closeCopyModalHandler = () => {
    dispatch(disposeCopyOrderModal())
  }

  const openModalHandler = (data) => {
    dispatch(setOrderCopyModalOpen(data))
  }

  if (!list.length && !isListLoading) {
    return <div className={styles.empty}>{t["orders-empty-message"]}</div>
  }

  return (
    <div className={styles.box}>
      <div className={styles.sort}>
        {tableSortlist?.map((item) => {
          return (
            <button
              key={item.id}
              onClick={() => {
                dispatch(
                  toggleTableSort({
                    key: item.key
                  })
                )
              }}
              type='button'
              className={cx(styles.button, {
                [styles.asc]: selectTableFilters[item.key] === SortOrderEnum.ASC,
                [styles.desc]: selectTableFilters[item.key] === SortOrderEnum.DESC,
                [styles.default]: selectTableFilters[item.key] === SortOrderEnum.DEFAULT
              })}
            >
              <span>{t[item.label]}</span>
              <Chewron3Icon />
            </button>
          )
        })}
      </div>
      <div className={styles.list}>
        {list.map((item) => {
          return (
            <OrderCard
              key={item.id}
              date={item.date}
              number={item.number}
              summ={item.summ}
              user={item.user}
              tooltip={<span dangerouslySetInnerHTML={{ __html: t["order-card-csv-tooltip"] }} />}
              id={item.id}
              copyClickHandler={() => openModalHandler(item)}
              imported={item.is_imported}
            />
          )
        })}
      </div>
      {isListLoading && <LoadingBullets size='sm' />}
      <CopyOrder isModalOpen={isOpen} closeModal={closeCopyModalHandler} data={data} />
    </div>
  )
}

export default OrdersList
