import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const SearchIcon = ({ color, strokeWidth, ...props }) => (
  <svg
    width={`${pxToRem(22)}rem`}
    height={`${pxToRem(23)}rem`}
    viewBox='0 0 22 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M21 21.5L17.1112 17.6111M19.8889 10.9444C19.8889 16.1605 15.6605 20.3889 10.4444 20.3889C5.22843 20.3889 1 16.1605 1 10.9444C1 5.72843 5.22843 1.5 10.4444 1.5C15.6605 1.5 19.8889 5.72843 19.8889 10.9444Z'
      stroke={color || "#333"}
      strokeWidth={strokeWidth || 2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

SearchIcon.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number
}

export default SearchIcon
