export const brandSliderMutationFunction = (data) => {
  const array = Object.values(data || {}).map((item) => {
    let buttons = Object.values(item?.buttons || {})

    if (!buttons.length) {
      buttons = [{}, {}]
    } else if (buttons.length === 1) {
      buttons = [buttons[0], {}]
    }

    return {
      logo: item.logo,
      image: item.image,
      title: item.title,
      description: item.description,
      color: item?.color,
      //   button 1
      button1Label: buttons[0].name,
      button1Type: buttons[0].type,
      button1Url: buttons[0].link,
      button1NewTab: buttons[0].newtab,
      //   button 2
      button2Label: buttons[1].name,
      button2Type: buttons[1].type,
      button2Url: buttons[1].link,
      button2NewTab: buttons[1].newtab
    }
  })

  return { data: array }
}
