import { useCallback, useEffect, useState } from "react"

export const setElementMaxHeight = (ref, setMaxHeight, extraHeight = 0) => {
  if (ref?.current) {
    const boxScrollHeight = ref.current.scrollHeight
    setMaxHeight(`${boxScrollHeight + extraHeight}px`)
  }
}

export const useAccordion = (panelRef, setActive, delay = 300) => {
  const [maxHeight, setMaxHeight] = useState("auto")

  const onEscapePress = (e) => {
    if (e.code === "Escape") {
      setActive(null)
    }
  }

  const handleResize = useCallback(() => {
    setTimeout(() => setElementMaxHeight(panelRef, setMaxHeight, 0), delay)
  }, [panelRef, setActive, delay])

  useEffect(() => {
    handleResize()

    window.document.addEventListener("keydown", onEscapePress)
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("keydown", onEscapePress)
      window.addEventListener("resize", handleResize)
    }
  }, [])

  return {
    maxHeight,
    setMaxHeight,
    handleResize
  }
}
