import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { useEffect, useMemo } from "react"
import Layout from "../../modules/Layout"
import Container from "../../modules/Container"
import { useTranslate } from "../../utils/hooks/useTranslate"
import SuccessMessage from "../../modules/SuccessMessage"
import OrderInfo from "./OrderInfo"
import OrderList from "./OrderList"
import Button, { ButtonVariantEnum } from "../../components/Button"
import Breadcrumbs from "../../components/Breadcrumbs"
import AddressModal from "./AddressModal"
import { AddressCreateEditModal } from "../../modules/AddressCreateEditModal"
import Spinner from "../../components/Spinner"

import {
  changeAddressDataSelected,
  disposeAddressCreateModal,
  disposePage,
  getCartData,
  selectCartAddressCreateModal,
  selectCartAddressModal,
  selectCartData,
  selectCartLoadingData,
  selectCartSubmitLoading,
  toggleAddressModal
} from "./cart.controller"
import {
  addShippingAddress,
  getInvoiceAddress,
  getShippingAddress,
  selectProfileShippingAddressList
} from "../Profile/profile.controller"

import styles from "./index.module.scss"

const Cart = () => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const success = searchParams.get("success")
  const orderNumber = searchParams.get("order-number")

  const addressModal = useSelector(selectCartAddressModal)
  const addressCreateModal = useSelector(selectCartAddressCreateModal)
  const shippingAddressList = useSelector(selectProfileShippingAddressList)
  const { selectedAddress } = useSelector(selectCartAddressModal)
  const { products, shippingAddress } = useSelector(selectCartData)
  const isLoading = useSelector(selectCartLoadingData)
  const isSubmitting = useSelector(selectCartSubmitLoading)

  const breadcrumbs = useMemo(
    () => [
      {
        url: "/cart",
        label: t["navigation-cart"]
      }
    ],
    [t]
  )

  useEffect(() => {
    dispatch(getCartData())
    dispatch(getShippingAddress())
    dispatch(getInvoiceAddress())

    return () => dispatch(disposePage())
  }, [])

  if (success && orderNumber) {
    return (
      <SuccessMessage
        breadcrumbs={<Breadcrumbs path={breadcrumbs} className={styles.breadcrumbs} />}
        title={t["order-success-text-1"]}
        subtitle={t["order-success-text-2"]}
        description={
          <>
            <span dangerouslySetInnerHTML={{ __html: t["order-success-text-3"] }} /> <b>{orderNumber}</b>
          </>
        }
        buttonUrl='/profile/orders'
        buttonLabel={t["button-to-my-orders"]}
      />
    )
  }

  if (!isLoading && !products?.length) {
    return (
      <SuccessMessage
        breadcrumbs={<Breadcrumbs path={breadcrumbs} className={styles.breadcrumbs} />}
        title={t["shop-cart-empty-text-1"]}
        description={<span dangerouslySetInnerHTML={{ __html: t["shop-cart-empty-text-3"] }} />}
        buttonUrl='/products'
        buttonLabel={t["button-continue-shopping"]}
      />
    )
  }

  if (isLoading)
    return (
      <Layout>
        <div className={styles.box}>
          <Container className={styles.box__inner}>
            <div className={styles.box__header}>
              <Breadcrumbs path={breadcrumbs} className={styles.breadcrumbs} />
            </div>
            <div>
              <Spinner />
            </div>
          </Container>
        </div>
      </Layout>
    )

  return (
    <Layout>
      <div className={styles.box}>
        <Container className={styles.box__inner}>
          <div className={styles.box__header}>
            <Breadcrumbs path={breadcrumbs} className={styles.breadcrumbs} />
          </div>
          <div className={styles.info}>
            <OrderInfo formId='order-info' />
          </div>
          <div className={styles.list}>
            <OrderList />
          </div>
          <div className={styles.buttons}>
            <Button text={<span dangerouslySetInnerHTML={{ __html: t["button-continue-shopping"] }} />} href='/products' />
            <Button
              text={<span dangerouslySetInnerHTML={{ __html: t["button-order"] }} />}
              variant={ButtonVariantEnum.SECONDARY}
              submitType
              form='order-info'
              loading={isSubmitting}
            />
          </div>
        </Container>
        <AddressModal
          isModalOpen={addressModal?.isOpen}
          onSubmit={(data) => {
            if (data) dispatch(changeAddressDataSelected(data))
            dispatch(toggleAddressModal(false))
          }}
          closeModal={() => dispatch(toggleAddressModal(false))}
          data={shippingAddressList}
          initSelected={selectedAddress || shippingAddress}
        />
        <AddressCreateEditModal
          isModalOpen={addressCreateModal?.isOpen}
          onSubmit={addShippingAddress}
          closeModal={() => {
            dispatch(disposeAddressCreateModal())
          }}
        />
      </div>
    </Layout>
  )
}

export default Cart
