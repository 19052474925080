import React from "react"
import PropTypes from "prop-types"
import { useWindowSize } from "../../utils/hooks/useWindowSize"

export const ScreenSizeEnum = {
  MOBILE: "mobile",
  SMALL_TABLET: "small_tablet",
  TABLET: "tablet",
  DESKTOP: "desktop"
}

const ScreenCheck = ({ children, mobile, smallTablet, tablet, desktop }) => {
  const { isMobile, isSmallTablet, isTablet, isDesktop } = useWindowSize()

  if (isMobile && mobile) return children
  if (isSmallTablet && smallTablet) return children
  if (isTablet && tablet) return children
  if (isDesktop && desktop) return children

  return null
}

ScreenCheck.propTypes = {
  mobile: PropTypes.bool,
  smallTablet: PropTypes.bool,
  tablet: PropTypes.bool,
  desktop: PropTypes.bool,
  children: PropTypes.node
}

export default ScreenCheck
