import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const Chewron3Icon = ({ color, strokeWidth, ...props }) => (
  <svg
    width={`${pxToRem(11)}rem`}
    height={`${pxToRem(7)}rem`}
    viewBox='0 0 11 7'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M1 1.5L5.5 5.5L10 1.5'
      stroke={color || "#333333"}
      strokeWidth={strokeWidth || 2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

Chewron3Icon.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number
}

export default Chewron3Icon
