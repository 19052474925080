/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getBrands, getCategories, getFavoritesProductsReq } from "../../api/product"
import { productResponseMapper } from "../../utils/product-response-mapper"

export const ListViewEnum = {
  TABLE: "table",
  LIST: "list"
}

export const FAVORITES_ALL_FILTER_OPTION = {
  id: "all",
  label: "all"
}

const initialState = {
  favorites: [],
  brandsOptions: [],
  categoriesOptions: [],

  meta: {
    all_page: 1,
    current_page: 1
  },

  view: ListViewEnum.LIST,

  filters: {
    brand: {},
    category: {},
    page: 1,
    isLoadMore: false
  },

  brandAccordion: {
    activeIndex: -1
  },
  categoryAccordeon: {
    activeIndex: -1
  },

  isFavoritesListLoading: false,
  isFavoritesBrandsOptionsLoading: false,
  isFavoritesCategoriesOptionsLoading: false
}

export const getFavorites = createAsyncThunk("favorites/getFavorites", async ({ data, isLoadMore }, { rejectWithValue }) => {
  try {
    const response = await getFavoritesProductsReq(data)
    if (response.status === 200) {
      if (response.data) {
        return { data: response.data.data, isLoadMore }
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

export const getFavoritesBrands = createAsyncThunk("favorites/getFavoritesBrands", async (_, { rejectWithValue }) => {
  try {
    const response = await getBrands()
    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

export const getFavoritesCategories = createAsyncThunk("favorites/getFavoritesCategories", async (_, { rejectWithValue }) => {
  try {
    const response = await getCategories()
    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

/* eslint-disable no-param-reassign */
export const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    toggleListView(state) {
      state.view = state.view === ListViewEnum.LIST ? ListViewEnum.TABLE : ListViewEnum.LIST
    },
    setFavoritesCategoriesFilter(state, action) {
      state.filters = { ...state.filters, category: action.payload, page: 1, isLoadMore: false }
    },
    setFavoritesBrandFilter(state, action) {
      state.filters = { ...state.filters, brand: action.payload, category: {}, page: 1, isLoadMore: false }
    },
    setFavoritesCategoryAccordionActiveIndex(state, action) {
      state.categoryAccordeon.activeIndex = action.payload
    },
    setFavoritesBrandAccordionActiveIndex(state, action) {
      state.brandAccordion.activeIndex = action.payload
    },
    setFavoritesClearFilters(state) {
      state.filters = {
        brand: {},
        category: {},
        page: 1,
        isLoadMore: false
      }
      state.brandAccordion = {
        activeIndex: -1
      }
      state.categoryAccordeon = {
        activeIndex: -1
      }
    },
    changePageProducts(state) {
      if (state.isFavoritesListLoading === false && state.meta.all_page > state.meta.current_page) {
        state.isFavoritesListLoading = true
        state.filters = {
          ...state.filters,
          page: state.meta.current_page + 1,
          isLoadMore: true
        }
      }
    },
    disposeState() {
      return {
        ...initialState
      }
    }
  },
  extraReducers: (builder) => {
    builder
      // Favorites list
      .addCase(getFavorites.pending, (state) => {
        state.isFavoritesListLoading = true
      })
      .addCase(getFavorites.fulfilled, (state, action) => {
        const { data, isLoadMore } = action.payload
        state.meta = {
          all_page: data?.meta?.all_page,
          current_page: data?.meta?.current_page
        }
        state.favorites = isLoadMore
          ? [...state.favorites, ...productResponseMapper(data.products)]
          : productResponseMapper(data.products)
        state.isFavoritesListLoading = false
      })
      .addCase(getFavorites.rejected, (state) => {
        state.isFavoritesListLoading = false
      })
      // Brands filter options
      .addCase(getFavoritesBrands.pending, (state) => {
        state.isFavoritesBrandsOptionsLoading = true
      })
      .addCase(getFavoritesBrands.fulfilled, (state, action) => {
        const { data } = action.payload
        const mapped = data.map((item) => {
          return {
            id: item.id,
            label: item.name
          }
        })
        state.brandsOptions = [{ ...FAVORITES_ALL_FILTER_OPTION }, ...mapped]
        state.isFavoritesBrandsOptionsLoading = false
      })
      .addCase(getFavoritesBrands.rejected, (state) => {
        state.isFavoritesBrandsOptionsLoading = false
      })
      // Categories filter options
      .addCase(getFavoritesCategories.pending, (state) => {
        state.isFavoritesCategoriesOptionsLoading = true
      })
      .addCase(getFavoritesCategories.fulfilled, (state, action) => {
        const { data } = action.payload
        state.categoriesOptions = data.map((item) => {
          const mapped =
            item.categories.map((category) => {
              return {
                id: category.id,
                label: category.name
              }
            }) || []

          return {
            ...item,
            categories: [{ ...FAVORITES_ALL_FILTER_OPTION }, ...mapped]
          }
        })
        state.isFavoritesCategoriesOptionsLoading = false
      })
      .addCase(getFavoritesCategories.rejected, (state) => {
        state.isFavoritesCategoriesOptionsLoading = false
      })
  }
})

// Lists data state
export const selectFavoritesList = (state) => state.favorites.favorites
export const selectFavoritesListMeta = (state) => state.favorites.meta
export const selectFavoritesBrandsOptions = (state) => state.favorites.brandsOptions
export const selectFavoritesCategoriesOptions = (state) => state.favorites.categoriesOptions
// Selected filters state
export const selectFavoritesBrandFilter = (state) => state.favorites.filters.brand
export const selectFavoritesCategoriesFilter = (state) => state.favorites.filters.category
export const selectFavoritesCurrentPageFilter = (state) => state.favorites.filters.page
export const selectFavoritesAllFilters = (state) => state.favorites.filters
// Views state
export const selectFavoritesListView = (state) => state.favorites.view
// Loaders
export const selectFavoritesListLoading = (state) => state.favorites.isFavoritesListLoading
export const selectFavoritesBrandsOptionsLoading = (state) => state.favorites.isFavoritesBrandsOptionsLoading
export const selectFavoritesCategoriesOptionsLoading = (state) => state.favorites.isFavoritesCategoriesOptionsLoading
// Accordions state
export const selectFavoritesBrandAccordion = (state) => state.favorites.brandAccordion
export const selectFavoritesCategoryAccordion = (state) => state.favorites.categoryAccordeon

export const {
  toggleListView,
  changePageProducts,
  setFavoritesCategoriesFilter,
  setFavoritesBrandFilter,
  setFavoritesCategoryAccordionActiveIndex,
  setFavoritesBrandAccordionActiveIndex,
  setFavoritesClearFilters,
  disposeState
} = favoritesSlice.actions

export default favoritesSlice.reducer
