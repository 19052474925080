import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const ThrashIcon = ({ color, strokeWidth, ...props }) => (
  <svg
    width={`${pxToRem(30)}rem`}
    height={`${pxToRem(32)}rem`}
    viewBox='0 0 31 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='15' cy='15' r='14.5' fill='white' stroke={color || "#333333"} />
    <path
      d='M18.3333 12.3846V19.1538C18.3333 19.6212 18.0017 20 17.5926 20H12.4074C11.9983 20 11.6667 19.6212 11.6667 19.1538V12.3846M13.1481 10.6923V9.84615C13.1481 9.37884 13.4798 9 13.8889 9L16.1111 9C16.5202 9 16.8519 9.37884 16.8519 9.84615V10.6923M13.8889 13.2308V18.3077M11 10.6923L19 10.6923M16.1111 13.2308V18.3077'
      stroke={color || "#333333"}
      strokeLinecap='round'
    />
  </svg>
)

ThrashIcon.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number
}

export default ThrashIcon
