import axios from "axios"

export const getProfileUserInfoReq = () => axios.get("/account")

export const changeProfileInfoReq = (data) => axios.post("/account", data)

// Shipping address list
export const getAddressesReq = () => axios.get("/addresses")
// Shipping address set default
export const setDefaultAddressReq = (id) => axios.post(`/addresses/set_main/${id}`)
// Shipping address new
export const addAddressReq = (data) => axios.post("/addresses/add", data)
export const editAddressReq = (id, data) => axios.put(`/addresses/update/${id}`, data)
// Shipping address delete
export const deleteAddressReq = (id) => axios.delete(`/addresses/delete/${id}`)

// Invoice address
export const getInvoiceAddressReq = () => axios.get("/addresses/invoice_address")
// Invoice address update
export const updateInvoiceAddressReq = (data) => axios.post("/addresses/invoice_address", data)
