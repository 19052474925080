import { brandSliderMutationFunction } from "./func/brand-slider.function"
import { textBlockMutationFunction } from "./func/text-block.function"

export const dataMutation = (data) => {
  if (data.code === "BRAND_SLIDER") {
    return brandSliderMutationFunction(data.slider)
  }

  if (data.code === "TEXT_BLOCK") {
    return textBlockMutationFunction(data)
  }

  return data
}
