/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { NOOP } from "../../../constants/noop"
import { formatBytes } from "../../../utils/format-bytes"

import CrossIcon from "../../../assets/icons/CrossIcon"
import FileIcon from "../../../assets/icons/FileIcon"

import styles from "./index.module.scss"

const FileItem = ({ name, size, proggress = 0, onCancel = NOOP, success, error }) => {
  return (
    <div
      className={cx(styles.box, {
        [styles.success]: success,
        [styles.error]: error
      })}
    >
      <div className={styles.file}>
        <FileIcon /> <span>{name}</span>
      </div>
      <button className={styles.cancel} type='button' onClick={onCancel}>
        <CrossIcon />
      </button>
      <div className={styles.proggress}>
        <div className={styles.top}>
          <div>{formatBytes(size)}</div>
          <div>{proggress}%</div>
        </div>
        <div className={styles.line}>
          <div
            className={styles.line__fill}
            style={{
              "--proggress-width": proggress
            }}
          />
        </div>
      </div>
    </div>
  )
}

FileItem.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  proggress: PropTypes.number,
  onCancel: PropTypes.func,
  error: PropTypes.bool,
  success: PropTypes.bool
}

export default memo(FileItem)
