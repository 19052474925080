/* eslint-disable react/no-danger */
import React, { memo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import ReactCodeInput from "react-code-input"
import cx from "classnames"
import * as Yup from "yup"

import { useDispatch, useSelector } from "react-redux"
import { useFormik } from "formik"
import { loginAuthCode, selectUserLoading } from "../../../store/slice/auth"
import Button from "../../../components/Button"
import WarningIcon from "../../../assets/icons/WarningIcon"
import { NOOP } from "../../../constants/noop"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"
import { useTranslate } from "../../../utils/hooks/useTranslate"

import styles from "./index.module.scss"

const SignInFormSchema = (t) =>
  Yup.object().shape({
    pin: Yup.string().required(t["form-required-field"])
  })

const PinStep = ({ prevStep = NOOP }) => {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [pinSieze, setPinSize] = useState(6)
  const { isMobile } = useWindowSize()
  const isLoadingUser = useSelector(selectUserLoading)

  const formik = useFormik({
    initialValues: {
      pin: ""
    },
    validationSchema: SignInFormSchema(t),
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        dispatch(loginAuthCode({ code: values.pin }))
          .unwrap()
          .then(() => {
            navigate("/")
          })
          .catch((er) => {
            formik.setErrors({
              pin: er.data
            })
          })
      } catch (error) {
        formik.setErrors({
          pin: error.message
        })
      }
    }
  })

  const handleKeyPress = (event) => {
    const { which, keyCode } = event
    if (keyCode === 13 || which === 13) {
      if (!formik.errors?.pin) {
        formik.handleSubmit()
      }
    }
  }

  return (
    <div className={styles.box}>
      <form className={styles.form} noValidate autoComplete='off' onSubmit={formik.handleSubmit}>
        <div className={styles.form__row}>
          <div className={styles.form__title}>Pin</div>
          <div className={styles.form__desc} dangerouslySetInnerHTML={{ __html: t["login-link-send-text"] }} />
        </div>
        <div
          className={cx(styles.form__row, styles.form__input, {
            [styles.errors]: formik.touched?.pin && formik.errors?.pin
          })}
          style={{
            "--pin-size": pinSieze
          }}
        >
          <ReactCodeInput
            type='number'
            disabled={isLoadingUser}
            fields={6}
            value={formik.values.pin}
            className={cx(styles.input)}
            fieldWidth='100%'
            touch={() =>
              formik.setTouched({
                pin: true
              })
            }
            untouch={() =>
              formik.setTouched({
                pin: false
              })
            }
            onChange={(v) => {
              formik.setTouched({
                pin: true
              })
              formik.setValues({
                pin: v
              })
            }}
          />
        </div>
        <div className={cx(styles.form__row, styles.form__submit)}>
          <Button disabled={isLoadingUser} text={t["button-login"]} size={isMobile ? "sm" : "lg"} submitType />
          <div
            className={cx(styles.form__alert, {
              [styles.visible]: formik.touched?.pin && formik.errors?.pin
            })}
          >
            <WarningIcon />
            {formik.errors?.pin}
          </div>
        </div>
        <div className={cx(styles.form__row, styles.form__link)}>
          <Link
            to='/'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              prevStep()
            }}
            dangerouslySetInnerHTML={{ __html: t["button-back"] }}
          />
        </div>
      </form>
    </div>
  )
}

PinStep.propTypes = {
  prevStep: PropTypes.func
}

export default memo(PinStep)
