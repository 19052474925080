import React, { memo } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Link } from "react-router-dom"
import { useTranslate } from "../../../utils/hooks/useTranslate"

import DiscountIcon from "../../../assets/icons/DiscountIcon"
import TopIcon from "../../../assets/icons/TopIcon"
import NewIcon from "../../../assets/icons/NewIcon"

import styles from "./index.module.scss"

export const HighlightTypeEnum = {
  TOP: "TOP",
  NEW: "NEW",
  DISCOUNT: "DISCOUNT"
}

export const HighlightIcons = {
  TOP: TopIcon,
  NEW: NewIcon,
  DISCOUNT: DiscountIcon
}
const HighlightCard = ({ id, type = "TOP", size = "md", href }) => {
  const { t } = useTranslate()

  const Icon = HighlightIcons[type]
  const title = {
    TOP: t["filter-top-seller"],
    NEW: t["filter-new"],
    DISCOUNT: t["filter-discount"]
  }

  return (
    <Link to={href} className={styles.link}>
      <div
        className={cx(styles.box, {
          [styles[type]]: type,
          [styles[size]]: size
        })}
      >
        <div className={styles.box__inner}>
          <div className={styles.icon}>
            <Icon />
          </div>
          <div>
            <div className={styles.title}>{title[type]}</div>
            <Link to={href} className={styles.link}>
              Ansehen
            </Link>
          </div>
        </div>
      </div>
    </Link>
  )
}

HighlightCard.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  href: PropTypes.string
}

export default memo(HighlightCard)
