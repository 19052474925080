import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import cx from "classnames"
import Search from "../../Search"
import { useTranslate } from "../../../utils/hooks/useTranslate"
import { useAccordion } from "../../../utils/hooks/useAccordion"
import { NOOP } from "../../../constants/noop"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"

import HeartIcon from "../../../assets/icons/HeartIcon"
import UserIcon from "../../../assets/icons/UserIcon"
import Chewron3Icon from "../../../assets/icons/Chewron3Icon"

import styles from "./index.module.scss"

const BurgerNavigation = ({ children, isAccordionOpen, setActive = NOOP }) => {
  const { t } = useTranslate()
  const { isMobile } = useWindowSize()
  const panelRef = useRef(null)

  const { maxHeight } = useAccordion(panelRef, setActive, 1000)

  if (!isMobile) return null

  return (
    <div className={styles.box}>
      <div className={styles.search}>
        <Search expanded leftMode size='sm' />
      </div>
      <div className={styles.list}>
        <Link to='/profile/favorites' className={styles.list__item}>
          <HeartIcon />
          {t["navigation-favorite"]}
        </Link>
        <div
          className={cx(styles.accordion, {
            [styles.open]: isAccordionOpen
          })}
        >
          <button
            type='button'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setActive(!isAccordionOpen)
            }}
            className={styles.list__item}
          >
            <UserIcon />
            {t["navigation-profile"]}
            <div
              className={cx(styles.icon, {
                [styles.active]: isAccordionOpen
              })}
            >
              <Chewron3Icon />
            </div>
          </button>
          <div className={styles.accordion__panel} ref={panelRef} style={{ "--max-height": maxHeight }}>
            <Link to='/' className={styles.list__item}>
              {t["navigation-dashboard"]}
            </Link>
            <Link to='/profile/orders' className={styles.list__item}>
              {t["navigation-orders"]}
            </Link>
            <Link to='/profile' className={styles.list__item}>
              {t["navigation-profile-2"]}
            </Link>
            <Link
              to='/'
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              className={styles.list__item}
            >
              {t["navigation-logout"]}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

BurgerNavigation.propTypes = {
  children: PropTypes.node,
  setActive: PropTypes.func,
  isAccordionOpen: PropTypes.bool
}

export default BurgerNavigation
