import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const MailIcon = ({ color, strokeWidth, ...props }) => (
  <svg
    width={`${pxToRem(17)}rem`}
    height={`${pxToRem(13)}rem`}
    viewBox='0 0 18 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M16 2.01456C16 1.19903 15.2969 0.5 14.4766 0.5H2.52344C1.70313 0.5 1 1.19903 1 2.01456M16 2.01456V10.9854C16 11.801 15.2969 12.5 14.4766 12.5H2.52344C1.70313 12.5 1 11.801 1 10.9854V2.01456M16 2.01456L8.5 7.25693L1 2.01456'
      stroke={color || "#333333"}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

MailIcon.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number
}

export default MailIcon
