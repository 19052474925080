import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const HomeIcon = ({ color, strokeWidth, ...props }) => (
  <svg
    width={`${pxToRem(27)}rem`}
    height={`${pxToRem(27)}rem`}
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M19 13.8175C19 16.9013 16.5376 19.4011 13.5 19.4011C10.4624 19.4011 8 16.9013 8 13.8175M3 26H24C25.1046 26 26 25.091 26 23.9696V9.85262C26 9.16029 25.6525 8.51567 25.0781 8.14246L14.5781 1.32025C13.9209 0.893248 13.0791 0.893248 12.4219 1.32026L1.92189 8.14246C1.34749 8.51567 1 9.16029 1 9.85262V23.9696C1 25.091 1.89543 26 3 26Z'
      stroke={color || "#333"}
      strokeWidth={strokeWidth || 2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

HomeIcon.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number
}

export default HomeIcon
