import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const EyeIcon = ({ color, strokeWidth, ...props }) => (
  <svg
    width={`${pxToRem(22)}rem`}
    height={`${pxToRem(14)}rem`}
    viewBox='0 0 22 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M11.1639 1C5.65574 1 2.0929 5.11396 1 7.17094C2.0929 9.33618 5.65574 13 11.1639 13C16.6721 13 20.0164 9.33618 21 7.17094C20.0164 5.11396 16.6721 1 11.1639 1Z'
      stroke={color || "#333333"}
      strokeLinejoin='round'
      strokeWidth={strokeWidth}
    />
    <path
      d='M15 7C15 9.20914 13.2091 11 11 11C8.79086 11 7 9.20914 7 7C7 4.79086 8.79086 3 11 3C13.2091 3 15 4.79086 15 7Z'
      stroke={color || "#333333"}
      strokeLinejoin='round'
      strokeWidth={strokeWidth}
    />
  </svg>
)

EyeIcon.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number
}

export default EyeIcon
