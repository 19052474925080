export const footerData = {
  info: {
    title: "TITANIA® FABRIK GMBH",
    address: "Fortunastr. 10-14",
    city: "42489 Wülfrath",
    tel: "02058 892880",
    mail: "sales@titania-beauty.de"
  },
  copyright: "© 2024 TITANIA® FABRIK GMBH. Alle Rechte vorbehalten.",
  logo: "/logo-titania.svg",
  logoLink: "/",
  data: [
    {
      title: "Information",
      links: [
        {
          label: "Impressum",
          url: "https://www.example.com"
        },
        {
          label: "Datenschutz",
          url: "https://www.example.com"
        },
        {
          label: "Cookie-Einstellungen",
          url: "https://www.example.com"
        },
        {
          label: "AGB",
          url: "https://www.example.com"
        },
        {
          label: "Anfahrt",
          url: "https://www.example.com"
        }
      ]
    },
    {
      title: "Service",
      links: [
        {
          label: "Kundenservice",
          url: "https://www.example.com"
        },
        {
          label: "Kontakt - Weltweit",
          url: "https://www.example.com"
        },
        {
          label: "B2B - Services",
          url: "https://www.example.com"
        }
      ]
    }
  ]
}

export const headerData = {
  logo: "/logo-titania.svg",
  logoLink: "/",
  countItems: 125,
  border: "#00202D"
}
