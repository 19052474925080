/* eslint-disable react/forbid-prop-types */
import React, { memo, useEffect, useRef, useState } from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom"
import { NOOP } from "../../../constants/noop"
import { pxToRem } from "../../../utils/px-to-rem"
import { useTouchEvent } from "../../../utils/hooks/useTouchEvent"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"

import styles from "./index.module.scss"

export const BurgerMenuDirectionEnum = {
  LEFT: "left",
  RIGHT: "right"
}

const BurgerMenu = ({ direction = BurgerMenuDirectionEnum.RIGHT, isOpen, onClose = NOOP, closeOnNavigate, content }) => {
  const ref = useRef()
  const location = useLocation()
  const [maxHeight, setMaxHeight] = useState(0)
  const { isMobile } = useWindowSize()

  const initMaxHeight = () => {
    setMaxHeight(`calc(${window.innerHeight}px - ${pxToRem(isMobile ? 67 : 81)}rem)`)
  }

  const onKeyDownHanlder = (e) => {
    if (e.key === "Escape") {
      onClose()
    }
  }

  const onResize = () => {
    if (window.innerWidth > 1024) {
      onClose()
    }

    initMaxHeight()
  }

  useTouchEvent(ref.current, {
    onTouchToRight: BurgerMenuDirectionEnum.RIGHT
      ? () => {
          onClose()
        }
      : () => {},
    onTouchToLeft: BurgerMenuDirectionEnum.LEFT
      ? () => {
          onClose()
        }
      : () => {}
  })

  useEffect(() => {
    initMaxHeight()

    window.addEventListener("keydown", onKeyDownHanlder)
    window.addEventListener("resize", onResize)

    return () => {
      window.removeEventListener("keydown", onKeyDownHanlder)
      window.removeEventListener("resize", onResize)
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      window.document.body.style.overflow = "hidden"
    } else {
      window.document.body.style.overflow = "auto"
    }
  }, [isOpen])

  useEffect(() => {
    if (closeOnNavigate) {
      onClose()
    }
  }, [location])

  return (
    <div
      className={cx(styles.box, {
        [styles[direction]]: direction,
        [styles.open]: isOpen
      })}
      style={{
        "--max-height": maxHeight
      }}
      ref={ref}
    >
      <div className={styles.box__inner}>{content}</div>
    </div>
  )
}

BurgerMenu.propTypes = {
  direction: PropTypes.string,
  closeOnNavigate: PropTypes.bool,
  content: PropTypes.any,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

export default memo(BurgerMenu)
