/* eslint-disable react/prop-types */
import cx from "classnames"
import moment from "moment"
import Button from "../../../../components/Button"
import Accordion from "../../../../components/Accordion"
import { numberFormat } from "../../../../utils/number-format"
import { CurrencyEnum } from "../../../../constants/currency"

import FileIcon from "../../../../assets/icons/FileIcon"

import styles from "./index.module.scss"

const Col = ({ title, children, alignCenter }) => (
  <div
    className={cx(styles.col, {
      [styles.center]: alignCenter
    })}
  >
    <div className={styles.col__title}>{title}</div>
    <div className={styles.col__content}>{children}</div>
  </div>
)

const MobileTable = ({
  id,
  number,
  delivery,
  desiredDeliveryDate,
  paymentMethod,
  link,
  downloadFileHandler,
  comment,
  paymentAddress,
  deliveryAddress,
  deliveryDate,
  summ,
  name,
  mail,
  downloadLink,
  t = {},
  tooltip,
  openModalHandler
}) => {
  return (
    <Accordion
      list={[
        {
          title: <span className={styles.title}>{t["order-detail-info-title"]}</span>,
          content: (
            <div className={styles.table}>
              <div className={styles.row}>
                {/* <Col title={t["order-delivery-method"]}>{delivery}</Col> */}
                <Col title={t["order-desired-date"]}>{moment(desiredDeliveryDate).format("DD.MM.YY")}</Col>
              </div>
              <div className={styles.row}>
                <Col title={t["order-payment-method"]}>{paymentMethod}</Col>
              </div>
              <div className={styles.row}>
                <Col alignCenter>
                  <Button
                    text={t["button-order-again"]}
                    size='sm'
                    onClick={() => {
                      openModalHandler({
                        id,
                        number,
                        date: desiredDeliveryDate,
                        paymentMethod,
                        deliveryAddress
                      })
                    }}
                  />
                  <button type='button' onClick={downloadFileHandler} className={styles["download-file"]}>
                    <FileIcon v2 />
                  </button>
                </Col>
              </div>
              <div className={styles.row}>
                <Col title={t["order-link"]}>{link}</Col>
              </div>
              <div className={styles.row}>
                <Col title={t["order-comment"]}>{comment}</Col>
              </div>
              <div className={styles.row}>
                <Col title={t["order-payment-address"]}>
                  <div className={styles.address}>
                    <span>{paymentAddress.Bezeichnung1}</span>
                    <span>{paymentAddress.Bezeichnung2}</span>
                    <span>{paymentAddress.Bezeichnung3}</span>
                    <span>{paymentAddress.Strasse}</span>
                    <span>{paymentAddress.PLZOrt}</span>
                    <span>{paymentAddress.Ort}</span>
                    <span>{paymentAddress.Land}</span>
                  </div>
                </Col>
              </div>
              <div className={styles.row}>
                <Col title={t["order-delivery-address"]}>
                  <div className={styles.address}>
                    <span>{deliveryAddress.Bezeichnung1}</span>
                    <span>{deliveryAddress.Bezeichnung2}</span>
                    <span>{deliveryAddress.Bezeichnung3}</span>
                    <span>{deliveryAddress.Strasse}</span>
                    <span>{deliveryAddress.PLZOrt}</span>
                    <span>{deliveryAddress.Ort}</span>
                    <span>{deliveryAddress.Land}</span>
                  </div>
                </Col>
              </div>
              <div className={styles.row}>
                <Col title={t["order-delivery-date"]}>
                  <span className={styles["big-text"]}>{moment(deliveryDate).format("DD.MM.YY")}</span>
                </Col>
                <Col title={t["order-delivery-summ"]}>
                  <span className={styles["big-text"]}>
                    {numberFormat(summ, 2)} {CurrencyEnum.EUR}
                  </span>
                </Col>
              </div>
              <div className={styles.row}>
                <Col title={t["order-person-info"]}>
                  <span>
                    {name}, <br /> <a href={`mailto:${mail}`}>{mail}</a>
                  </span>
                </Col>
              </div>
            </div>
          )
        }
      ]}
      classNames={styles.accordion}
    />
  )
}

export default MobileTable
