/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
import React, { memo, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Navigation, Pagination, Autoplay } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import cx from "classnames"
import { useSelector } from "react-redux"
import { NOOP } from "../../../constants/noop"
import Modal from "../../Modal"
import FallbackImg from "../../FallbackImg"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"
import { pxToRem } from "../../../utils/px-to-rem"
import { selectCurrentLocale } from "../../../store/slice/translations"
import { PRODUCT_CARD_FALLBACK_IMAGE_URLS } from "../../../constants/fallback-image"

import ExpandIcon from "../../../assets/icons/ExpandIcon"
import ChewronLeftIcon from "../../../assets/icons/ChewronLeftIcon"
import FallbackIcon from "../../../assets/icons/FallbackImg"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import "swiper/css/autoplay"

import styles from "./index.module.scss"

const PreviewSlider = ({ data = [], background, title, openModal = NOOP, isModalOpen, closeModal = NOOP }) => {
  const [loaded, setLoaded] = useState(false)
  const { isMobile } = useWindowSize()
  const locale = useSelector(selectCurrentLocale)

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  useEffect(() => {
    setLoaded(true)
  })

  const getSlider = () =>
    loaded && (
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        slidesOffsetBefore={0}
        height='auto'
        loop
        slidesOffsetAfter={0}
        pagination={{ clickable: true }}
        onSlideChange={NOOP}
        onSwiper={NOOP}
        breakpoints={{
          767: {
            slidesPerView: 1,
            spaceBetween: 0,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0
          }
        }}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current
          swiper.params.navigation.nextEl = navigationNextRef.current
          swiper.navigation.init()
          swiper.navigation.update()
        }}
        onAfterInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current
          swiper.params.navigation.nextEl = navigationNextRef.current
          swiper.navigation.init()
          swiper.navigation.update()
        }}
        onResize={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current
          swiper.params.navigation.nextEl = navigationNextRef.current
          swiper.navigation.init()
          swiper.navigation.update()
        }}
      >
        {data.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <FallbackImg src={item} alt={item} fallback={PRODUCT_CARD_FALLBACK_IMAGE_URLS[locale]} />
            </SwiperSlide>
          )
        })}
      </Swiper>
    )

  return (
    <div
      className={`${styles.box} preview-slider`}
      style={{
        "--product-slider-bg": background
      }}
    >
      {!!data.length && <div className={styles.slider}>{getSlider()}</div>}
      {!data.length && (
        <div className={styles.empty}>
          <img src={PRODUCT_CARD_FALLBACK_IMAGE_URLS[locale]} alt='empty' />
        </div>
      )}
      {!isMobile && !!data.length && (
        <button className={styles.expand} type='button' onClick={openModal}>
          <ExpandIcon />
        </button>
      )}
      {!!data.length && (
        <>
          <button type='button' className={styles.button_prev} ref={navigationPrevRef}>
            <ChewronLeftIcon color='#333333' strokeWidth={1} />
          </button>
          <button type='button' className={styles.button_next} ref={navigationNextRef}>
            <ChewronLeftIcon color='#333333' strokeWidth={1} />
          </button>
        </>
      )}
      <Modal
        modalIsOpen={isModalOpen}
        closeModal={closeModal}
        title=''
        cancelHandler={closeModal}
        style={{
          content: {
            width: "80%",
            height: "80%",
            maxWidth: `${pxToRem(812)}rem`,
            maxHeight: `${pxToRem(870)}rem`
          }
        }}
      >
        <div className={cx(styles.slider, styles.modal__slider, "preview-slider")}>
          <div className={styles.button_prev} ref={navigationPrevRef}>
            <ChewronLeftIcon color='#333333' strokeWidth={1} />
          </div>
          <div className={styles.button_next} ref={navigationNextRef}>
            <ChewronLeftIcon color='#333333' strokeWidth={1} />
          </div>
          {getSlider()}
        </div>
      </Modal>
    </div>
  )
}

PreviewSlider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string
    })
  ),
  background: PropTypes.string,
  title: PropTypes.string,
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  openModal: PropTypes.func
}

export default PreviewSlider
