import React, { memo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import ChewronTopIcon from "../../assets/icons/ChewronTopIcon"

import styles from "./index.module.scss"

const ScrollToTop = ({ fixed }) => {
  const [isShow, setShow] = useState(true)

  const onPress = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  useEffect(() => {
    // TODO: IF BUTTON MUST SHOW|HIDE ON SCROLL
    // setShow(window.scrollY > window.innerHeight)
    // window.addEventListener("scroll", (e) => {
    //   setShow(window.scrollY > 300)
    // })
  }, [])

  return (
    <div
      className={cx(styles.box, {
        [styles.visible]: isShow,
        [styles.fixed]: fixed
      })}
      role='button'
      tabIndex={0}
      onClick={onPress}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onPress()
        }
      }}
    >
      <ChewronTopIcon />
    </div>
  )
}

ScrollToTop.propTypes = {
  fixed: PropTypes.bool
}

export default memo(ScrollToTop)
