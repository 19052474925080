/* eslint-disable camelcase */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import HeartIcon from "../../../assets/icons/HeartIcon"
import Badge from "../../Badge"
import Button, { ButtonVariantEnum } from "../../Button"
import Counter from "../../Counter"
import FallbackImg from "../../FallbackImg"
import { NOOP } from "../../../constants/noop"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"
import { useTranslate } from "../../../utils/hooks/useTranslate"
import { setProductFavoriteReq, unsetProductFavoriteReq } from "../../../api/product"
import { CurrencyEnum } from "../../../constants/currency"
import { PRODUCT_CARD_FALLBACK_IMAGE_URLS } from "../../../constants/fallback-image"
import { selectCurrentLocale } from "../../../store/slice/translations"
import { addProductToCart, getCartProductCount } from "../../../pages/Cart/cart.controller"
import { responseErrorParser } from "../../../utils/response-error-parser"
import { TOAST_DEFAULT_CONFIG } from "../../../constants/toast"
import { numberFormat } from "../../../utils/number-format"

import styles from "./index.module.scss"

const ProductCard = ({
  id,
  horizontal,
  type,
  favorite,
  price,
  currency = CurrencyEnum.EUR,
  discount,
  article,
  title,
  image,
  count,
  ve,
  gk,
  addToFavorite = NOOP
}) => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const locale = useSelector(selectCurrentLocale)
  const [innerFavorite, setInnerFavorite] = useState(favorite)
  const [countInner, setCountInner] = useState(count || ve || 0)
  const { isDesktop, isMobile, isSmallTablet, isTablet } = useWindowSize()

  const buttonType = useMemo(() => {
    if (horizontal) return ButtonVariantEnum.SECONDARY
    if (isDesktop) return ButtonVariantEnum.PRIMARY

    return ButtonVariantEnum.TERTIARY
  }, [isDesktop, horizontal])

  const buttonSize = useMemo(() => {
    if (horizontal) return "sm"
    if (isDesktop) return "md"

    return "sm-2"
  }, [isDesktop, horizontal])

  const badgeSize = useMemo(() => {
    if (isDesktop) return "md"
    if (isSmallTablet || isTablet) return "sm-2"
    if (isMobile) return "sm"

    return "sm"
  }, [isDesktop, isMobile, isSmallTablet, isTablet])

  const counterSize = useMemo(() => {
    if (horizontal) return "sm"
    if (isDesktop) return "md"

    return "full"
  }, [isDesktop, isMobile, isSmallTablet, isTablet, horizontal])

  const changeFavorite = useCallback(
    async (favorite, id) => {
      try {
        if (!favorite) {
          await setProductFavoriteReq(id)
          setInnerFavorite(true)
          addToFavorite(true)
        } else {
          await unsetProductFavoriteReq(id)
          setInnerFavorite(false)
          addToFavorite(false)
        }
      } catch {
        addToFavorite(favorite)
        setInnerFavorite(favorite)
      }
    },
    [id, innerFavorite]
  )

  const addToCartHandler = () => {
    dispatch(
      addProductToCart({
        id,
        quantity: countInner
      })
    )
      .unwrap()
      .then((res) => {
        const { is_optimize, message } = res.data || {}
        setInnerFavorite()
        dispatch(getCartProductCount())

        if (is_optimize) {
          toast.warning(<span dangerouslySetInnerHTML={{ __html: message }} />, TOAST_DEFAULT_CONFIG)
        } else {
          toast.success(<span dangerouslySetInnerHTML={{ __html: message }} />, TOAST_DEFAULT_CONFIG)
        }
      })
      .catch((err) => {
        toast.error(responseErrorParser(err), TOAST_DEFAULT_CONFIG)
      })
  }

  useEffect(() => {
    setInnerFavorite(favorite)
  }, [favorite])

  const getCardTop = () => {
    if (horizontal) {
      return (
        <Link to={`/products/${id}`} className={styles.info__row}>
          <div className={styles.info__number}>{article}</div>
          <div className={styles.info__title}>{title}</div>
          <div
            className={cx(styles.info__price, {
              [styles.discount]: discount
            })}
          >
            {!!discount && (
              <span className={styles.info__discount}>
                {/* {horizontal && <Badge size='sm' type='sale' className={styles.badge} />} */}
                <span>
                  {numberFormat(discount, 2)} {currency}
                </span>
              </span>
            )}
            {numberFormat(price, 2, 2, currency) || t["product-price-on-request"]}
          </div>
        </Link>
      )
    }

    return (
      <>
        <Link to={`/products/${id}`} className={styles.info__row}>
          <div
            className={cx(styles.info__price, {
              [styles.discount]: discount
            })}
          >
            {!!discount && (
              <span className={styles.info__discount}>
                {horizontal && <Badge size='sm' type={type} className={styles.badge} />}
                <span>
                  {numberFormat(discount, 2)} {currency}
                </span>
              </span>
            )}
            {numberFormat(price, 2, 2, currency) || t["product-price-on-request"]}
          </div>
          <div className={styles.info__number}>
            {t["product-info-number"]}: {article}
          </div>
        </Link>
        <Link to={`/products/${id}`} className={cx(styles.info__row, styles["info__row-title"])}>
          <div className={styles.info__title}>{title}</div>
        </Link>
      </>
    )
  }

  return (
    <div
      className={cx(styles.box, {
        [styles.horizontal]: horizontal
      })}
    >
      <Link to={`/products/${id}`}>
        <div className={styles.image}>
          <FallbackImg src={image} alt={title} fallback={PRODUCT_CARD_FALLBACK_IMAGE_URLS[locale]} />
          <Badge type={type} size={badgeSize} className={styles.badge} />
          {!horizontal && (
            <button
              className={styles.favorite}
              type='button'
              onClick={(e) => {
                e.preventDefault()
                changeFavorite(innerFavorite, id)
              }}
            >
              <HeartIcon color={innerFavorite ? "#ffb7b7" : "#333333"} fill={innerFavorite} strokeWidth={1} />
            </button>
          )}
        </div>
      </Link>
      <div className={styles.info}>
        {getCardTop()}
        <div className={styles.info__row}>
          <div className={styles.info__btns}>
            {horizontal && (
              <button
                className={styles.favorite}
                type='button'
                onClick={(e) => {
                  e.preventDefault()
                  changeFavorite(innerFavorite, id)
                }}
              >
                <HeartIcon color={innerFavorite ? "#ffb7b7" : "#333333"} fill={innerFavorite} strokeWidth={1} />
              </button>
            )}
            <div className={styles.info__counter}>
              <Counter
                count={count}
                size={counterSize}
                gk={gk}
                ve={ve}
                step={ve}
                onChange={(v) => {
                  setCountInner(v)
                }}
                min={1}
              />
            </div>
            <Button text={t["button-add-to-cart"]} onClick={addToCartHandler} variant={buttonType} size={buttonSize} />
          </div>
        </div>
      </div>
    </div>
  )
}

ProductCard.propTypes = {
  id: PropTypes.string,
  horizontal: PropTypes.bool,
  favorite: PropTypes.bool,
  price: PropTypes.number,
  ve: PropTypes.number,
  gk: PropTypes.number,
  discount: PropTypes.number,
  article: PropTypes.string,
  count: PropTypes.number,
  title: PropTypes.string,
  currency: PropTypes.string,
  type: PropTypes.string,
  image: PropTypes.string,
  addToFavorite: PropTypes.func
}

export default memo(ProductCard)
