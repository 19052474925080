import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const HeartIcon = ({ color, strokeWidth, fill, ...props }) => (
  <svg
    width={`${pxToRem(27)}rem`}
    height={`${pxToRem(25)}rem`}
    viewBox='0 0 27 25'
    fill={fill ? color || "none" : "none"}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13.4725 23.75C13.2751 23.7514 13.0854 23.6723 12.9457 23.5306L4.05418 14.4947C2.18692 12.5985 1.13542 10.4615 1.01213 8.31489C0.900437 6.3577 1.56321 4.54497 2.88038 3.20842C4.19756 1.87187 5.98145 1.19773 7.90876 1.31543C9.83607 1.43315 11.7402 2.32023 13.4725 3.90182C15.2238 2.2967 17.1512 1.39139 19.0828 1.26512C21.0144 1.13884 22.8141 1.80662 24.1323 3.1453C25.4505 4.48397 26.107 6.29993 25.9858 8.27104C25.8498 10.4294 24.7814 12.5825 22.8953 14.4979L13.9942 23.5306C13.8558 23.6711 13.6682 23.75 13.4725 23.75Z'
      stroke={color || "#333333"}
      strokeWidth={strokeWidth || 2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

HeartIcon.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
  fill: PropTypes.bool
}

export default HeartIcon
