import React from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { selectIsAuth } from "../../store/slice/auth"
import { selectBurgerMenu, selectBurgerMenuAccordion, toggleBurgerMenu, toggleBurgerMenuAccordion } from "../../store/slice/page"
import BurgerNavigation from "../Burger/BurgerNavigation"

import Header from "../Header"
import Footer from "../Footer"
import BurgerMenu from "../Burger/BurgerMenu"

import { footerData, headerData } from "./fakeApi"

const Layout = ({ children }) => {
  const dispatch = useDispatch()
  const isAuth = useSelector(selectIsAuth)
  const { isOpen: isBurgerOpen } = useSelector(selectBurgerMenu)
  const { isOpen: isBurgerAccordionOpen } = useSelector(selectBurgerMenuAccordion)
  const header = headerData
  const footer = footerData

  return (
    <>
      <Header logo={header.logo} logoLink={header.logoLink} isAuth={isAuth} />
      <BurgerMenu
        content={
          <BurgerNavigation setActive={(v) => dispatch(toggleBurgerMenuAccordion(v))} isAccordionOpen={isBurgerAccordionOpen} />
        }
        isOpen={isBurgerOpen}
        closeOnNavigate
        onClose={() => {
          dispatch(toggleBurgerMenu(false))
          dispatch(toggleBurgerMenuAccordion(false))
        }}
      />
      <main>{children}</main>
      <Footer logo={footer.logo} logoLink={footer.logoLink} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node
}

export default Layout
