/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getCategories, getViewedProductReq } from "../../api/product"
import { productResponseMapper } from "../../utils/product-response-mapper"

// TODO: Test data
import { csvColumnFilters } from "./fakeApi"

export const QuickLinksStateEnum = {
  OPEN: "open",
  CLOSE: "close"
}

const storedQuicklinksInfo = localStorage.getItem("quicklinks")

const getStoredQuickLinksInitState = () => {
  if (window.innerWidth <= 768 || (storedQuicklinksInfo && storedQuicklinksInfo === QuickLinksStateEnum.CLOSE)) {
    return -1
  }
  return storedQuicklinksInfo ? Number(storedQuicklinksInfo) : 1
}

const initialState = {
  categoryTags: [],
  isCategoryTagsLoading: false,

  highlightsTags: [],

  categories: [],
  quicklinksAccordion: {
    activeIndex: getStoredQuickLinksInitState()
  },

  quicklinks: {
    file: null
  },

  columnPreviewModal: {
    isOpen: false,
    data: null,
    accordionOne: {
      activeIndex: -1,
      selectedFilter: null
    },
    accordionTwo: {
      activeIndex: -1,
      selectedFilter: null
    },
    filters: [...csvColumnFilters]
  },
  viewedProducts: [],
  viewedProductsLoading: true
}

export const getViewidProducts = createAsyncThunk("home/getViewidProducts", async (id, { rejectWithValue }) => {
  try {
    const response = await getViewedProductReq()
    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

export const getDataCategory = createAsyncThunk("home/getCategory", async (_, { rejectWithValue }) => {
  try {
    const response = await getCategories()
    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

/* eslint-disable no-param-reassign */
export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    toggleQuicklinks(state, action) {
      localStorage.setItem("quicklinks", action.payload ? action.payload : QuickLinksStateEnum.CLOSE)

      return {
        ...state,
        quicklinksAccordion: {
          activeIndex: action.payload
        }
      }
    },
    setCategoryTag(state, action) {
      return {
        ...state,
        categoryTags: state.categoryTags.map((item) => {
          if (item.id === action.payload) {
            return {
              ...item,
              active: true
            }
          }
          return {
            ...item,
            active: false
          }
        })
      }
    },
    setHighlightsTag(state, action) {
      return {
        ...state,
        highlightsTags: state.highlightsTags.map((item) => {
          if (item.id === action.payload) {
            return {
              ...item,
              active: true
            }
          }
          return {
            ...item,
            active: false
          }
        })
      }
    },
    setQuicklinksFile(state, action) {
      return {
        ...state,
        quicklinks: {
          ...state.quicklinks,
          file: action.payload
        }
      }
    },
    toggleColumnPreviewModal(state, action) {
      return {
        ...state,
        columnPreviewModal: {
          ...state.columnPreviewModal,
          accordionOne: {
            ...state.columnPreviewModal.accordionOne,
            selectedFilter: null,
            activeIndex: -1
          },
          accordionTwo: {
            ...state.columnPreviewModal.accordionTwo,
            selectedFilter: null,
            activeIndex: -1
          },
          isOpen: action.payload
        }
      }
    },
    setColumnPreviewModalData(state, action) {
      return {
        ...state,
        columnPreviewModal: {
          ...state.columnPreviewModal,
          data: action.payload
        }
      }
    },
    setColumnAccordionOneActiveIndex(state, action) {
      return {
        ...state,
        columnPreviewModal: {
          ...state.columnPreviewModal,
          accordionOne: {
            ...state.columnPreviewModal.accordionOne,
            activeIndex: action.payload
          }
        }
      }
    },
    setColumnAccordionOneFilter(state, action) {
      return {
        ...state,
        columnPreviewModal: {
          ...state.columnPreviewModal,
          accordionOne: {
            ...state.columnPreviewModal.accordionOne,
            selectedFilter: action.payload
          }
        }
      }
    },
    setColumnAccordionTwoActiveIndex(state, action) {
      return {
        ...state,
        columnPreviewModal: {
          ...state.columnPreviewModal,
          accordionTwo: {
            ...state.columnPreviewModal.accordionTwo,
            activeIndex: action.payload
          }
        }
      }
    },
    setColumnAccordionTwoFilter(state, action) {
      return {
        ...state,
        columnPreviewModal: {
          ...state.columnPreviewModal,
          accordionTwo: {
            ...state.columnPreviewModal.accordionTwo,
            selectedFilter: action.payload
          }
        }
      }
    },
    disposePreviewModal(state) {
      return {
        ...state,
        columnPreviewModal: {
          ...state.columnPreviewModal,
          isOpen: false,
          data: null,
          accordionOne: {
            activeIndex: -1,
            selectedFilter: null
          },
          accordionTwo: {
            activeIndex: -1,
            selectedFilter: null
          }
        }
      }
    },
    disposePage() {
      return {
        ...initialState
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getViewidProducts.pending, (state) => {
        state.viewedProductsLoading = true
      })
      .addCase(getViewidProducts.fulfilled, (state, action) => {
        state.viewedProductsLoading = false
        state.viewedProducts = productResponseMapper(action.payload.data)
      })
      .addCase(getViewidProducts.rejected, (state) => {
        state.viewedProductsLoading = false
      })
      .addCase(getDataCategory.pending, (state) => {
        state.isCategoryTagsLoading = true
      })
      .addCase(getDataCategory.fulfilled, (state, action) => {
        let clearCategoryTags = action.payload.data.filter(
          (value, index, self) => index === self.findIndex((t) => t.id === value.id)
        )
        clearCategoryTags = clearCategoryTags.map((item, i) => {
          if (i === 0) return { ...item, active: true }
          return { ...item, active: false }
        })

        return {
          ...state,
          isCategoryTagsLoading: false,
          categoryTags: clearCategoryTags,
          highlightsTags: clearCategoryTags
        }
      })
      .addCase(getDataCategory.rejected, (state, action) => {
        state.isCategoryTagsLoading = false
      })
  }
})

export const selectCategoryTags = (state) => state.home.categoryTags
export const selectHighlightsTags = (state) => state.home.highlightsTags
export const selectCategories = (state) => state.home.categories
export const selectQuickLinksAccorion = (state) => state.home.quicklinksAccordion
export const selectQuickLinksData = (state) => state.home.quicklinks
export const selectColumnPreviewModal = (state) => state.home.columnPreviewModal
export const selectColumnPreviewModalAccordionOne = (state) => state.home.columnPreviewModal.accordionOne
export const selectColumnPreviewModalAccordionTwo = (state) => state.home.columnPreviewModal.accordionTwo
export const selectDashboardViewedProducts = (state) => state.home.viewedProducts
export const selectDashboardViewedProductsLoading = (state) => state.home.viewedProductsLoading

export const {
  toggleQuicklinks,
  setQuicklinksFile,
  toggleColumnPreviewModal,
  setColumnPreviewModalData,
  setCategoryTag,
  setHighlightsTag,
  setColumnAccordionOneActiveIndex,
  setColumnAccordionOneFilter,
  setColumnAccordionTwoActiveIndex,
  setColumnAccordionTwoFilter,
  disposePreviewModal,
  disposePage
} = homeSlice.actions

export default homeSlice.reducer
