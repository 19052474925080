/* eslint-disable no-param-reassign */
import CONFIG from "../config"

export default function setupAxios(axios) {
  axios.defaults.baseURL = CONFIG.API_URL
  axios.interceptors.request.use(
    (config) => {
      config.headers = {
        "Content-Type": "application/json",
        "Accept-Language": localStorage.getItem("locale") || "de",
        Accept: "application/json"
      }

      if (localStorage.getItem("auth")) {
        config.headers = {
          ...config.headers,
          "X-Authorization": `Bearer ${localStorage.getItem("auth")}`
        }
      }

      return config
    },
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      async (error) => {
        const { response } = error
        if (response?.status === 401) {
          localStorage.removeItem("auth")
          window.location = "/login"
        }
        return Promise.reject(response)
      }
    ),
    (err) => Promise.reject(err)
  )
}
