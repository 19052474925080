import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet"
import Layout from "../../modules/Layout"
import Container from "../../modules/Container"
import { getPageData, selectFooterData, disposePage, selectIsFooterDataLoaded, selectPageData } from "../../store/slice/page"
import { selectCurrentLocale } from "../../store/slice/translations"
import DynamicComponents from "../../modules/Dynamic"

import styles from "./index.module.scss"

const DynamicPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const path = useLocation()
  const dynamicLinks = useSelector(selectFooterData)
  const isFooterDataLoaded = useSelector(selectIsFooterDataLoaded)
  const currentLocale = useSelector(selectCurrentLocale)
  const data = useSelector(selectPageData)

  // check is window path name exist in dynamic pages list & get page id, else redirect on 404
  const getPageId = () => {
    const pathname = path.pathname.replace("/", "")

    const isInformationDynamicPage = dynamicLinks.service.find((item) => item.linkname === pathname)
    const isServiceDynamicPage = dynamicLinks.information.find((item) => item.linkname === pathname)

    return isInformationDynamicPage?.textid || isServiceDynamicPage?.textid
  }

  useEffect(() => {
    if (isFooterDataLoaded.service && isFooterDataLoaded.information) {
      const id = getPageId()
      if (!id) {
        return navigate("/404")
      }

      dispatch(getPageData(id))
    }
  }, [dynamicLinks, isFooterDataLoaded, path])

  useEffect(() => {
    const id = getPageId()
    if (!id) return

    dispatch(getPageData(id))
  }, [currentLocale])

  useEffect(() => {
    document.title = data?.meta_title
  }, [data])

  useEffect(() => {
    return () => dispatch(disposePage())
  }, [])

  return (
    <Layout>
      <Helmet>
        <title>{data?.meta_title}</title>
        <meta name='description' content={data?.meta_description} />
        <meta property='og:title' content={data?.meta_title} />
        <meta property='og:description' content={data?.og_description} />
        <meta property='og:image' content={data?.og_img} />
      </Helmet>
      <div className={styles.box}>
        <Container className={styles.box__inner}>{data?.id && <DynamicComponents content={data.content} />}</Container>
      </div>
    </Layout>
  )
}

export default DynamicPage
