/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getOrderInfoReq, getOrdersReq } from "../../api/order"
import { orderDataApi } from "./fakeApi"
import { CurrencyEnum } from "../../constants/currency"
import { productResponseMapper } from "../../utils/product-response-mapper"

const initialState = {
  data: {
    id: "",
    number: "",
    delivery: "",
    desiredDeliveryDate: "",
    paymentMethod: "",
    link: " ",
    comment: "",
    paymentAddress: "",
    deliveryAddress: "",
    deliveryDate: "",
    summ: 0,
    name: "",
    mail: "",
    downloadLink: "",
    products: [],
    total: 0,
    currency: CurrencyEnum.EUR
  },
  copyOrderModal: {
    isOpen: false,
    data: null
  },
  isOrderLoading: false,
  methodAccordion: {
    activeIndex: -1,
    selected: null
  },
  methodOptions: [],
  addressModal: {
    isOpen: false
  },
  addressData: []
}

export const getOrderData = createAsyncThunk("order/getOrder", async (id, { rejectWithValue }) => {
  try {
    const response = await getOrderInfoReq(id)
    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrderCopyModalOpen(state, action) {
      return {
        ...state,
        copyOrderModal: {
          ...state.copyOrderModal,
          isOpen: true,
          data: action.payload
        }
      }
    },
    disposeCopyOrderModal(state) {
      return {
        ...state,
        copyOrderModal: {
          ...initialState.copyOrderModal
        }
      }
    },
    disposePage() {
      return {
        ...initialState
      }
    }
  },
  extraReducers: (builder) => {
    builder
      // Get order info
      .addCase(getOrderData.pending, (state) => {
        state.isOrderLoading = true
      })
      .addCase(getOrderData.fulfilled, (state, action) => {
        const { data } = action.payload
        const mappedProducts = productResponseMapper(data.products) || []
        state.isOrderLoading = false
        state.data = {
          ...state.data,
          id: data.id,
          number: data.number,
          delivery: orderDataApi.delivery,
          desiredDeliveryDate: data.desired_date,
          paymentMethod: data.payment || "-",
          link: data.link || "-",
          comment: data.comment || "-",
          paymentAddress: data.billing_address || {},
          deliveryAddress: data.shipping_address || {},
          deliveryDate: data.date,
          summ: +data.summ,
          name: data.user_name,
          mail: data.user_email,
          products: mappedProducts,
          total: mappedProducts.reduce((summ, current) => {
            return Number((summ + current.total).toFixed(2))
          }, 0)
        }
        state.methodAccordion = {
          ...initialState.methodAccordion,
          selected: orderDataApi.methodOptions[0]
        }
        state.methodOptions = orderDataApi.methodOptions || []
        state.isCartLoading = false
        state.addressData = orderDataApi.addressData || []
      })
      .addCase(getOrderData.rejected, (state) => {
        state.isOrderLoading = false
      })
  }
})

export const selectOrderData = (state) => state.order.data
export const selectOrderLoadingData = (state) => state.order.isOrderLoading
export const selectOrderCopyOrderModal = (state) => state.order.copyOrderModal

export const { setOrderCopyModalOpen, disposeCopyOrderModal, disposePage } = orderSlice.actions

export default orderSlice.reducer
