import PropTypes from "prop-types"
import cx from "classnames"
import Button, { ButtonVariantEnum } from "../../components/Button"
import Container from "../Container"
import Layout from "../Layout"

import styles from "./index.module.scss"

const SuccessMessage = ({ breadcrumbs, title, subtitle, description, buttonLabel, buttonUrl }) => {
  return (
    <Layout>
      <div className={styles.box}>
        <Container className={styles.box__inner}>{breadcrumbs}</Container>
        <Container
          className={cx(styles.box__inner, styles.body, {
            [styles["padding-small"]]: breadcrumbs
          })}
        >
          <h1 className={styles.title}>
            {title && <div dangerouslySetInnerHTML={{ __html: title }} />}
            {subtitle && <div dangerouslySetInnerHTML={{ __html: subtitle }} />}
          </h1>
          {description && <div className={styles.text}>{description}</div>}
          <div className={styles.button}>
            <Button href={buttonUrl} text={buttonLabel} variant={ButtonVariantEnum.SECONDARY} />
          </div>
        </Container>
      </div>
    </Layout>
  )
}

SuccessMessage.propTypes = {
  breadcrumbs: PropTypes.node,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  description: PropTypes.node,
  buttonLabel: PropTypes.node,
  buttonUrl: PropTypes.string
}

export default SuccessMessage
