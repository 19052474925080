/* eslint-disable react/forbid-prop-types */

import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import * as ModalComponent from "react-modal"
import cx from "classnames/bind"
import Button from "../Button"
import { NOOP } from "../../constants/noop"
import { pxToRem } from "../../utils/px-to-rem"

import CloseIcon from "../../assets/icons/CloseIcon"

import styles from "./index.module.scss"

ModalComponent.setAppElement("body")

const Modal = ({
  children,
  modalIsOpen,
  afterOpenModal = NOOP,
  closeModal = NOOP,
  cancelHandler = NOOP,
  successHangler = NOOP,
  style = {},
  title,
  contentLabel,
  maxWidth,
  buttonSuccessLabel,
  buttonCanselLabel,
  isLoading,
  hideButtons
}) => {
  const customStyles = {
    overlay: {
      background: "rgba(0, 0, 0, 0.45)",
      backdropFilter: `blur(${pxToRem(5)}rem)`,
      zIndex: 99999,
      ...style?.overlay
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: `${pxToRem(10)}rem`,
      padding: `${pxToRem(20)}rem ${pxToRem(20)}rem`,
      overflow: "visible",
      width: `calc(100% - ${pxToRem(20)}rem)`,
      maxWidth: maxWidth || `${pxToRem(800)}rem`,
      height: "80%",
      animation: "fadeIn 0.3s",
      ...style?.content
    }
  }

  return (
    <ModalComponent
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      onAfterOpen={() => {
        document.body.style.overflow = "hidden"
        afterOpenModal()
      }}
      onAfterClose={() => {
        document.body.style.overflow = "unset"
        closeModal()
      }}
      style={{
        ...customStyles
      }}
      contentLabel={contentLabel}
      title={title}
    >
      <div className={styles.modal}>
        <div className={styles.modal__title}>
          {title}
          <button className={styles["modal__title-btn"]} onClick={closeModal} type='button'>
            <CloseIcon />
          </button>
        </div>
        <div
          className={cx(styles.modal__content, {
            [styles.full]: !title
          })}
        >
          {children}
          {(buttonSuccessLabel || buttonCanselLabel) && !hideButtons && (
            <div
              className={styles.modal__btns}
              style={{
                "--grid-size": buttonSuccessLabel && buttonCanselLabel ? 2 : 1
              }}
            >
              {buttonCanselLabel && <Button text={buttonCanselLabel} onClick={cancelHandler} loading={isLoading} />}
              {buttonSuccessLabel && <Button text={buttonSuccessLabel} onClick={successHangler} loading={isLoading} />}
            </div>
          )}
        </div>
      </div>
    </ModalComponent>
  )
}

Modal.propTypes = {
  children: PropTypes.node,
  modalIsOpen: PropTypes.bool,
  hideButtons: PropTypes.bool,
  isLoading: PropTypes.bool,
  afterOpenModal: PropTypes.func,
  closeModal: PropTypes.func,
  cancelHandler: PropTypes.func,
  successHangler: PropTypes.func,
  style: PropTypes.any,
  title: PropTypes.string,
  maxWidth: PropTypes.number,
  contentLabel: PropTypes.any,
  buttonSuccessLabel: PropTypes.string,
  buttonCanselLabel: PropTypes.string
}

export default Modal
