import React, { memo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { NOOP } from "../../constants/noop"

import styles from "./index.module.scss"

const RadioButton = ({ id, name, checked, onChange = NOOP, children, isValid = true, rightDirection, disabled }) => {
  const [val, setVal] = useState(checked || false)

  useEffect(() => {
    setVal(checked)
  }, [checked])

  return (
    <div
      className={cx(styles.box, {
        [styles.invalid]: !isValid,
        [styles.right]: rightDirection
      })}
    >
      <input
        name={name}
        type='radio'
        id={id}
        checked={val}
        onChange={(e) => {
          setVal(e.target.checked)
          onChange(e.target.checked)
        }}
        disabled={disabled}
        aria-label={name}
      />
      <label htmlFor={id}>{children}</label>
    </div>
  )
}

RadioButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  checked: PropTypes.bool,
  rightDirection: PropTypes.bool,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.node
}

export default memo(RadioButton)
