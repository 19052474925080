import { useSelector } from "react-redux"
import { useTranslate } from "../../../utils/hooks/useTranslate"
import ProductBasketCard from "../../../components/Cards/ProductBasketCard"
import { selectOrderData } from "../order.controller"
import { CurrencyEnum } from "../../../constants/currency"

import styles from "./index.module.scss"
import { numberFormat } from "../../../utils/number-format"

const OrderList = () => {
  const { products, total } = useSelector(selectOrderData)

  const { t } = useTranslate()

  return (
    <div className={styles.box}>
      <div className={styles.title}>{t["order-detail-title-2"]}</div>
      <div className={styles.table}>
        <div className={styles.table__header}>
          <span>{t["order-article-number"]}</span>
          <span>{t["order-article-name"]}</span>
          <span>{t["order-product-count"]}</span>
          <span>{t["order-product-amount"]}</span>
          <span>{t["order-product-total-amount"]}</span>
        </div>
        <div className={styles.table__body}>
          {products.map(({ image, title, quantity, article, price, total, id, visible }, index) => {
            return (
              <ProductBasketCard
                key={`${id}-${index}`}
                id={id}
                image={image}
                name={title}
                count={`${quantity} VE`}
                number={article}
                currency={CurrencyEnum.EUR}
                summ={price}
                totalSumm={total}
                visible={visible}
              />
            )
          })}
        </div>
        <div className={styles.table__footer}>
          <span className={styles["table__footer-title"]}>{t["order-final-summ"]}</span>
          <span className={styles["table__footer-summ"]}>
            {numberFormat(total, 2)} {CurrencyEnum.EUR}
          </span>
        </div>
      </div>
    </div>
  )
}

export default OrderList
