/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { memo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { NOOP } from "../../constants/noop"

import styles from "./index.module.scss"
import EditIcon from "../../assets/icons/Edit"

const Input = ({
  id,
  name,
  label,
  type = "text",
  value,
  placeholder,
  required,
  disabled,
  errors,
  size = "md",
  onBlur = NOOP,
  onChange = NOOP,
  onFocus = NOOP,
  onKeyPress = NOOP,
  isEdit = true
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [valueInput, setValueInput] = useState(value)

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  useEffect(() => {
    setValueInput(value)
  }, [value])

  return (
    <div
      className={cx(styles.box, {
        [styles.disabled]: disabled,
        [styles[size]]: size,
        [styles["with-label"]]: label,
        [styles.focused]: isFocused,
        [styles.active]: isFocused || valueInput?.length || !isEdit,
        [styles.error]: errors
      })}
    >
      <div className={cx(styles.box__inner)}>
        <div className={styles.field}>
          <span className={styles.title}>{label}</span>
          {isEdit ? (
            <input
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={(e) => {
                onChange(id, e.target.value)
                setValueInput(e.target.value)
              }}
              className={styles.description}
              value={valueInput}
            />
          ) : (
            <div className={styles.description}>{valueInput}</div>
          )}
          <EditIcon />
        </div>
      </div>
      {errors && <p className={styles.error}>{errors.message}</p>}
    </div>
  )
}

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  errors: PropTypes.objectOf(
    PropTypes.shape({
      message: PropTypes.string
    })
  ),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  isEdit: PropTypes.bool
}

export default memo(Input)
