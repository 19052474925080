import { useEffect, useState } from "react"
import { NOOP } from "../../constants/noop"

export const useTouchEvent = (
  ref,
  { onTouchToLeft = NOOP, onTouchToRight = NOOP, onTouchToTop = NOOP, onTouchToDown = NOOP }
) => {
  let xDown = null
  let yDown = null

  const getTouches = (evt) => {
    return evt.touches
  }

  const handleTouchStart = (evt) => {
    const firstTouch = getTouches(evt)[0]
    xDown = firstTouch.clientX
    yDown = firstTouch.clientY
  }

  const handleTouchMove = (evt) => {
    if (!xDown || !yDown) {
      return
    }

    const xUp = evt.touches[0].clientX
    const yUp = evt.touches[0].clientY

    const xDiff = xDown - xUp
    const yDiff = yDown - yUp

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        onTouchToLeft()
      } else {
        onTouchToRight()
      }
    } else if (yDiff > 0) {
      onTouchToTop()
    } else {
      onTouchToDown()
    }

    xDown = null
    yDown = null
  }

  useEffect(() => {
    if (ref) {
      ref.addEventListener("touchstart", handleTouchStart, false)
      ref.addEventListener("touchmove", handleTouchMove, false)
    }

    return () => {
      if (ref) {
        ref.addEventListener("touchstart", handleTouchStart, false)
        ref.addEventListener("touchmove", handleTouchMove, false)
      }
    }
  }, [ref])

  return null
}
