import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { useTranslate } from "../../../utils/hooks/useTranslate"
import { selectOrderData } from "../order.controller"
import DesktopTable from "./DesktopTable"
import MobileTable from "./MobileTable"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"
import { NOOP } from "../../../constants/noop"
import CONFIG from "../../../config"
import { downloadFileByFetchUrl } from "../../../utils/download-by-fetch"
import { orderDownloadFileReq } from "../../../api/order"

import styles from "./index.module.scss"

const OrderInfo = ({ openModalHandler = NOOP }) => {
  const { t } = useTranslate()
  const { isDesktop } = useWindowSize()

  const {
    id,
    number,
    delivery,
    desiredDeliveryDate,
    paymentMethod,
    comment,
    link,
    paymentAddress,
    deliveryAddress,
    deliveryDate,
    summ,
    name,
    mail
  } = useSelector(selectOrderData)

  const downloadFileHandler = () => {
    downloadFileByFetchUrl(orderDownloadFileReq(id), "data:application/csv;charset=utf-8", `${number}.csv`)
  }

  return (
    <div className={styles.box}>
      <div className={styles.title}>
        {t["order-detail-title"]}
        <span>{number}</span>
      </div>
      {isDesktop && (
        <DesktopTable
          id={id}
          number={number}
          delivery={delivery}
          desiredDeliveryDate={desiredDeliveryDate}
          paymentMethod={paymentMethod}
          link={link}
          downloadFileHandler={downloadFileHandler}
          comment={comment}
          paymentAddress={paymentAddress}
          deliveryAddress={deliveryAddress}
          deliveryDate={deliveryDate}
          summ={summ}
          name={name}
          mail={mail}
          tooltip={<span dangerouslySetInnerHTML={{ __html: t["order-card-csv-tooltip"] }} />}
          downloadLink={`${CONFIG.API_URL}/order/download/${id}`}
          openModalHandler={openModalHandler}
          t={t}
        />
      )}
      {!isDesktop && (
        <MobileTable
          id={id}
          number={number}
          delivery={delivery}
          desiredDeliveryDate={desiredDeliveryDate}
          paymentMethod={paymentMethod}
          link={link}
          downloadFileHandler={downloadFileHandler}
          comment={comment}
          paymentAddress={paymentAddress}
          deliveryAddress={deliveryAddress}
          deliveryDate={deliveryDate}
          summ={summ}
          name={name}
          mail={mail}
          tooltip={<span dangerouslySetInnerHTML={{ __html: t["order-card-csv-tooltip"] }} />}
          downloadLink={`${CONFIG.API_URL}/order/download/${id}`}
          openModalHandler={openModalHandler}
          t={t}
        />
      )}
    </div>
  )
}

OrderInfo.propTypes = {
  openModalHandler: PropTypes.func
}

export default OrderInfo
