import { useEffect } from "react"
import Layout from "../../modules/Layout"
import { useTranslate } from "../../utils/hooks/useTranslate"
import Button, { ButtonVariantEnum } from "../../components/Button"
import Container from "../../modules/Container"

import styles from "./index.module.scss"

const ErrorPage = () => {
  const { t } = useTranslate()

  useEffect(() => {
    document.title = "404 - Titania"
  })

  return (
    <Layout>
      <div className={styles.box}>
        <Container className={styles.box__inner}>
          <h1 className={styles.title}>
            <div dangerouslySetInnerHTML={{ __html: t["404-page-title-1"] }} />
          </h1>
          <div className={styles.text}>{t["404-page-text"]}</div>
          <div className={styles.button}>
            <Button href='/products' text={t["button-to-shop"]} variant={ButtonVariantEnum.SECONDARY} />
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default ErrorPage
