/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  addProductToCartReq,
  confirmCartDataReq,
  deleteProductFromCartReq,
  getCartInfoReq,
  getOrdersCartCountReq,
  updateCartInfoReq,
  updateCartProductCountReq
} from "../../api/order"
import { productResponseMapper } from "../../utils/product-response-mapper"

import { orderDataApi } from "./fakeApi"
import { CurrencyEnum } from "../../constants/currency"
import { addressResponseMapper } from "../../utils/address-mapper"

const initialState = {
  data: {
    paymentMethod: "",
    date: "",
    comment: "",
    link: "",
    products: [],
    total: 0,
    shippingAddress: null,
    currency: CurrencyEnum.EUR
  },
  productCount: 0,
  methodAccordion: {
    activeIndex: -1,
    selected: null
  },
  methodOptions: [],
  addressModal: {
    isOpen: false,
    selectedAddress: null
  },
  addressCreateModal: {
    isOpen: false
  },
  isCartLoading: true,
  isCartSubmitLoading: false,
  isAddProductToCartLoading: false,
  isCartProductCountLoading: false,
  isCartProductDeleteLoading: false,
  isCartProductUpdateLoading: false
}

export const getCartData = createAsyncThunk("cart/getCartData", async (id, { rejectWithValue }) => {
  try {
    const response = await getCartInfoReq()
    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

export const addProductToCart = createAsyncThunk("cart/addProductToCart", async ({ id, quantity }, { rejectWithValue }) => {
  try {
    const response = await addProductToCartReq(id, quantity)
    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const updateCartProductCount = createAsyncThunk(
  "cart/updateCartProductCount",
  async ({ id, quantity }, { rejectWithValue }) => {
    try {
      const response = await updateCartProductCountReq(id, quantity)
      if (response.status === 200) {
        if (response.data) {
          return response.data
        }
        return {}
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const deleteCartProduct = createAsyncThunk("cart/deleteCartProduct", async (id, { rejectWithValue }) => {
  try {
    const response = await deleteProductFromCartReq(id)
    if (response.status === 200) {
      if (response.data) {
        return { id }
      }
      return { id }
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getCartProductCount = createAsyncThunk("cart/getCartProductCount", async (_, { rejectWithValue }) => {
  try {
    const response = await getOrdersCartCountReq()
    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const confirmCartData = createAsyncThunk("cart/confirmCartData", async (data, { rejectWithValue }) => {
  try {
    const response = await confirmCartDataReq(data)
    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const updateCart = createAsyncThunk("cart/updateCart", async (data, { rejectWithValue }) => {
  try {
    const response = await updateCartInfoReq(data)
    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setShippingAccordionActive(state, action) {
      return {
        ...state,
        methodAccordion: {
          ...state.methodAccordion,
          activeIndex: action.payload
        }
      }
    },
    setShippingAccordionValue(state, action) {
      return {
        ...state,
        methodAccordion: {
          ...state.methodAccordion,
          activeIndex: -1,
          selected: action.payload
        }
      }
    },
    toggleAddressModal(state, action) {
      return {
        ...state,
        addressModal: {
          ...state.addressModal,
          isOpen: action.payload
        }
      }
    },
    toggleAddressCreateModal(state, action) {
      return {
        ...state,
        addressCreateModal: {
          ...state.addressCreateModal,
          isOpen: action.payload
        }
      }
    },
    changeAddressDataSelected(state, action) {
      return {
        ...state,
        addressModal: {
          ...state.addressModal,
          selectedAddress: action.payload
        }
      }
    },
    disposeAddressModal(state) {
      return {
        ...state,
        addressModal: {
          ...initialState.addressModal
        }
      }
    },
    disposeAddressCreateModal(state) {
      return {
        ...state,
        addressCreateModal: {
          ...initialState.addressCreateModal
        }
      }
    },
    disposePage(state) {
      return {
        ...initialState,
        productCount: state.productCount
      }
    }
  },
  extraReducers: (builder) => {
    builder
      // Get cart info
      .addCase(getCartData.pending, (state) => {
        state.isCartLoading = true
      })
      .addCase(getCartData.fulfilled, (state, action) => {
        const { products, comment, link, desired_date, payment, shipping_address } = action.payload?.data || {}
        const mappedProducts = productResponseMapper(products || [])
        state.isCartLoading = false
        state.data.date = desired_date
        state.data.paymentMethod = payment || "-"
        state.data.shippingAddress = addressResponseMapper([shipping_address])[0] || {}
        state.data.link = link
        state.data.comment = comment
        state.data.products = mappedProducts
        state.data.total = mappedProducts.reduce((summ, current) => {
          return Number((summ + current.total).toFixed(2))
        }, 0)
        state.data.products = mappedProducts
        state.methodAccordion = {
          ...initialState.methodAccordion,
          selected: orderDataApi.methodOptions[0]
        }
        state.methodOptions = orderDataApi.methodOptions || []
        state.isCartLoading = false
        state.addressData = orderDataApi.addressData || []
      })
      .addCase(getCartData.rejected, (state) => {
        state.isCartLoading = false
      })
      // Quick buy: Add to cart
      .addCase(addProductToCart.pending, (state, action) => {
        state.isAddProductToCartLoading = true
      })
      .addCase(addProductToCart.fulfilled, (state, action) => {
        state.isAddProductToCartLoading = false
      })
      .addCase(addProductToCart.rejected, (state) => {
        state.isAddProductToCartLoading = false
      })
      // Orders cart product count
      .addCase(getCartProductCount.pending, (state) => {
        state.isCartProductCountLoading = true
      })
      .addCase(getCartProductCount.fulfilled, (state, action) => {
        state.isCartProductCountLoading = false
        state.productCount = action.payload.data
      })
      .addCase(getCartProductCount.rejected, (state) => {
        state.isCartProductCountLoading = false
      })
      // Update cart's product count
      .addCase(updateCartProductCount.pending, (state, action) => {
        // fix a bug when the backend returns the same value of the number of goods and the frontend does not update view,
        // because React.memo() is used (or rework ProductBasketCard)
        const { id, quantity } = action.meta.arg || {}
        const products = state.data.products.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              quantity
            }
          }
          return item
        })
        state.data.products = products
        state.isCartProductUpdateLoading = true
      })
      .addCase(updateCartProductCount.fulfilled, (state, action) => {
        const { id, quantity, total } = action.payload?.data?.product || {}
        const products = state.data.products.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              quantity,
              total
            }
          }
          return item
        })
        state.data.products = products
        state.data.total = products.reduce((summ, current) => {
          return Number((summ + current.total).toFixed(2))
        }, 0)
        state.isCartProductUpdateLoading = false
      })
      .addCase(updateCartProductCount.rejected, (state) => {
        state.isCartProductUpdateLoading = false
      })
      // Delete product from cart
      .addCase(deleteCartProduct.pending, (state) => {
        state.isCartProductDeleteLoading = true
      })
      .addCase(deleteCartProduct.fulfilled, (state, action) => {
        state.isCartProductDeleteLoading = false
        const products = state.data.products.filter((item) => item.id !== action.payload.id)
        state.data.products = products
        state.data.total = products.reduce((summ, current) => {
          return Number((summ + current.total).toFixed(2))
        }, 0)
      })
      .addCase(deleteCartProduct.rejected, (state) => {
        state.isCartProductDeleteLoading = false
      })
      // Submit cart data
      .addCase(confirmCartData.pending, (state) => {
        state.isCartSubmitLoading = true
      })
      .addCase(confirmCartData.fulfilled, (state, action) => {
        state.isCartSubmitLoading = false
        state.productCount = 0
      })
      .addCase(confirmCartData.rejected, (state) => {
        state.isCartSubmitLoading = false
      })
      .addCase(updateCart.pending, (state) => {})
      .addCase(updateCart.fulfilled, (state, action) => {})
      .addCase(updateCart.rejected, (state) => {})
  }
})

// Cart info
export const selectCartData = (state) => state.cart.data
export const selectCartProductCount = (state) => state.cart.productCount
// Loaders
export const selectAddProductToCartLoading = (state) => state.cart.isAddProductToCartLoading
export const selectCartProductCountLoading = (state) => state.cart.isCartProductCountLoading
export const selectCartLoadingData = (state) => state.cart.isCartLoading
export const selectCartProductDeleteLoading = (state) => state.cart.isCartProductDeleteLoading
export const selectCartProductUpdateLoading = (state) => state.cart.isCartProductUpdateLoading
export const selectCartSubmitLoading = (state) => state.cart.isCartSubmitLoading
// Options data
export const selectCartShippingMethod = (state) => state.cart.methodAccordion
export const selectCartShippingOptions = (state) => state.cart.methodOptions
// Address modal
export const selectCartAddressModal = (state) => state.cart.addressModal
export const selectCartAddressCreateModal = (state) => state.cart.addressCreateModal

export const {
  toggleAddressModal,
  toggleAddressCreateModal,
  changeAddressDataSelected,
  setShippingAccordionActive,
  setShippingAccordionValue,
  disposeAddressModal,
  disposeAddressCreateModal,
  disposePage
} = cartSlice.actions

export default cartSlice.reducer
