import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const CrossIcon = ({ color, fill, strokeWidth, ...props }) => (
  <svg
    width={`${pxToRem(11)}rem`}
    height={`${pxToRem(11)}rem`}
    viewBox='0 0 11 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M1 1L10 10M10 1L1 10' stroke={color || "#333333"} strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)

CrossIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  strokeWidth: PropTypes.number
}

export default CrossIcon
