/* eslint-disable consistent-return */
import { useCallback, useEffect } from "react"

export const useInfinityScroll = (ref, loading, dispatch, onScrollEndHandler) => {
  const onBottom = useCallback(() => {
    const container = ref?.current ? ref.current : document.documentElement
    if (loading === false) {
      if (window.innerHeight + document.documentElement.scrollTop + 100 > container.offsetHeight) {
        if (dispatch) dispatch(onScrollEndHandler())
        if (!dispatch) onScrollEndHandler()
      }
    }
  }, [ref, loading, onScrollEndHandler])

  useEffect(() => {
    window.addEventListener("scroll", onBottom, false)
    window.addEventListener("resize", onBottom, false)

    return () => {
      window.removeEventListener("scroll", onBottom, false)
      window.removeEventListener("resize", onBottom, false)
    }
  }, [ref, loading, dispatch, onScrollEndHandler])
}
