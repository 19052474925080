import React, { memo } from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import styles from "./index.module.scss"

const LoadingBullets = ({ count = 3, size = "md" }) => {
  return (
    <div className={styles.dots}>
      {Array.from({
        length: count
      }).map((item, index) => {
        return (
          <div
            key={index}
            className={cx(styles.dot, {
              [styles[size]]: size
            })}
            style={{
              animationDelay: `${(index + 2) / 10}s`
            }}
          />
        )
      })}
    </div>
  )
}

LoadingBullets.propTypes = {
  count: PropTypes.number,
  size: PropTypes.string
}

export default memo(LoadingBullets)
