/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  addAddressReq,
  deleteAddressReq,
  editAddressReq,
  getAddressesReq,
  getInvoiceAddressReq,
  getProfileUserInfoReq,
  setDefaultAddressReq,
  updateInvoiceAddressReq
} from "../../api/profile"
import { getManagerReq } from "../../api/manager"
import { addressResponseMapper } from "../../utils/address-mapper"

const initialState = {
  user: {
    salutation: "",
    name: "",
    surname: "",
    email: "",
    position: "",
    phone: "",
    lang: ""
  },
  managerInfo: {
    name: "",
    post: "",
    mail: "",
    phone: "",
    image: ""
  },
  shippingAddress: [],
  invoiceAddress: {},
  editProfileModal: {
    isOpen: false
  },
  newShippingAddressModal: {
    isOpen: false
  },
  editShippingAddressModal: {
    isOpen: false,
    data: null
  },
  updateInvoiceAddressModal: {
    isOpen: false
  },
  deleteAddressConfirmModal: {
    isOpen: false,
    id: null
  },
  isUserInfoLoading: false,
  isManagerInfoLoading: false,
  isShippingAddressListLoading: false,
  isShippingAddressAddLoading: false,
  isShippingAddressDeleteLoading: false,
  isInvoiceAddressLoading: false
}

export const getProfileUserData = createAsyncThunk("profile/getProfileUserData", async (_, { rejectWithValue }) => {
  try {
    const response = await getProfileUserInfoReq()
    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

export const getManagerInfo = createAsyncThunk("profile/getManagerInfo", async (_, { rejectWithValue }) => {
  try {
    const response = await getManagerReq()
    if (response.status === 200) {
      if (response.data) {
        return response.data.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

export const getShippingAddress = createAsyncThunk("profile/getShippingAddress", async (_, { rejectWithValue }) => {
  try {
    const response = await getAddressesReq()
    if (response.status === 200) {
      if (response.data) {
        return response.data.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

export const setShippingAddressDefault = createAsyncThunk(
  "profile/setShippingAddressDefault",
  async ({ id, value }, { rejectWithValue }) => {
    try {
      const response = await setDefaultAddressReq(id)
      if (response.status === 200) {
        if (response.data) {
          return { id, value }
        }
        return { id, value }
      }
    } catch (error) {
      return rejectWithValue()
    }
  }
)

export const addShippingAddress = createAsyncThunk("profile/addShippingAddress", async (data, { rejectWithValue }) => {
  try {
    const response = await addAddressReq(data)
    if (response.status === 200) {
      if (response.data) {
        return response.data.data
      }
      return { data }
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const editShippingAddress = createAsyncThunk("profile/editShippingAddress", async ({ id, data }, { rejectWithValue }) => {
  try {
    const response = await editAddressReq(id, data)
    if (response.status === 200) {
      if (response.data) {
        return response.data.data
      }
      return { data }
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const deleteShippingAddress = createAsyncThunk("profile/deleteShippingAddress", async (id, { rejectWithValue }) => {
  try {
    const response = await deleteAddressReq(id)
    if (response.status === 200) {
      if (response.data) {
        return { id }
      }
      return { id }
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getInvoiceAddress = createAsyncThunk("profile/getInvoiceAddress", async (_, { rejectWithValue }) => {
  try {
    const response = await getInvoiceAddressReq()
    if (response.status === 200) {
      if (response.data) {
        return response.data.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

export const updateInvoiceAddress = createAsyncThunk("profile/updateInvoiceAddress", async (data, { rejectWithValue }) => {
  try {
    const response = await updateInvoiceAddressReq(data)
    if (response.status === 200) {
      if (response.data) {
        return response.data.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

/* eslint-disable no-param-reassign */
export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    toggleProfileEditUserInfoModal(state, action) {
      return {
        ...state,
        editProfileModal: {
          isOpen: action.payload
        }
      }
    },
    toggleProfileAddNewShippingAddressModal(state, action) {
      return {
        ...state,
        newShippingAddressModal: {
          isOpen: action.payload
        }
      }
    },
    toggleProfileEditShippingAddressModal(state, action) {
      return {
        ...state,
        editShippingAddressModal: {
          isOpen: action.payload.open,
          data: action.payload.data || null
        }
      }
    },
    toggleProfileUpdateInvoiceAddressModal(state, action) {
      return {
        ...state,
        updateInvoiceAddressModal: {
          isOpen: action.payload
        }
      }
    },
    toggleProfileConfirmDeleteAddressModal(state, action) {
      return {
        ...state,
        deleteAddressConfirmModal: {
          isOpen: !!action.payload,
          id: action.payload || null
        }
      }
    },
    disposeProfilePage(state) {
      return {
        ...initialState,
        managerInfo: state.managerInfo
      }
    }
  },
  extraReducers: (builder) => {
    builder
      // Get user data
      .addCase(getProfileUserData.pending, (state) => {
        state.isUserInfoLoading = true
      })
      .addCase(getProfileUserData.fulfilled, (state, action) => {
        const { data } = action.payload
        state.isUserInfoLoading = false
        state.user = {
          salutation: data.salutation,
          name: data.name,
          surname: data.surname,
          email: data.email,
          position: data.position,
          phone: data.phone,
          lang: data.phone
        }
        localStorage.setItem("locale", data.lang)
      })
      .addCase(getProfileUserData.rejected, (state) => {
        state.isUserInfoLoading = false
      })
      // Get personal manager data
      .addCase(getManagerInfo.pending, (state) => {
        return {
          ...state,
          isManagerInfoLoading: true
        }
      })
      .addCase(getManagerInfo.fulfilled, (state, action) => {
        const { name, surname, position, email, phone, photo } = action.payload || {}
        return {
          ...state,
          managerInfo: {
            name: `${name} ${surname}`,
            post: position,
            mail: email,
            phone,
            image: photo
          },
          isManagerInfoLoading: false
        }
      })
      .addCase(getManagerInfo.rejected, (state) => {
        return {
          ...state,
          isManagerInfoLoading: false
        }
      })
      // Get shipping address list
      .addCase(getShippingAddress.pending, (state) => {
        state.isShippingAddressListLoading = true
      })
      .addCase(getShippingAddress.fulfilled, (state, action) => {
        state.isShippingAddressListLoading = false
        state.shippingAddress = addressResponseMapper(action.payload).sort((a, b) => {
          if (a.isDefault) return -1
          if (!a.isDefault) return 1

          return 0
        })
      })
      .addCase(getShippingAddress.rejected, (state) => {
        state.isShippingAddressListLoading = false
      })
      // Add shipping address
      .addCase(addShippingAddress.pending, (state) => {
        state.isShippingAddressAddLoading = true
      })
      .addCase(addShippingAddress.fulfilled, (state, action) => {
        // state.shippingAddress = [...state.shippingAddress, ...addressResponseMapper([action.payload])]
        state.isShippingAddressAddLoading = false
      })
      .addCase(addShippingAddress.rejected, (state) => {
        state.isShippingAddressAddLoading = false
      })
      .addCase(editShippingAddress.pending, (state) => {
        state.isShippingAddressAddLoading = true
      })
      .addCase(editShippingAddress.fulfilled, (state, action) => {
        state.isShippingAddressAddLoading = false
      })
      .addCase(editShippingAddress.rejected, (state) => {
        state.isShippingAddressAddLoading = false
      })
      // Delete shipping address
      .addCase(deleteShippingAddress.pending, (state) => {
        state.isShippingAddressDeleteLoading = true
      })
      .addCase(deleteShippingAddress.fulfilled, (state, action) => {
        state.isShippingAddressDeleteLoading = false
      })
      .addCase(deleteShippingAddress.rejected, (state) => {
        state.isShippingAddressDeleteLoading = false
      })
      // Set to default shipping address
      .addCase(setShippingAddressDefault.pending, (state) => {})
      .addCase(setShippingAddressDefault.fulfilled, (state, action) => {
        const { id, value } = action.payload
        state.shippingAddress = state.shippingAddress.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              isDefault: value
            }
          }
          return {
            ...item,
            isDefault: false
          }
        })
      })
      .addCase(setShippingAddressDefault.rejected, (state) => {})
      // Get invoice address
      .addCase(getInvoiceAddress.pending, (state) => {
        state.isInvoiceAddressLoading = true
      })
      .addCase(getInvoiceAddress.fulfilled, (state, action) => {
        const data = action.payload ? addressResponseMapper([action.payload])[0] : {}
        state.isInvoiceAddressLoading = false
        state.invoiceAddress = data
      })
      .addCase(getInvoiceAddress.rejected, (state) => {
        state.isInvoiceAddressLoading = false
      })
      // Update invoice address
      .addCase(updateInvoiceAddress.pending, (state) => {})
      .addCase(updateInvoiceAddress.fulfilled, (state, action) => {})
      .addCase(updateInvoiceAddress.rejected, (state) => {})
  }
})

// Profile state
export const selectProfileUserInfo = (state) => state.profile.user
export const selectManagerInfo = (state) => state.profile.managerInfo
export const selectProfileShippingAddressList = (state) => state.profile.shippingAddress
export const selectProfileInvoiceAddress = (state) => state.profile.invoiceAddress
// Profile loaders
export const selectProfileUserInfoLoading = (state) => state.profile.isUserInfoLoading
export const selectIsManagerInfoLoading = (state) => state.profile.isManagerInfoLoading
export const selectIsShippingAddressListLoading = (state) => state.profile.isShippingAddressListLoading
export const selectIsShippingAddressAddLoading = (state) => state.profile.isShippingAddressAddLoading
export const selectIsShippingAddressDeleteLoading = (state) => state.profile.isShippingAddressDeleteLoading
export const selectIsInvoiceAddressLoading = (state) => state.profile.isInvoiceAddressLoading
// Modals
export const selectProfileEditUserInfoModal = (state) => state.profile.editProfileModal
export const selectProfileAddShippingAddressModal = (state) => state.profile.newShippingAddressModal
export const selectProfileEditShippingAddressModal = (state) => state.profile.editShippingAddressModal
export const selectProfileUpdateInvoiceAddressModal = (state) => state.profile.updateInvoiceAddressModal
export const selectProfileConfirmDeleteAddressModal = (state) => state.profile.deleteAddressConfirmModal

export const {
  toggleProfileEditUserInfoModal,
  toggleProfileAddNewShippingAddressModal,
  toggleProfileUpdateInvoiceAddressModal,
  toggleProfileConfirmDeleteAddressModal,
  toggleProfileEditShippingAddressModal,
  disposeProfilePage
} = profileSlice.actions

export default profileSlice.reducer
