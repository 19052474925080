/* eslint-disable indent */
/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { useTranslate } from "../../../utils/hooks/useTranslate"
import Modal from "../../../components/Modal"
import RadioButton from "../../../components/RadioButton"
import Button from "../../../components/Button"
import { NOOP } from "../../../constants/noop"
import { pxToRem } from "../../../utils/px-to-rem"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"

import styles from "./index.module.scss"

const AddressModal = ({ isModalOpen, data = [], initSelected = {}, onSubmit = NOOP, closeModal = NOOP }) => {
  const { t } = useTranslate()
  const { isDesktop } = useWindowSize()
  const [selected, setSelected] = useState(initSelected)

  const modalStyles = isDesktop
    ? {
        content: {
          width: "80%",
          height: "80%",
          maxWidth: `${pxToRem(570)}rem`,
          maxHeight: `${pxToRem(529)}rem`,
          padding: `${pxToRem(40)}rem`,
          transform: "translate(-50%, -50%)"
        }
      }
    : {
        content: {
          width: "95%",
          // height: "95%",
          maxWidth: `${pxToRem(570)}rem`,
          maxHeight: "95%",
          padding: `${pxToRem(25)}rem ${pxToRem(15)}rem`,
          transform: "translate(-50%, -50%)"
        }
      }

  useEffect(() => {
    if (!isModalOpen) setSelected(null)
    if (isModalOpen) setSelected(initSelected || {})
  }, [isModalOpen])

  useEffect(() => {
    setSelected(initSelected)
  }, [initSelected])

  return (
    <Modal
      modalIsOpen={isModalOpen}
      closeModal={closeModal}
      cancelHandler={closeModal}
      title={<span dangerouslySetInnerHTML={{ __html: t["choose-address"] }} />}
      style={modalStyles}
    >
      <div className={styles.box}>
        <div className={styles.list}>
          {data.map((item, index) => {
            return (
              <div className={styles.list__item} key={index}>
                <RadioButton
                  id={item.id}
                  checked={selected ? item.id === selected?.id : item.isDefault}
                  rightDirection
                  onChange={(e) => {
                    setSelected(item)
                  }}
                >
                  <div className={styles.text}>
                    <div
                      className={styles.text__title}
                      dangerouslySetInnerHTML={{
                        __html: item.info_1
                      }}
                    />
                    <div className={styles.text__desc}>
                      <span>{item.info_2}</span>
                      <span>{item.info_3}</span>
                      <span>{item.street}</span>
                      <span>{item.index}</span>
                      <span>{item.city}</span>
                      <span>{item.land}</span>
                    </div>
                  </div>
                </RadioButton>
              </div>
            )
          })}
        </div>
        <div className={styles.button}>
          <Button text={<span dangerouslySetInnerHTML={{ __html: t["button-save"] }} />} onClick={() => onSubmit(selected)} />
        </div>
      </div>
    </Modal>
  )
}

AddressModal.propTypes = {
  isModalOpen: PropTypes.bool,
  data: PropTypes.any,
  initSelected: PropTypes.any,
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func
}

export default AddressModal
