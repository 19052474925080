import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const MinusIcon = ({ color, strokeWidth, ...props }) => (
  <svg
    width={`${pxToRem(13)}rem`}
    height={`${pxToRem(2)}rem`}
    viewBox='0 0 13 2'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M1.5 1H11.5' stroke={color || "#333"} strokeWidth={strokeWidth || 2} strokeLinecap='round' />
  </svg>
)

MinusIcon.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number
}

export default MinusIcon
