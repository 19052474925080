/* eslint-disable prefer-const */
import { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import CONFIG from "../../config"
import { selectIsAuth } from "../../store/slice/auth"
import { selectProfileUserInfo } from "../../pages/Profile/profile.controller"

export const resetMatomoTracking = () => {
  // eslint-disable-next-line
  let _paq = window._paq || []
  _paq.push(["resetUserId"])
  _paq.push(["appendToTrackingUrl", "new_visit=1"])
  _paq.push(["trackPageView"])
  _paq.push(["appendToTrackingUrl", ""])
}
const initMatomoScript = () => {
  const scriptId = "matomo-tracker"
  if (!document.getElementById(scriptId)) {
    // eslint-disable-next-line
    let d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0]
    g.async = true
    g.src = CONFIG.MATOMO_URL
    g.id = scriptId
    s.parentNode.insertBefore(g, s)
  }
}
export const useMatomoTracker = () => {
  const prevIsAuth = useRef()
  const isAuth = useSelector(selectIsAuth)
  const user = useSelector(selectProfileUserInfo)
  useEffect(() => {
    if (prevIsAuth.current === isAuth) {
      return
    }

    // eslint-disable-next-line
    let _mtm = (window._mtm = window._mtm || [])
    // eslint-disable-next-line
    let _paq = window._paq || []
    if (isAuth !== undefined) {
      if (isAuth) {
        if (user?.email && user.email !== "") {
          // Update the ref to the current value
          prevIsAuth.current = isAuth
          if (_mtm?.length > 0) {
            resetMatomoTracking()
          }
          _paq.push(["setUserId", user.email])
          _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start", uemail: user.email })
          _paq.push(["trackPageView"])
          initMatomoScript()
        }
      } else {
        // Update the ref to the current value
        prevIsAuth.current = isAuth
        if (_mtm?.length > 0) {
          resetMatomoTracking()
        } else {
          _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" })
        }
        initMatomoScript()
      }
    }
  }, [user, isAuth])
}
