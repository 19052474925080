import React, { memo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { NOOP } from "../../constants/noop"

import PlusIcon from "../../assets/icons/PlusIcon"
import MinusIcon from "../../assets/icons/MinusIcon"

import styles from "./index.module.scss"

export const CounterDirectionEnum = {
  VERTICAL: "vertical",
  HORIZONTAL: "horizontal"
}

const calculateStep = (val, step) => {
  const calculated = val - (val % step)
  return calculated || step
}

const Counter = ({ count, disabled, min, max, step, size, onChange, direction, ve, gk }) => {
  const [countValue, setCountValue] = useState(count || step)

  const decrement = () => {
    if (countValue - step < min) {
      return
    }

    setCountValue(countValue - step)
    onChange(countValue - step)
  }

  const increment = () => {
    if (countValue + step > max) {
      return
    }

    setCountValue(countValue + step)
    onChange(countValue + step)
  }

  const handleChange = (e) => {
    setCountValue(e.target.value)
  }

  const handleBlur = (e) => {
    const { value } = e.target
    let num = parseInt(value, 10)

    num = num > max ? max : num
    num = num < min ? min : num

    if (Number.isNaN(num) === true) {
      setCountValue(step)
      onChange(step)
      e.target.value = step
    } else {
      setCountValue(num)
      onChange(num)
      e.target.value = num
    }
  }

  useEffect(() => {
    setCountValue(count || step)
  }, [count, step])

  return (
    <div
      className={cx(styles.box, {
        [styles[size]]: size,
        [styles[direction]]: direction,
        [styles.disabled]: disabled
      })}
    >
      <div className={cx(styles.box__inner, {})}>
        <button type='button' onClick={decrement} className={cx(styles.btn, styles.decrement)}>
          <MinusIcon />
        </button>
        <input
          type='number'
          disabled={disabled}
          value={countValue}
          onChange={handleChange}
          onBlur={handleBlur}
          aria-label='counter'
        />
        <button type='button' onClick={increment} className={cx(styles.btn, styles.increment)}>
          <PlusIcon />
        </button>
      </div>
      <div className={styles["pack-info"]}>
        VE:{ve} | GK: {gk}
      </div>
    </div>
  )
}

Counter.defaultProps = {
  count: 0,
  max: Infinity,
  min: 0,
  disabled: false,
  step: 1,
  size: "md",
  direction: CounterDirectionEnum.HORIZONTAL,
  onChange: NOOP,
  ve: 0,
  gk: 0
}

Counter.propTypes = {
  count: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  size: PropTypes.string,
  direction: PropTypes.string,
  disabled: PropTypes.bool,
  step: PropTypes.number,
  onChange: PropTypes.func,
  ve: PropTypes.number,
  gk: PropTypes.number
}

export default memo(Counter)
