import React, { memo, useCallback, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { useDispatch, useSelector } from "react-redux"
import _debounce from "lodash/debounce"
import { useLocation } from "react-router-dom"
import TextInput from "../../components/TextInput"
import SearchResultPanel from "../../components/SearchResultPanel"
import { useOnClickOutside } from "../../utils/hooks/useClickOutside"
import { useTranslate } from "../../utils/hooks/useTranslate"

import {
  disposeHeaderSearch,
  headerSearchProductData,
  selectHeaderSearchData,
  setPageHeaderSearchValue,
  toggleHeaderSearchPanel,
  toggleHeaderSearchTextInput
} from "../../store/slice/search"

import SearchIcon from "../../assets/icons/SearchIcon"

import styles from "./index.module.scss"

const Search = ({ expanded, leftMode, size }) => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const location = useLocation()

  const ref = useRef()
  const containerRef = useRef()

  const { isShowTextInput, isShowResultPanel, value, isLoading, result } = useSelector(selectHeaderSearchData)

  useOnClickOutside(containerRef, () => {
    dispatch(toggleHeaderSearchPanel(false))
  })

  const onClick = () => {
    dispatch(toggleHeaderSearchTextInput(!isShowTextInput))
  }

  const fetchData = (val) => {
    dispatch(headerSearchProductData(val))
  }

  const debounceFetch = useCallback(
    _debounce((val) => fetchData(val), 300, {
      leading: true
    }),
    []
  )

  const onChange = (e) => {
    dispatch(setPageHeaderSearchValue(e.target.value))
  }

  useEffect(() => {
    if (value?.length) {
      debounceFetch(value)
      dispatch(toggleHeaderSearchPanel(true))
    } else {
      dispatch(toggleHeaderSearchPanel(false))
    }
  }, [value])

  useEffect(() => {
    dispatch(disposeHeaderSearch())
  }, [location])

  useEffect(() => {
    dispatch(toggleHeaderSearchTextInput(expanded))
  }, [])

  return (
    <div
      className={cx(styles.box, {
        [styles.visible]: isShowTextInput,
        [styles.expanded]: expanded,
        [styles.left]: leftMode,
        [styles[size]]: size
      })}
      ref={containerRef}
    >
      <div className={styles.input} ref={ref}>
        <TextInput
          placeholder={t["search-input-placeholder"]}
          value={value}
          size={size}
          onChange={onChange}
          onFocus={() => {
            if (value?.length > 3) {
              dispatch(toggleHeaderSearchPanel(true))
            }
          }}
        />
      </div>
      <button className={cx(styles.button, {})} type='button' onClick={onClick}>
        <SearchIcon />
      </button>
      {isShowResultPanel && isShowTextInput && (
        <div className={styles.search__panel}>
          <SearchResultPanel
            data={result}
            isLoading={isLoading}
            emptyMsg={t["search-empty-message-2"]}
            parentRef={ref}
            onClick={(data) => {
              dispatch(toggleHeaderSearchPanel(false))
            }}
          />
        </div>
      )}
    </div>
  )
}

Search.propTypes = {
  expanded: PropTypes.bool,
  size: PropTypes.string,
  leftMode: PropTypes.bool
}

export default memo(Search)
