import { useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Breadcrumbs from "@/components/Breadcrumbs"
import Container from "@/modules/Container"
import Layout from "@/modules/Layout"
import List from "./List"
import AccordionPanel from "@/components/Accordion/AccordionPanel"
import Accordion from "@/components/Accordion"
import Spinner from "../../components/Spinner"
import { useWindowSize } from "@/utils/hooks/useWindowSize"
import { useTranslate } from "@/utils/hooks/useTranslate"
import { useInfinityScroll } from "../../utils/hooks/useInfinityScroll"

import {
  ListViewEnum,
  selectFavoritesListLoading,
  selectFavoritesListView,
  setFavoritesCategoriesFilter,
  setFavoritesBrandFilter,
  toggleListView,
  selectFavoritesCategoriesFilter,
  selectFavoritesBrandFilter,
  selectFavoritesList,
  selectFavoritesCategoriesOptions,
  selectFavoritesBrandsOptions,
  getFavorites,
  changePageProducts,
  getFavoritesBrands,
  getFavoritesCategories,
  disposeState,
  setFavoritesCategoryAccordionActiveIndex,
  setFavoritesBrandAccordionActiveIndex,
  selectFavoritesBrandAccordion,
  selectFavoritesCategoryAccordion,
  selectFavoritesCurrentPageFilter,
  selectFavoritesAllFilters
} from "./favorites.controller"

import ListIcon from "@/assets/icons/ListIcon"
import GridIcon from "@/assets/icons/GridIcon"

import styles from "./index.module.scss"

const getLabel = (label, t) => {
  if (label === "all") return t.all
  return label
}

const Profile = () => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const { isMobile, isTablet } = useWindowSize()

  const brandFilter = useSelector(selectFavoritesBrandFilter)
  const categoryByBrandFilter = useSelector(selectFavoritesCategoriesFilter)
  const currentPage = useSelector(selectFavoritesCurrentPageFilter)
  const allFilters = useSelector(selectFavoritesAllFilters)

  const brandAccordion = useSelector(selectFavoritesBrandAccordion)
  const categoryAccordion = useSelector(selectFavoritesCategoryAccordion)

  const listView = useSelector(selectFavoritesListView)
  const listData = useSelector(selectFavoritesList)
  const isFavoritesListLoading = useSelector(selectFavoritesListLoading)

  const sortOptionsBrandsList = useSelector(selectFavoritesBrandsOptions)
  const sortOptionsCategories = useSelector(selectFavoritesCategoriesOptions)

  const listContainerRef = useRef()

  const breadcrumbs = useMemo(() => {
    return [
      {
        url: "/profile",
        label: t["navigation-profile"]
      },
      {
        url: "/profile/favorites",
        label: t["navigation-favorite"]
      }
    ]
  }, [t])

  const column = useMemo(() => {
    if (isMobile) return 2
    if (isTablet) return 3
    return 4
  }, [isMobile, isTablet])

  const categoryOptionsByBrand = useMemo(() => {
    const data = sortOptionsCategories.find((item) => item.id === brandFilter.id)
    if (data) {
      return data.categories || []
    }
    return []
  }, [brandFilter, sortOptionsCategories])

  useInfinityScroll(null, isFavoritesListLoading, dispatch, changePageProducts)

  useEffect(() => {
    const payload = {
      brandId: brandFilter.id && brandFilter.id !== "all" ? brandFilter.id : null,
      categoryId: categoryByBrandFilter.id && categoryByBrandFilter.id !== "all" ? categoryByBrandFilter.id : null,
      page: currentPage
    }

    dispatch(getFavorites({ data: payload, isLoadMore: allFilters.isLoadMore }))
  }, [allFilters, brandFilter, categoryByBrandFilter, currentPage])

  useEffect(() => {
    document.title = "Titania - Favorites"

    dispatch(getFavoritesBrands())
    dispatch(getFavoritesCategories())

    return () => dispatch(disposeState())
  }, [])

  return (
    <Layout>
      <div className={styles.box}>
        <Container className={styles.box__inner}>
          <div className={styles.box__header}>
            <Breadcrumbs path={breadcrumbs} className={styles.breadcrumbs} />
          </div>
          <div className={styles.box__content}>
            <div className={styles.box__profile}>
              <div className={styles.title}>{isMobile ? t["favorite-title-mobile"] : t["favorite-title-desktop"]}</div>
              <div className={styles.description} dangerouslySetInnerHTML={{ __html: t["favorite-text-description"] }} />
            </div>
          </div>
          <div className={styles.filters}>
            <div className={styles.box__header}>
              <div className={styles.toolbar}>
                <div
                  className={styles.toolbar__col}
                  style={{
                    "--grid-template-cols": column
                  }}
                >
                  <div className={styles.toolbar__sort}>
                    <Accordion
                      activeIndex={brandAccordion.activeIndex}
                      collapseOnBlur
                      size={isMobile && "sm"}
                      onOpen={(i) => {
                        dispatch(setFavoritesBrandAccordionActiveIndex(i))
                      }}
                      list={[
                        {
                          title: (
                            <span
                              dangerouslySetInnerHTML={{ __html: getLabel(brandFilter?.label, t) || t["favorite-sort-brands"] }}
                            />
                          ),
                          content: (
                            <AccordionPanel
                              hover
                              background='#ffffff'
                              paddingBottom='10px'
                              size={isMobile && "sm-light"}
                              data={sortOptionsBrandsList.map((item, index) => {
                                return (
                                  <button
                                    type='button'
                                    key={index}
                                    onClick={() => {
                                      dispatch(setFavoritesBrandFilter(item))
                                      dispatch(setFavoritesBrandAccordionActiveIndex(-1))
                                    }}
                                    dangerouslySetInnerHTML={{ __html: getLabel(item.label, t) }}
                                  />
                                )
                              })}
                            />
                          )
                        }
                      ]}
                      classNames={styles["accordion-regular"]}
                    />
                  </div>
                  <div className={styles.toolbar__sort}>
                    <Accordion
                      activeIndex={categoryAccordion.activeIndex}
                      collapseOnBlur
                      size={isMobile && "sm"}
                      disabled={!brandFilter?.id || brandFilter?.id === "all"}
                      onOpen={(i) => {
                        dispatch(setFavoritesCategoryAccordionActiveIndex(i))
                      }}
                      list={[
                        {
                          title: (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getLabel(categoryByBrandFilter?.label, t) || t["favorite-sort-categories"]
                              }}
                            />
                          ),
                          content: (
                            <AccordionPanel
                              hover
                              background='#ffffff'
                              paddingBottom='10px'
                              size={isMobile && "sm-light"}
                              emptyMsg={t["favorite-sort-categories-empty"]}
                              data={categoryOptionsByBrand.map((item, index) => {
                                return (
                                  <button
                                    type='button'
                                    key={index}
                                    onClick={() => {
                                      dispatch(setFavoritesCategoriesFilter(item))
                                      dispatch(setFavoritesCategoryAccordionActiveIndex(-1))
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: getLabel(item.label, t)
                                    }}
                                  />
                                )
                              })}
                            />
                          )
                        }
                      ]}
                      classNames={styles["accordion-regular"]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              type='button'
              onClick={() => {
                dispatch(toggleListView())
              }}
              className={styles["view-btn"]}
            >
              {listView === ListViewEnum.TABLE && <GridIcon />}
              {listView === ListViewEnum.LIST && <ListIcon />}
            </button>
          </div>
          <div className={styles.products} ref={listContainerRef}>
            {!!listData.length && (
              <List column={column} listData={listData} isFavoritesListLoading={isFavoritesListLoading} listView={listView} />
            )}
            {!listData.length && !isFavoritesListLoading && (
              <div className={styles["empty-message"]}>{t["product-favorite-empty-message"]}</div>
            )}
            {isFavoritesListLoading && <Spinner classNames={styles.spinner} />}
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default Profile
