/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { memo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import cx from "classnames"
import { useSelector } from "react-redux"
import Container from "../Container/index"
import ScrollToTop from "../../components/ScrollToTop"
import { useWindowSize } from "../../utils/hooks/useWindowSize"
import { useTranslate } from "../../utils/hooks/useTranslate"
import { selectFooterData } from "../../store/slice/page"

import styles from "./index.module.scss"

export const FooterColTypeEnum = {
  INFORMATION: "information",
  SERVICE: "service"
}

const Footer = ({ logo, logoLink }) => {
  const { isMobile } = useWindowSize()
  const { t } = useTranslate()

  const footerData = useSelector(selectFooterData)

  return (
    <footer className={styles.box}>
      <Container className={styles.box__inner}>
        <div className={styles.top}>
          <div className={styles.list}>
            <div
              className={styles.list__title}
              dangerouslySetInnerHTML={{
                __html: t["footer-text-company-name"]
              }}
            />
            <div className={styles.list__item} dangerouslySetInnerHTML={{ __html: t["footer-address"] }} />
          </div>
          {!!footerData.information.length && (
            <div className={styles.list}>
              <div
                className={cx(styles.list__title, {
                  [styles.bordered]: true
                })}
              >
                {t["footer-text-information"]}
                <div className={styles.divider} />
              </div>
              {footerData.information?.map((link, index) => {
                return (
                  <div className={styles.list__item} key={`${index}`}>
                    <Link to={`/${link.linkname}`}>{link.name_f}</Link>
                  </div>
                )
              })}
            </div>
          )}
          {!!footerData.service.length && (
            <div className={styles.list}>
              <div
                className={cx(styles.list__title, {
                  [styles.bordered]: true
                })}
              >
                {t["footer-text-service"]}
                <div className={styles.divider} />
              </div>
              {footerData.service?.map((link, index) => {
                return (
                  <div className={styles.list__item} key={`${index}`}>
                    {link.link_url ? (
                      <a href={link.link_url} target={link.link_opennew ? "_blank" : "_self"} rel='noreferrer'>
                        {link.name_f}
                      </a>
                    ) : (
                      <Link to={`/${link.linkname}`} target={link.link_opennew ? "_blank" : "_self"}>
                        {link.name_f}
                      </Link>
                    )}
                  </div>
                )
              })}
            </div>
          )}
          {!isMobile && (
            <div className={styles.scroll}>
              <ScrollToTop />
            </div>
          )}
        </div>
        <div className={styles.bottom}>
          <div
            className={styles.copyright}
            dangerouslySetInnerHTML={{
              __html: t["footer-text-copyright"]
            }}
          />
          {isMobile && (
            <div className={styles.scroll}>
              <ScrollToTop />
            </div>
          )}
          <Link to={logoLink}>
            <img src={logo} alt='logo' className={styles.logo} />
          </Link>
        </div>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  logo: PropTypes.string,
  logoLink: PropTypes.string
}

export default memo(Footer)
