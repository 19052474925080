import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const CloseIcon = ({ color, circleColor, fill, strokeWidth, ...props }) => (
  <svg
    width={`${pxToRem(30)}rem`}
    height={`${pxToRem(30)}rem`}
    viewBox='0 0 31 31'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='15' cy='15' r='14.5' fill={fill || "white"} stroke={circleColor || "#333333"} />
    <path d='M10 10L20 20M20 10L10 20' stroke={color || "#333333"} strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)

CloseIcon.propTypes = {
  color: PropTypes.string,
  circleColor: PropTypes.string,
  fill: PropTypes.string,
  strokeWidth: PropTypes.number
}

export default CloseIcon
