export const TRANSLATE_FALLBACK = {
  de: {
    "search-input-placeholder": "Artikelnummer oder Namen eingeben",
    "form-required-field": "Required field",
    "form-invalid-email": "The email address value is not valid.",
    "search-empty-message": "No result",
    "search-empty-message-2": "The Product is not found",
    "filter-top-seller": "Top Seller",
    "filter-new": "Neue eingetroffen",
    "filter-discount": "Reduziert",
    "date-input-placeholder": "tt.mm.jjjj",

    "favorite-title-desktop": "Meine Favoriten",
    "favorite-title-mobile": "Favoriten",
    "favorite-text-description":
      "Unser Produktsortiment. ... Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
    "favorite-sort-brands": "Marke wählen",
    "favorite-sort-categories": "Kategorie wählen",
    "favorite-sort-categories-empty": "Es gibt keine Kategorie",

    "request-manager-modal-title": "Anfrage für Anmeldedaten",
    "request-manager-modal-success": "Nachricht gesendet, danke",
    "request-manager-modal-comment-placeholder": "Hinterlasse ein Kommentar",

    "navigation-products": "Produkte",
    "navigation-cart": "Warenkorb",
    "navigation-favorite": "Favoriten",
    "navigation-profile": "Mein Profil",
    "navigation-dashboard": "Dashboard",
    "navigation-orders": "Bestellungen",
    "navigation-profile-2": "Profil",
    "navigation-logout": "Ausloggen",
    "navigation-home": "Home",
    "navigation-sortiment": "Sortiment",
    "navigation-declaration": "Angaben",

    "sign-in-title": "SIGN IN",
    "sign-in-no-login-data": "Noch keine Zugangsdaten?",
    "input-mail-placeholder": "Bitte E-Mail eingeben",
    "login-link-request-button": "Log-In-Link anfordern",
    "login-link-send-text":
      " Wir haben Ihnen per E-Mail einen Login-Link geschickt. Alternativ können Sie sich auch per PIN-Code einloggen.",

    "quick-buy-placeholder-article": "AA1013 SET",
    "quick-buyp-laceholder-stk": "STK",
    "quick-buy-title": "Schnellkauf",
    "quick-buy-title-2": "Produkt nach der Suche aus der Liste wählen und zum Warenkorb hinzufügen:",
    "quick-buy-button": "Zum warenkob hinzufügen",
    "quick-functions-title": "Schnellfunktionen",
    "quick-buy-success": "Das Produkt wurde erfolgreich zum Warenkorb hinzugefügt",

    "file-upload-title": "Produkte per CSV oder Excel importieren und in den Warenkorb legen.",
    "file-upload-dnd-area-1": "Datei",
    "file-upload-dnd-area-2": "wählen",
    "file-upload-dnd-area-3": "oder hereinziehen",
    "file-upload-tooltip-label": "Infos zur Datei",
    "file-upload-tooltip-detail":
      "Sie können eine Excel oder CSV Datei verwenden. Die Spaltenzuordnung können Sie nach dem Upload wählen. Es werden nur Artikelnummer und Anzahl benötigt.",
    "file-upload-tooltip-success": "Produkte wurden zum Warenkorb hinzugefügt",
    "invalid-file-foormat": "Das Format ist falsch",
    "file-template-download-title": "XLS Template runterladen",

    "contact-manager-card-title": "Ihr persönlicher Ansprechpartner bei TITANIA",
    "svg-accordrion-label": "Erste Spalte wählen",
    "brands-and-categories-title": "Marken & Kategorien",
    "highlights-title": "Highlights",
    "last-seen-title": "Zuletzt angesehen",
    "simillar-products-title": "Ähnliche Produkte",

    "show-navigation": "Filter einblenden",
    "hide-navigation": "Navigation ausblenden",
    "sort-filter-title": "Sortiren",
    "reset-filters": "Filter zurücksetzen",

    "product-type-neu": "Neu",
    "product-type-sale": "Sale",
    "product-price-on-request": "Auf Anfrage",

    "product-tooltip-amount": "Menge",
    "product-tooltip-ean-gtin": "EAN / GTIN",
    "product-tooltip-weight": "Gewicht",
    "product-tooltip-length": "Länge",
    "product-tooltip-width": "Breite",
    "product-tooltip-height": "Höhe",
    "product-tooltip-not-exist": "Produkt existiert nicht",

    "product-info-number": "Art. Nr.",
    "product-info-gtin-bean": "BEAN / GTIN",
    "product-info-country": "Ursprungsland",
    "product-info-amount-ve": "Menge je VE",
    "product-info-amount-gk": "Menge je GK",
    "product-logistic-information": "Logistikinformationen",
    "product-empty-history-msg": "Sie haben noch keine Produkte angesehen",
    "product-description-title": "Beschreibung",
    "product-description-empty-msg": "Es gibt keine Produktbeschreibung",

    "product-favorite-empty-message": "Es gibt keine Lieblingsprodukte",
    "products-list-empty-message": "Es gibt keine Produkte",
    "product-added-to-cart-msg": "zum Warenkorb hinzugefügt",

    "year-accordion-label": "Jahr",
    "month-accordion-label": "Monat",
    "sort-accordion-label": "Sortieren",

    "column-preview-title": "Spaltenvorschau",
    "orders-title": "Bestellungen",
    "orders-table-sort-number": "Online Bestellnummer",
    "orders-table-sort-summ": "Betrag",
    "orders-table-sort-date": "Bestelldatum",
    "orders-table-sort-name": "Nutzer",
    "orders-empty-message": "There are no orders",
    "order-card-csv-tooltip": "Bestellung als CSV herunterladen",
    "order-card-import": "Von einem TITANIA-Mitarbeiter erstellte Bestellung:",

    "404-page-title-1": "Wir konnten die gewünschte",
    "404-page-title-2": "Seite nicht finden.",
    "404-page-text": "404",

    all: "Alle",
    yes: "Ja",
    no: "Nein",

    "delete-approval-message": "Möchten Sie die Adresse löschen?",

    "month-name-1": "Januar",
    "month-name-2": "Februar",
    "month-name-3": "März",
    "month-name-4": "April",
    "month-name-5": "Mai",
    "month-name-6": "Juni",
    "month-name-7": "Juli",
    "month-name-8": "August",
    "month-name-9": "September",
    "month-name-10": "Oktober",
    "month-name-11": "November",
    "month-name-12": "Dezember",

    "whish-data-label": "Wunschtermin",
    "payment-method-label": "Zahlungsweise",

    "agreed-conditions-payment": "Vereinbarte Konditionen",

    "copy-and-edit": "Kopieren und bearbeiten",
    "copy-and-order": "Kopieren und bestellen",

    "copy-order-text-1": "Möchten Sie die Bestellung",
    "copy-order-text-2": "vom",
    "copy-order-text-3": "kopieren und direkt noch ein Mal bestellen oder Informationen bearbeiten?",

    "form-error-invalid-date": "Ungültiges Datum",
    "form-error-assign-col": "Bitte Spalten zuordnen",

    "check-order": "Bestellung prüfen",

    "order-error-text-1": "Leider ist das Kopieren und die direkte  Bestellung aufgrund von Produktänderungen nicht möglich.",
    "order-error-text-2": "Bitte prüfen Sie die Bestellung manuell.",
    "order-error-text-3": "Ihr Warenkorb ist leer, Sie können mit dem Einkauf fortfahren",

    "order-success-text-1": "Vielen Dank!",
    // "order-success-text-2": "Wir haben Ihre Bestellung erhalten.",
    "order-success-text-3": "Die Online Bestellnummer lautet:",
    "button-to-my-orders": "Zu meinen Bestellungen",

    "button-all-orders": "Alle Bestellungen",
    "button-review-all-orders": "Alle Bestellungen ansehen",
    "button-order-again": "Noch ein Mal bestellen",
    "button-add-to-cart": "In den warenkob",
    "button-view": "Ansehen",
    "button-login": "Einloggen",
    "button-back": "Zurück",
    "button-save-and-add": "Speichern & hinzufügen",
    "button-to-shop": "Zum Shop",

    "order-detail-info-title": "Bestellinformationen",
    "order-detail-title": "Bestellung",
    "order-detail-title-2": "Bestellte Artikel",

    "order-delivery-method": "Versandart",
    "order-desired-date": "Wunsch-Termin",
    "order-payment-method": "Zahlungsweise",
    "order-link": "Ihre Auftrags-Referenz",
    "order-comment": "Kommentar",
    "order-payment-address": "Rechnungsadresse",
    "order-delivery-address": "Versandadresse",
    "order-delivery-date": "Bestelldatum",
    "order-delivery-summ": "Betrag",
    "order-person-info": "Ansprechpartner",

    "order-final-summ": "Endsumme",
    "order-product-count": "Menge",
    "order-article-number": "Artikel-Nr.:",
    "order-article-name": "Title Name",
    "order-product-amount": "Einzelpreis",
    "order-product-total-amount": "Gesamtpreis",

    "shop-cart-title": "Warenkorb",
    "shop-cart-article": "Artikel",

    "button-continue-shopping": "Weiter einkaufen",
    "button-order": "bestellen",
    "order-cart-link": "Auftrags-Referenz",
    "text-area-placeholder": "Bei Bedarf ausfüllen",
    "shipping-type-placeholder": "Versandart",
    "validation-error-max-250": "Die maximale Anzahl an Symbolen beträgt 250",
    "choose-others": "Andere wählen",

    "choose-address": "Adresse auswählen",
    "button-save": "Speichern",
    "button-send": "Senden",

    "profile-field-company": "Firma",
    "profile-field-street": "Strasse",
    "profile-field-city": "Ort",
    "profile-field-postal_code": "PLZ",
    "profile-field-country": "Land",

    "profile-field-firstName": "Vorname",
    "profile-field-lastName": "Nachname",
    "profile-field-phone": "Telefonnummer",
    "profile-field-email": "E-Mail",
    "profile-field-position": "Position",
    "profile-field-optional": "Optional info",
    "profile-field-optional-2": "Optional info 2",
    "profile-field-message": "Nachricht",
    "profile-content-personal": "Persönliche Informationen",

    "profile-content-address": "Versandadressen",
    "profile-content-company": "Unternehmensdaten",
    "profile-content-billing": "Rechnungsadresse",
    "profile-company-edit": "Änderung mitteilen",
    "profile-edit-profile": "Andere wählen",
    "profile-add-address": "Neue Adresse Hinzufügen",
    "profile-shipping-address": "Primäre Versandadresse",
    "profile-success-create-address": "Die Neue Adresse wurde hinzugefügt",
    "profile-success-edit-address": "Die Adresse wurde geändert.",
    "profile-success-delete-address": "Die Anfrage zum Entfernen der Lieferadresse wurde an den Manager gesendet",
    "profile-add-new-address": "Neue Adresse hinzufügen",
    "profile-edit-new-address": "Adresse hinzufügen",
    "profile-unable-edit-delete-tooltip": "Adresse kann nicht gelöscht/bearbeitet werden",
    "profile-content-success": "Erfolg",
    "profile-edit-profile-success":
      "Wir haben Ihre Änderungen erhalten. Wir werden Ihre Daten so schnell wie möglich aktualisieren.",
    "profile-edit-info-modal-title": "Änderung mitteilen",

    "footer-text-company-name": "TITANIA® FABRIK GMBH",
    "footer-text-copyright": "© 2024 TITANIA® FABRIK GMBH. Alle Rechte vorbehalten.",
    "footer-text-service": "service",
    "footer-text-information": "information"
  },
  en: {
    "search-input-placeholder": "EN Artikelnummer oder Namen eingeben",
    "form-required-field": "EN Required field",
    "form-invalid-email": "EN The email address value is not valid.",
    "search-empty-message": "EN No result",
    "search-empty-message-2": "EN The Product is not found",
    "filter-top-seller": "EN Top Seller",
    "filter-new": "EN Neue eingetroffen",
    "filter-discount": "EN Reduziert",
    "date-input-placeholder": "dd.mm.yyyy",

    "favorite-title-desktop": "EN Meine Favoriten",
    "favorite-title-mobile": "EN Favoriten",
    "favorite-text-description":
      "Unser Produktsortiment. ... Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
    "favorite-sort-brands": "EN Marke wählen",
    "favorite-sort-categories": "EN Kategorie wählen",
    "favorite-sort-categories-empty": "There are no categories",

    "request-manager-modal-title": "Login data request",
    "request-manager-modal-success": "Message sent, thank you",
    "request-manager-modal-comment-placeholder": "Leave your comment",

    "navigation-products": "EN Produkte",
    "navigation-cart": "EN Warenkorb",
    "navigation-favorite": "EN Favoriten",
    "navigation-profile": "EN Mein Profil",
    "navigation-dashboard": "EN Dashboard",
    "navigation-orders": "EN Bestellungen",
    "navigation-profile-2": "EN Profil",
    "navigation-logout": "EN Ausloggen",
    "navigation-home": "EN Home",
    "navigation-sortiment": "EN Sortiment",
    "navigation-declaration": "EN Angaben",

    "sign-in-title": "EN SIGN IN",
    "sign-in-no-login-data": "EN Noch keine Zugangsdaten?",
    "input-mail-placeholder": "EN Bitte E-Mail eingeben",
    "login-link-request-button": "EN Log-In-Link anfordern",
    "login-link-send-text":
      " Wir haben Ihnen per E-Mail einen Login-Link geschickt. Alternativ können Sie sich auch per PIN-Code einloggen.",

    "quick-buy-placeholder-article": "EN AA1013 SET",
    "quick-buyp-laceholder-stk": "EN STK",
    "quick-buy-title": "EN Schnellkauf",
    "quick-buy-title-2": "EN Produkt nach der Suche aus der Liste wählen und zum Warenkorb hinzufügen:",
    "quick-buy-button": "EN Zum warenkob hinzufügen",
    "quick-functions-title": "EN Schnellfunktionen",
    "quick-buy-success": "The product has been successfully added to the cart",

    "file-upload-title": "EN Produkte per CSV oder Excel importieren und in den Warenkorb legen.",
    "file-upload-dnd-area-1": "EN Datei",
    "file-upload-dnd-area-2": "EN wählen",
    "file-upload-dnd-area-3": "EN oder hereinziehen",
    "file-upload-tooltip-label": "EN Infos zur Datei",
    "file-upload-tooltip-detail":
      "Sie können eine Excel oder CSV Datei verwenden. Die Spaltenzuordnung können Sie nach dem Upload wählen. Es werden nur Artikelnummer und Anzahl benötigt.",
    "file-upload-tooltip-success": "EN Produkte wurden zum Warenkorb hinzugefügt",
    "invalid-file-foormat": "EN Das Format ist falsch",
    "file-template-download-title": "Download xls template",

    "contact-manager-card-title": "EN Ihr persönlicher Ansprechpartner bei TITANIA",

    "svg-accordrion-label": "EN Erste Spalte wählen",
    "brands-and-categories-title": "EN Marken & Kategorien",
    "highlights-title": "EN Highlights",
    "last-seen-title": "EN Zuletzt angesehen",
    "simillar-products-title": "EN Ähnliche Produkte",

    "show-navigation": "EN Filter einblenden",
    "hide-navigation": "EN Navigation ausblenden",
    "reset-filters": "EN Filter zurücksetzen",
    "sort-filter-title": "EN Sortiren",

    "product-type-neu": "Neu",
    "product-type-sale": "Sale",
    "product-price-on-request": "On Request",

    "product-tooltip-amount": "EN Menge",
    "product-tooltip-ean-gtin": "EN EAN / GTIN",
    "product-tooltip-weight": "EN Gewicht",
    "product-tooltip-length": "EN Länge",
    "product-tooltip-width": "EN Breite",
    "product-tooltip-height": "EN Höhe",
    "product-tooltip-not-exist": "Product not exist",

    "product-info-number": "EN Art. Nr.",
    "product-info-gtin-bean": "EN BEAN / GTIN",
    "product-info-country": "EN Ursprungsland",
    "product-info-amount-ve": "EN Menge je VE",
    "product-info-amount-gk": "EN Menge je GK",
    "product-logistic-information": "EN Logistikinformationen",
    "product-empty-history-msg": " You have not viewed any products yet",
    "product-description-title": "EN Beschreibung",
    "product-description-empty-msg": "There is no product description",

    "product-favorite-empty-message": "There are no favorite products",
    "products-list-empty-message": "There are no products",
    "product-added-to-cart-msg": "added to cart",

    "year-accordion-label": "EN Jahr",
    "month-accordion-label": "EN Monat",
    "sort-accordion-label": "EN Sortieren",

    "column-preview-title": "EN Spaltenvorschau",
    "orders-title": "EN Bestellungen",
    "orders-table-sort-number": "EN Online Bestellnummer",
    "orders-table-sort-summ": "EN Betrag",
    "orders-table-sort-date": "EN Bestelldatum",
    "orders-table-sort-name": "EN Nutzer",
    "orders-empty-message": "EN There are no orders",
    "order-card-csv-tooltip": "EN Bestellung als CSV herunterladen",
    "order-card-import": "Order created by an TITANIA employee:",

    "404-page-title-1": "EN Wir konnten die gewünschte",
    "404-page-title-2": "EN Seite nicht finden.",
    "404-page-text": "EN 404",

    all: "EN Alle",
    yes: "Yes",
    no: "No",

    "delete-approval-message": "Do you want to delete the address? ",

    "month-name-1": "EN Januar",
    "month-name-2": "EN Februar",
    "month-name-3": "EN März",
    "month-name-4": "EN April",
    "month-name-5": "EN Mai",
    "month-name-6": "EN Juni",
    "month-name-7": "EN Juli",
    "month-name-8": "EN August",
    "month-name-9": "EN September",
    "month-name-10": "EN Oktober",
    "month-name-11": "EN November",
    "month-name-12": "EN Dezember",

    "whish-data-label": "EN Wunschtermin",
    "payment-method-label": "EN Zahlungsweise",

    "agreed-conditions-payment": "EN Vereinbarte Konditionen",

    "copy-and-edit": "EN Kopieren und bearbeiten",
    "copy-and-order": "EN Kopieren und bestellen",

    "copy-order-text-1": "EN Möchten Sie die Bestellung",
    "copy-order-text-2": "EN vom",
    "copy-order-text-3": "EN kopieren und direkt noch ein Mal bestellen oder Informationen bearbeiten?",

    "form-error-invalid-date": "EN Ungültiges Datum",
    "form-error-assign-col": "EN Bitte Spalten zuordnen",

    "check-order": "EN Bestellung prüfen",

    "order-error-text-1": "EN Leider ist das Kopieren und die direkte  Bestellung aufgrund von Produktänderungen nicht möglich.",
    "order-error-text-2": "EN Bitte prüfen Sie die Bestellung manuell.",
    "order-error-text-3": "Your cart is empty, you can continue shopping",

    "order-success-text-1": "EN Vielen Dank!",
    // "order-success-text-2": "EN Wir haben Ihre Bestellung erhalten.",
    "order-success-text-3": "EN Die Online Bestellnummer lautet:",
    "button-to-my-orders": "EN Zu meinen Bestellungen",

    "button-all-orders": "EN Alle Bestellungen",
    "button-review-all-orders": "EN Alle Bestellungen ansehen",
    "button-order-again": "EN Noch ein Mal bestellen",
    "button-add-to-cart": "EN In den warenkob",
    "button-view": "EN Ansehen",
    "button-login": "EN Einloggen",
    "button-back": "EN Zurück",
    "button-save-and-add": "EN Speichern & hinzufügen",
    "button-to-shop": "EN Zum Shop",

    "order-detail-info-title": "EN Bestellinformationen",
    "order-detail-title": "EN Bestellung",
    "order-detail-title-2": "EN Bestellte Artikel",

    "order-delivery-method": "EN Versandart",
    "order-desired-date": "EN Wunsch-Termin",
    "order-payment-method": "EN Zahlungsweise",
    "order-link": "EN Ihre Auftrags-Referenz",
    "order-comment": "EN Kommentar",
    "order-payment-address": "EN Rechnungsadresse",
    "order-delivery-address": "EN Versandadresse",
    "order-delivery-date": "EN Bestelldatum",
    "order-delivery-summ": "EN Betrag",
    "order-person-info": "EN Ansprechpartner",

    "order-final-summ": "EN Endsumme",
    "order-product-count": "EN Menge",
    "order-article-number": "EN Artikel-Nr.:",
    "order-article-name": "Title Name",
    "order-product-amount": "EN Einzelpreis",
    "order-product-total-amount": "EN Gesamtpreis",

    "shop-cart-title": "EN Warenkorb",
    "shop-cart-article": "EN Artikel",

    "button-continue-shopping": "EN Weiter einkaufen",
    "button-order": "EN bestellen",
    "order-cart-link": "EN Auftrags-Referenz",
    "text-area-placeholder": "EN Bei Bedarf ausfüllen",
    "shipping-type-placeholder": "EN Versandart",
    "validation-error-max-250": "EN Die maximale Anzahl an Symbolen beträgt 250",
    "choose-others": "EN Andere wählen",

    "choose-address": "EN Adresse auswählen",
    "button-save": "EN Speichern",
    "button-send": "Send",

    "profile-field-company": "EN Firma",
    "profile-field-street": "EN Strasse",
    "profile-field-city": "EN Ort",
    "profile-field-postal_code": "EN PLZ",
    "profile-field-country": "EN Land",

    "profile-field-firstName": "EN Vorname",
    "profile-field-lastName": "EN Nachname",
    "profile-field-email": "EN E-Mail",
    "profile-field-phone": "EN Telefonnummer",
    "profile-field-position": "EN Position",
    "profile-field-optional": "Optional info",
    "profile-field-optional-2": "Optional info 2",
    "profile-field-message": "Message",
    "profile-content-personal": "EN Persönliche Informationen",

    "profile-content-address": "EN Versandadressen",
    "profile-content-company": "EN Unternehmensdaten",
    "profile-content-billing": "EN Rechnungsadresse",
    "profile-company-edit": "EN Änderung mitteilen",
    "profile-edit-profile": "EN Andere wählen",
    "profile-add-address": "EN Neue Adresse Hinzufügen",
    "profile-shipping-address": "EN Primäre Versandadresse",
    "profile-success-create-address": "EN Die Neue Adresse wurde hinzugefügt",
    "profile-success-edit-address": "Die Adresse wurde geändert.",
    "profile-success-delete-address": "The request to remove the shipping address has been sent to the manager",
    "profile-add-new-address": "EN Neue Adresse hinzufügen",
    "profile-unable-edit-delete-tooltip": "EN Adresse kann nicht gelöscht/bearbeitet werden",
    "profile-content-success": "EN Erfolg",
    "profile-edit-profile-success":
      "Wir haben Ihre Änderungen erhalten. Wir werden Ihre Daten so schnell wie möglich aktualisieren.",
    "profile-edit-info-modal-title": "Änderung mitteilen",

    "footer-text-company-name": "EN TITANIA® FABRIK GMBH",
    "footer-text-copyright": "EN © 2024 TITANIA® FABRIK GMBH. Alle Rechte vorbehalten.",
    "footer-text-service": "EN service",
    "footer-text-information": "EN information"
  }
}
