/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useDispatch, useSelector } from "react-redux"
import { useTranslate } from "../../../../utils/hooks/useTranslate"
import Input from "../../../../components/Input"
import { ModalEditProfile } from "../../ModalEditProfile"
import { selectProfileEditUserInfoModal, selectProfileUserInfo, toggleProfileEditUserInfoModal } from "../../profile.controller"

import styles from "../index.module.scss"

const UserInformationTab = () => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const data = useSelector(selectProfileUserInfo)

  const { isOpen } = useSelector(selectProfileEditUserInfoModal)

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <Input isEdit={false} value={data.name} label={t["profile-field-firstName"]} id='name' />
        <Input isEdit={false} value={data.surname} label={t["profile-field-lastName"]} id='surname' />
        <Input isEdit={false} value={data.email} label={t["profile-field-email"]} id='email' />
        <Input isEdit={false} value={data.phone} label={t["profile-field-phone"]} id='phone' />
        <Input isEdit={false} value={data.position} label={t["profile-field-position"]} id='position' />
      </div>
      <div
        onClick={() => dispatch(toggleProfileEditUserInfoModal(true))}
        className={styles.button}
        dangerouslySetInnerHTML={{ __html: t["profile-edit-profile"] }}
      />
      <ModalEditProfile
        initialData={data}
        isModalOpen={isOpen}
        closeModal={() => dispatch(toggleProfileEditUserInfoModal(false))}
      />
    </div>
  )
}

export default UserInformationTab
