import React from "react"
import { Routes, Route } from "react-router-dom"
import IconPreview from "../assets/icons"
import UnauthorizedRoute from "./UnauthorizedRoute"
import PrivateRoute from "./PrivateRoute"

// Pages
import Login from "../pages/Auth/Login"
import Home from "../pages/Home"
import Products from "../pages/Products"
import Product from "../pages/Product"
import Orders from "../pages/Orders"
import ErrorPage from "../pages/404"
import Order from "../pages/Order"
import Profile from "../pages/Profile"
import Cart from "../pages/Cart"
import Favorites from "../pages/Favorites"
import DynamicPage from "../pages/Dynamic"

const RouterConfig = () => (
  <Routes>
    <Route
      path='/login'
      exact
      element={
        <UnauthorizedRoute redirect='/'>
          <Login />
        </UnauthorizedRoute>
      }
    />
    <Route path='/preview' exact element={<Login />} />
    <Route
      path='/'
      element={
        <PrivateRoute redirect='/login'>
          <Home />
        </PrivateRoute>
      }
    />
    <Route
      path='/products'
      element={
        <PrivateRoute redirect='/'>
          <Products />
        </PrivateRoute>
      }
    />
    <Route
      path='/products/:id'
      element={
        <PrivateRoute redirect='/'>
          <Product />
        </PrivateRoute>
      }
    />
    <Route
      path='/profile/orders'
      element={
        <PrivateRoute redirect='/'>
          <Orders />
        </PrivateRoute>
      }
    />
    <Route
      path='/profile/orders/:id'
      element={
        <PrivateRoute redirect='/'>
          <Order />
        </PrivateRoute>
      }
    />
    <Route
      path='/profile/orders/download/:id'
      element={
        <PrivateRoute redirect='/'>
          <Orders />
        </PrivateRoute>
      }
    />
    <Route
      path='/profile'
      element={
        <PrivateRoute redirect='/'>
          <Profile />
        </PrivateRoute>
      }
    />
    <Route
      path='/profile/favorites'
      element={
        <PrivateRoute redirect='/'>
          <Favorites />
        </PrivateRoute>
      }
    />
    <Route
      path='/cart'
      element={
        <PrivateRoute redirect='/'>
          <Cart />
        </PrivateRoute>
      }
    />
    {process.env.NODE_ENV === "development" && <Route path='/icons' element={<IconPreview />} />}
    <Route path='/404' element={<ErrorPage />} />
    <Route path='*' element={<DynamicPage />} />
  </Routes>
)

export default RouterConfig
