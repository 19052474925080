import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const InfoIcon = ({ color, strokeWidth, fill, ...props }) => (
  <svg
    width={`${pxToRem(20)}rem`}
    height={`${pxToRem(21)}rem`}
    viewBox='0 0 21 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle
      cx='10'
      cy='10'
      r='9.5'
      fill={fill || "white"}
      stroke={color || "#AAAAAA"}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.6719 7.54688V16H9.21875V7.54688H10.6719ZM9.10938 5.30469C9.10938 5.07031 9.17969 4.8724 9.32031 4.71094C9.46615 4.54948 9.67969 4.46875 9.96094 4.46875C10.237 4.46875 10.4479 4.54948 10.5938 4.71094C10.7448 4.8724 10.8203 5.07031 10.8203 5.30469C10.8203 5.52865 10.7448 5.72135 10.5938 5.88281C10.4479 6.03906 10.237 6.11719 9.96094 6.11719C9.67969 6.11719 9.46615 6.03906 9.32031 5.88281C9.17969 5.72135 9.10938 5.52865 9.10938 5.30469Z'
      fill={color || "#AAAAAA"}
    />
  </svg>
)

InfoIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  strokeWidth: PropTypes.number
}

export default InfoIcon
