import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useMemo } from "react"
import Accordion from "../../../components/Accordion"
import AccordionPanel from "../../../components/Accordion/AccordionPanel"
import CheckBox from "../../../components/CheckBox"
import { useTranslate } from "../../../utils/hooks/useTranslate"
import { getFiltersFromParams } from "../utils/parse-params"
import { updateSubcategoryID } from "../utils/category-filter"

import {
  selectProductsBrandAccordion,
  selectProductsBrands,
  setProductsBrandAccordionActiveIndex,
  ProductFilterKeyEnum,
  selectProductsCategoryAccordion,
  selectProductsCategoriesOptions,
  setProductsCategoryAccordionActiveIndex,
  getProductBanners
} from "../products.controller"

import styles from "./index.module.scss"

const Navigation = () => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const brandAccordion = useSelector(selectProductsBrandAccordion)
  const categoryAccordion = useSelector(selectProductsCategoryAccordion)
  const brandsOptions = useSelector(selectProductsBrands)
  const categoriesOptions = useSelector(selectProductsCategoriesOptions)
  const navigate = useNavigate()

  const { brandID, categoryIDS } = getFiltersFromParams()

  const selectedBrand = useMemo(() => {
    return brandsOptions.find((item) => item.id === Number(brandID))
  }, [brandID, brandsOptions])

  return (
    <div className={styles.box}>
      <div className={styles.brands}>
        <Accordion
          fill
          uppercase
          activeIndex={brandAccordion.activeIndex}
          onOpen={(i) => {
            dispatch(setProductsBrandAccordionActiveIndex(i))
          }}
          list={[
            {
              title: selectedBrand?.name,
              content: (
                <AccordionPanel
                  hover
                  data={brandsOptions
                    .filter((item) => item.id !== selectedBrand?.id)
                    .map((item, index) => {
                      return (
                        <button
                          type='button'
                          key={index}
                          onClick={() => {
                            dispatch(setProductsCategoryAccordionActiveIndex(-1))
                            dispatch(setProductsBrandAccordionActiveIndex(-1))
                            const params = new URLSearchParams("")
                            params.set(ProductFilterKeyEnum.BRAND, item.id)
                            params.set(ProductFilterKeyEnum.PAGE, 1)
                            dispatch(
                              getProductBanners({
                                brand: item.id
                              })
                            )
                            navigate(
                              {
                                search: params.toString()
                              },
                              {
                                replace: true
                              }
                            )
                          }}
                        >
                          {item.name}
                        </button>
                      )
                    })}
                />
              )
            }
          ]}
          classNames={styles["accordion-regular"]}
        />
      </div>
      <div className={styles.category}>
        <Accordion
          activeIndex={categoryAccordion.activeIndex}
          onOpen={(i) => {
            dispatch(setProductsCategoryAccordionActiveIndex(i))
          }}
          list={categoriesOptions?.map((categoryItem, i) => {
            return {
              title: <span dangerouslySetInnerHTML={{ __html: categoryItem.name }} />,
              content: (
                <AccordionPanel
                  size='sm'
                  paddingBottom
                  data={categoryItem.subcategories?.map((subcategory, index) => {
                    const filterCategory = categoryIDS?.find((cat) => cat.id === categoryItem.id)
                    const isChecked = filterCategory?.subcategoryIDS?.find((sub) => sub === String(subcategory.id))

                    return (
                      <CheckBox
                        checked={!!isChecked}
                        id={`${subcategory.id}-${i}-${index}`}
                        onChange={(e) => {
                          updateSubcategoryID({
                            subcategoryID: subcategory.id,
                            categoryID: categoryItem.id,
                            navigate,
                            getProductBanners: ({ categoryID, subcategorID }) => {
                              dispatch(
                                getProductBanners({
                                  brand: brandID,
                                  category: categoryID,
                                  subcategory: subcategorID
                                })
                              )
                            }
                          })
                        }}
                      >
                        {subcategory.name === "all" ? t.all : subcategory.name}
                      </CheckBox>
                    )
                  })}
                />
              )
            }
          })}
          classNames={styles["accordion-regular"]}
        />
      </div>
      <div className={styles.clear}>
        <button
          className={styles.clear__btn}
          type='button'
          onClick={() => {
            const params = new URLSearchParams("")
            params.set(ProductFilterKeyEnum.BRAND, brandID)
            params.set(ProductFilterKeyEnum.PAGE, 1)
            navigate(
              {
                search: params.toString()
              },
              {
                replace: true
              }
            )
          }}
          dangerouslySetInnerHTML={{
            __html: t["reset-filters"]
          }}
        />
      </div>
    </div>
  )
}

export default Navigation
