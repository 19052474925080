/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { memo, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { useOnClickOutside } from "../../../utils/hooks/useClickOutside"
import Chewron3Icon from "../../../assets/icons/Chewron3Icon"

import styles from "./index.module.scss"

export const setElementMaxHeight = (ref, setMaxHeight, extraHeight = 0) => {
  if (ref?.current) {
    const boxScrollHeight = ref.current.scrollHeight
    setMaxHeight(`${boxScrollHeight + extraHeight}px`)
  }
}

const AccordionButton = ({
  title,
  content,
  setActive,
  activeCollapseIndex,
  collapseIndex,
  isActive,
  fill,
  uppercase,
  size,
  labelHover,
  collapseOnBlur,
  single,
  borderBottom,
  disabled,
  classNames
}) => {
  const [maxHeight, setMaxHeight] = useState("auto")
  const panelRef = useRef(null)
  const containerRef = useRef(null)

  const handleResize = () => {
    setTimeout(() => setElementMaxHeight(panelRef, setMaxHeight, 0), 200)
  }

  const handleSetActive = () => {
    if (activeCollapseIndex === collapseIndex) {
      setActive(null)
    } else {
      setActive(collapseIndex)
      handleResize()
    }
  }

  const onEscapePress = (e) => {
    if (!collapseOnBlur) return

    if (e.code === "Escape") {
      setActive(null)
    }
  }

  useOnClickOutside(containerRef, () => {
    if (isActive && collapseOnBlur) {
      setActive(null)
    }
  })

  useEffect(() => {
    handleResize()

    window.document.addEventListener("keydown", onEscapePress)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("keydown", onEscapePress)
      window.addEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div
      className={`${cx(styles.box, {
        [styles.open]: isActive,
        [styles.fill]: fill,
        [styles.uppercase]: uppercase,
        [styles[size]]: size,
        [styles["collapse-on-blur"]]: collapseOnBlur,
        [styles.single]: single,
        [styles["border-bottom"]]: borderBottom,
        [styles.disabled]: disabled
      })} ${classNames}`}
      ref={containerRef}
    >
      <div className={styles.button} onClick={handleSetActive} onKeyPress={() => {}} tabIndex={0} role='button'>
        <div
          className={cx(styles.button__label, {
            [styles.hover]: labelHover
          })}
        >
          {title}
        </div>
        <div
          className={cx(styles.button__icon, {
            [styles.active]: isActive
          })}
        >
          <Chewron3Icon />
        </div>
      </div>
      <div className={styles.panel} style={{ "--max-height": maxHeight }}>
        <div ref={panelRef}>{content}</div>
      </div>
    </div>
  )
}

AccordionButton.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node,
  setActive: PropTypes.func,
  activeCollapseIndex: PropTypes.number,
  collapseIndex: PropTypes.number,
  isActive: PropTypes.bool,
  fill: PropTypes.bool,
  uppercase: PropTypes.bool,
  disabled: PropTypes.bool,
  labelHover: PropTypes.bool,
  borderBottom: PropTypes.bool,
  collapseOnBlur: PropTypes.bool,
  single: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "md"]),
  classNames: PropTypes.string
}

export default memo(AccordionButton)
