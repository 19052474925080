import React, { memo, useCallback, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { useDispatch, useSelector } from "react-redux"
import _debounce from "lodash/debounce"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import { Tooltip } from "react-tooltip"
import { NOOP } from "../../constants/noop"
import Button, { ButtonVariantEnum } from "../../components/Button"
import TextInput from "../../components/TextInput"
import SearchResultPanel from "../../components/SearchResultPanel"
import { useOnClickOutside } from "../../utils/hooks/useClickOutside"
import { useTranslate } from "../../utils/hooks/useTranslate"
import { responseErrorParser } from "../../utils/response-error-parser"
import { TOAST_DEFAULT_CONFIG } from "../../constants/toast"

import {
  addToCartSearchProductData,
  disposeAddToCartSearch,
  selectAddToCartSearchData,
  setAddToCartSearchValue,
  toggleAddToCartSearchPanel
} from "../../store/slice/search"

import { addProductToCart, getCartProductCount, selectAddProductToCartLoading } from "../../pages/Cart/cart.controller"

import styles from "./index.module.scss"
import InfoIcon from "../../assets/icons/InfoIcon"

const AddToCart = ({
  onChange = NOOP,
  title,
  subtitle,
  buttonLabel,
  stkPlaceholder,
  namePlaceholder,
  cardType,
  buttonSize,
  burgerType,
  infoTooltip
}) => {
  const { t } = useTranslate()
  const ref = useRef()
  const containerRref = useRef()
  const inputsRref = useRef()

  const dispatch = useDispatch()
  const location = useLocation()

  const { isShowResultPanel, selectedID, name, count, isLoading, result } = useSelector(selectAddToCartSearchData)
  const isAddToCartLoading = useSelector(selectAddProductToCartLoading)

  useOnClickOutside(ref, () => {
    dispatch(toggleAddToCartSearchPanel(false))
  })

  const fetchData = (val) => {
    dispatch(addToCartSearchProductData(val))
  }

  const debounceFetch = useCallback(
    _debounce((val) => fetchData(val), 300, {
      leading: true
    }),
    []
  )

  const addToCartHandler = () => {
    dispatch(
      addProductToCart({
        id: selectedID,
        quantity: Number(count)
      })
    )
      .unwrap()
      .then(() => {
        dispatch(getCartProductCount())
        toast.success(
          <span dangerouslySetInnerHTML={{ __html: `${name} ${t["product-added-to-cart-msg"]}` }} />,
          TOAST_DEFAULT_CONFIG
        )
      })
      .catch((err) => {
        toast.error(responseErrorParser(err), TOAST_DEFAULT_CONFIG)
      })
  }

  useEffect(() => {
    if (name?.length) {
      debounceFetch(name)
    }
  }, [name])

  useEffect(() => {
    dispatch(disposeAddToCartSearch())
  }, [location])

  return (
    <div
      className={cx(styles.box, {
        [styles.card]: cardType,
        [styles.burger]: burgerType
      })}
      ref={containerRref}
    >
      <div
        className={cx(styles.title, {
          [styles.info]: infoTooltip
        })}
      >
        <div dangerouslySetInnerHTML={{ __html: title }} />
        {!infoTooltip && !!subtitle && <div dangerouslySetInnerHTML={{ __html: subtitle }} className={styles.subtitle} />}
        {infoTooltip && (
          <>
            <div className={styles.tooltip} data-tooltip-id='quick-add-to-cart-info-tooltip'>
              <InfoIcon />
            </div>
            <Tooltip id='quick-add-to-cart-info-tooltip' place='bottom-start' className='tooltip-content'>
              <div
                dangerouslySetInnerHTML={{
                  __html: subtitle
                }}
              />
            </Tooltip>
          </>
        )}
      </div>
      <div className={styles.inputs} ref={inputsRref}>
        <div className={styles.search} ref={ref}>
          <TextInput
            value={name}
            onChange={(e) => {
              dispatch(
                setAddToCartSearchValue({
                  name: e.target.value
                })
              )
            }}
            placeholder={namePlaceholder}
            size='sm'
            onFocus={() => dispatch(toggleAddToCartSearchPanel(true))}
          />
          {isShowResultPanel && !!name?.length && (
            <div className={styles.search__panel}>
              <SearchResultPanel
                data={result}
                isLoading={isLoading}
                emptyMsg={t["search-empty-message"]}
                parentRef={cardType || burgerType ? inputsRref : containerRref}
                onClick={({ id, article }) => {
                  dispatch(
                    setAddToCartSearchValue({
                      name: article,
                      selectedID: id
                    })
                  )
                  dispatch(toggleAddToCartSearchPanel(false))
                }}
              />
            </div>
          )}
        </div>
        <TextInput
          value={count}
          onChange={(e) => {
            dispatch(
              setAddToCartSearchValue({
                count: e.target.value
              })
            )
          }}
          type='number'
          placeholder={stkPlaceholder}
          size='sm'
        />
      </div>
      <div className={styles.btn}>
        <Button
          loading={isAddToCartLoading}
          onClick={addToCartHandler}
          size={buttonSize || (cardType ? "md" : "sm")}
          text={<span dangerouslySetInnerHTML={{ __html: buttonLabel }} />}
          disabled={!name || !count || !selectedID}
          variant={cardType ? ButtonVariantEnum.PRIMARY : ButtonVariantEnum.SECONDARY}
        />
      </div>
    </div>
  )
}

AddToCart.propTypes = {
  onChange: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonLabel: PropTypes.string,
  stkPlaceholder: PropTypes.string,
  namePlaceholder: PropTypes.string,
  buttonSize: PropTypes.string,
  cardType: PropTypes.bool,
  infoTooltip: PropTypes.bool,
  burgerType: PropTypes.bool
}

export default memo(AddToCart)
