import axios from "axios"

export const getProductReq = (id) => axios.get(`/products/${id}`)

export const setProductFavoriteReq = (id) => axios.post(`/favorites/${id}`)

export const unsetProductFavoriteReq = (id) => axios.delete(`/favorites/${id}`)

export const getProductsReq = (data) => axios.post("/products", data)

export const getViewedProductReq = () => axios.get("/viewed")

export const getSimilarProductsReq = (id) => axios.get(`/similar/${id}`)

export const getFavoritesProductsReq = (data) => axios.post("/favorites", data)

export const getBrands = () => axios.get("/brands")

export const getCategories = () => axios.get("/categories")

export const getSubCategories = (id) => axios.get(`/categories/${id}`)

export const getProductSortOptionsReq = () => axios.get("/products/filters")

export const getProductBannersReq = ({ brand, category, subcategory }) =>
  axios.post("/sliders", {
    brand,
    category,
    subcategory
  })
