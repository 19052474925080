import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const ExpandIcon = ({ color, strokeWidth, ...props }) => (
  <svg
    width={`${pxToRem(26)}rem`}
    height={`${pxToRem(26)}rem`}
    viewBox='0 0 26 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M3.02784 9.08291L3.02784 3.02754M3.02784 3.02754L9.08321 3.02754M3.02784 3.02754L9.97134 9.97105'
      stroke={color || "#E2E2E2"}
      strokeWidth={strokeWidth || 2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M22.9722 9.08291L22.9722 3.02754M22.9722 3.02754L16.9168 3.02754M22.9722 3.02754L16.0287 9.97105'
      stroke={color || "#E2E2E2"}
      strokeWidth={strokeWidth || 2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.02781 16.8155L3.02781 22.8709M3.02781 22.8709L9.08318 22.8709M3.02781 22.8709L9.97131 15.9274'
      stroke={color || "#E2E2E2"}
      strokeWidth={strokeWidth || 2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M22.9722 16.8155L22.9722 22.8709M22.9722 22.8709L16.9168 22.8709M22.9722 22.8709L16.0287 15.9274'
      stroke={color || "#E2E2E2"}
      strokeWidth={strokeWidth || 2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

ExpandIcon.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number
}

export default ExpandIcon
