/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
import React, { memo, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Navigation, Autoplay } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import cx from "classnames"
import ChewronLeftIcon from "../../../assets/icons/ChewronLeftIcon"
import CategoryCard from "../../Cards/CategoryCard"
import Container from "../../../modules/Container"
import { NOOP } from "../../../constants/noop"
import HighlightCard from "../../Cards/HighlightCard"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/scrollbar"
import "swiper/css/autoplay"

import styles from "./index.module.scss"

const CategorySlider = ({ data = [], highlights, background, title, brandId, mobileNavRight }) => {
  const [loaded, setLoaded] = useState(false)
  const [isNoSwipe, setIsNoSwipe] = useState(false)

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const checkIsNoSwipe = () => {
    setIsNoSwipe(
      navigationPrevRef?.current?.classList.contains("swiper-button-lock") &&
        navigationNextRef?.current?.classList.contains("swiper-button-lock")
    )
  }

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <div
      className={cx(styles.box, {
        [styles.highlights]: highlights,
        [styles["no-swipe"]]: isNoSwipe,
        [styles["nav-right"]]: mobileNavRight
      })}
      style={{
        "--product-slider-bg": background
      }}
    >
      <Container className={styles.header}>
        <div className={styles.title}>{title}&nbsp;</div>
        {!!data.length && (
          <>
            <button type='button' className={styles.button_prev} ref={navigationPrevRef}>
              <ChewronLeftIcon color='#333333' />
            </button>
            <button type='button' className={styles.button_next} ref={navigationNextRef}>
              <ChewronLeftIcon color='#333333' />
            </button>
          </>
        )}
      </Container>
      <div className={styles.slider}>
        {loaded && !!data.length && (
          <Swiper
            modules={[Navigation, Autoplay]}
            spaceBetween={0}
            slidesPerView='auto'
            slidesOffsetBefore={0}
            height='auto'
            slidesOffsetAfter={0}
            pagination={{ clickable: true }}
            onSlideChange={NOOP}
            onSwiper={NOOP}
            watchSlidesProgress
            watchOverflow
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
              swiper.navigation.init()
              swiper.navigation.update()
            }}
            onAfterInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
              swiper.navigation.init()
              swiper.navigation.update()
              checkIsNoSwipe()
            }}
            onResize={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
              swiper.navigation.init()
              swiper.navigation.update()
              checkIsNoSwipe()
            }}
          >
            {data.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  {!highlights && <CategoryCard brandId={brandId} key={index} {...item} title={item.name} />}
                  {highlights && <HighlightCard key={index} {...item} title={item.name} />}
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}
      </div>
    </div>
  )
}

CategorySlider.propTypes = {
  data: PropTypes.any,
  highlights: PropTypes.bool,
  mobileNavRight: PropTypes.bool,
  background: PropTypes.string,
  title: PropTypes.string,
  brandId: PropTypes.string
}

export default memo(CategorySlider)
