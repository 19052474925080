/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types"
import cx from "classnames"

import { useDispatch, useSelector } from "react-redux"
import ImageSlider from "../../../components/Sliders/ImageSlider"
import ProductCard from "../../../components/Cards/ProductCard"
import TeaserCard from "../../../components/Cards/TeaserCard"

import {
  ListViewEnum,
  selectProductsBanners,
  selectProductsBannersVisible,
  selectProductsList,
  selectProductsListView,
  selectProductsSidebarVisible,
  toggleProductFavorite,
  toggleProductsBannersVisible
} from "../products.controller"

import styles from "./index.module.scss"
import CloseIcon from "../../../assets/icons/CloseIcon"

const List = ({ column = 3 }) => {
  const dispatch = useDispatch()
  const listView = useSelector(selectProductsListView)
  const listData = useSelector(selectProductsList)
  const brandsSlider = useSelector(selectProductsBanners)
  const isBannersVisible = useSelector(selectProductsBannersVisible)
  const sidebarVisible = useSelector(selectProductsSidebarVisible)

  return (
    <div
      className={cx(styles.box, {
        [styles.grid]: listView === ListViewEnum.LIST
      })}
      style={{
        "--grid-template-cols": column
      }}
    >
      {listView === ListViewEnum.LIST && !!brandsSlider.length && isBannersVisible && (
        <div
          className={cx(styles.box__item, {
            [styles.full]: true,
            [styles.partial]: column < 3
          })}
          style={{
            "--slider-item-col-size": column - 1
          }}
        >
          <ImageSlider
            images={brandsSlider}
            md={sidebarVisible}
            hideHandler={() => dispatch(toggleProductsBannersVisible(!isBannersVisible))}
          />
        </div>
      )}
      {listData.map((item, index) => {
        return item.isTeaser ? (
          listView !== ListViewEnum.TABLE ? (
            <div className={styles.box__item}>
              <TeaserCard key={index} {...item} />
            </div>
          ) : null
        ) : (
          <div className={styles.box__item}>
            <ProductCard
              key={index}
              {...item}
              horizontal={listView === ListViewEnum.TABLE}
              addToFavorite={(favorite) => {
                dispatch(
                  toggleProductFavorite({
                    id: item.id,
                    favorite
                  })
                )
              }}
            />
          </div>
        )
      })}
    </div>
  )
}

List.propTypes = {
  column: PropTypes.number
}

export default List
