/* eslint-disable camelcase */
import React, { memo } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Link } from "react-router-dom"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"
import FallbackImg from "../../FallbackImg"
import { CATEGORY_CARD_FALLBACK_IMAGE_URL } from "../../../constants/fallback-image"
import { useTranslate } from "../../../utils/hooks/useTranslate"

import styles from "./index.module.scss"

const TeaserCard = ({ title, description, link, image, target }) => {
  const { isMobile } = useWindowSize()
  const { t } = useTranslate()

  return (
    <div className={cx(styles.box, {})}>
      <div className={styles.bg}>
        <FallbackImg src={image} alt={title} fallback={CATEGORY_CARD_FALLBACK_IMAGE_URL} />
      </div>
      <div className={styles.info}>
        <div className={styles.title}>{title}</div>
        {!isMobile && <div className={styles.desc}>{description}</div>}
        {link && (
          <Link to={link} target={target ? "_blank" : "_self"} className={styles.link}>
            {t["button-view"]}
          </Link>
        )}
      </div>
    </div>
  )
}

TeaserCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string,
  target: PropTypes.bool
}

export default memo(TeaserCard)
