import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const BagIcon = ({ color, strokeWidth, ...props }) => (
  <svg
    width={`${pxToRem(27)}rem`}
    height={`${pxToRem(27)}rem`}
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M7.25 7.25C7.25 3.79822 10.0482 1 13.5 1C16.9518 1 19.75 3.79822 19.75 7.25M3.1962 11H23.8038L25.7915 18.2342C26.8754 22.1788 23.5963 26 19.1274 26H7.87259C3.40368 26 0.124612 22.1788 1.20848 18.2342L3.1962 11Z'
      stroke={color || "#333333"}
      strokeWidth={strokeWidth || 2}
      strokeLinejoin='round'
    />
  </svg>
)

BagIcon.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number
}

export default BagIcon
