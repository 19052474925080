import React, { memo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { NOOP } from "../../constants/noop"

import styles from "./index.module.scss"
import WarningIcon from "../../assets/icons/WarningIcon"

const TextAreaInput = ({
  name,
  label,
  type = "text",
  value,
  placeholder,
  required,
  resizable,
  disabled,
  errors,
  size = "md",
  onBlur = NOOP,
  onChange = NOOP,
  onFocus = NOOP,
  onKeyPress = NOOP
}) => {
  return (
    <div
      className={cx(styles.box, {
        [styles.disabled]: disabled,
        [styles[size]]: size,
        [styles[resizable]]: resizable,
        [styles["with-label"]]: label
      })}
    >
      <div
        className={cx(styles.box__inner, {
          [styles.error]: errors
        })}
      >
        {label && (
          <label
            className={cx(styles.label, {
              [styles.required]: required
            })}
            htmlFor={name}
          >
            {label}
          </label>
        )}
        <textarea
          id={name}
          name={name}
          type={type}
          disabled={disabled}
          value={value || ""}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          aria-label={name}
          placeholder={placeholder}
        />
      </div>
      {errors && (
        <p className={styles.error__msg}>
          <WarningIcon />
          {errors}
        </p>
      )}
    </div>
  )
}

TextAreaInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  resizable: PropTypes.bool,
  errors: PropTypes.objectOf(
    PropTypes.shape({
      message: PropTypes.string
    })
  ),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func
}

export default memo(TextAreaInput)
