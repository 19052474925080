import { ProductTypeEnum } from "../constants/product"

export const productResponseMapper = (data = []) => {
  if (!data || !data?.length) {
    return []
  }

  return data.map((item) => {
    return {
      ...item,
      quantity: Number(item?.quantity || 0),
      price: Number(item?.price || 0),
      total: Number(item?.total || 0),
      favorite: !!item.is_favorite,
      type: item.is_new ? ProductTypeEnum.NEU : item.is_sale ? ProductTypeEnum.SALE : null,
      isTeaser: item.type === "teaser",
      target: !!item.link_opennew
    }
  })
}
