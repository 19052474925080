/* eslint-disable react/no-danger */
import React, { memo } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import InnerHTML from "dangerously-set-html-content"

import styles from "./index.module.scss"

const TextBlock = ({ leftText = "", rightText = "" }) => {
  return (
    <div className={styles.box}>
      <div className={styles.box__inner}>
        <div
          className={cx(styles.text, {
            [styles.row]: leftText && rightText
          })}
        >
          {leftText && <InnerHTML className={styles.left} html={leftText} />}
          {rightText && <InnerHTML className={styles.right} html={rightText} />}
        </div>
      </div>
    </div>
  )
}

TextBlock.propTypes = {
  leftText: PropTypes.string,
  rightText: PropTypes.string
}

export default memo(TextBlock)
