import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const GridIcon = ({ color, strokeWidth, ...props }) => (
  <svg
    width={`${pxToRem(40)}rem`}
    height={`${pxToRem(40)}rem`}
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect x='13.5' y='13.5' width='3' height='3' rx='1' fill={color || "#333333"} />
    <rect x='18.5' y='13.5' width='3' height='3' rx='1' fill={color || "#333333"} />
    <rect x='23.5' y='13.5' width='3' height='3' rx='1' fill={color || "#333333"} />
    <rect x='13.5' y='18.5' width='3' height='3' rx='1' fill={color || "#333333"} />
    <rect x='18.5' y='18.5' width='3' height='3' rx='1' fill={color || "#333333"} />
    <rect x='23.5' y='18.5' width='3' height='3' rx='1' fill={color || "#333333"} />
    <rect x='13.5' y='23.5' width='3' height='3' rx='1' fill={color || "#333333"} />
    <rect x='18.5' y='23.5' width='3' height='3' rx='1' fill={color || "#333333"} />
    <rect x='23.5' y='23.5' width='3' height='3' rx='1' fill={color || "#333333"} />
  </svg>
)

GridIcon.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number
}

export default GridIcon
