import React, { memo, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Link } from "react-router-dom"
import { useTranslate } from "../../../utils/hooks/useTranslate"
import FallbackImg from "../../FallbackImg"
import { CATEGORY_CARD_FALLBACK_IMAGE_URL } from "../../../constants/fallback-image"

import styles from "./index.module.scss"

const CategoryCard = ({ id, image, title, brandId }) => {
  const { t } = useTranslate()
  const [isImageError, setImageError] = useState(false)

  return (
    <Link to={`/products?brand=${brandId}&${id}=all`}>
      <div
        className={cx(styles.box, {
          [styles["image-error"]]: isImageError
        })}
      >
        <div className={styles.box__inner}>
          <div className={styles.image}>
            <FallbackImg src={image} alt={title} fallback={CATEGORY_CARD_FALLBACK_IMAGE_URL} setIsFallback={setImageError} />
          </div>
          <div className={styles.content}>
            <div
              className={styles.title}
              dangerouslySetInnerHTML={{
                __html: title
              }}
            />
            <Link to={`/products?brand=${brandId}&${id}=all`} className={styles.link}>
              {t["button-view"]}
            </Link>
          </div>
        </div>
      </div>
    </Link>
  )
}

CategoryCard.propTypes = {
  id: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  brandId: PropTypes.string
}

export default CategoryCard
