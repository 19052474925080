import React, { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectProductsSortOptions, toggleProductsFiltersBurger, toggleProductsSortBurger } from "../products.controller"
import { getFiltersFromParams } from "../utils/parse-params"
import { useTranslate } from "../../../utils/hooks/useTranslate"

import FilterIcon from "../../../assets/icons/FilterIcon"
import Chewron3Icon from "../../../assets/icons/Chewron3Icon"

import styles from "./index.module.scss"

const FiltersBar = () => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const sortOptions = useSelector(selectProductsSortOptions)

  const { sorting } = getFiltersFromParams()

  const selectSortOption = useMemo(() => {
    return sortOptions.find((item) => item.id === sorting)
  }, [sorting, sortOptions])

  return (
    <div className={styles.box}>
      <div className={styles.box__col}>
        <button
          type='button'
          onClick={() => {
            dispatch(toggleProductsSortBurger(true))
          }}
          className={styles.button}
        >
          <span dangerouslySetInnerHTML={{ __html: selectSortOption?.name || t["sort-filter-title"] }} />
          <Chewron3Icon />
        </button>
      </div>
      <div className={styles.box__col}>
        <button
          type='button'
          onClick={() => {
            dispatch(toggleProductsFiltersBurger(true))
          }}
          className={styles.button}
        >
          <span>Alle Filter</span>
          <div className={styles.button__icon}>
            <FilterIcon />
          </div>
        </button>
      </div>
    </div>
  )
}

export default FiltersBar
