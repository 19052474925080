import { getFiltersFromParams } from "./parse-params"

const getSubcategoryIDS = (selectedCategoryID = "", selectedSubcategoryIDS = [], categoriesData = []) => {
  const isAllExist = selectedSubcategoryIDS.find((item) => item === "all")

  if (isAllExist) {
    return []
  }

  return selectedSubcategoryIDS.map((item) => Number(item))
}

export const preparePayload = ({ brandID, sorting, page, categoryIDS = [], allCategoryData = [], isLoadMore }) => {
  return {
    data: {
      brandId: brandID,
      categories: categoryIDS?.map((item) => item.id) || allCategoryData.map((item) => item.id),
      subcategories: categoryIDS.reduce((prev, curr) => {
        const subcategoryIDS = getSubcategoryIDS(curr.id, curr.subcategoryIDS, allCategoryData)
        return [...prev, ...subcategoryIDS]
      }, []),
      sorting,
      page
    },
    isLoadMore
  }
}

export const prepareBannersPayload = (categoryID, subcategoryID) => {
  const { brandID, categoryIDS } = getFiltersFromParams()

  const existTargetCategory = categoryIDS.find((cat) => cat.id === categoryID)

  let preparedCategoryID
  let preparedSubcategoryID

  if (existTargetCategory) {
    const subcategoryIDExist = existTargetCategory.subcategoryIDS.find((sub) => sub === String(subcategoryID))

    if (subcategoryIDExist && subcategoryID !== "all") {
      const filteredCategory = {
        ...existTargetCategory,
        subcategoryIDS: existTargetCategory.subcategoryIDS.filter((sub) => sub !== String(subcategoryID))
      }

      preparedCategoryID = categoryID

      if (filteredCategory.subcategoryIDS.length) {
        preparedSubcategoryID = filteredCategory.subcategoryIDS[filteredCategory.subcategoryIDS.length - 1]
      } else {
        preparedSubcategoryID = null
      }
    } else if (subcategoryIDExist && subcategoryID === "all") {
      preparedSubcategoryID = null
      preparedCategoryID = null
    } else if (!subcategoryIDExist && subcategoryID !== "all") {
      preparedSubcategoryID = subcategoryID
      preparedCategoryID = categoryID
    } else {
      preparedSubcategoryID = null
      preparedCategoryID = categoryID
    }
  } else {
    preparedCategoryID = categoryID
    preparedSubcategoryID = subcategoryID === "all" ? null : subcategoryID
  }

  return {
    categoryID: preparedCategoryID,
    subcategorID: preparedSubcategoryID
  }
}
