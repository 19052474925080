import React, { Suspense } from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import axios from "axios"

import { BrowserRouter } from "react-router-dom"
import { store } from "./store/store"
import setupAxios from "./axios/setupAxios"
import reportWebVitals from "./reportWebVitals"

import App from "./App"

import "./i18n"
import "./styles/index.scss"

setupAxios(axios)

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <Provider store={store}>
    {/* <Suspense fallback={<div />}> */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
    {/* </Suspense> */}
  </Provider>
)

reportWebVitals()
