import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"

import Lottie from "lottie-web"
import animationData from "./json/index.json"

import styles from "./index.module.scss"

const SpinnerCircle = ({ classNames }) => {
  return (
    <div className={`${styles.loader} ${classNames}`}>
      <div className={styles["lds-ring"]}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

SpinnerCircle.propTypes = {
  classNames: PropTypes.string
}

const SpinnerLottie = ({ classNames }) => {
  const animBox = useRef(null)

  useEffect(() => {
    const anim = Lottie.loadAnimation({
      container: animBox.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData
    })

    return () => {
      anim.destroy()
    }
  }, [])

  return (
    <div className={`${styles.container} ${classNames}`}>
      <div ref={animBox} />
    </div>
  )
}

SpinnerLottie.propTypes = {
  classNames: PropTypes.string
}

const Spinner = ({ classNames, circle }) => {
  return circle ? <SpinnerCircle classNames={classNames} /> : <SpinnerLottie classNames={classNames} />
}

Spinner.propTypes = {
  classNames: PropTypes.string,
  circle: PropTypes.bool
}

export default Spinner
