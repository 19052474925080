import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const PlusIcon = ({ color, v2, strokeWidth, ...props }) => {
  if (v2) {
    return (
      <svg
        width={`${pxToRem(22)}rem`}
        height={`${pxToRem(22)}rem`}
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z'
          stroke={color || "#333"}
          strokeWidth={strokeWidth || "0.8"}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11 7V11.2426M11 11.2426V15.4853M11 11.2426H15.2426M11 11.2426H6.75732'
          stroke={color || "#333"}
          strokeWidth={strokeWidth || "0.8"}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )
  }

  return (
    <svg
      width={`${pxToRem(13)}rem`}
      height={`${pxToRem(12)}rem`}
      viewBox='0 0 13 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.5 11C5.5 11.5523 5.94772 12 6.5 12C7.05228 12 7.5 11.5523 7.5 11V7.00001H11.5C12.0523 7.00001 12.5 6.55229 12.5 6.00001C12.5 5.44772 12.0523 5.00001 11.5 5.00001H7.5V1C7.5 0.447715 7.05228 0 6.5 0C5.94772 0 5.5 0.447715 5.5 1V5.00001H1.5C0.947715 5.00001 0.5 5.44772 0.5 6.00001C0.5 6.55229 0.947715 7.00001 1.5 7.00001H5.5V11Z'
        fill={color || "#333"}
      />
    </svg>
  )
}

PlusIcon.propTypes = {
  color: PropTypes.string,
  v2: PropTypes.bool,
  strokeWidth: PropTypes.string
}

export default PlusIcon
