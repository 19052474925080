/* eslint-disable camelcase */
/* eslint-disable indent */
/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types"
import { useFormik } from "formik"
import * as Yup from "yup"
import cx from "classnames"
import moment from "moment"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useTranslate } from "../../utils/hooks/useTranslate"
import Button, { ButtonVariantEnum } from "../../components/Button"
import Modal from "../../components/Modal"
import { NOOP } from "../../constants/noop"
import MaskInput from "../../components/MaskInput"
import { pxToRem } from "../../utils/px-to-rem"
import { dateInputValidate } from "../../utils/date-input-validate"
import { useWindowSize } from "../../utils/hooks/useWindowSize"
import { copyOrderAndOrderReq, copyOrderReq } from "../../api/order"
import { selectProfileShippingAddressList } from "../../pages/Profile/profile.controller"
import { getOrdersData } from "../../pages/Orders/orders.controller"
import { dateFormatInput, dateFormatReq } from "../../utils/date-format"

import WarningIcon from "../../assets/icons/WarningIcon"

import styles from "./index.module.scss"

const SignInFormSchema = (t) =>
  Yup.object().shape({
    date: Yup.string().required(t["form-required-field"])
  })

const CopyOrder = ({ isModalOpen, data, closeModal = NOOP, redirect }) => {
  const { t } = useTranslate()
  const { isMobile } = useWindowSize()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [productsNotFount, setProductsNotFount] = useState([])
  const [isCartExist, setIsCartExist] = useState(false)
  const defaultShippingAddress = useSelector(selectProfileShippingAddressList)
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      date: ""
    },
    validationSchema: SignInFormSchema(t),
    validateOnBlur: true,
    onSubmit: async (values) => null
  })

  const handleKeyPress = (event) => {
    const { which, keyCode } = event
    if (keyCode === 13 || which === 13) {
      if (!formik.errors?.date) {
        formik.handleSubmit()
      }
    }
  }

  const copyAndEdit = async () => {
    try {
      const date = formik.values.date ? dateFormatReq(formik.values.date) : null
      setLoading(true)
      await copyOrderReq(data?.id, date)
      navigate("/cart")
    } catch (err) {
      const { cart_create, products } = err.data?.data || {}
      setLoading(false)
      setError(true)
      setIsCartExist(!!cart_create)
      setProductsNotFount(products)
    }
  }

  const copyAndOrder = async () => {
    await formik.validateForm()
    await formik.setFieldTouched("date", true)

    const val = formik.values.date

    if (!val.length) return

    const isCorrectDate = dateInputValidate(val)

    if (!isCorrectDate.isValid || !isCorrectDate.isAfterToday) {
      return formik.setErrors({
        date: t["form-error-invalid-date"]
      })
    }

    try {
      setLoading(true)
      await copyOrderAndOrderReq(data?.id, {
        date: dateFormatReq(val),
        shipping_address: data?.deliveryAddress?.id || defaultShippingAddress?.find((item) => item.isDefault)?.id
      })

      if (redirect) {
        navigate("/profile/orders")
      } else {
        dispatch(getOrdersData({ data: {}, isLoadMore: false }))
        closeModal()
      }
    } catch (err) {
      const { cart_create, products } = err.data?.data || {}
      setLoading(false)
      setIsCartExist(!!cart_create)
      setProductsNotFount(products)
      setError(true)
    }
  }

  const getModalStyles = () => {
    return isMobile
      ? {
          content: {
            width: "95%",
            height: "90%",
            maxWidth: `${pxToRem(570)}rem`,
            maxHeight: `${pxToRem(error ? (productsNotFount.length ? 500 : 322) : 406)}rem`,
            padding: `${pxToRem(25)}rem ${pxToRem(15)}rem`,
            transform: "translate(-50%, -50%)"
          }
        }
      : {
          content: {
            width: "80%",
            height: "80%",
            maxWidth: `${pxToRem(570)}rem`,
            maxHeight: `${pxToRem(error ? (productsNotFount.length ? 500 : 322) : 379)}rem`,
            padding: `${pxToRem(40)}rem`,
            transform: "translate(-50%, -50%)"
          }
        }
  }

  useEffect(() => {
    if (!isModalOpen) {
      formik.resetForm()
      setError(false)
      setLoading(false)
      setProductsNotFount([])
      setIsCartExist(false)
    }
  }, [isModalOpen])

  return (
    <Modal modalIsOpen={isModalOpen} closeModal={closeModal} cancelHandler={closeModal} style={getModalStyles()}>
      {!error && (
        <div className={styles.box}>
          <div className={styles.text}>
            <div>
              <div dangerouslySetInnerHTML={{ __html: t["copy-order-text-1"] }} /> <span>{data?.number}</span>{" "}
              <div dangerouslySetInnerHTML={{ __html: t["copy-order-text-2"] }} />{" "}
              <span>{moment(data?.date).format("DD.MM.YYYY")}</span>{" "}
              <div dangerouslySetInnerHTML={{ __html: t["copy-order-text-3"] }} />
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.info__col}>
              <form className={styles.form} noValidate autoComplete='off' onSubmit={formik.handleSubmit}>
                <MaskInput
                  label={t["whish-data-label"]}
                  placeholder={t["date-input-placeholder"]}
                  required
                  name='date'
                  value={formik.values?.date}
                  errors={!!formik.errors?.date && !!formik.touched?.date}
                  onChange={(e) => formik.setFieldValue("date", e.target.value)}
                  onFocus={() => formik.setFieldTouched("date", false)}
                  onBlur={(e) => {
                    formik?.setFieldValue("date", dateFormatInput(e.target.value))
                    formik?.setFieldTouched("date", true)
                  }}
                  onKeyPress={handleKeyPress}
                />
                <div
                  className={cx(styles.form__alert, {
                    [styles.visible]: !!formik.errors?.date && !!formik.touched?.date
                  })}
                >
                  <WarningIcon />
                  {formik.errors?.date}
                </div>
              </form>
            </div>
            <div className={styles.info__col}>
              <div>{t["payment-method-label"]}</div>
              <div>{t["agreed-conditions-payment"]}</div>
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              text={<span dangerouslySetInnerHTML={{ __html: t["copy-and-edit"] }} />}
              onClick={copyAndEdit}
              disabled={loading}
              loading={loading}
            />
            {!isMobile && (
              <Button
                text={<span dangerouslySetInnerHTML={{ __html: t["copy-and-order"] }} />}
                onClick={copyAndOrder}
                disabled={loading}
                loading={loading}
              />
            )}
          </div>
        </div>
      )}
      {error && (
        <div
          className={cx(styles.box, {
            [styles.error]: error
          })}
        >
          <div className={styles.text}>
            <div>
              <span dangerouslySetInnerHTML={{ __html: t["order-error-text-1"] }} />
              <br />
              <br />
              {!!productsNotFount.length && (
                <div className={styles.list}>
                  {productsNotFount.map((item, index) => {
                    return <span key={index}>{item}</span>
                  })}
                </div>
              )}
              {!isCartExist && <span dangerouslySetInnerHTML={{ __html: t["order-error-text-2"] }} />}
              {isCartExist && <span dangerouslySetInnerHTML={{ __html: t["order-error-text-3"] }} />}
            </div>
          </div>
          <div className={styles.buttons}>
            {isCartExist && (
              <Button
                text={<span dangerouslySetInnerHTML={{ __html: t["check-order"] }} />}
                onClick={() => navigate("/cart")}
                disabled={loading}
                loading={loading}
              />
            )}
            {!isCartExist && (
              <Button href='/products' text={t["button-continue-shopping"]} variant={ButtonVariantEnum.SECONDARY} />
            )}
          </div>
        </div>
      )}
    </Modal>
  )
}

CopyOrder.propTypes = {
  isModalOpen: PropTypes.bool,
  data: PropTypes.any,
  closeModal: PropTypes.func,
  redirect: PropTypes.bool
}

export default CopyOrder
