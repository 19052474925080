import React, { memo } from "react"
import PropTypes from "prop-types"
import { NOOP } from "../../../constants/noop"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"
import ProductBasketCardMobile from "./ProductBasketCardMobile"
import ProductBasketCardDesktop from "./ProductBasketCardDesktop"

const ProductBasketCard = ({
  id,
  image,
  name,
  count,
  number,
  summ,
  totalSumm,
  interactive,
  gk,
  ve,
  currency,
  deleteHandler = NOOP,
  changeHandler = NOOP,
  horizontal = true,
  packInfo,
  visible,
  fullBorder
}) => {
  const { isDesktop } = useWindowSize()

  return isDesktop ? (
    <ProductBasketCardDesktop
      id={id}
      image={image}
      name={name}
      count={count}
      number={number}
      summ={summ}
      totalSumm={totalSumm}
      interactive={interactive}
      gk={gk}
      ve={ve}
      currency={currency}
      deleteHandler={deleteHandler}
      changeHandler={changeHandler}
      horizontal={horizontal}
      visible={visible}
    />
  ) : (
    <ProductBasketCardMobile
      id={id}
      image={image}
      name={name}
      count={count}
      number={number}
      summ={summ}
      totalSumm={totalSumm}
      interactive={interactive}
      gk={gk}
      ve={ve}
      packInfo={packInfo}
      currency={currency}
      deleteHandler={deleteHandler}
      changeHandler={changeHandler}
      horizontal={horizontal}
      fullBorder={fullBorder}
      visible={visible}
    />
  )
}

ProductBasketCard.propTypes = {
  id: PropTypes.string,
  image: PropTypes.string,
  number: PropTypes.string,
  summ: PropTypes.number,
  name: PropTypes.string,
  count: PropTypes.string,
  gk: PropTypes.number,
  ve: PropTypes.number,
  packInfo: PropTypes.bool,
  totalSumm: PropTypes.number,
  visible: PropTypes.number,
  currency: PropTypes.string,
  horizontal: PropTypes.bool,
  interactive: PropTypes.bool,
  fullBorder: PropTypes.bool,
  deleteHandler: PropTypes.func,
  changeHandler: PropTypes.func
}

export default memo(ProductBasketCard)
