import React, { memo } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Tooltip } from "react-tooltip"
import { NOOP } from "../../../../constants/noop"
import Counter from "../../../Counter"
import FallbackImg from "../../../FallbackImg"
import { PRODUCT_CARD_FALLBACK_IMAGE_URLS } from "../../../../constants/fallback-image"
import { selectCurrentLocale } from "../../../../store/slice/translations"
import { numberFormat } from "../../../../utils/number-format"
import { useTranslate } from "../../../../utils/hooks/useTranslate"

import CloseIcon from "../../../../assets/icons/CloseIcon"

import styles from "./index.module.scss"

const ProductBasketCardDesktop = ({
  id,
  image,
  name,
  count,
  number,
  summ,
  totalSumm,
  interactive,
  gk,
  ve,
  currency,
  deleteHandler = NOOP,
  changeHandler = NOOP,
  horizontal = true,
  visible
}) => {
  const locale = useSelector(selectCurrentLocale)
  const { t } = useTranslate()

  return (
    <div
      className={cx(styles.box, {
        [styles.interactive]: interactive,
        [styles.visible]: !!visible
      })}
      data-tooltip-id={`cart-product-tooltip-${id}`}
    >
      <div className={styles.box__inner}>
        <div className={styles.image}>
          <FallbackImg src={image} alt={name} fallback={PRODUCT_CARD_FALLBACK_IMAGE_URLS[locale]} />
        </div>
        <div className={styles.number}>{number}</div>
        <div className={styles.name}>
          {visible ? (
            <Link to={`/products/${id}`} className={styles.name__inner}>
              {name}
            </Link>
          ) : (
            <div className={styles.name__inner}>{name}</div>
          )}
        </div>
        {!interactive && <div className={styles.count}>{count}</div>}
        {interactive && (
          <div className={styles.counter}>
            <Counter disabled={!visible} count={count} onChange={changeHandler} size='sm' gk={gk} ve={ve} min={1} step={ve} />
          </div>
        )}
        <div className={styles.summ}>{numberFormat(summ, 2, 2, currency) || t["product-price-on-request"]}</div>
        <div className={styles.total}>{numberFormat(totalSumm, 2, 2, currency) || t["product-price-on-request"]}</div>
        {interactive && (
          <div className={styles.delete}>
            <button onClick={deleteHandler} type='button'>
              <CloseIcon />
            </button>
          </div>
        )}
      </div>
      {visible === 0 && (
        <Tooltip id={`cart-product-tooltip-${id}`} className='tooltip-content'>
          <div dangerouslySetInnerHTML={{ __html: t["product-tooltip-not-exist"] }} />
        </Tooltip>
      )}
    </div>
  )
}

ProductBasketCardDesktop.propTypes = {
  id: PropTypes.string,
  image: PropTypes.string,
  number: PropTypes.string,
  summ: PropTypes.number,
  name: PropTypes.string,
  count: PropTypes.string,
  gk: PropTypes.number,
  ve: PropTypes.number,
  totalSumm: PropTypes.number,
  visible: PropTypes.number,
  currency: PropTypes.string,
  horizontal: PropTypes.bool,
  interactive: PropTypes.bool,
  deleteHandler: PropTypes.func,
  changeHandler: PropTypes.func
}

export default memo(ProductBasketCardDesktop)
