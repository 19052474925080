import moment from "moment"
import { dateFormatReq } from "./date-format"

export const dateInputValidate = (str = "") => {
  const today = moment()
  const targetDate = moment(dateFormatReq(str))

  return {
    isValid: targetDate.isValid(),
    isAfterToday: targetDate.isAfter(today)
  }
}
