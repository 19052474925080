/* eslint-disable react/prop-types */
import React, { memo } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Link } from "react-router-dom"
import { Tooltip } from "react-tooltip"
import moment from "moment"
import Button from "../../Button"
import { pxToRem } from "../../../utils/px-to-rem"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"
import { useTranslate } from "../../../utils/hooks/useTranslate"
import { CurrencyEnum } from "../../../constants/currency"
import { downloadFileByFetchUrl } from "../../../utils/download-by-fetch"
import { orderDownloadFileReq } from "../../../api/order"
import { numberFormat } from "../../../utils/number-format"

import FileIcon from "../../../assets/icons/FileIcon"
import EyeIcon from "../../../assets/icons/EyeIcon"
import ImportedIcon from "../../../assets/icons/ImportedIcon"

import styles from "./index.module.scss"

const MobileOrderCard = ({
  id,
  imported,
  number,
  summ,
  date,
  user,
  downloadFileHandler,
  tooltip,
  copyClickHandler,
  horizontal = true
}) => {
  const { t } = useTranslate()

  return (
    <div className={cx(styles.box, styles.mobile)}>
      <div className={styles.box__inner}>
        <div className={styles.row}>
          <div className={cx(styles.col, styles.col__number)}>
            <div className={styles.number}>{number}</div>
          </div>
          <div className={cx(styles.col, styles.col__summ)}>
            <div className={styles.summ}>
              {numberFormat(+summ, 2)} {CurrencyEnum.EUR}
            </div>
          </div>
          <div className={cx(styles.col, styles.col__date)}>
            <div className={styles.date}>{moment(date).format("DD.MM.YYYY")}</div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={cx(styles.col, styles.col__user)}>
            <div className={styles.user}>
              {!!imported && <ImportedIcon />}
              {user}
            </div>
          </div>
          <div className={cx(styles.col, styles.col__link)}>
            <button
              className={styles.download}
              onClick={downloadFileHandler}
              type='button'
              data-tooltip-id={`${id}-order-tooltip`}
            >
              <FileIcon v2 />
              <Tooltip id={`${id}-order-tooltip`} place='top-start' className={`tooltip-content ${styles.tooltip}`}>
                {tooltip}
              </Tooltip>
            </button>
          </div>
        </div>
        <div className={styles.row}>
          <div className={cx(styles.col, styles.col__button)}>
            <Button text={t["button-order-again"]} size='sm' onClick={copyClickHandler} />
          </div>
          <div className={cx(styles.col, styles.col__detail)}>
            <Link to={`/profile/orders/${id}`} className={styles.detail}>
              <EyeIcon width={`${pxToRem(40)}rem`} height={`${pxToRem(22)}rem`} strokeWidth={1} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

const DesktopOrderCard = ({
  id,
  imported,
  number,
  summ,
  date,
  user,
  downloadFileHandler,
  tooltip,
  copyClickHandler,
  horizontal = true
}) => {
  const { t } = useTranslate()

  return (
    <div className={cx(styles.box, {})}>
      <div className={styles.box__inner}>
        <div className={styles.number}>{number}</div>
        <div className={styles.summ}>
          {numberFormat(+summ, 2)} {CurrencyEnum.EUR}
        </div>
        <div className={styles.date}>{moment(date).format("DD.MM.YY")}</div>
        <div className={styles.user}>
          {!!imported && <div className={styles.import__txt}>{t["order-card-import"]}</div>}
          <div>
            {!!imported && <ImportedIcon />}
            {user}
          </div>
        </div>
        <div className={styles.tools}>
          <button className={styles.download} onClick={downloadFileHandler} type='button' data-tooltip-id={`${id}-order-tooltip`}>
            <FileIcon v2 />
            <Tooltip id={`${id}-order-tooltip`} place='top-start' className={`tooltip-content ${styles.tooltip}`}>
              {tooltip}
            </Tooltip>
          </button>
          <Link to={`/profile/orders/${id}`} className={styles.detail}>
            <EyeIcon width={`${pxToRem(28)}rem`} height={`${pxToRem(20)}rem`} />
          </Link>
          <Button text={t["button-order-again"]} size='sm' onClick={copyClickHandler} />
        </div>
      </div>
    </div>
  )
}

const OrderCard = ({ id, number, summ, date, user, tooltip, copyClickHandler, imported, horizontal = true }) => {
  const { isDesktop } = useWindowSize()

  const downloadFileHandler = () => {
    downloadFileByFetchUrl(orderDownloadFileReq(id), "data:application/csv;charset=utf-8", `${number}.csv`)
  }

  return isDesktop ? (
    <DesktopOrderCard
      id={id}
      date={date}
      downloadFileHandler={downloadFileHandler}
      number={number}
      summ={summ}
      user={user}
      tooltip={tooltip}
      copyClickHandler={copyClickHandler}
      horizontal={horizontal}
      imported={imported}
    />
  ) : (
    <MobileOrderCard
      id={id}
      date={date}
      downloadFileHandler={downloadFileHandler}
      number={number}
      summ={summ}
      user={user}
      tooltip={tooltip}
      copyClickHandler={copyClickHandler}
      horizontal={horizontal}
      imported={imported}
    />
  )
}

OrderCard.propTypes = {
  id: PropTypes.string,
  number: PropTypes.string,
  summ: PropTypes.string,
  date: PropTypes.string,
  user: PropTypes.string,
  tooltip: PropTypes.string,
  copyClickHandler: PropTypes.func,
  horizontal: PropTypes.bool
}

export default memo(OrderCard)
