import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import AccordionButton from "./AccordionButton"
import { NOOP } from "../../constants/noop"

const Accordion = ({
  list = [],
  fill,
  uppercase,
  activeIndex,
  onOpen = NOOP,
  size,
  labelHover,
  collapseOnBlur,
  borderBottom,
  disabled,
  classNames
}) => {
  const [activeCollapseIndex, setActiveCollapseIndex] = useState()

  useEffect(() => {
    setActiveCollapseIndex(activeIndex)
  }, [activeIndex])

  return list?.map((item, index) => (
    <AccordionButton
      {...item}
      borderBottom={borderBottom}
      activeCollapseIndex={activeCollapseIndex}
      collapseIndex={index}
      setActive={(i) => {
        setActiveCollapseIndex(i)
        onOpen(i)
      }}
      isActive={activeCollapseIndex === index}
      key={index}
      fill={fill}
      uppercase={uppercase}
      size={size}
      labelHover={labelHover}
      collapseOnBlur={collapseOnBlur}
      single={list.length === 1}
      disabled={disabled}
      classNames={classNames}
    />
  ))
}

Accordion.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node
    })
  ),
  fill: PropTypes.bool,
  uppercase: PropTypes.bool,
  activeIndex: PropTypes.number,
  borderBottom: PropTypes.bool,
  labelHover: PropTypes.bool,
  disabled: PropTypes.bool,
  collapseOnBlur: PropTypes.bool,
  onOpen: PropTypes.func,
  size: PropTypes.oneOf(["sm", "md"]),
  classNames: PropTypes.string
}

export default React.memo(Accordion)
