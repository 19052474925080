/* eslint-disable func-names */
/* eslint-disable indent */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import PropTypes from "prop-types"
import { toast } from "react-toastify"
import { isValidPhoneNumber } from "react-phone-number-input"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import Modal from "../../../components/Modal"
import PhoneInputComponent from "../../../components/PhoneInputComponent"
import { pxToRem } from "../../../utils/px-to-rem"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"
import { useTranslate } from "../../../utils/hooks/useTranslate"
import { NOOP } from "../../../constants/noop"
import { changeProfileInfoReq } from "../../../api/profile"
import { responseErrorParser } from "../../../utils/response-error-parser"
import { TOAST_DEFAULT_CONFIG } from "../../../constants/toast"

import styles from "./index.module.scss"

Yup.addMethod(Yup.string, "phoneValueValidation", function (errorMessage) {
  return this.test("test-card-type", errorMessage, function (value) {
    const { path, createError } = this
    return isValidPhoneNumber(value) || createError({ path, message: errorMessage })
  })
})

const formSchemaProfile = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t["form-required-field"]),
    surname: Yup.string().nullable(),
    phone: Yup.string().required(t["form-required-field"]).phoneValueValidation(t["form-invalid-phone"]),
    email: Yup.string().required(t["form-required-field"]),
    position: Yup.string().required(t["form-required-field"])
  })

const ModalEditProfile = ({ isModalOpen, closeModal = NOOP, initialData = {} }) => {
  const { t, locale } = useTranslate()
  const { isDesktop } = useWindowSize()
  const [isSubmited, setIsSubmited] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: initialData.name,
      surname: initialData.surname,
      email: initialData.email,
      position: initialData.position,
      phone: initialData.phone
    },
    validationSchema: formSchemaProfile(t),
    validateOnBlur: true,
    onSubmit: async (values) => {
      const nameCompare = initialData.name === formik.values.name
      const surNameCompare = initialData.surname === formik.values.surname
      const phoneCompare = initialData.phone === formik.values.phone
      const mailCompare = initialData.email === formik.values.email
      const positionCompare = initialData.position === formik.values.position

      if (nameCompare && surNameCompare && phoneCompare && mailCompare && positionCompare) {
        return closeModal()
      }

      try {
        setIsLoading(true)
        const { name, surname, email, position, phone } = values
        await changeProfileInfoReq({
          name,
          surname,
          email,
          position,
          phone
        })
        setIsSubmited(true)
      } catch (err) {
        toast.error(responseErrorParser(err), TOAST_DEFAULT_CONFIG)
        setIsLoading(false)
        setIsSubmited(false)
      }
    }
  })

  const initForm = () => {
    formik.setValues({
      name: initialData.name,
      surname: initialData.surname,
      email: initialData.email,
      position: initialData.position,
      phone: initialData.phone
    })
    formik.setErrors({})
    formik.setTouched({})
    setIsLoading(false)
    setIsSubmited(false)
  }

  useEffect(() => {
    initForm()
  }, [initialData])

  useEffect(() => {
    if (!isModalOpen) {
      initForm()
    }
  }, [isModalOpen])

  return (
    <Modal
      modalIsOpen={isModalOpen}
      closeModal={closeModal}
      title={
        <span
          dangerouslySetInnerHTML={{
            __html: !isSubmited ? t["profile-edit-info-modal-title"] : t["profile-content-success"]
          }}
        />
      }
      cancelHandler={closeModal}
      maxWidth={`${pxToRem(600)}rem`}
      style={{
        content: isDesktop
          ? {
              padding: `${pxToRem(25)}rem`,
              width: `${pxToRem(550)}rem`,
              height: "min-height"
            }
          : {
              padding: `${pxToRem(25)}rem ${pxToRem(15)}rem`,
              height: "min-height",
              maxHeight: "90%"
            }
      }}
    >
      <div className={styles.modal}>
        {!isSubmited ? (
          <form onSubmit={formik.handleSubmit} noValidate autoComplete='off'>
            <div className={styles.inputs}>
              <Input
                label={t["profile-field-firstName"]}
                value={formik.values?.name}
                errors={!!formik.errors?.name && !!formik.touched?.name}
                onChange={(e, val) => formik.setFieldValue("name", val)}
              />
              <Input
                label={t["profile-field-lastName"]}
                value={formik.values?.surname}
                errors={!!formik.errors?.surname && !!formik.touched?.surname}
                onChange={(e, val) => formik.setFieldValue("surname", val)}
              />
              <Input
                label={t["profile-field-email"]}
                value={formik.values?.email}
                errors={!!formik.errors?.email && !!formik.touched?.email}
                onChange={(e, val) => formik.setFieldValue("email", val)}
              />
              <PhoneInputComponent
                label={t["profile-field-phone"]}
                value={formik.values?.phone}
                country={locale || "de"}
                errors={!!formik.errors?.phone && !!formik.touched?.phone}
                onChange={(val) => {
                  formik.setFieldValue("phone", val)
                }}
              />
              <Input
                label={t["profile-field-position"]}
                value={formik.values?.position}
                errors={!!formik.errors?.position && !!formik.touched?.position}
                onChange={(id, val) => formik.setFieldValue("position", val)}
              />
            </div>
            <div>
              <Button disabled={isLoading} loading={isLoading} submitType text={t["button-save"]} />
            </div>
          </form>
        ) : (
          <p dangerouslySetInnerHTML={{ __html: t["profile-edit-profile-success"] }} />
        )}
      </div>
    </Modal>
  )
}

ModalEditProfile.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  initialData: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    position: PropTypes.string
  })
}

export { ModalEditProfile }
