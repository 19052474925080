/* eslint-disable no-plusplus */
import { useRef, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { useDispatch, useSelector } from "react-redux"
import Container from "../../../modules/Container"
import ContactCard from "../../../components/Cards/ContactCard"
import FileUpload from "../../../components/FileUpload"
import AddToCart from "../../../modules/AddToCart"
import ColumnsPreview from "../../../modules/ColumnsPreview"
import { useAccordion } from "../../../utils/hooks/useAccordion"

import {
  disposePreviewModal,
  selectColumnPreviewModal,
  selectQuickLinksData,
  setColumnPreviewModalData,
  setQuicklinksFile,
  toggleColumnPreviewModal
} from "../home.controller"
import { selectManagerInfo } from "../../Profile/profile.controller"

import { TEXT_FILES_TYPES_STRING } from "../../../constants/files"
import { XLSXParser } from "../../../utils/xlsx-parser"
import { useTranslate } from "../../../utils/hooks/useTranslate"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"
import { NOOP } from "../../../constants/noop"

import ChewronLeftIcon from "../../../assets/icons/ChewronLeftIcon"

import styles from "./index.module.scss"

const Quicklinks = ({ activeCollapseIndex, collapseIndex, setActive = NOOP }) => {
  const { t } = useTranslate()
  const panelRef = useRef(null)
  const dispatch = useDispatch()
  const { isDesktop } = useWindowSize()
  const { maxHeight, handleResize } = useAccordion(panelRef, setActive, 500)
  const { isOpen, data } = useSelector(selectColumnPreviewModal)
  const { file } = useSelector(selectQuickLinksData)
  const { name, post, mail, phone, image } = useSelector(selectManagerInfo)

  const [parseError, setParseEror] = useState()
  const [isParse, setIsParse] = useState(false)

  const [proggress, setProggress] = useState(0)
  const [showSuccess, setShowSuccess] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)

  const handleSetActive = () => {
    if (activeCollapseIndex === collapseIndex) {
      setActive(null)
    } else {
      setActive(collapseIndex)
      handleResize()
    }
  }

  const parseXlsx = (file) => {
    XLSXParser(
      file,
      setIsParse,
      (res) => {
        setParseEror()
        dispatch(setColumnPreviewModalData(res))
        dispatch(toggleColumnPreviewModal(true))
      },
      (err) => {
        setParseEror({ message: t[err.message] })
      }
    )
  }

  const submitHandler = async () => {
    // TODO: Old design realization (file upload with progress bar, now not actual because we upload simple json payload). can be refactoring???
    setIsSubmit(true)
    setTimeout(() => dispatch(toggleColumnPreviewModal(false)))
    setShowSuccess(true)
    dispatch(setQuicklinksFile(null))
    setProggress(100)

    setTimeout(() => {
      setShowSuccess(false)
      setIsSubmit(false)
      dispatch(disposePreviewModal())
      setProggress(0)
    }, [5000])
  }

  const errorHandler = (err) => {
    setProggress(100)
    setParseEror(err)
  }

  return (
    <div
      className={cx(styles.box, {
        [styles.open]: activeCollapseIndex === collapseIndex
      })}
    >
      <Container className={styles.box__inner}>
        <div className={styles.panel} style={{ "--max-height": maxHeight }}>
          <div className={styles.panel__inner} ref={panelRef}>
            <div className={styles.card}>
              <AddToCart
                cardType
                buttonLabel={t["quick-buy-button"]}
                namePlaceholder={t["quick-buy-placeholder-article"]}
                stkPlaceholder={t["quick-buyp-laceholder-stk"]}
                title={t["quick-buy-title"]}
                subtitle={`${t["quick-buy-title-2"]}`}
              />
            </div>
            {isDesktop && (
              <div className={styles.card}>
                <FileUpload
                  title={t["file-upload-title"]}
                  tooltipTitle={t["file-upload-tooltip-label"]}
                  tooltipInfo={t["file-upload-tooltip-detail"]}
                  successMsg={`<span>${data?.length || 0}</span> ${t["file-upload-tooltip-success"]}`}
                  fileTypes={TEXT_FILES_TYPES_STRING}
                  file={file}
                  onCancel={() => {
                    dispatch(setQuicklinksFile(null))
                    setProggress(0)
                  }}
                  onChange={(file) => {
                    dispatch(setQuicklinksFile(file))
                    parseXlsx(file)
                  }}
                  errorMsg={parseError}
                  proggress={proggress}
                  showSuccess={showSuccess}
                />
              </div>
            )}
            <div className={styles.card}>
              <ContactCard name={name} post={post} mail={mail} phone={phone} image={image} vertical={!isDesktop} />
            </div>
          </div>
        </div>
        <button type='button' className={styles.expand__btn} onClick={handleSetActive}>
          <span>{t["quick-functions-title"]}</span> <ChewronLeftIcon />
        </button>
        <ColumnsPreview
          closeModal={() => {
            dispatch(toggleColumnPreviewModal(false))
            if (!isSubmit) {
              dispatch(setQuicklinksFile(null))
              dispatch(disposePreviewModal())
            }
          }}
          isModalOpen={isOpen}
          submitHandler={submitHandler}
          onSubmit={submitHandler}
          onError={errorHandler}
        />
      </Container>
    </div>
  )
}

Quicklinks.propTypes = {
  setActive: PropTypes.func,
  activeCollapseIndex: PropTypes.number,
  collapseIndex: PropTypes.number
}

export default Quicklinks
