export const TRANSLATE_CONSTANT_KEYS = [
  "footer-address",
  "search-input-placeholder",
  "form-required-field",
  "form-invalid-email",
  "form-invalid-phone",
  "date-input-placeholder",
  "search-empty-message",
  "search-empty-message-2",
  "filter-top-seller",
  "filter-new",
  "filter-discount",
  "favorite-title-desktop",
  "favorite-title-mobile",
  "favorite-text-description",
  "favorite-sort-brands",
  "favorite-sort-categories",
  "favorite-sort-categories-empty",
  "navigation-products",
  "navigation-cart",
  "navigation-favorite",
  "navigation-profile",
  "navigation-dashboard",
  "navigation-orders",
  "navigation-profile-2",
  "navigation-logout",
  "navigation-home",
  "navigation-sortiment",
  "navigation-declaration",
  "sign-in-title",
  "sign-in-no-login-data",
  "input-mail-placeholder",
  "login-link-request-button",
  "login-link-send-text",
  "quick-buy-placeholder-article",
  "quick-buyp-laceholder-stk",
  "quick-buy-title",
  "quick-buy-title-2",
  "quick-buy-button",
  "quick-functions-title",
  "file-upload-title",
  "file-upload-dnd-area-1",
  "file-upload-dnd-area-2",
  "file-upload-dnd-area-3",
  "file-upload-tooltip-label",
  "file-upload-tooltip-detail",
  "file-upload-tooltip-success",
  "file-template-download-title",
  "invalid-file-foormat",
  "svg-accordrion-label",
  "contact-manager-card-title",
  "brands-and-categories-title",
  "highlights-title",
  "last-seen-title",
  "simillar-products-title",
  "show-navigation",
  "hide-navigation",
  "sort-filter-title",
  "reset-filters",
  "product-type-neu",
  "product-type-sale",
  "product-price-on-request",
  "product-tooltip-amount",
  "product-tooltip-ean-gtin",
  "product-tooltip-weight",
  "product-tooltip-length",
  "product-tooltip-width",
  "product-tooltip-height",
  "product-tooltip-not-exist",
  "product-info-number",
  "product-info-gtin-bean",
  "product-info-country",
  "product-info-amount-ve",
  "product-info-amount-gk",
  "product-empty-history-msg",
  "product-description-title",
  "product-description-empty-msg",
  "product-logistic-information",
  "product-favorite-empty-message",
  "products-list-empty-message",
  "product-added-to-cart-msg",
  "year-accordion-label",
  "month-accordion-label",
  "sort-accordion-label",
  "column-preview-title",
  "orders-title",
  "orders-table-sort-number",
  "orders-table-sort-summ",
  "orders-table-sort-date",
  "orders-table-sort-name",
  "orders-empty-message",
  "order-card-csv-tooltip",
  "404-page-title-1",
  "404-page-text",
  "delete-approval-message",
  "all",
  "yes",
  "no",
  "month-name-1",
  "month-name-2",
  "month-name-3",
  "month-name-4",
  "month-name-5",
  "month-name-6",
  "month-name-7",
  "month-name-8",
  "month-name-9",
  "month-name-10",
  "month-name-11",
  "month-name-12",
  "whish-data-label",
  "payment-method-label",
  "agreed-conditions-payment",
  "copy-and-edit",
  "copy-and-order",
  "copy-order-text-1",
  "copy-order-text-2",
  "copy-order-text-3",
  "form-error-invalid-date",
  "form-error-assign-col",
  "check-order",
  "order-error-text-1",
  "order-error-text-2",
  "order-error-text-3",
  "order-success-text-1",
  "order-success-text-3",
  "order-card-import",
  "button-to-my-orders",
  "button-all-orders",
  "button-review-all-orders",
  "button-order-again",
  "button-add-to-cart",
  "button-view",
  "button-login",
  "button-back",
  "button-save-and-add",
  "button-to-shop",
  "order-detail-info-title",
  "order-detail-title",
  "order-detail-title-2",
  "order-delivery-method",
  "order-desired-date",
  "order-payment-method",
  "order-link",
  "order-comment",
  "order-payment-address",
  "order-delivery-address",
  "order-delivery-date",
  "order-delivery-summ",
  "order-person-info",
  "order-final-summ",
  "order-product-count",
  "order-article-number",
  "order-article-name",
  "order-product-amount",
  "order-product-total-amount",
  "shop-cart-title",
  "shop-cart-article",
  "shop-cart-empty-text-1",
  "shop-cart-empty-text-3",
  "button-continue-shopping",
  "button-order",
  "order-cart-link",
  "text-area-placeholder",
  "shipping-type-placeholder",
  "validation-error-max-250",
  "choose-others",
  "choose-address",
  "button-save",
  "button-send",
  "profile-field-company",
  "profile-field-street",
  "profile-field-city",
  "profile-field-optional",
  "profile-field-optional-2",
  "profile-field-message",
  "profile-field-postal_code",
  "profile-field-country",
  "profile-field-firstName",
  "profile-field-lastName",
  "profile-field-phone",
  "profile-field-email",
  "profile-field-position",
  "profile-content-personal",
  "profile-content-address",
  "profile-content-company",
  "profile-content-billing",
  "profile-company-edit",
  "profile-edit-profile",
  "profile-add-address",
  "profile-shipping-address",
  "profile-success-create-address",
  "profile-success-edit-address",
  "profile-success-delete-address",
  "profile-add-new-address",
  "profile-edit-new-address",
  "profile-unable-edit-delete-tooltip",
  "profile-content-success",
  "profile-edit-profile-success",
  "profile-edit-info-modal-title",
  "footer-text-company-name",
  "footer-text-copyright",
  "footer-text-information",
  "footer-text-service",
  "request-manager-modal-title",
  "request-manager-modal-success",
  "request-manager-modal-comment-placeholder"
]
