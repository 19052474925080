import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const WarningIcon = ({ color, strokeWidth, ...props }) => (
  <svg
    width={`${pxToRem(15)}rem`}
    height={`${pxToRem(16)}rem`}
    viewBox='0 0 15 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='7.5' cy='7.5' r='7.5' fill={color || "#EB5757"} />
    <path
      d='M8.33154 2.75781L8.1792 9.27686H6.87158L6.71289 2.75781H8.33154ZM6.66846 11.2764C6.66846 11.0436 6.74463 10.849 6.89697 10.6924C7.05355 10.5316 7.26937 10.4512 7.54443 10.4512C7.81527 10.4512 8.02897 10.5316 8.18555 10.6924C8.34212 10.849 8.42041 11.0436 8.42041 11.2764C8.42041 11.5007 8.34212 11.6932 8.18555 11.854C8.02897 12.0106 7.81527 12.0889 7.54443 12.0889C7.26937 12.0889 7.05355 12.0106 6.89697 11.854C6.74463 11.6932 6.66846 11.5007 6.66846 11.2764Z'
      fill='#ffffff'
    />
  </svg>
)

WarningIcon.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number
}

export default WarningIcon
