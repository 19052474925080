import React, { memo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import ReactInputMask from "react-input-mask"
import { NOOP } from "../../constants/noop"

import styles from "./index.module.scss"

const MaskInput = ({
  name,
  label,
  value,
  placeholder,
  required,
  disabled,
  errors,
  size = "md",
  maskChar = "",
  onBlur = NOOP,
  onChange = NOOP,
  onFocus = NOOP,
  onKeyPress = NOOP
}) => {
  return (
    <div
      className={cx(styles.box, {
        [styles.disabled]: disabled,
        [styles[size]]: size,
        [styles["with-label"]]: label
      })}
    >
      <div
        className={cx(styles.box__inner, {
          [styles.error]: errors
        })}
      >
        {label && (
          <label
            className={cx(styles.label, {
              [styles.required]: required
            })}
            htmlFor={name}
          >
            {label}
          </label>
        )}
        <ReactInputMask
          id={name}
          name={name}
          disabled={disabled}
          value={value || ""}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          aria-label={name}
          placeholder={placeholder}
          mask='99.99.9999'
          maskChar={maskChar}
        />
      </div>
      {errors && <p className={styles.error}>{errors.message}</p>}
    </div>
  )
}

MaskInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  maskChar: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  errors: PropTypes.objectOf(
    PropTypes.shape({
      message: PropTypes.string
    })
  ),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func
}

export default memo(MaskInput)
