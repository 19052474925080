import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const ChewronLeftIcon = ({ color, strokeWidth, ...props }) => (
  <svg
    width={`${pxToRem(9)}rem`}
    height={`${pxToRem(18)}rem`}
    viewBox='0 0 9 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M7.40039 1.8002L1.00039 9.0002L7.40039 16.2002'
      stroke={color || "#333333"}
      strokeWidth={strokeWidth || 2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

ChewronLeftIcon.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number
}

export default ChewronLeftIcon
