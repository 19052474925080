/* eslint-disable react/no-danger */
import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import Breadcrumbs from "../../components/Breadcrumbs"
import Container from "../../modules/Container"
import Layout from "../../modules/Layout"
import ProductSlider from "../../components/Sliders/ProductSlider"
import ProductTop from "./ProductTop"
import { useWindowSize } from "../../utils/hooks/useWindowSize"
import { useTranslate } from "../../utils/hooks/useTranslate"
import Spinner from "../../components/Spinner"

import {
  closeProductPreview,
  disposeProduct,
  getProduct,
  getSimilarProduct,
  getViewedProducts,
  openProductPreview,
  selectIsSimilarProductsLoading,
  selectIsViewedProductsLoading,
  selectProductContentText,
  selectProductData,
  selectProductLoaded,
  selectProductLoading,
  selectProductPreviewActive,
  selectProductSimilarProducts,
  selectProductViewedProducts
} from "./product.controller"

import styles from "./index.module.scss"

const Product = () => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const params = useParams()
  const { isMobile } = useWindowSize()
  const isPreviewActive = useSelector(selectProductPreviewActive)

  const similarProductData = useSelector(selectProductSimilarProducts)
  const viewedProductsData = useSelector(selectProductViewedProducts)
  const {
    id,
    title,
    description,
    type,
    price,
    discount,
    currency,
    article,
    beanGtin,
    countryOrigin,
    ve,
    gk,
    count,
    favorite,
    images,
    logistics,
    category,
    brand,
    subcategory
  } = useSelector(selectProductData)
  const productLoading = useSelector(selectProductLoading)
  const isProductLoaded = useSelector(selectProductLoaded)
  const isViewedProductsLoading = useSelector(selectIsViewedProductsLoading)
  const isSimillarProductsLoading = useSelector(selectIsSimilarProductsLoading)
  const { text } = useSelector(selectProductContentText)

  const breadcrumbsDesktop = useMemo(
    () => [
      {
        url: "/",
        label: t["navigation-home"]
      },
      {
        url: `/products?brand=${brand?.id}`,
        label: t["navigation-sortiment"]
      },
      {
        url: `/products?brand=${brand?.id}&${category?.id}=all`,
        label: category?.name
      },
      {
        url: `/products?brand=${brand?.id}&${category?.id}=${subcategory?.id}`,
        label: subcategory?.name
      },
      {
        url: `/products/${id}`,
        label: article
      }
    ],
    [t, id, category, brand, subcategory]
  )

  const breadcrumbsMobile = useMemo(
    () => [
      {
        url: `/products?brand=${brand?.id}&${category?.id}=all`,
        label: category?.name
      },
      {
        url: `/products?brand=${brand?.id}&${category?.id}=${subcategory?.id}`,
        label: subcategory?.name
      },
      {
        url: `/products/${id}`,
        label: article
      }
    ],
    [id, category, brand, subcategory]
  )

  useEffect(() => {
    if (!title) return

    document.title = title
  }, [title])

  useEffect(() => {
    if (params?.id) {
      dispatch(getProduct(params.id))
      dispatch(getSimilarProduct(params.id))
      dispatch(getViewedProducts())
    }
  }, [params])

  useEffect(() => {
    return () => dispatch(disposeProduct())
  }, [])

  if (productLoading || !isProductLoaded) {
    return (
      <Layout>
        <div className={styles.box}>
          <Container className={styles.box__inner}>
            <Spinner />
          </Container>
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <div className={styles.box}>
        <Container className={styles.box__inner}>
          <div className={styles.box__header}>
            <Breadcrumbs path={isMobile ? breadcrumbsMobile : breadcrumbsDesktop} />
          </div>
          <div className={styles.top}>
            {!productLoading && (
              <ProductTop
                id={id}
                images={images}
                type={type}
                title={title}
                /* TODO: Not currently in use */
                // description={description}
                price={price}
                discount={discount}
                currency={currency}
                article={article}
                beanGtin={beanGtin}
                countryOrigin={countryOrigin}
                ve={ve}
                gk={gk}
                count={count}
                logistics={logistics}
                favorite={favorite}
                isPreviewActive={isPreviewActive}
                closeProductPreview={() => {
                  dispatch(closeProductPreview())
                }}
                openProductPreview={() => {
                  dispatch(openProductPreview())
                }}
              />
            )}
          </div>
          <div className={styles.description}>
            <div className={styles.description__title} dangerouslySetInnerHTML={{ __html: t["product-description-title"] }} />
            <div
              className={styles.description__text}
              dangerouslySetInnerHTML={{ __html: description || t["product-description-empty-msg"] }}
            />
          </div>
        </Container>
        <div>
          {!isViewedProductsLoading && (
            <ProductSlider
              mobileNavRight
              data={similarProductData}
              background='#E0DBDA'
              title={<span dangerouslySetInnerHTML={{ __html: t["simillar-products-title"] }} />}
            />
          )}
        </div>
        <div>
          {!isSimillarProductsLoading && (
            <ProductSlider
              mobileNavRight
              data={viewedProductsData}
              title={<span dangerouslySetInnerHTML={{ __html: t["last-seen-title"] }} />}
              emptyMsg={
                <span
                  dangerouslySetInnerHTML={{
                    __html: t["product-empty-history-msg"]
                  }}
                />
              }
            />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Product
