import { useEffect } from "react"

export const usePageMinHeight = () => {
  const setPageMinHeight = () => {
    const mainContainer = document.querySelector("div#root")
    const windowHeight = window.innerHeight

    if (mainContainer) mainContainer.style.minHeight = `${windowHeight}px`
  }

  useEffect(() => {
    setPageMinHeight()
    window.addEventListener("resize", setPageMinHeight)

    return () => {
      window.removeEventListener("resize", setPageMinHeight)
    }
  }, [])
}
