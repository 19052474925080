import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const FilterIcon = ({ color, strokeWidth, ...props }) => (
  <svg
    width={`${pxToRem(20)}rem`}
    height={`${pxToRem(22)}rem`}
    viewBox='0 0 20 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M8.82353 13.098V17.8166L11.1765 21V13.098C11.1765 12.8817 11.2466 12.6711 11.3765 12.498L18.8 2.6C19.2944 1.94076 18.824 1 18 1H2C1.17595 1 0.705574 1.94076 1.2 2.6L8.62353 12.498C8.75335 12.6711 8.82353 12.8817 8.82353 13.098Z'
      stroke={color || "#333333"}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

FilterIcon.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number
}

export default FilterIcon
