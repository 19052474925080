/* eslint-disable react/no-danger */
import React, { memo, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { useDispatch, useSelector } from "react-redux"
import Accordion from "../../components/Accordion"
import AccordionPanel from "../../components/Accordion/AccordionPanel"
import Button from "../../components/Button"
import Modal from "../../components/Modal"
import WarningIcon from "../../assets/icons/WarningIcon"
import { useTranslate } from "../../utils/hooks/useTranslate"
import { pxToRem } from "../../utils/px-to-rem"

import {
  selectColumnPreviewModal,
  selectColumnPreviewModalAccordionOne,
  selectColumnPreviewModalAccordionTwo,
  setColumnAccordionOneActiveIndex,
  setColumnAccordionOneFilter,
  setColumnAccordionTwoActiveIndex,
  setColumnAccordionTwoFilter
} from "../../pages/Home/home.controller"

import { importProductsReq } from "../../api/order"
import { NOOP } from "../../constants/noop"
import { responseErrorParser } from "../../utils/response-error-parser"
import { getCartProductCount } from "../../pages/Cart/cart.controller"

import styles from "./index.module.scss"

const ColumnsPreview = ({ isModalOpen, onSubmit = NOOP, onError = NOOP, closeModal }) => {
  const { t, locale } = useTranslate()
  const dispatch = useDispatch()
  const { data, filters } = useSelector(selectColumnPreviewModal)
  const accordionOne = useSelector(selectColumnPreviewModalAccordionOne)
  const accordionTwo = useSelector(selectColumnPreviewModalAccordionTwo)

  const [showError, setShowError] = useState("")
  const [isSubmit, setIsSubmit] = useState(false)
  const [notExistProductsResponse, setNotExistProductsResponse] = useState([])

  const submitHandler = async (res) => {
    setIsSubmit(true)
    setNotExistProductsResponse([])

    try {
      await importProductsReq(res)
      setIsSubmit(false)
      dispatch(getCartProductCount())
      onSubmit()
    } catch (error) {
      setIsSubmit(false)
      dispatch(getCartProductCount())

      if (error.status === 404) {
        const notExistProducts = error.data?.data?.data
        setNotExistProductsResponse(notExistProducts)
        setShowError(`${notExistProducts.length} ${error.data?.data?.message}`)
      } else {
        onError({ message: responseErrorParser(error) })
      }
    }
  }

  const clickHandler = () => {
    const isColumnsNotDefined = accordionOne.selectedFilter?.id === accordionTwo.selectedFilter?.id

    if (isColumnsNotDefined) return setShowError(t["form-error-assign-col"])

    const preparedData = data.map((item) => {
      const keys = Object.keys(item)

      return {
        article: accordionOne?.selectedFilter?.id === "2" ? item[keys[1]] : item[keys[0]],
        quantity: accordionTwo?.selectedFilter?.id === "1" ? item[keys[0]] : item[keys[1]]
      }
    })
    submitHandler(preparedData)
  }

  useEffect(() => {
    setShowError("")
  }, [accordionOne, accordionTwo])

  useEffect(() => {
    if (!isModalOpen) {
      setIsSubmit(false)
      setShowError("")
      setNotExistProductsResponse([])
    }
  }, [isModalOpen])

  return (
    <Modal
      modalIsOpen={isModalOpen}
      closeModal={closeModal}
      title={t["column-preview-title"]}
      cancelHandler={closeModal}
      style={{
        content: {
          width: "80%",
          height: "80%",
          maxWidth: `${pxToRem(560)}rem`,
          maxHeight: `${pxToRem(477)}rem`,
          padding: `${pxToRem(40)}rem`,
          transform: "translate(-50%, -50%)"
        }
      }}
    >
      <div className={cx(styles.content)}>
        <div className={styles.grid}>
          <div className={styles.header}>
            <div className={styles.col}>
              <Accordion
                size='sm'
                activeIndex={accordionOne?.activeIndex}
                collapseOnBlur
                onOpen={(i) => {
                  dispatch(setColumnAccordionOneActiveIndex(i))
                }}
                list={[
                  {
                    title: (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: accordionOne?.selectedFilter?.label?.[locale] || t["svg-accordrion-label"]
                        }}
                      />
                    ),
                    content: (
                      <AccordionPanel
                        size='sm-light'
                        hover
                        background='#ffffff'
                        data={filters.map((item, index) => {
                          return (
                            <button
                              type='button'
                              key={index}
                              onClick={() => {
                                dispatch(setColumnAccordionOneActiveIndex(-1))
                                dispatch(setColumnAccordionOneFilter(item))
                              }}
                              dangerouslySetInnerHTML={{ __html: item.label?.[locale] }}
                            />
                          )
                        })}
                      />
                    )
                  }
                ]}
              />
            </div>
            <div className={styles.col}>
              <Accordion
                size='sm'
                activeIndex={accordionTwo?.activeIndex}
                collapseOnBlur
                onOpen={(i) => {
                  dispatch(setColumnAccordionTwoActiveIndex(i))
                }}
                list={[
                  {
                    title: (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: accordionTwo?.selectedFilter?.label?.[locale] || t["svg-accordrion-label"]
                        }}
                      />
                    ),
                    content: (
                      <AccordionPanel
                        size='sm-light'
                        hover
                        background='#ffffff'
                        data={filters.map((item, index) => {
                          return (
                            <button
                              type='button'
                              key={index}
                              onClick={() => {
                                dispatch(setColumnAccordionTwoActiveIndex(-1))
                                dispatch(setColumnAccordionTwoFilter(item))
                              }}
                              dangerouslySetInnerHTML={{ __html: item.label?.[locale] }}
                            />
                          )
                        })}
                      />
                    )
                  }
                ]}
              />
            </div>
          </div>
          <div className={styles.body}>
            {data?.map((item, i) => {
              const keys = Object.keys(item)
              const articlePos = accordionOne?.selectedFilter?.id === "1" ? 0 : 1
              const invalid = notExistProductsResponse.find((article) => article === item[keys[articlePos]])

              return (
                <>
                  <div
                    className={cx(styles.col, {
                      [styles.first]: i === 0,
                      [styles.last]: i === data.length - 1,
                      [styles.invalid]: invalid,
                      [styles.valid]: !invalid
                    })}
                  >
                    <div className={styles.item}>{item[keys[0]]}</div>
                  </div>
                  <div
                    className={cx(styles.col, {
                      [styles.first]: i === 0,
                      [styles.last]: i === data.length - 1,
                      [styles.invalid]: invalid,
                      [styles.valid]: !invalid
                    })}
                  >
                    <div className={styles.item}>{item[keys[1]]}</div>
                  </div>
                </>
              )
            })}
          </div>
        </div>
        <div className={styles.footer}>
          <div
            className={cx(styles.error, {
              [styles.visible]: showError
            })}
          >
            <WarningIcon />
            <span dangerouslySetInnerHTML={{ __html: showError }} />
          </div>
          <div className={styles.btn}>
            <Button
              loading={isSubmit}
              text={<span dangerouslySetInnerHTML={{ __html: t["button-save-and-add"] }} />}
              onClick={clickHandler}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

ColumnsPreview.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func
}

export default memo(ColumnsPreview)
