import { configureStore } from "@reduxjs/toolkit"
import PageReducer from "./slice/page"
import AuthReducer from "./slice/auth"
import TranslateReducer from "./slice/translations"
import HomeReducer from "../pages/Home/home.controller"
import ProductsReducer from "../pages/Products/products.controller"
import ProductReducer from "../pages/Product/product.controller"
import OrderReducer from "../pages/Order/order.controller"
import OrdersReducer from "../pages/Orders/orders.controller"
import ProfileReducer from "../pages/Profile/profile.controller"
import CartReducer from "../pages/Cart/cart.controller"
import FavoriteReducer from "../pages/Favorites/favorites.controller"
import SearchReducer from "./slice/search"

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    home: HomeReducer,
    products: ProductsReducer,
    product: ProductReducer,
    page: PageReducer,
    order: OrderReducer,
    orders: OrdersReducer,
    translate: TranslateReducer,
    profile: ProfileReducer,
    cart: CartReducer,
    favorites: FavoriteReducer,
    search: SearchReducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    })
  },
  devTools: process.env.NODE_ENV !== "production"
})
