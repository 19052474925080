/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getOrderYearsFilterOptionsReq, getOrdersReq } from "../../api/order"
import { MONTH_FILTER_OPTIONS } from "../../constants/month-filter-options"

export const OrderFilterTypeEnum = {
  YEAR: "year",
  MONTH: "month",
  SORT: "sort"
}

export const OrderSortTableTypeEnum = {
  NUMBER: "number",
  SUMM: "summ",
  DATE: "date",
  USER: "user"
}

export const ORDERS_TABLE_SORT_OPTIONS = [
  {
    id: 1,
    label: "orders-table-sort-number",
    key: OrderSortTableTypeEnum.NUMBER
  },
  {
    id: 2,
    label: "orders-table-sort-summ",
    key: OrderSortTableTypeEnum.SUMM
  },
  {
    id: 3,
    label: "orders-table-sort-date",
    key: OrderSortTableTypeEnum.DATE
  },
  {
    id: 4,
    label: "orders-table-sort-name",
    key: OrderSortTableTypeEnum.USER
  }
]

export const SortOrderEnum = {
  ASC: "asc",
  DESC: "desc",
  DEFAULT: "default"
}

export const CopyOrderTypeEnum = {
  COPY_AND_EDIT: "copy_and_edit",
  COPY_AND_ORDER: "copy_and_order"
}

const initialState = {
  data: [],

  meta: {
    all_page: 1,
    current_page: 1
  },

  // Ui accordions state
  accordions: {
    yearAccordion: {
      activeIndex: -1,
      options: []
    },
    monthAccordion: {
      activeIndex: -1,
      options: MONTH_FILTER_OPTIONS
    },
    sortAccordion: {
      activeIndex: -1,
      options: ORDERS_TABLE_SORT_OPTIONS
    }
  },

  // Ui sort buttons state
  tableSortButtonsState: {
    number: SortOrderEnum.DEFAULT,
    summ: SortOrderEnum.DEFAULT,
    date: SortOrderEnum.DEFAULT,
    user: SortOrderEnum.DEFAULT
  },

  // Selected filters
  ordersFilters: {
    year: null,
    month: null,
    sort: {
      key: "",
      sort: ""
    },
    page: 1,
    quantity: 15,
    isLoadMore: false
  },

  copyOrderModal: {
    isOpen: false,
    data: null
  },

  tableSortOptions: ORDERS_TABLE_SORT_OPTIONS,
  isOrdersListLoading: true,
  isYearsFilterOptionsLoading: true
}

export const getOrdersData = createAsyncThunk("orders/getOrders", async ({ data, isLoadMore }, { rejectWithValue }) => {
  try {
    const response = await getOrdersReq(data)
    if (response.status === 200) {
      if (response.data) {
        return { data: response.data.data, isLoadMore }
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

export const getOrderYearsFilterOptions = createAsyncThunk(
  "orders/getOrderYearsFilterOptions",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getOrderYearsFilterOptionsReq(id)
      if (response.status === 200) {
        if (response.data) {
          return response.data
        }
        return {}
      }
    } catch (error) {
      return rejectWithValue()
    }
  }
)

/* eslint-disable no-param-reassign */
export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setYearAccordionActiveIndex(state, action) {
      return {
        ...state,
        accordions: {
          ...state.accordions,
          yearAccordion: {
            ...state.accordions.yearAccordion,
            activeIndex: action.payload
          }
        }
      }
    },
    setMonthAccordionActiveIndex(state, action) {
      return {
        ...state,
        accordions: {
          ...state.accordions,
          monthAccordion: {
            ...state.accordions.monthAccordion,
            activeIndex: action.payload
          }
        }
      }
    },
    setSortAccordionActiveIndex(state, action) {
      return {
        ...state,
        accordions: {
          ...state.accordions,
          sortAccordion: {
            ...state.accordions.sortAccordion,
            activeIndex: action.payload
          }
        }
      }
    },
    setOrderFilter(state, action) {
      return {
        ...state,
        ordersFilters: {
          ...state.ordersFilters,
          [action.payload.type]: action.payload.data,
          isLoadMore: false,
          page: 1
        }
      }
    },
    toggleTableSort(state, action) {
      const currentSortType = state.tableSortButtonsState[action.payload.key]
      const newSortType =
        currentSortType === SortOrderEnum.DEFAULT
          ? SortOrderEnum.ASC
          : currentSortType === SortOrderEnum.ASC
            ? SortOrderEnum.DESC
            : SortOrderEnum.DEFAULT

      return {
        ...state,
        tableSortButtonsState: {
          ...initialState.tableSortButtonsState,
          [action.payload.key]: newSortType
        },
        ordersFilters: {
          ...state.ordersFilters,
          sort: {
            key: action.payload.key,
            sort: newSortType
          },
          isLoadMore: false,
          page: 1
        }
      }
    },
    changeOrdersPageProducts(state) {
      if (state.isOrdersListLoading === false && state.meta.all_page > state.meta.current_page) {
        state.isOrdersListLoading = true
        state.ordersFilters = {
          ...state.ordersFilters,
          page: state.meta.current_page + 1,
          isLoadMore: true
        }
      }
    },
    setOrderCopyModalOpen(state, action) {
      return {
        ...state,
        copyOrderModal: {
          ...state.copyOrderModal,
          isOpen: true,
          data: action.payload
        }
      }
    },
    disposeCopyOrderModal(state) {
      return {
        ...state,
        copyOrderModal: {
          ...initialState.copyOrderModal
        }
      }
    },
    disposePage() {
      return {
        ...initialState
      }
    }
  },
  extraReducers: (builder) => {
    builder
      // Get orders list
      .addCase(getOrdersData.pending, (state) => {
        state.isOrdersListLoading = true
      })
      .addCase(getOrdersData.fulfilled, (state, action) => {
        const { data, isLoadMore } = action.payload
        state.isOrdersListLoading = false
        state.data = isLoadMore ? [...state.data, ...data.orders] : data.orders
        state.meta = data.meta
      })
      .addCase(getOrdersData.rejected, (state) => {
        state.isOrdersListLoading = false
      })
      // Orders years filter options
      .addCase(getOrderYearsFilterOptions.pending, (state) => {
        state.isYearsFilterOptionsLoading = true
      })
      .addCase(getOrderYearsFilterOptions.fulfilled, (state, action) => {
        const data =
          action?.payload?.data?.map((item) => ({
            id: item,
            label: item
          })) || []

        data.unshift({
          id: -1,
          label: "all"
        })

        state.isYearsFilterOptionsLoading = false
        state.accordions.yearAccordion.options = data
      })
      .addCase(getOrderYearsFilterOptions.rejected, (state) => {
        state.isYearsFilterOptionsLoading = false
      })
  }
})

// List state
export const selectOrdersData = (state) => state.orders.data
export const selectOrdersMeta = (state) => state.orders.meta
// Sort accordions state
export const selectOrdersYearsAccordion = (state) => state.orders.accordions.yearAccordion
export const selectOrdersMonthAccordion = (state) => state.orders.accordions.monthAccordion
export const selectOrdersSortAccordion = (state) => state.orders.accordions.sortAccordion
// Table sort state
export const selectOrdersTableSortFilters = (state) => state.orders.tableSortButtonsState
export const selectOrdersTableSortOptions = (state) => state.orders.tableSortOptions
// List filters
export const selectOrdersFilter = (state) => state.orders.ordersFilters
// Copy order modal
export const selectOrdersCopyOrderModal = (state) => state.orders.copyOrderModal
// Loaders
export const selectOrdersListLoading = (state) => state.orders.isOrdersListLoading
export const selectOrdersYearsFilterOptionsLoading = (state) => state.orders.isYearsFilterOptionsLoading

export const {
  setYearAccordionActiveIndex,
  setMonthAccordionActiveIndex,
  setSortAccordionActiveIndex,
  setOrderFilter,
  toggleTableSort,
  changeOrdersPageProducts,
  setOrderCopyModalOpen,
  disposeCopyOrderModal,
  disposePage
} = ordersSlice.actions

export default ordersSlice.reducer
