import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const EditIcon = ({ color = "#333333" }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={`${pxToRem(17)}rem`}
    height={`${pxToRem(16)}rem`}
    viewBox='0 0 17 16'
    fill='none'
  >
    <path
      d='M2.5 11L6 14.5M2.5 11L1.53365 14.2212C1.3048 14.984 2.01602 15.6952 2.77882 15.4664L6 14.5M2.5 11L10.2116 3.28843M6 14.5L13.7116 6.78843M10.2116 3.28843L11.7929 1.70711C12.1834 1.31658 12.8166 1.31658 13.2071 1.70711L15.2929 3.79289C15.6834 4.18342 15.6834 4.81658 15.2929 5.20711L13.7116 6.78843M10.2116 3.28843L13.7116 6.78843'
      stroke={color}
    />
  </svg>
)

EditIcon.propTypes = {
  color: PropTypes.string
}

export default EditIcon
