/* eslint-disable no-restricted-globals */
const format = {
  en: "en-EN",
  de: "de-DE"
}
export const numberFormat = (num, min = 0, max = 2, currency = null) => {
  if (isNaN(num)) return num
  if (Number(num) === 0) return 0

  const lang = localStorage.getItem("locale")
  const formatted = new Intl.NumberFormat(format[lang], {
    minimumFractionDigits: min,
    maximumFractionDigits: max
  }).format(num)

  return currency ? `${formatted} ${currency}` : formatted
}
