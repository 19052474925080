import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const FileIcon = ({ color, strokeWidth, v2, ...props }) => {
  if (v2) {
    return (
      <svg
        width={`${pxToRem(17)}rem`}
        height={`${pxToRem(21)}rem`}
        viewBox='0 0 17 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M11 0.5H2.875C1.83947 0.5 1 1.39543 1 2.5V18.5C1 19.6046 1.83947 20.5 2.875 20.5H14.125C15.1605 20.5 16 19.6046 16 18.5V5.9054M11 0.5L16 5.9054M11 0.5V3.90541C11 5.00998 11.8395 5.9054 12.875 5.9054H16M12.75 8.65176L4.25 8.65176M8.49999 11.355L8.49999 17.6523M8.49999 17.6523L5.49999 14.4091M8.49999 17.6523L11.5 14.4091'
          stroke={color || "#00202D"}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )
  }
  return (
    <svg
      width={`${pxToRem(17)}rem`}
      height={`${pxToRem(22)}rem`}
      viewBox='0 0 17 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.1667 1H2.5C1.39543 1 0.5 1.89543 0.5 3V19C0.5 20.1046 1.39543 21 2.5 21H14.5C15.6046 21 16.5 20.1046 16.5 19V6.40541M11.1667 1L16.5 6.40541M11.1667 1V4.40541C11.1667 5.50998 12.0621 6.40541 13.1667 6.40541H16.5M8.5 7.48649V14.7838M8.5 14.7838L5.3 11.5405M8.5 14.7838L11.7 11.5405M3.96667 17.4865H13.0333'
        stroke={color || "#00202D"}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

FileIcon.propTypes = {
  color: PropTypes.string,
  v2: PropTypes.bool,
  strokeWidth: PropTypes.number
}

export default FileIcon
