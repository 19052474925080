import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const UserIcon = ({ color, strokeWidth, ...props }) => (
  <svg
    width={`${pxToRem(27)}rem`}
    height={`${pxToRem(27)}rem`}
    viewBox='0 0 27 27'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M1 26C1 18.9902 6.59644 15.8077 13.5 15.8077M13.5 15.8077C20.4036 15.8077 26 18.9902 26 26M13.5 15.8077C17.5271 15.8077 20.7917 12.4929 20.7917 8.40385C20.7917 4.31481 17.5271 1 13.5 1C9.47292 1 6.20833 4.31481 6.20833 8.40385C6.20833 12.4929 9.47292 15.8077 13.5 15.8077Z'
      stroke={color || "#333"}
      strokeWidth={strokeWidth || 2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

UserIcon.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number
}

export default UserIcon
