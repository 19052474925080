import React, { memo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import BagIcon from "../../assets/icons/BagIcon"
import { NOOP } from "../../constants/noop"

import styles from "./index.module.scss"

const CartBadge = ({ count, onClick = NOOP }) => {
  return (
    <button className={cx(styles.box, {})} type='button' onClick={onClick}>
      <BagIcon />
      {!!count && <div className={styles.count}>{count > 999 ? 999 : count}</div>}
    </button>
  )
}

CartBadge.propTypes = {
  count: PropTypes.number,
  onClick: PropTypes.func
}

export default memo(CartBadge)
