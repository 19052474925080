import React, { memo } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { NOOP } from "../../../../constants/noop"
import { useTranslate } from "@/utils/hooks/useTranslate"
import Counter, { CounterDirectionEnum } from "../../../Counter"
import FallbackImg from "../../../FallbackImg"
import { selectCurrentLocale } from "../../../../store/slice/translations"
import { PRODUCT_CARD_FALLBACK_IMAGE_URLS } from "../../../../constants/fallback-image"
import { numberFormat } from "../../../../utils/number-format"

import CloseIcon from "../../../../assets/icons/CloseIcon"

import styles from "./index.module.scss"

const ProductBasketCardMobile = ({
  id,
  image,
  name,
  count,
  number,
  summ,
  totalSumm,
  interactive,
  gk,
  ve,
  currency,
  deleteHandler = NOOP,
  changeHandler = NOOP,
  horizontal = true,
  fullBorder,
  packInfo,
  visible
}) => {
  const { t } = useTranslate()
  const locale = useSelector(selectCurrentLocale)

  return (
    <div
      className={cx(styles.box, {
        [styles.interactive]: interactive,
        [styles.fullBorder]: interactive,
        [styles.visible]: visible
      })}
    >
      <div className={styles.box__inner}>
        <div className={styles.image}>
          <FallbackImg src={image} alt={name} fallback={PRODUCT_CARD_FALLBACK_IMAGE_URLS[locale]} />
          {interactive && (
            <div className={styles.delete}>
              <button onClick={deleteHandler} type='button'>
                <CloseIcon />
              </button>
            </div>
          )}
        </div>
        <div className={styles.box__group}>
          {visible ? (
            <Link to={`/products/${id}`} className={styles.name}>
              {name}
            </Link>
          ) : (
            <div className={styles.name}>{name}</div>
          )}
          {packInfo && (
            <div className={styles.pack}>
              VE:{ve} | GK: {gk}
            </div>
          )}
          <div className={styles["box__group-sub"]}>
            <div>
              <span className={styles.title}>{t["order-article-number"]}</span>
              <span className={styles.desc}>{number}</span>
            </div>
            {!interactive && (
              <div>
                <span className={styles.title}>{t["order-product-count"]}</span>
                <span className={styles.desc}>{count}</span>
              </div>
            )}
            <div>
              <span className={styles.title}>{t["order-product-total-amount"]}</span>
              <span className={styles.desc}>{numberFormat(totalSumm, 2, 2, currency) || t["product-price-on-request"]}</span>
            </div>
          </div>
        </div>
        {interactive && (
          <div className={styles.counter}>
            <Counter
              disabled={!visible}
              count={count}
              onChange={changeHandler}
              size='sm'
              direction={CounterDirectionEnum.VERTICAL}
              gk={gk}
              ve={ve}
              step={ve}
              min={1}
            />
          </div>
        )}
      </div>
    </div>
  )
}

ProductBasketCardMobile.propTypes = {
  id: PropTypes.string,
  image: PropTypes.string,
  number: PropTypes.string,
  summ: PropTypes.number,
  name: PropTypes.string,
  count: PropTypes.string,
  gk: PropTypes.number,
  ve: PropTypes.number,
  totalSumm: PropTypes.number,
  visible: PropTypes.number,
  currency: PropTypes.string,
  horizontal: PropTypes.bool,
  fullBorder: PropTypes.bool,
  packInfo: PropTypes.bool,
  interactive: PropTypes.bool,
  deleteHandler: PropTypes.func,
  changeHandler: PropTypes.func
}

export default memo(ProductBasketCardMobile)
