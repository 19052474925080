/* eslint-disable react/prop-types */
import cx from "classnames"
import MaskInput from "../../../../components/MaskInput"
import TextAreaInput from "../../../../components/TextAreaInput"
import { NOOP } from "../../../../constants/noop"
import { useTranslate } from "../../../../utils/hooks/useTranslate"
import { dateFormatInput, dateFormatReq } from "../../../../utils/date-format"

import WarningIcon from "../../../../assets/icons/WarningIcon"

import styles from "./index.module.scss"

const Col = ({ title, children, alignCenter, required, formId }) => (
  <div
    className={cx(styles.col, {
      [styles.center]: alignCenter,
      [styles.required]: required
    })}
  >
    <div className={styles.col__title}>{title}</div>
    <div className={styles.col__content}>{children}</div>
  </div>
)

const DesktopTable = ({
  formik,
  formId,
  /* TODO: Not in use */
  // methodAccordion,
  // methodOptions,
  paymentMethod,
  paymentAddress,
  deliveryAddress,
  handleKeyPress = NOOP,
  /* TODO: Not in use */
  // setShippingAccordionActive = NOOP,
  // setShippingAccordionValue = NOOP,
  toggleAddressModal = NOOP,
  toggleAddressCreateModal = NOOP,
  updateCartInfo = NOOP
}) => {
  const { t } = useTranslate()

  return (
    <div className={styles.table}>
      <div className={styles.row}>
        {/* TODO: Not in use */}
        {/* <Col title={t["order-delivery-method"]} required>
          <div className={styles.select}>
            <Accordion
              size='sm'
              activeIndex={methodAccordion?.activeIndex}
              collapseOnBlur
              onOpen={(i) => {
                setShippingAccordionActive(i)
              }}
              list={[
                {
                  title: methodAccordion?.selected?.label || t["shipping-type-placeholder"],
                  content: (
                    <AccordionPanel
                      size='sm-light'
                      hover
                      background='#ffffff'
                      data={methodOptions?.map((item, index) => {
                        return (
                          <button
                            type='button'
                            key={index}
                            onClick={() => {
                              setShippingAccordionActive(-1)
                              setShippingAccordionValue(item)
                            }}
                          >
                            {item.label}
                          </button>
                        )
                      })}
                    />
                  )
                }
              ]}
            />
          </div>
        </Col> */}
        <Col title={t["whish-data-label"]} required>
          <form id={formId} className={styles.form} noValidate autoComplete='off' onSubmit={formik.handleSubmit}>
            <MaskInput
              placeholder={t["date-input-placeholder"]}
              required
              name='date'
              size='sm'
              value={formik?.values?.date}
              errors={!!formik?.errors?.date && !!formik?.touched?.date}
              onChange={(e) => formik?.setFieldValue("date", e.target.value)}
              onFocus={() => formik?.setFieldTouched("date", false)}
              onBlur={(e) => {
                const formatted = dateFormatInput(e.target.value)
                formik?.setFieldValue("date", formatted)
                formik?.setFieldTouched("date", true)
                updateCartInfo({ date: dateFormatReq(formatted) })
              }}
              onKeyPress={handleKeyPress}
            />
            <div
              className={cx(styles.form__alert, {
                [styles.visible]: !!formik?.errors?.date && !!formik?.touched?.date
              })}
            >
              <WarningIcon />
              {formik?.errors?.date}
            </div>
          </form>
        </Col>
        <Col title={t["order-payment-method"]}>{paymentMethod}</Col>
        <Col alignCenter />
      </div>
      <div className={styles.row}>
        <Col title={t["order-cart-link"]}>
          <TextAreaInput
            placeholder={t["text-area-placeholder"]}
            value={formik?.values?.link}
            errors={!!formik?.errors?.link && !!formik?.touched?.link ? formik?.errors?.link : null}
            onChange={(e) => formik?.setFieldValue("link", e.target.value)}
            onFocus={() => formik?.setFieldTouched("link", false)}
            onBlur={() => {
              formik?.setFieldTouched("link", true)
              updateCartInfo()
            }}
            onKeyPress={handleKeyPress}
          />
        </Col>
        <Col title={t["order-comment"]}>
          <TextAreaInput
            placeholder={t["text-area-placeholder"]}
            value={formik?.values?.comment}
            errors={!!formik?.errors?.comment && !!formik?.touched?.comment ? formik?.errors?.comment : null}
            onChange={(e) => formik?.setFieldValue("comment", e.target.value)}
            onFocus={() => formik?.setFieldTouched("comment", false)}
            onBlur={() => {
              formik?.setFieldTouched("comment", true)
              updateCartInfo()
            }}
            onKeyPress={handleKeyPress}
          />
        </Col>
        <Col title={t["order-payment-address"]}>
          <div className={styles.address}>
            <span>{paymentAddress.info_1}</span>
            <span>{paymentAddress.info_2}</span>
            <span>{paymentAddress.info_3}</span>
            <span>{paymentAddress.street}</span>
            <span>{paymentAddress.index}</span>
            <span>{paymentAddress.city}</span>
            <span>{paymentAddress.land}</span>
          </div>
        </Col>
        <Col title={t["order-delivery-address"]}>
          <>
            <div className={styles.address}>
              <span>{deliveryAddress.info_1}</span>
              <span>{deliveryAddress.info_2}</span>
              <span>{deliveryAddress.info_3}</span>
              <span>{deliveryAddress.street}</span>
              <span>{deliveryAddress.index}</span>
              <span>{deliveryAddress.city}</span>
              <span>{deliveryAddress.land}</span>
            </div>
            <div className={styles["other-btn"]}>
              <button className={styles["other-btn"]} type='button' onClick={toggleAddressModal}>
                <span dangerouslySetInnerHTML={{ __html: t["choose-others"] }} />
              </button>
            </div>
          </>
        </Col>
      </div>
    </div>
  )
}

export default DesktopTable
