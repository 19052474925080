import { toast } from "react-toastify"
import { TOAST_DEFAULT_CONFIG } from "../constants/toast"
import { downloadURI } from "./download-uri"

export const downloadFileByFetchUrl = async (callback, options, name) => {
  try {
    const res = await callback
    if (res.status === 200) {
      if (res.data) {
        return downloadURI(`${options},${encodeURIComponent(res.data)}`, name)
      }
    }
    return null
  } catch (err) {
    toast.error(err, TOAST_DEFAULT_CONFIG)
    return null
  }
}
