/* eslint-disable camelcase */
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { useTranslate } from "../../../utils/hooks/useTranslate"
import ProductBasketCard from "../../../components/Cards/ProductBasketCard"
import { deleteCartProduct, getCartProductCount, selectCartData, updateCartProductCount } from "../cart.controller"
import { CurrencyEnum } from "../../../constants/currency"
import { numberFormat } from "../../../utils/number-format"
import { TOAST_DEFAULT_CONFIG } from "../../../constants/toast"
import { responseErrorParser } from "../../../utils/response-error-parser"

import styles from "./index.module.scss"

const OrderList = () => {
  const { t } = useTranslate()
  const dispatch = useDispatch()

  const { products, total, currency } = useSelector(selectCartData)

  const changeProductCountHandler = (id, val) => {
    dispatch(updateCartProductCount({ id, quantity: val }))
      .unwrap()
      .then((res) => {
        const { is_optimize, message } = res.data || {}

        if (is_optimize) {
          toast.warning(<span dangerouslySetInnerHTML={{ __html: message }} />, TOAST_DEFAULT_CONFIG)
        }
      })
      .catch((err) => toast.error(responseErrorParser(err), TOAST_DEFAULT_CONFIG))
  }

  const deleteProductHandler = (id) => {
    dispatch(deleteCartProduct(id))
      .unwrap()
      .then(() => {
        dispatch(getCartProductCount())
      })
      .catch((err) => toast.error(responseErrorParser(err), TOAST_DEFAULT_CONFIG))
  }

  return (
    <div className={styles.box}>
      <div className={styles.title} dangerouslySetInnerHTML={{ __html: t["shop-cart-article"] }} />
      <div className={styles.table}>
        <div className={styles.table__header}>
          <span>{t["order-article-number"]}</span>
          <span>{t["order-article-name"]}</span>
          <span>{t["order-product-count"]}</span>
          <span>{t["order-product-amount"]}</span>
          <span>{t["order-product-total-amount"]}</span>
        </div>
        <div className={styles.table__body}>
          {products.map(({ image, title, quantity, article, price, total, id, gk, ve, visible }, index) => {
            return (
              <ProductBasketCard
                key={`${id}-${index}`}
                id={id}
                image={image}
                name={title}
                count={quantity}
                number={article}
                summ={price}
                currency={CurrencyEnum.EUR}
                totalSumm={total}
                gk={gk}
                ve={ve}
                packInfo
                fullBorder
                interactive
                changeHandler={(v) => changeProductCountHandler(id, v)}
                deleteHandler={() => deleteProductHandler(id)}
                visible={visible}
              />
            )
          })}
        </div>
        <div className={styles.table__footer}>
          <span className={styles["table__footer-title"]}>{t["order-final-summ"]}</span>
          <span className={styles["table__footer-summ"]}>
            {numberFormat(total, 2)} {currency}
          </span>
        </div>
      </div>
    </div>
  )
}

export default OrderList
