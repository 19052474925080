import React from "react"
import PropTypes from "prop-types"

import styles from "./index.module.scss"

const Container = ({ children, className }) => <div className={`${className} ${styles.container}`}>{children}</div>

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default Container
