/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React from "react"
import ContentBlock1 from "./ContentBlock1"
import { dataMutation } from "./utils/data.mutation"
import AuthBanner from "../AuthBanner"
import TextBlock from "./TextBlock"

const Components = {
  MAIN_HEADER: AuthBanner,
  BRAND_SLIDER: ContentBlock1,
  TEXT_BLOCK: TextBlock
}

// TODO: BAD PAGESPEED??
// const getComponent = (key) => {
//   switch (key) {
//     case 'helpMeBlock':
//       return dynamic(() => import('./helpMeBlock'))
//     default:
//       break
//   }
// }

export default function DynamicComponents({ content = [], ...other }) {
  // const customDynamicBlocks = dynamicBlocks.length
  //   ? dynamicBlocks
  //   : [{ sys: { contentType: { sys: { id: 'emptyBlock' } } } }]
  // const newBlocks = [...blocks, ...customDynamicBlocks]

  const newBlocks = [...content]

  return (
    <div className='dynamic-components'>
      {newBlocks.map((item) => {
        const mutatedData = dataMutation(item)
        // TODO: Static import
        const Component = Components[item.code]
        // TODO: Dynamic import
        // const Component = getComponent(item.code)
        if (Component) return <Component key={item.code} id={item.code} {...mutatedData} {...other} />
        return null
      })}
    </div>
  )
}
