/* eslint-disable react/prop-types */
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { selectIsAuth } from "../store/slice/auth"

const UnauthorizedRoute = ({ children, ...props }) => {
  const auth = useSelector(selectIsAuth)

  if (auth) {
    return props.redirect ? <Navigate to={props.redirect} /> : <Navigate to='/404' />
  }

  return children
}
export default UnauthorizedRoute
