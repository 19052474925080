export const breadcrumbsApi = [
  {
    url: "/profile",
    label: "Mein Profi"
  },
  {
    url: "/profile/orders",
    label: "Bestellungen"
  },
  {
    url: "/profile/orders/1",
    label: "OO-10588"
  }
]

export const orderDataApi = {
  id: "1",
  number: "OO-10588",
  delivery: "Luftfracht",
  desiredDeliveryDate: "01.06.2024",
  paymentMethod: "Vereinbarte Konditionen",
  link: "Lorem Ipsum amet",
  comment:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. ",
  paymentAddress: "TITANIA® FABRIK GMBH Hauptsitz Fortunastraße 10, Wülfrath, Nordrhein-Westfalen 42489, Deutschland",
  deliveryAddress: "TITANIA® FABRIK GMBH Hauptsitz Fortunastraße 10, Wülfrath, Nordrhein-Westfalen 42489, Deutschland",
  deliveryDate: "12.01.24",
  summ: "2.356€",
  name: "Max Mustermann",
  mail: "j.hollefeld@stepstoft.de",
  downloadLink: "/",
  products: [
    {
      id: "1",
      image: "/img/products/p-1.png",
      name: "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel...",
      count: 10,
      ve: 12,
      gk: 80,
      number: "1013 Set",
      summ: 3.99,
      totalSumm: 3.99,
      currency: "EUR"
    },
    {
      id: "12",
      image: "/img/products/p-1.png",
      name: "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel...",
      count: 122,
      ve: 12,
      gk: 80,
      number: "1013 Set",
      summ: 3.99,
      totalSumm: 3.99,
      currency: "EUR"
    },
    {
      id: "13",
      image: "/img/products/p-1.png",
      name: "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel...",
      count: 10,
      ve: 12,
      gk: 80,
      number: "1013 Set",
      summ: 3.99,
      totalSumm: 3.99,
      currency: "EUR"
    },
    {
      id: "14",
      image: "/img/products/p-1.png",
      name: "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel...",
      count: 10,
      ve: 12,
      gk: 80,
      number: "1013 Set",
      summ: 3.99,
      totalSumm: 3.99,
      currency: "EUR"
    },
    {
      id: "15",
      image: "/img/products/p-1.png",
      name: "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel...",
      count: 10,
      ve: 12,
      gk: 80,
      number: "1013 Set",
      summ: 3.99,
      totalSumm: 3.99,
      currency: "EUR"
    },
    {
      id: "16",
      image: "/img/products/p-1.png",
      name: "Handtaschenset 3 tlg., Bürste, Kamm, Spiegel...",
      count: 10,
      ve: 12,
      gk: 80,
      number: "1013 Set",
      summ: 3.99,
      totalSumm: 3.99,
      currency: "EUR"
    }
  ],
  total: 80.52,
  currency: "EUR",
  methodOptions: [
    {
      id: 1,
      label: "Standard",
      value: "1"
    },
    {
      id: 2,
      label: "Type 1",
      value: "2"
    },
    {
      id: 3,
      label: "Type 2",
      value: "3"
    }
  ],
  addressData: [
    {
      id: "1",
      title: "Musterfirma Ltd. 1",
      text: "Hauptsitz Fortunastraße 10, Wülfrath, Nordrhein-Westfalen 42489, Deutschland",
      checked: false
    },
    {
      id: "2",
      title: "Musterfirma Ltd. 2",
      text: "TITANIA® FABRIK GMBH Hauptsitz Fortunastraße 10, Wülfrath, Nordrhein-Westfalen 42489, Deutschland",
      checked: true
    }
  ]
}
