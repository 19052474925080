/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { memo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import PhoneInput from "react-phone-input-2"
import { NOOP } from "../../constants/noop"

import EditIcon from "../../assets/icons/Edit"

import "react-phone-input-2/lib/style.css"
import styles from "./index.module.scss"

const PhoneInputComponent = ({ id, label, value, disabled, errors, country, onChange = NOOP, isEdit = true }) => {
  const [isFocused, setIsFocused] = useState(false)
  const [valueInput, setValueInput] = useState(value)

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  useEffect(() => {
    setValueInput(value)
  }, [value])

  return (
    <div
      className={cx(styles.box, {
        [styles.disabled]: disabled,
        [styles.focused]: isFocused,
        [styles.error]: errors
      })}
    >
      <div className={cx(styles.box__inner)}>
        <div className={styles.field}>
          <span className={styles.title}>{label}</span>
          {isEdit ? (
            <PhoneInput
              onFocus={handleFocus}
              onBlur={handleBlur}
              buttonClass={styles.dropdown}
              inputClass={styles.input}
              country={country}
              value={valueInput}
              onChange={(phone) => {
                onChange(`+${phone}`)
                setValueInput(`+${phone}`)
              }}
            />
          ) : (
            <div className={styles.description}>{valueInput}</div>
          )}
          <EditIcon />
        </div>
      </div>
      {errors && <p className={styles.error}>{errors.message}</p>}
    </div>
  )
}

PhoneInputComponent.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  country: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.objectOf(
    PropTypes.shape({
      message: PropTypes.string
    })
  ),
  onChange: PropTypes.func,
  isEdit: PropTypes.bool
}

export default memo(PhoneInputComponent)
